import React from "react";
// used for making the prop types of this component
import PropTypes from "prop-types";

// reactstrap components
import { Button, Row, Col, FormGroup } from "reactstrap";

// core components
import defaultImage from "assets/img/image_placeholder.jpg";
import defaultAvatar from "assets/img/placeholder.jpg";

function ImageUpload(props) {
  const [fileState, setFileState] = React.useState(null);
  const [imagePreviewUrl, setImagePreviewUrl] = React.useState(
    props.avatar ? defaultAvatar : defaultImage
  );
  const fileInput = React.useRef();
  const handleImageChange = (e) => {
    e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];
    reader.onloadend = () => {
      setFileState(file);
      setImagePreviewUrl(reader.result);
    };
    if (file) {
      reader.readAsDataURL(file);
    }
  };
  // eslint-disable-next-line
  const handleSubmit = (e) => {
    e.preventDefault();
    // fileState is the file/image uploaded
    // in this function you can save the image (fileState) on form submit
    // you have to call it yourself
  };
  const handleClick = () => {
    fileInput.current.click();
  };
  const handleRemove = () => {
    fileInput.current.value = null;
    setFileState(null);
    setImagePreviewUrl(props.avatar ? defaultAvatar : defaultImage);
  };
  return (
    
    <Row>
      <Col md="6">
      <FormGroup> 
        <input id="photo" type="file" onChange={handleImageChange} ref={fileInput} />
        <div className={"thumbnail" + (props.avatar ? " img-circle" : "")}>
          <img style={{"width":"80px","height":"auto"}} src={imagePreviewUrl} alt="..." />
        </div>
      </FormGroup>
      </Col>

      <Col md="6">
      <FormGroup>
        <div>
          {fileState === null ? (
            <Button style={{"background":"white","color":"black","border":"1px black solid"}} className="btn-round" onClick={() => handleClick()}>
              {props.avatar ? "Choose File" : "Choose File"}
            </Button>
          ) : (
            <span>
              <Button style={{"background":"black"}} className="btn-round" onClick={() => handleClick()}>
                Change
              </Button>
              {props.avatar ? <br /> : null}
              <Button style={{"background":"#ff3636"}}
                color="danger"
                className="btn-round"
                onClick={() => handleRemove()}
              >
                <i className="fa fa-times" />
                Remove
              </Button>
            </span>
          )}
        </div>
      </FormGroup>
      </Col>
    </Row>
  );
}

ImageUpload.propTypes = {
  avatar: PropTypes.bool
};

export default ImageUpload;
