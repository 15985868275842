import React, {Component} from "react";
import $ from "jquery";

import datepickerFactory from 'jquery-datepicker';
import TableFilter from "views/Components/TableFilter.js";
import axios from "axios";
import {base_api_url, default_start_date, default_end_date, defPaginPage, defPaginPerpage, toPopulatePage, replaceEndTime} from "variables/general.js";

import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Row,
  Col,
  Button
} from "reactstrap";

import PanelHeader from "components/PanelHeader/PanelHeader.js";

function overrideDtColor(){
  $(".paginate_button").attr("style","border-radius: 30px;background: #B6C9D5;color: #FFFFFF!important;");
  $(".paginate_button.previous").html("<i class='now-ui-icons arrows-1_minimal-left'></i>").attr("style","borderRadius: 0px;background: #fff;color: #000!important;font-weight:bold;");
  $(".paginate_button.next").html("<i class='now-ui-icons arrows-1_minimal-right'></i>").attr("style","borderRadius: 0px;background: #fff;color: #000!important;font-weight:bold;");
  $(".dt-buttons").attr("style","display:none");
}

function formatStandard(str){
 let date = new Date(str);
 date = date.toLocaleString();
 return date;
}

function getData(page, perpage, searchKey="") {

  let user_login = JSON.parse(localStorage.getItem('user_login'));

  const params = new Proxy(new URLSearchParams(window.location.search), {
    get: (searchParams, prop) => searchParams.get(prop),
  });

  $("#pagLink").removeClass("active");
  let curpage = Number(page) - 1;
  let fromDate = $('#startDate').val();
  let toDate = $("#endDate").val();

  if ($('#startDate').val()!=="") {
    fromDate = fromDate.split("/")
    fromDate = fromDate[2].substring(0, 4) + "-" + fromDate[1] + "-" + fromDate[0]
    fromDate = new Date(fromDate);
    fromDate = fromDate.toISOString();
  }

  if ($("#endDate").val()!=="") {
    toDate = toDate.split("/")
    toDate = toDate[2].substring(0, 4) + "-" + toDate[1] + "-" + toDate[0]
    toDate = new Date(toDate);
    toDate = toDate.toISOString().replace("T00:00:00",replaceEndTime);
  }
  
  let urlTarget = base_api_url + "/api/v1/notification/getNotificationsByReceiver?page=" + curpage + '&size=' + perpage + "&searchTerm=" + encodeURIComponent(searchKey.trim()) + "&method=Request&receiversId=" + user_login.reference_id + "&receiversRole=" + user_login.role + "&fromdate=" + fromDate + "&todate=" + toDate; 
  return axios.get(urlTarget).then(res => {
    let datas = res.data.response.content;
    let totalPage = Number(res.data.response.totalPages);

    showTable(datas);
    loopPagination(Number(page), totalPage);

    let total = $("tbody tr").length;
    let checked = $(".selectorRow:checked").length;
    $("#rowSelectedLabel").attr("placeholder", checked + " of " + total + " selected");

  })
}

function loopPagination(curPage, sizePage) {
  let paginContent = "";
  let prevPage = curPage - 1;
  let nextPage = curPage + 1;

  if (sizePage > 0) {
    paginContent = paginContent.concat(`<a data-perpage="${defPaginPerpage}" data-page="${prevPage}" class="prevPag pagLink arrowpag" href="#"><i class="now-ui-icons arrows-1_minimal-left"></i></a>`);

    // Determine the start and end of the page range
    let startPage = Math.max(1, curPage - 4);
    let endPage = Math.min(sizePage, startPage + 8);

    // Expand the range if fewer than 9 pages are shown
    while ((endPage - startPage) < 8 && (startPage > 1 || endPage < sizePage)) {
      if (startPage > 1) {
        startPage--;
      }
      if (endPage < sizePage) {
        endPage++;
      }
    }

    // Display the page links within the range
    for (let i = startPage; i <= endPage; i++) {
      if (i == curPage) {
        paginContent = paginContent.concat(`<a href="#" data-perpage="${defPaginPerpage}" data-page="${i}" class="pagLink active">${i}</a>`);
      } else {
        paginContent = paginContent.concat(`<a href="#" data-perpage="${defPaginPerpage}" data-page="${i}" class="pagLink">${i}</a>`);
      }
    }

    let haveless = "";

    // Add "..." at the end if there are more pages
    if (endPage < sizePage) {
      haveless = "<a style='background: white;color: #416D86!important;'> ... </a>";
    }

    paginContent = paginContent.concat(`${haveless}<a data-perpage="${defPaginPerpage}" data-page="${nextPage}" class="nextPag arrowpag pagLink" href="#"><i class="now-ui-icons arrows-1_minimal-right"></i></a>`);
  }

  setTimeout(function () {
    if (curPage == 1) {
      $(".prevPag").hide();
    } else {
      $(".prevPag").show();
    }

    if (curPage == sizePage) {
      $(".nextPag").hide();
    } else {
      $(".nextPag").show();
    }
  }, toPopulatePage);

  $(".pagination").html(paginContent);
}

function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

function showTable(datas) {

  let segments = window.location.pathname.split('/');
  let section = segments[4];

  let currentUrl = window.location.origin + "/coordinator/requests";

  let tableContent = "";
  $("#tbody").empty();

  let iconBell;
  let detailPage;

  if (datas.length>0) {
    for (let i=0;i<datas.length;i++) {

      if (datas[i].isread==true) {
        iconBell = "";
      } else {
        iconBell = "<i style='position:relative;top:5px;left:5px;' class='now-ui-icons cs-mail'></i>";
      }

      if (datas[i].formtype=="tpf" || datas[i].formtype=="Travel Permission Form") {
        detailPage = window.location.origin + "/coordinator/requests/travel-permission-form/";
      } else if (datas[i].formtype=="apf" || datas[i].formtype=="Activity Permission Form") {
        detailPage = window.location.origin + "/coordinator/requests/activity-permission-form/";
      } else if (datas[i].formtype=="photographic" || datas[i].formtype=="videography") {
        detailPage = window.location.origin + "/coordinator/requests/photograpic/";
      } else {
        detailPage = window.location.origin + "/coordinator/coordinator/"+datas[i].formtype+"/";
      }

      tableContent = tableContent.concat(`<tr>
            <td style="padding-left: 15px;padding-right:10px;width:4%;fontStyle: normal;fontWeight: 400;fontSize: 14px,lineHeight: 20px" className="td-noborder"><input type="checkbox" name="selectorRow" class="selectorRow"></td>
            <td class="td-noborder">${capitalizeFirstLetter(datas[i].formtype) + " " + iconBell}</td>
            <td class="td-noborder">${datas[i].submittersname}</td>
            <td class="td-noborder">${formatStandard(datas[i].timestamp)}</td>
            <td style="width:17%" class="td-noborder">
              <a style="color:#F9B933;fontStyle:normal;fontWeight: 400;fontSize: 12px;lineHeight: 16px" href=${detailPage + datas[i].id + "?id=" + datas[i].id + "&formid=" + datas[i].fkformid}><i style="position: relative;top:8px" class="now-ui-icons cs-ov-edit-pen"></i>Views Detail</a>
            </td>
          </tr>`);
    }
    $(".containerPagination").show();
  } else {
    tableContent = tableContent.concat(`<tr><td></td><td>Not found</td><td></td><td></td></tr>`);
    $(".containerPagination").hide();
  }

  $("#tbody").html(tableContent);

}

class Request extends React.Component {
  
  state = {
    datas: [],
    page:defPaginPage,
    perpage:defPaginPerpage,
    url:"",
    totalPage:0
  }

  componentDidMount(){
    overrideDtColor();
    datepickerFactory($);
    $('#startDate').datepicker({dateFormat:"dd/mm/yyyy"});
    $('#endDate').datepicker({dateFormat:"dd/mm/yyyy"});
    $("#startDate").val(default_start_date);
    $('#endDate').val(default_end_date);
    getData(defPaginPage, defPaginPerpage);

    $(document).on("click",".pagination a.pagLink",function(e){
      e.preventDefault();
      let page = $(this).data('page');
      let perpage = defPaginPerpage
      let searchKey = $("#dtSearchCs").val();
      getData(Number(page),Number(perpage), searchKey);
    });

    $(document).on("click","#submitSearch",function(e){
      e.preventDefault();
      let page = defPaginPage;
      let perpage = defPaginPerpage;
      let searchKey = $("#dtSearchCs").val();
      getData(Number(page),Number(perpage),searchKey);
    });

    $(document).on("change",".selectorRowAll",function(){
      if(this.checked) {
        $(".selectorRow").prop("checked", true);
      } else {
        $(".selectorRow").prop("checked", false);
      }
      let total = $("tbody tr").length;
      let checked = $(".selectorRow:checked").length;
      $("#rowSelectedLabel").attr("placeholder", checked + " of " + total + " selected");
    });

    $(document).on("change",".selectorRow",function(){
      let total = $("tbody tr").length;
      let checked = $(".selectorRow:checked").length;
      $("#rowSelectedLabel").attr("placeholder", checked + " of " + total + " selected");
    });
  }

  render(){
    return(
    <>
      <PanelHeader size="sm"
        content={
          <div className="content">
            <div className="header text-left">
              <span className="panelHeaderDesc">View and manage Request details.</span>
              <nav aria-label="breadcrumb" role="navigation">
                <ol class="breadcrumb">
                  <li class="breadcrumb-item"><a href="/coordinator/overview">Dashboard</a></li>
                  <li class="breadcrumb-item active" aria-current="page">Request</li>
                </ol>
              </nav>
            </div>
          </div>
        }
      />
      <br/>
      <div className="content">
        <Row>
          <Col xs={12} md={12}>
            <Card>
              <CardBody>
                <div className="table-responsive p-0 pb-2">
                  <TableFilter/>
                  <hr/>
                  <table id="table" style={{width:"100%"}} className="align-items-center justify-content-center mb-0">
                     <thead>
                        <tr>
                          <th style={{paddingLeft: "15px",borderTopLeftRadius:"30px",borderBottomLeftRadius:"30px"}}><input type="checkbox" name="selectorRow" className="selectorRowAll"/></th>
                          <th>Request Type</th>
                          <th>Sender</th>
                          <th>Date & Time</th>
                          <th></th>
                        </tr>
                     </thead>
                     <tbody id="tbody" className="tbodycs">
                     </tbody>
                  </table>
                  <div className="containerPagination" style={{width:"100%"}} align="center">
                    <br/>
                    <div className="pagination">
                    </div>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
    )
  }
}

export default Request;