import React from "react";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Label,
  FormGroup,
  Form,
  Input,
  FormText,
  Row,
  Col,
  Table,
  Collapse
} from "reactstrap";

// core components
import PanelHeader from "components/PanelHeader/PanelHeader.js";
import Switch from "react-bootstrap-switch";
import profileImg from "assets/upload/default_profile.png";
import ImageUpload from "components/CustomUpload/ImageUpload.js";
import axios from "axios";
import {base_api_url, file_api_url} from "variables/general.js";
import $ from "jquery";
import 'select2';
import 'select2/dist/css/select2.css';
import swal from 'sweetalert';

function uploadFile() {
  var bodyFormData = new FormData();
  let imageFile = $("#photo")[0].files[0];
  let user_login = JSON.parse(localStorage.getItem('user_login'));

  bodyFormData.append('file', imageFile);
  bodyFormData.append('username', user_login.email);
  
  axios({
    method: "post",
    url: file_api_url + "/api/v1/user/upload/file",
    data: bodyFormData,
    headers: { "Content-Type": "multipart/form-data" },
  }).then(function (response) {
    $("#s_photourl").val(response.data[0].path);
    submitData();
  });
}

function sendMail(name, email, coord_name, coord_email, coord_phone) {
  let data = JSON.stringify({
    "name": name,
    "email": email,
    "coord_name":coord_name,
    "coord_email":coord_email,
    "coord_phone":coord_phone
  });

  let config = {
    method: 'post',
    maxBodyLength: Infinity,
    url: file_api_url + '/api/v1/school/email/registration',
    headers: { 
      'Content-Type': 'application/json'
    },
    data : data
  };

  axios.request(config)
  .then((response) => {
    console.log(JSON.stringify(response.data));
  })
  .catch((error) => {
    console.log(error);
  });
}

function submitData() {

  let user_login = JSON.parse(localStorage.getItem('user_login'));
  var tzoffset = (new Date()).getTimezoneOffset() * 60000; //offset in milliseconds
  var localISOTime = (new Date(Date.now() - tzoffset)).toISOString().slice(0, -1);
  var timestamp = localISOTime;

  let pipeParam = "?coordsId=" + user_login.reference_id;

  let requestData = {
    activities: $("#school_activities").val().join(","),
    address: $("#school_address").val(),
    adminapprovalstatus: "PENDING",
    country: $("#school_country").val(),
    emailaddress: $("#school_email").val(),
    // fkhf: $("#hf_id").val(),
    fkcoord: user_login.reference_id,
    id: $("#school_id").val(),
    isactive: true,
    jackets: $("#school_jackets").val(),
    languages: $("#school_languages").val().join(","),
    mobile: $("#school_mobile").val(),
    name: $("#school_name").val(),
    photosharingagreedandread: 1,
    photourl: $("#s_photourl").val(),
    principalname: $("#school_principal_name").val(),
    school5thyear: $("#school_fifth_year").val(),
    school5thyearclasses: $("#school_fifth_year_classes").val().join(","),
    schoolpecentageyearcost: $("#school_fifth_year_cost").val(),
    timestamp: timestamp,
    town: $("#school_town").val(),
    tracksuits: $("#school_tracksuite").val(),
    transitionclasses: $("#school_transition_classes").val().join(","),
    transitioncost: $("#school_transition_cost").val(),
    transitionyear: $("#school_transition_year").val(),
    type: $("#school_type").val(),
    uniformdetails: $("#school_uniform_detail").val(),
    website: $("#school_website").val(),
    yearlyenrolementfees: $("#school_year_enrolement_fees").val()
  }

  let pipeHf = $("#hf_id").val().join("%7C");

  if (pipeHf.length>0){
    pipeParam = pipeParam + "&pipeSepratedHf=" + pipeHf;
  }

  return axios.post(base_api_url + "/api/v1/school/addSchoolByCoord" + pipeParam, requestData).then(res => {
    sendMail($("#school_name").val(), $("#school_email").val(), $("#coordinator_name").val(), $("#coordinator_email").val());
    swal("Success","Successfully create data!","success");
    setTimeout(function(){
      window.location.href = window.location.origin + "/coordinator/school";
    },500);
  }).catch(function (error) {
    swal("Failed!", "Something went wrong!", "error");
  });
}

function checkEmailExist(elem) {
  if (elem.val()) {
    var settings = {
      "url": base_api_url + "/api/v1/authentication/isemailtaken",
      "method": "POST",
      "timeout": 0,
      "headers": {
        "Content-Type": "text/plain"
      },
      "data": elem.val(),
    };

    $.ajax(settings).done(function (response) {
      if (response.response==true) {
        elem.parent().append("<span class='errorCs'>Email already taken!</span>")
      } else {
        uploadFile();
      }
    });
  }
  return 0;
}

function getDataCoordinator() {
  let selEl = "#coordinator_id";

  return axios.get(base_api_url + '/api/v1/coord').then(res => {
    let datas = res.data.response;

    $(selEl).empty();
    $(selEl).append("<option value=''>Select</option>");
    
    for (var i=0;i<datas.length;i++) {
      $(selEl).append("<option data-company="+datas[i].company+" data-name="+datas[i].name+" value="+datas[i].id+">"+datas[i].name+"</option>");
    }

    let user_login = JSON.parse(localStorage.getItem('user_login'));
    $("#coordinator_id").val(user_login.reference_id).trigger("change");
    $("#coordinator_id").prop("disabled",true);

  })
}

function getDataHf() {
  let selEl = "#hf_id";

  return axios.get(base_api_url + '/api/v1/hf').then(res => {
    let datas = res.data.response;

    $(selEl).empty();
    $(selEl).append("<option value=''>Select</option>");
    for (var i=0;i<datas.length;i++) {
      $(selEl).append("<option data-familyname="+datas[i].familyname+" data-mothername="+datas[i].mothername+" data-datevisited="+datas[i].datevisited+" data-hostfamilychild="+datas[i].nohostfamilychild+" data-fathername="+datas[i].fathername+" data-address="+datas[i].address+" value="+datas[i].id+">"+datas[i].familyname+"</option>");
    }

  })

}

class SchoolRegistration extends React.Component {

  componentDidMount(){
    
    getDataCoordinator();
    getDataHf();

    $(document).on("click",".btn-csconfirm",function(e){
      e.preventDefault();
      
      let notValid = 0;
      let msgval = "";
      let cv;

      $(".errorCs").remove();

      if ($("#photo").val()==="") {
        $("#photo").parent().parent().append("<span class='errorCs'>File required!</span>");
      } else {

        $.each($('form input'), function(index, formField) {
          if ($(formField).val()==="") {
            cv = $(formField).parent().find("label").text();
            if (cv!=="" && $(formField).attr("type")!=="file" && $(formField).attr("type")!=="hidden"  && !$(formField).attr("readonly")) {
              msgval = cv + " is required! <br/>";
              $(formField).parent().append("<span class='errorCs'>" + msgval + "</span>");
              notValid += 1;
            }
          }
        });

        if (notValid>0) {
          //$("#msgValidation").show();
        } else {
          checkEmailExist($("#school_email"));
        }

      }
    });

    $(document).on("click",".close",function(){
      $(this).parent().parent().hide();
    });

    let select2Init = $(".select2-tc").select2({placeholder:"Transition Classes",closeOnSelect : false,
      allowHtml: true,
      allowClear: true,
      tags: false
    });

    $(document).on('change','select.select2-tc', function (e) {
      e.preventDefault(); 
      let counter = select2Init.select2('data').length;
      $(".select2Counter1").remove();
      if (counter>0) {
        $(this).parent().find(".select2-selection--multiple").append("<span class='select2Counter1'>"+counter+" selected</span>")
      }

      if ($(this).is(':selected')){
        //
      } else {
        //
      }
    });


    let select2Initfc = $(".select2-fc").select2({placeholder:"Fifth Classes",closeOnSelect : false,
      allowHtml: true,
      allowClear: true,
      tags: false
    });

    $(document).on('change','select.select2-fc', function (e) {
      e.preventDefault(); 
      let counter = select2Initfc.select2('data').length;
      $(".select2Counter2").remove();
      if (counter>0) {
        $(this).parent().find(".select2-selection--multiple").append("<span class='select2Counter2'>"+counter+" selected</span>")
      }

      if ($(this).is(':selected')){
        //
      } else {
        //
      }
    });


    let select2Initln = $(".select2-ln").select2({placeholder:"Languages",closeOnSelect : false,
      allowHtml: true,
      allowClear: true,
      tags: false
    });

    $(document).on('change','select.select2-ln', function (e) {
      e.preventDefault(); 
      let counter = select2Initln.select2('data').length;
      $(".select2Counter3").remove();
      if (counter>0) {
        $(this).parent().find(".select2-selection--multiple").append("<span class='select2Counter3'>"+counter+" selected</span>")
      }

      if ($(this).is(':selected')){
        //
      } else {
        //
      }
    });

    let select2Initac = $(".select2-ac").select2({placeholder:"Activies",closeOnSelect : false,
      allowHtml: true,
      allowClear: true,
      tags: false
    });

    $(document).on('change','select.select2-ac', function (e) {
      e.preventDefault(); 
      let counter = select2Initac.select2('data').length;
      $(".select2Counter4").remove();
      if (counter>0) {
        $(this).parent().find(".select2-selection--multiple").append("<span class='select2Counter4'>"+counter+" selected</span>")
      }

      if ($(this).is(':selected')){
        //
      } else {
        //
      }
    });

    $('#photo').change(function(){
      const file = this.files[0];
      if (file){
        $(".errorCs").remove();
        if (file.type=="image/jpeg" || file.type=="image/png") {
          let reader = new FileReader();
          reader.onload = function(event){
            $('#imgPreview').attr('src', event.target.result);
          }
          reader.readAsDataURL(file);
        } else {
          $('#photo').val("");
          $("#imgPreview").attr("src","");
          $('#photo').parent().parent().append("<span class='errorCs'>The image must be a file of type: jpg, jpeg, png.</span>");
        }
      }
    });

    $(document).on("change","#coordinator_id",function(e){
      $("#coordinator_name").val($(this).find(':selected').data('name'));
      $("#coordinator_company").val($(this).find(':selected').data('company'));
    });

    $(document).on("change","#hf_id",function(e){
      $("#hf_name").val($(this).find(':selected').data('familyname'));
    });

    $(document).on("change","#switch_school_jackets",function(){
      let val = String($(this).is(':checked'));

      if ($(this).is(':checked')==true) {
        $("#school_jackets").val("yes").trigger("change");
      } else {
        $("#school_jackets").val("no").trigger("change");
      }
    });

    $(document).on("change","#switch_school_tracksuite",function(){
      let val = String($(this).is(':checked'));

      if ($(this).is(':checked')==true) {
        $("#school_tracksuite").val("yes").trigger("change");
      } else {
        $("#school_tracksuite").val("no").trigger("change");
      }
    });

    let select2Inithf = $(".select2-hf").select2({placeholder:"Host Family",closeOnSelect : false,
      allowHtml: true,
      allowClear: true,
      tags: false
    });

    $(document).on('change','select.select2-hf', function (e) {
      e.preventDefault(); 
      let counter = select2Inithf.select2('data').length;
      $(".select2Counter6").remove();
      if (counter>0) {
        $(this).parent().find(".select2-selection--multiple").append("<span class='select2Counter6'>"+counter+" selected</span>")
      }

      if ($(this).is(':selected')){
        //
      } else {
        //
      }
    });

  }

  render(){
    return (
      <>
        <PanelHeader size="sm"
          content={
            <div className="content">
              <div className="header text-left">
                <span className="panelHeaderDesc">Add School here by inputting details.</span>
                <nav aria-label="breadcrumb" role="navigation">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item"><a href="/coordinator/overview">Dashboard</a></li>
                    <li className="breadcrumb-item"><a href="/coordinator/school">Schools</a></li>
                    <li className="breadcrumb-item active" aria-current="page">School Registration</li>
                  </ol>
                </nav>
              </div>
            </div>
          }
        />
        <br/>
        <div className="content">
          <Form name="formsubmit" id="formsubmit" action="/" className="form-horizontal" method="post">
            <Row>
              <Col md="3">
                <Card className="card-chart">
                  <CardBody>
                    <Table responsive>
                      <tbody>
                        <tr>
                          <td className="td-noborder">
                            <a href="#c_spil_details" className="anchorItemDetail">SPIL Details</a>
                            <a href="#c_school_details" className="anchorItemDetail">School Details</a>
                            <a href="#c_submissions" className="anchorItemDetail">Submissions</a>
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </CardBody>
                </Card>
              </Col>
              <Col md="9">
                <Card id="c_spil_details">
                  <CardHeader>
                    <CardTitle tag="h4" className="panelTitle">SPIL Details</CardTitle>
                  </CardHeader>
                  <CardBody>
                    <Row>
                      <Col sm="12">
                        <FormGroup>
                          <h4 style={{"fontFamily": "Noto Sans","fontStyle": "normal","fontWeight": 400,"fontSize": "16px","lineHeight": "24px","color": "#70B2D8"}}>School</h4>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm="12">
                        <Row>
                          <Col md="12">
                            <FormGroup>
                              <div className="form-floating">
                                <Input readonly="true" id="school_id" style={{"borderRadius": "5px"}} placeholder="ID" type="text" />
                                <label htmlFor="pwd">ID</label>
                              </div>
                            </FormGroup>
                          </Col>
                        </Row>
                      </Col>
                    </Row>

                    <Row>
                      <Col sm="12">
                        <FormGroup>
                          <h4 style={{"fontFamily": "Noto Sans","fontStyle": "normal","fontWeight": 400,"fontSize": "16px","lineHeight": "24px","color": "#70B2D8"}}>Coordinator</h4>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={6}>
                        <FormGroup>
                          <div className="form-floating">
                            <select placeholder="s" id="coordinator_id" style={{borderRadius:"5px"}} className="form-control">
                              <option value="">Select</option>
                            </select>
                            <label htmlFor="pwd">Coordinator</label>
                          </div>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={6}>
                        <FormGroup>
                          <div className="form-floating">
                            <Input readonly="true" id="coordinator_name" style={{"borderRadius": "5px"}} placeholder="Name" type="text" />
                            <label htmlFor="pwd">Name</label>
                          </div>
                        </FormGroup>
                      </Col>
                      <Col md={6}>
                        <FormGroup>
                          <div className="form-floating">
                            <Input readonly="true" id="coordinator_company" style={{"borderRadius": "5px"}} placeholder="Town" type="text" />
                            <label htmlFor="pwd">Company</label>
                          </div>
                        </FormGroup>
                      </Col>
                    </Row>
      
                    <Row>
                      <Col sm="12">
                        <FormGroup>
                          <h4 style={{"fontFamily": "Noto Sans","fontStyle": "normal","fontWeight": 400,"fontSize": "16px","lineHeight": "24px","color": "#70B2D8"}}>Host Family</h4>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={12}>
                        <FormGroup>
                          <select id="hf_id" className="select2-hf form-control" multiple style={{height:"58px",width:"50%","borderRadius":"5px"}}>
                          </select>
                        </FormGroup>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
                <Card id="c_school_details">
                  <CardHeader>
                    <CardTitle tag="h4" className="panelTitle">School Details</CardTitle>
                  </CardHeader>
                  <CardBody>
                    <Row>
                      <Col sm="12">
                        <FormGroup>
                          <h4 style={{"fontFamily": "Noto Sans","fontStyle": "normal","fontWeight": 400,"fontSize": "16px","lineHeight": "24px","color": "#70B2D8"}}>School</h4>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm="12">
                        <Row>
                          <Col md="6">
                            <FormGroup>
                              <div className="form-floating">
                                <Input id="school_name" style={{"borderRadius": "5px"}} placeholder="ID" type="text" />
                                <label htmlFor="pwd">Name</label>
                              </div>
                            </FormGroup>
                          </Col>
                          <Col md="6">
                            <FormGroup>
                              <div className="form-floating">
                                <Input id="school_country" style={{"borderRadius": "5px"}} placeholder="ID" type="text" />
                                <label htmlFor="pwd">Country</label>
                              </div>
                            </FormGroup>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm="12">
                        <Row>
                          <Col md="6">
                            <FormGroup>
                              <div className="form-floating">
                                <Input id="school_town" style={{"borderRadius": "5px"}} placeholder="ID" type="text" />
                                <label htmlFor="pwd">Town</label>
                              </div>
                            </FormGroup>
                          </Col>
                          <Col md="6">
                            <FormGroup>
                              <div className="form-floating">
                                <Input id="school_type" style={{"borderRadius": "5px"}} placeholder="ID" type="text" />
                                <label htmlFor="pwd">School Type</label>
                              </div>
                            </FormGroup>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm="12">
                        <Row>
                          <Col md="12">
                            <FormGroup>
                              <div className="form-floating">
                                <Input id="school_address" style={{"borderRadius": "5px"}} placeholder="ID" type="text" />
                                <label htmlFor="pwd">Adress</label>
                              </div>
                            </FormGroup>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm="12">
                        <Row>
                          <Col md="6">
                            <FormGroup>
                              <div className="form-floating">
                                <Input id="school_email" style={{"borderRadius": "5px"}} placeholder="ID" type="text" />
                                <label htmlFor="pwd">Email</label>
                              </div>
                            </FormGroup>
                          </Col>
                          <Col md="6">
                            <FormGroup>
                              <div className="form-floating">
                                <Input id="school_mobile" style={{"borderRadius": "5px"}} placeholder="ID" type="text" />
                                <label htmlFor="pwd">Phone</label>
                              </div>
                            </FormGroup>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm="12">
                        <Row>
                          <Col md="6">
                            <FormGroup>
                              <div className="form-floating">
                                <Input id="school_website" style={{"borderRadius": "5px"}} placeholder="ID" type="text" />
                                <label htmlFor="pwd">Website</label>
                              </div>
                            </FormGroup>
                          </Col>
                          <Col md="6">
                            <FormGroup>
                              <div className="form-floating">
                                <Input id="school_principal_name" style={{"borderRadius": "5px"}} placeholder="ID" type="text" />
                                <label htmlFor="pwd">Principal Name</label>
                              </div>
                            </FormGroup>
                          </Col>
                        </Row>
                      </Col>
                    </Row>

                    <Row>
                      <Col sm="12">
                        <Row>
                          <Col md="6">
                            <FormGroup>
                              <div className="form-floating">
                                <Input id="school_year_enrolement_fees" style={{"borderRadius": "5px"}} placeholder="ID" type="text" />
                                <label htmlFor="pwd">Yearly Enrolement Fees</label>
                              </div>
                            </FormGroup>
                          </Col>
                          <Col md="6">
                            <FormGroup>
                              
                            </FormGroup>
                          </Col>
                        </Row>
                      </Col>
                    </Row>

                    <Row>
                      <Col sm="6">
                        <FormGroup>
                          <h4 style={{"fontFamily": "Noto Sans","fontStyle": "normal","fontWeight": 400,"fontSize": "16px","lineHeight": "24px","color": "#70B2D8"}}>Transition Year</h4>
                        </FormGroup>
                      </Col>
                      <Col sm="6">
                        <FormGroup>
                          <h4 style={{"fontFamily": "Noto Sans","fontStyle": "normal","fontWeight": 400,"fontSize": "16px","lineHeight": "24px","color": "#70B2D8"}}>Fifth Year</h4>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm="12">
                        <Row>
                          <Col md="6">
                            <FormGroup>
                              <div className="form-floating">
                                <Input id="school_transition_year" style={{"borderRadius": "5px"}} placeholder="ID" type="text" />
                                <label htmlFor="pwd">Transition Year</label>
                              </div>
                            </FormGroup>
                          </Col>
                          <Col md="6">
                            <FormGroup>
                              <div className="form-floating">
                                <Input id="school_fifth_year" style={{"borderRadius": "5px"}} placeholder="ID" type="text" />
                                <label htmlFor="pwd">Fifth Year</label>
                              </div>
                            </FormGroup>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm="12">
                        <Row>
                          <Col md="6">
                            <FormGroup>
                              <div className="form-floating">
                                <Input id="school_transition_cost" style={{"borderRadius": "5px"}} placeholder="ID" type="number" />
                                <label htmlFor="pwd">Transition Year Cost</label>
                              </div>
                            </FormGroup>
                          </Col>
                          <Col md="6">
                            <FormGroup>
                              <div className="form-floating">
                                <Input id="school_fifth_year_cost" style={{"borderRadius": "5px"}} placeholder="ID" type="number" />
                                <label htmlFor="pwd">Fifth Year Cost</label>
                              </div>
                            </FormGroup>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm="12">
                        <Row>
                          <Col md="6">
                            <FormGroup>
                              <select id="school_transition_classes" className="select2-tc" multiple style={{width:"50%"}}>
                                <option>Math</option>
                                <option>English</option>
                                <option>Irish</option>
                                <option>Geopgraphy</option>
                                <option>History</option>
                                <option>French</option>
                                <option>German</option> 
                                <option>Spanish</option>
                                <option>Physics</option> 
                                <option>Biology</option> 
                                <option>Chemestry</option>
                                <option>Art</option> 
                                <option>Music</option> 
                                <option>Woodwork</option> 
                                <option>PE</option>
                                <option>Business Studies</option>
                                <option>Environmental studies</option>
                                <option>Chinese culture and language studies</option>
                                <option>Global development issues</option>
                                <option>Debating development</option>
                                <option>Tourism studies</option>
                                <option>Student safety in the workplace</option>
                                <option>Soap operas and popular culture</option>
                                <option>Food matters</option>
                                <option>Legal studies</option>
                                <option>Sports coaching</option>
                                <option>Introduction to genetics and biotechnology</option>
                                <option>Media and communications</option>
                                <option>Japanese studies</option>
                              </select>
                            </FormGroup>
                          </Col>
                          <Col md="6">
                            <FormGroup>
                              <select id="school_fifth_year_classes" className="select2-fc" multiple style={{width:"50%"}}>
                                <option>Maths</option>
                                <option>English</option>
                                <option>Irish</option>
                                <option>Geopgraphy</option>
                                <option>History</option>
                                <option>French</option>
                                <option>German</option>
                                <option>Spanish</option>
                                <option>Physics</option>
                                <option>Biology</option>
                                <option>Chemestry</option>
                                <option>Art</option>
                                <option>Music</option> 
                                <option>Woodwork</option> 
                                <option>PE</option>
                                <option>Business Studies</option>
                              </select>
                            </FormGroup>
                          </Col>
                        </Row>
                      </Col>
                    </Row>

                    <Row>
                      <Col sm="12">
                        <Row className="bundleBorder" style={{"height": "59px"}}>
                          <Col md="11">
                            <FormGroup>
                              <div className="form-floating">             
                                <label htmlFor="pwd">School Jackets</label>
                              </div>
                            </FormGroup>
                          </Col>
                          <Col md="1">
                            <div className="form-floating">    
                              <div class="material-switch">
                                  <input id="switch_school_jackets" type="checkbox"/>
                                  <label for="switch_school_jackets" className="label-primary"></label>
                              </div>
                              <Input type="hidden" id="school_jackets" value="no"/>
                            </div>
                          </Col>
                        </Row>
                      </Col>
                    </Row>

                    <Row>
                      <Col sm="12">
                        <Row className="bundleBorder" style={{"height": "59px"}}>
                          <Col md="11">
                            <FormGroup>
                              <div className="form-floating">             
                                <label htmlFor="pwd">Tracksuites</label>
                              </div>
                            </FormGroup>
                          </Col>
                          <Col md="1">
                            <div className="form-floating">    
                              <div class="material-switch">
                                  <input id="switch_school_tracksuite" type="checkbox"/>
                                  <label for="switch_school_tracksuite" className="label-primary"></label>
                              </div>
                              <Input type="hidden" id="school_tracksuite" value="no"/>
                            </div>
                          </Col>
                        </Row>
                      </Col>
                    </Row>

                    <Row>
                      <Col sm={12}>
                        <FormGroup>
                          <h4 style={{"fontFamily": "Noto Sans","fontStyle": "normal","fontWeight": 400,"fontSize": "16px","lineHeight": "24px","color": "#70B2D8"}}>Uniform</h4>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm="12">
                        <Row>
                          <Col md="12">
                            <FormGroup>
                              <div className="form-floating">
                                <Input id="school_uniform_detail" style={{"borderRadius": "5px"}} placeholder="ID" type="text" />
                                <label htmlFor="pwd">Uniform Details</label>
                              </div>
                            </FormGroup>
                          </Col>
                        </Row>
                      </Col>
                    </Row>


                    <Row>
                      <Col sm={6}>
                        <FormGroup>
                          <h4 style={{"fontFamily": "Noto Sans","fontStyle": "normal","fontWeight": 400,"fontSize": "16px","lineHeight": "24px","color": "#70B2D8"}}>Languages</h4>
                        </FormGroup>
                      </Col>
                      <Col sm={6}>
                        <FormGroup>
                          <h4 style={{"fontFamily": "Noto Sans","fontStyle": "normal","fontWeight": 400,"fontSize": "16px","lineHeight": "24px","color": "#70B2D8"}}>Activities</h4>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm="12">
                        <Row>
                          <Col md="6">
                            <FormGroup>
                              <select id="school_languages" className="select2-ln" multiple style={{width:"50%"}}>
                                <option>English</option>
                              </select>
                            </FormGroup>
                          </Col>
                          <Col md="6">
                            <FormGroup>
                              <select id="school_activities" className="select2-ac" multiple style={{width:"50%"}}>
                                <option>Sleep</option>
                              </select>
                            </FormGroup>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
                <Card id="c_submissions">
                  <CardHeader>
                    <CardTitle tag="h4" className="panelTitle">Submissions</CardTitle>
                  </CardHeader>
                  <CardBody>
                    <Row>
                      <Col sm="12">
                        <FormGroup>
                          <h4 style={{"fontFamily": "Noto Sans","fontStyle": "normal","fontWeight": 400,"fontSize": "16px","lineHeight": "24px","color": "#70B2D8"}}>Photo</h4>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm="12">
                        <Row className="bundleBorder">
                          <Col md="2">
                            <FormGroup>
                              <div className="form-floating">             
                                <label htmlFor="pwd">Thumbnail</label>
                              </div>
                            </FormGroup>
                          </Col>
                          <img id="imgPreview" style={{float: "left",width:"76px",height:"76px",position:"relative"}} src={profileImg}/>
                          <input style={{marginTop: "23px",marginLeft: "18px"}} type="file" id="photo"/>
                          <input value={{profileImg}} type="hidden" id="s_photourl"/>
                        </Row>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col md="3">
              </Col>
              <Col md="9">
                <Row>
                  <Col sm="12">
                    <div align="center">
                      <button style={{"background":"#416D86","color":"white"}} className="btn-csconfirm">Create School</button>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Form>
        </div>
      </>
    );
  }
}

export default SchoolRegistration;
