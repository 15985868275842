import React from "react";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Label,
  FormGroup,
  Form,
  Input,
  FormText,
  Row,
  Col,
  Table
} from "reactstrap";

// core components
import PanelHeader from "components/PanelHeader/PanelHeader.js";
import Switch from "react-bootstrap-switch";
import profileImg from "assets/upload/default.png";
import ImageUpload from "components/CustomUpload/ImageUpload.js";
import axios from "axios";
import {base_api_url, file_api_url} from "variables/general.js";
import $ from "jquery";
import swal from 'sweetalert';

const params = new Proxy(new URLSearchParams(window.location.search), {
  get: (searchParams, prop) => searchParams.get(prop),
});

function validateEmail(input) {
  var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
  if (input.match(validRegex)) {
    return true;
  }
  return false;
}

function checkPhoneExist(phone) {
  let validation = JSON.parse($.ajax({
      type: "POST",
      url: base_api_url + "/api/v1/authentication/isPhoneTaken",
      async: false,
      cache:false,
      data: {
          "phone": phone
      },
      success: function(result) {
          return result;
      },
  }).responseText);
  return validation.response;
}

function checkEmailExist(elem) {
  if (elem.val()) {
    var settings = {
      "url": base_api_url + "/api/v1/authentication/isemailtaken",
      "method": "POST",
      "timeout": 0,
      "headers": {
        "Content-Type": "text/plain"
      },
      "data": elem.val(),
    };

    $.ajax(settings).done(function (response) {
      if (response.response==true) {
        elem.parent().append("<span class='errorCs'>Email already taken!</span>")
      } else {
        uploadFile();
      }
    });
  }
  return 0;
}

function validatePhoneNumber(input_str) {
  var re = /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/;
  return re.test(input_str);
}

function getData() {
  const params = new Proxy(new URLSearchParams(window.location.search), {
    get: (searchParams, prop) => searchParams.get(prop),
  });

  let urlTarget = base_api_url + '/api/v1/coord/webgetcoordbyid?coordId=' + params.id; 
  return axios.get(urlTarget).then(res => {
    let datas = res.data.response;
    localStorage.setItem("detailCoord" + params.id, JSON.stringify(datas.coord));
    showData(datas);
  })
}

function uploadDocuments() {
  var bodyFormData = new FormData();
  let imageFile = $("#document_upload")[0].files[0];
  const params = new Proxy(new URLSearchParams(window.location.search), {
    get: (searchParams, prop) => searchParams.get(prop),
  });

  bodyFormData.append('file', imageFile);
  bodyFormData.append('username', "coord_id_"+params.id);

  axios({
    method: "post",
    url: file_api_url + "/api/v1/user/upload/file",
    data: bodyFormData,
    headers: { "Content-Type": "multipart/form-data" },
  }).then(function (response) {
    let pdfPath = response.data[0].path;

    if (pdfPath) {

      const parts = pdfPath.split("/");

      // Get the last part which contains the file name
      const fileNameWithExtension = parts[parts.length - 1];

      // Split the file name by "." to separate the name and extension
      const fileNameParts = fileNameWithExtension.split(".");
      let fileName = fileNameParts[0];
      const extension = fileNameParts[1];
      fileName = fileName + "." + extension;
      let user_login = JSON.parse(localStorage.getItem('user_login'));

      axios({
        method: "post",
        url: base_api_url + "/api/v1/file",
        data: {
          doctype: extension,
          filelocation: pdfPath,
          filename: fileName,
          fkassociation: 0,
          fkformtype: 0, 
          fkuserid: params.id, 
          fkuserrole: "coord",
          id: null,
          isactive: true,
          notes: "File Upload",
          uploadbyrole:"admin",
          uploadbyid:user_login.reference_id
        }
      }).then(function (response) {

        let fileId = response.data.response.id;

          $("#documents_lists").append(`<div class="bundleBorder row">
            <div class="col-md-3">
              <div class="form-group">
                <div class="form-floating">
                  <label for="pwd">${fileName}</label>
                </div>
              </div>
            </div>
            <div class="col-md-9" align="right">
              <div class="form-group">
                <a style="color: rgb(113, 113, 113); font-style: normal; font-weight: 400; font-size: 12px; line-height: 16px;" target="_blank" href="${pdfPath}"><i style="position: relative; top: 8px;" class="now-ui-icons cs-ov-edit-pen-dark"></i> Show File</a>
                <a data-id=${fileId} style="color:red;cursor:pointer;font-style: normal; font-weight: 400; font-size: 12px; line-height: 16px;" class="delDoc"><i style="position: relative; top: 2px;" class="now-ui-icons ui-1_simple-remove"></i> Remove</a>
                </div>
              </div>
            </div>`);

      });

    }
    
  });
}

function getDocumentsByUserIdAndRole(userid, role) {

  let user_login = JSON.parse(localStorage.getItem('user_login'));
  let uploadById = user_login.reference_id;
  let uploadByRole = "admin";
  
  axios({
    method: "GET",
    url: base_api_url + `/api/v1/file/getfilelist?userId=${userid}&userRole=${role}&uploadById=${uploadById}&uploadByRole=${uploadByRole}`
  }).then(function (response) {
    let datas = response.data.response;
    for (var i=0;i<datas.length;i++) {

      const parts = datas[i].filelocation.split("/");

      // Get the last part which contains the file name
      const fileNameWithExtension = parts[parts.length - 1];

      // Split the file name by "." to separate the name and extension
      const fileNameParts = fileNameWithExtension.split(".");
      let fileName = fileNameParts[0];
      const extension = fileNameParts[1];
      fileName = fileName + "." + extension;

      $("#documents_lists").append(`
        <div class="bundleBorder row">
          <div class="col-md-3">
            <div class="form-group">
              <div class="form-floating">
                <label for="pwd">${datas[i].filename}</label>
              </div>
            </div>
          </div>
          <div class="col-md-9" align="right">
            <div class="form-group">
              <a style="color: rgb(113, 113, 113); font-style: normal; font-weight: 400; font-size: 12px; line-height: 16px;" target="_blank" href="${datas[i].filelocation}"><i style="position: relative; top: 8px;" class="now-ui-icons cs-ov-edit-pen-dark"></i> Show File</a>
              <a data-id=${datas[i].id} style="color:red;cursor:pointer;font-style: normal; font-weight: 400; font-size: 12px; line-height: 16px;" class="delDoc"><i style="position: relative; top: 2px;" class="now-ui-icons ui-1_simple-remove"></i> Remove</a>
              </div>
            </div>
          </div>
      `);

    }
  });
}

function showData(data) {
  let schools = data.schools;
  data = data.coord;
  let selSchool = [];

  getDocumentsByUserIdAndRole(data.id, "coord");

  if (schools) {
    for (var i=0;i<schools.length;i++) {
      selSchool.push(schools[i].fkSchoolId);
    }
  }

  setTimeout(function(){
    $("#school_id").val(selSchool).trigger("change");
  },1500);

  $("#attachedHf").attr("href","/admin/coordinator/attached/host-family?id="+data.id)
  $("#attachedSchool").attr("href","/admin/coordinator/attached/school?id="+data.id)
  $("#coordinator_id").val(data.id);
  $("#br_name").html(data.name);
  $("#t_name").html(data.name);
  $("#t_id").html(data.id);
  $("#coordinator_name").val(data.name);
  $("#coordinator_company").val(data.company);
  $("#coordinator_email").val(data.emailaddress);
  $("#coordinator_address").val(data.address);
  $("#coordinator_telephone").val(data.telephone);
  $("#coordinator_mobile").val(data.mobile);
  $("#coordinator_language").val(data.company);
  if (data.photourl) {
    $("#profile").attr("src",data.photourl);
    $("#imgPreview").attr("src",data.photourl);
  }
  $("#c_photourl").val(data.photourl);
}

function uploadFile() {
  var bodyFormData = new FormData();
  let imageFile = $("#photo")[0].files[0];
  let user_login = JSON.parse(localStorage.getItem('user_login'));

  bodyFormData.append('file', imageFile);
  bodyFormData.append('username', user_login.email);

  axios({
    method: "post",
    url: file_api_url + "/api/v1/user/upload/file",
    data: bodyFormData,
    headers: { "Content-Type": "multipart/form-data" },
  }).then(function (response) {
    $("#c_photourl").val(response.data[0].path);
    submitData();
  });
}

function getDataSchool() {
  let selEl = "#school_id"; 
  return axios.get(base_api_url + '/api/v1/school').then(res => {
    let datas = res.data.response;

    $(selEl).empty();
    $(selEl).append("<option value=''>Select</option>");

    for (var i=0;i<datas.length;i++) {
      $(selEl).append("<option data-type="+datas[i].type+" data-town="+datas[i].town+" data-country="+datas[i].country+" data-name="+datas[i].name+" value="+datas[i].id+">"+datas[i].name+"</option>");
    }

  })
}

function submitData() {

  let user_login = JSON.parse(localStorage.getItem('user_login'));
  var date = new Date();
  var timestamp = date.toISOString();

  let requestData = JSON.parse(localStorage.getItem("detailCoord" + params.id));
  let pipeSchool = $("#school_id").val().join("%7C");
  let pipeParam = "";

  requestData["address"] = $("#coordinator_address").val();
  requestData["company"] = $("#coordinator_company").val();
  requestData["emailaddress"] = $("#coordinator_email").val();
  requestData["language"] = $("#coordinator_language").val();
  requestData["mobile"] = $("#coordinator_mobile").val();
  requestData["name"] = $("#coordinator_name").val();
  requestData["photourl"] = $("#c_photourl").val();
  requestData["telephone"] = $("#coordinator_telephone").val();
  requestData["timestamp"] = timestamp;

  if (pipeSchool.length>0){
    pipeParam = "?pipeSeparatedSchool=" + pipeSchool + "&role=" + user_login.role;
  } else {
    pipeParam = "?role=" + user_login.role;
  }

  return axios.put(base_api_url + "/api/v1/coord/updatebycoordid" + pipeParam, requestData).then(res => {
    swal("Success","Successfully updated data!","success");
    setTimeout(function(){
      window.location.reload();
    },500);
  }).catch(function (error) {
    swal("Failed!", "Something went wrong!", "error");
  });
}

class CoordinatorDetail extends React.Component {

  componentDidMount(){
    getDataSchool();
    getData()

    $(document).on("click",".btn-csconfirm",function(e){
      e.preventDefault();
      $(".errorCs").remove();
      
      let notValid = 0;
      let msgval = "";
      let cv;

      $.each($('form input'), function(index, formField) {
        if ($(formField).val()==="") {
          cv = $(formField).parent().find("label").text();
          if (cv!=="" && $(formField).attr("type")!=="file" && $(formField).attr("type")!=="hidden") {
            msgval = cv + " is required! <br/>";
            $(formField).parent().append("<span class='errorCs'>" + msgval + "</span>");
            notValid += 1;
          }
        }
      });

      let requestData = JSON.parse(localStorage.getItem("detailCoord" + params.id));

      if (requestData.emailaddress!==$("#coordinator_email").val()) {

        let checkValidateEmail = validateEmail($("#coordinator_email").val());

        if (!checkValidateEmail) {
          $("#coordinator_email").parent().append("<span class='errorCs'>Email format is invalid.</span>");
          notValid+=1;
        }

      }

      if (isNaN($("#coordinator_telephone").val())) {
        $("#coordinator_telephone").parent().append("<span class='errorCs'>Telephone must be numeric.</span>");
        notValid+=1;
      }

      if (isNaN($("#coordinator_mobile").val())) {
        $("#coordinator_mobile").parent().append("<span class='errorCs'>Mobile phone must be numeric.</span>");
        notValid+=1;
      }

      if (requestData.mobile!==$("#coordinator_mobile").val()) {
        let valmob = checkPhoneExist($("#coordinator_mobile").val());
        if (valmob==true) {
          $("#coordinator_mobile").parent().append("<span class='errorCs'>Mobile phone is exists.</span>");
          notValid+=1;
        }
      }

      if (notValid>0) {
        //$("#msgValidation").show();
      } else {
        if ($("#photo").val()=="") {
          submitData();
        } else {
          uploadFile();
        }
      }

    });

    $(document).on("click",".close",function(){
      $(this).parent().parent().hide();
    });

    $('#photo').change(function(){
      const file = this.files[0];
      if (file){
        $(".errorCs").remove();
        if (file.type=="image/jpeg" || file.type=="image/png") {
          let reader = new FileReader();
          reader.onload = function(event){
            $('#imgPreview').attr('src', event.target.result);
          }
          reader.readAsDataURL(file);
        } else {
          $('#photo').val("");
          $("#imgPreview").attr("src","");
          $('#photo').parent().parent().append("<span class='errorCs'>The image must be a file of type: jpg, jpeg, png.</span>");
        }
      }
    });

    let select2Init = $(".select2-tc").select2({placeholder:"School",closeOnSelect : false,
      allowHtml: true,
      allowClear: true,
      tags: false
    });

    $(document).on('change','select.select2-tc', function (e) {
      e.preventDefault(); 
      let counter = select2Init.select2('data').length;
      $(".select2Counter1").remove();
      if (counter>0) {
        $(this).parent().find(".select2-selection--multiple").append("<span class='select2Counter1'>"+counter+" selected</span>")
      }

      if ($(this).is(':selected')){
        //
      } else {
        //
      }
    });

    $(document).on("keyup","#coordinator_telephone",function(){
      let val = isNaN($(this).val());
      if (val) {
        $(this).val($(this).val().replace(/\D/g,''));
      }
    });

    $(document).on("keyup","#coordinator_mobile",function(){
      let val = isNaN($(this).val());
      if (val) {
        $(this).val($(this).val().replace(/\D/g,''));
      }
    });

    $(document).on("click",".uploadDoc",function(e){
      e.preventDefault();
      
      if ($("#document_upload").val()=="") {
        alert("Please upload the PDF/Image file!");
      } else {
        let ftype = $("#document_upload")[0].files[0].type;
        if (ftype!=="application/pdf"&&ftype!=="image/jpeg"&&ftype!=="image/png") {
          alert("Only PDF/Image file allowed!");
        } else {
          uploadDocuments();
        }
      }

    });

    $(document).on("click",".delDoc",function(e){
      e.preventDefault();

      let curPar = $(this).parent().parent().parent();
      let fileId = $(this).data("id");

      const params = new Proxy(new URLSearchParams(window.location.search), {
        get: (searchParams, prop) => searchParams.get(prop),
      });

      swal({
        title: "Are you sure?",
        text: "Are you sure that you want to delete this file ?",
        icon: "warning",
        buttons: ["Cancel", "Yes, delete it"],
      })
      .then(willDelete => {
        if (willDelete) {
          axios({
            method: "delete",
            url: base_api_url + `/api/v1/file/${fileId}/${params.id}/coord`
          }).then(function (response) {
              $(curPar).remove();
          });
        }
      });

    });

  }

  render(){
    return (
      <>
        <PanelHeader size="sm"
          content={
            <div className="content">
              <div className="header text-left">
                <span className="panelHeaderDesc">View and manage the Coordinator Details.</span>
                <nav aria-label="breadcrumb" role="navigation">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item"><a href="/admin/overview">Dashboard</a></li>
                    <li className="breadcrumb-item"><a href="/admin/coordinator">Coordinator</a></li>
                    <li className="breadcrumb-item active" aria-current="page" id="br_name"></li>
                  </ol>
                </nav>
              </div>
            </div>
          }
        />
        <br/>
        <div className="content">
          <Form name="formsubmit" id="formsubmit" action="/" className="form-horizontal" method="post">
            <Row>
              <Col md="3">
                <Card className="card-chart">
                  <CardBody>
                    <Table responsive>
                      <tbody>
                        <tr>
                          <td className="td-noborder">
                            <a href="#c_spil_details" className="anchorItemDetail">SPIL Details</a>
                            <a href="#c_coordinator_details" className="anchorItemDetail">Coordinator Details</a>
                            <a href="#c_submissions" className="anchorItemDetail">Submissions</a>
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </CardBody>
                </Card>
              </Col>
              <Col md="9">
                <Card>
                  <CardHeader className="card-header-profile">
                    <Row>
                      <Col md={2}>
                        <img id="profile" style={{float: "left",width:"76px",height:"76px",position:"relative",bottom:"18px"}} src={profileImg}></img>
                      </Col>
                      <Col md={4}>
                        <p className="profile-title" id="t_name"></p>
                        <p className="profile-desc" id="t_id"></p>
                      </Col>
                      <Col md={6} align="right">
                        <a className="viewAttached" id="attachedHf">View attached Host Families</a>&nbsp;&nbsp;&nbsp;<a className="viewAttached" id="attachedSchool">View attached Schools</a>
                      </Col>
                    </Row>
                  </CardHeader>
                </Card>
                <Card id="c_spil_details">
                  <CardHeader>
                    <CardTitle tag="h4" className="panelTitle">SPIL Details</CardTitle>
                  </CardHeader>
                  <CardBody>
                    <Row>
                      <Col sm="12">
                        <FormGroup>
                          <h4 style={{"fontFamily": "Noto Sans","fontStyle": "normal","fontWeight": 400,"fontSize": "16px","lineHeight": "24px","color": "#70B2D8"}}>Coordinator</h4>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm="12">
                        <Row>
                          <Col md="12">
                            <FormGroup>
                              <div className="form-floating">
                                <Input readonly="true" id="coordinator_id" style={{"borderRadius": "5px"}} placeholder="ID" type="text" />
                                <label htmlFor="pwd">ID</label>
                              </div>
                            </FormGroup>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
                <Card id="c_coordinator_details">
                  <CardHeader>
                    <CardTitle tag="h4" className="panelTitle">Coordinator Details</CardTitle>
                  </CardHeader>
                  <CardBody>
                    <Row>
                      <Col sm="12">
                        <FormGroup>
                          <h4 style={{"fontFamily": "Noto Sans","fontStyle": "normal","fontWeight": 400,"fontSize": "16px","lineHeight": "24px","color": "#70B2D8"}}>Coordinator</h4>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm="12">
                        <Row>
                          <Col md="6">
                            <FormGroup>
                              <div className="form-floating">
                                <Input id="coordinator_name" style={{"borderRadius": "5px"}} placeholder="ID" type="text" />
                                <label htmlFor="pwd">Name</label>
                              </div>
                            </FormGroup>
                          </Col>
                          <Col md="6">
                            <FormGroup>
                              <div className="form-floating">
                                <Input id="coordinator_company" style={{"borderRadius": "5px"}} placeholder="ID" type="text" />
                                <label htmlFor="pwd">Company</label>
                              </div>
                            </FormGroup>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm="12">
                        <Row>
                          <Col md="6">
                            <FormGroup>
                              <div className="form-floating">
                                <Input id="coordinator_email" style={{"borderRadius": "5px"}} placeholder="ID" type="text" />
                                <label htmlFor="pwd">Email</label>
                              </div>
                            </FormGroup>
                          </Col>
                          <Col md="6">
                            <FormGroup>
                              <div className="form-floating">
                                <Input id="coordinator_address" style={{"borderRadius": "5px"}} placeholder="ID" type="text" />
                                <label htmlFor="pwd">Address</label>
                              </div>
                            </FormGroup>
                          </Col>
                        </Row>
                      </Col>
                    </Row>

                    <Row>
                      <Col sm="12">
                        <Row>
                          <Col md="6">
                            <FormGroup>
                              <div className="form-floating">
                                <Input id="coordinator_telephone" style={{"borderRadius": "5px"}} placeholder="ID" type="text" />
                                <label htmlFor="pwd">Telephone</label>
                              </div>
                            </FormGroup>
                          </Col>
                          <Col md="6">
                            <FormGroup>
                              <div className="form-floating">
                                <Input id="coordinator_mobile" style={{"borderRadius": "5px"}} placeholder="ID" type="text" />
                                <label htmlFor="pwd">Mobile</label>
                              </div>
                            </FormGroup>
                          </Col>
                        </Row>
                      </Col>
                    </Row>

                    <Row>
                      <Col sm="12">
                        <Row>
                          <Col md="6">
                            <FormGroup>
                              <div className="form-floating">
                                <Input id="coordinator_language" style={{"borderRadius": "5px"}} placeholder="ID" type="text" />
                                <label htmlFor="pwd">Language</label>
                              </div>
                            </FormGroup>
                          </Col>
                        </Row>
                      </Col>
                    </Row>

                    <Row>
                      <Col sm="12">
                        <FormGroup>
                          <h4 style={{"fontFamily": "Noto Sans","fontStyle": "normal","fontWeight": 400,"fontSize": "16px","lineHeight": "24px","color": "#70B2D8"}}>School</h4>
                        </FormGroup>
                      </Col>
                    </Row>

                    <Row>
                      <Col md={12}>
                        <FormGroup>
                          <select id="school_id" className="select2-tc form-control" multiple style={{height:"58px",width:"50%","borderRadius":"5px"}}>
                          </select>
                        </FormGroup>
                      </Col>
                    </Row>

                  </CardBody>
                </Card>
                <Card id="c_submissions">
                  <CardHeader>
                    <CardTitle tag="h4" className="panelTitle">Submissions</CardTitle>
                  </CardHeader>
                  <CardBody>
                      <Row>
                        <Col sm="12">
                          <FormGroup>
                            <h4 style={{"fontFamily": "Noto Sans","fontStyle": "normal","fontWeight": 400,"fontSize": "16px","lineHeight": "24px","color": "#70B2D8"}}>Documents & Images</h4>
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col sm="12" id="documents_lists">
                        </Col>
                        <Col sm="12">
                          <input style={{marginLeft: "20px",marginTop: "24px"}} type="file" id="document_upload"/> <button className="btn btn-primary uploadDoc">Upload</button>
                        </Col>
                      </Row>

                      <Row>
                        <Col sm="12">
                          <FormGroup>
                            <h4 style={{"fontFamily": "Noto Sans","fontStyle": "normal","fontWeight": 400,"fontSize": "16px","lineHeight": "24px","color": "#70B2D8"}}>Coordinator</h4>
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col sm="12">
                          <Row className="bundleBorder">
                            <Col md="2">
                              <FormGroup>
                                <div className="form-floating">             
                                  <label htmlFor="pwd">Photos</label>
                                </div>
                              </FormGroup>
                            </Col>
                            <img id="imgPreview" style={{float: "left",width:"76px",height:"76px",position:"relative"}} src={profileImg}></img>
                            <input style={{marginLeft: "20px",marginTop: "24px"}} accept="image/*" type="file" id="photo"/>
                            <input type="hidden" id="c_photourl"/>
                          </Row>
                        </Col>
                      </Row>
                  </CardBody>
                </Card>
                <Row>
                  <Col sm="12">
                    <div align="center">
                      <button className="btn-csconfirm">Update Changes</button>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Form>
        </div>
      </>
    );
  }
}

export default CoordinatorDetail;
