import React from "react";
import axios from "axios";
import {base_api_url} from "variables/general.js";
import $ from "jquery";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Label,
  FormGroup,
  Form,
  Input,
  FormText,
  Row,
  Col
} from "reactstrap";

// core components
import PanelHeader from "components/PanelHeader/PanelHeader.js";
import Modals from "views/Components/Modals.js";

function submitData() {

  const params = new Proxy(new URLSearchParams(window.location.search), {
    get: (searchParams, prop) => searchParams.get(prop),
  });

  let user_login = JSON.parse(localStorage.getItem('user_login'));

  let insideoroutside;
  if ($("#location_status").val()=="Indoor") {
    insideoroutside = "inside";
  } else {
    insideoroutside = "outside";
  }

  var date = new Date();
  var timestamp = date.toISOString();

  let stData = base_api_url + "/api/v1/hf/updatebyhostFamilyid?role="+user_login.role;

  let fathersmoker = false;
  let mothersmoker = false;

  if ($("#hf_father_smoker").val()=="true") {
    fathersmoker = true;
  }

  if ($("#hf_mother_smoker").val()=="true") {
    mothersmoker = true;
  }

  let requestData = JSON.parse(localStorage.getItem("detailSmoking" + params.id));
  requestData["familyname"] = $("#hf_family_name").val();
  requestData["fathersmoker"] = fathersmoker;
  requestData["mothersmoker"] = mothersmoker;
 
  return axios.put(stData, requestData).then(res => {
    if (res.data=="OK"){
      $("#msgSuccess").show();
      $("#contentSuccess").html("Success!");
    }
  }).catch(function (error) {
    alert(error)
  });
}

export default class LiveReportDetail extends React.Component {

  state = {
    data:{}
  }

  async getData () {
    
    const params = new Proxy(new URLSearchParams(window.location.search), {
      get: (searchParams, prop) => searchParams.get(prop),
    });

    return await axios.get(base_api_url + "/api/v1/hf/getAccountDetailsByHfId?hostFamilyId=" + params.id).then(res => {
      const data = res.data.response.hostFamilyDetails;
      localStorage.setItem("detailSmoking" + params.id, JSON.stringify(data));
      $("#hf_id").val(data.id);
      $("#hf_family_name").val(data.familyname);
      $("#br_name").html(data.familyname);
      $("#hf_mother_smoker").val(String(data.mothersmoker)).trigger("change");
      $("#hf_father_smoker").val(String(data.fathersmoker)).trigger("change");
    })
  }

  componentDidMount(){
    this.getData();
    
    $(document).on("click",".close",function(){
      $(this).parent().parent().hide();
    });

    $(document).on("click",".btn-csconfirm",function(e){
      e.preventDefault();
      let notValid = 0;
      let cv;
      let msgval;

      $.each($('form input'), function(index, formField) {
        if ($(formField).val()=="") {
          if (index==0) {
            cv = $(formField).parent().find("label").text();
            if (cv!=="") {
              msgval = cv + " is required! <br/>";
              $("#contentValidation").html(msgval);
              notValid += 1;
            }
          }
        }
      });

      if (notValid>0) {
        $("#msgValidation").show();
      } else {
        $("#msgValidation").hide();
        $("#contentValidation").empty();
        submitData();
      }

    });
  }

  render() {
    return (
      <>
        <Modals/>
        <PanelHeader size="sm"
          content={
            <div className="content">
              <div className="header text-left">
                <span className="panelHeaderDesc">View and manage smoking details.</span>
                <nav aria-label="breadcrumb" role="navigation">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item"><a href="/admin/overview">Dashboard</a></li>
                    <li className="breadcrumb-item"><a href="/admin/report/live-report">Live Reports</a></li>
                    <li className="breadcrumb-item"><a href="/admin/report/live-report/smoking" id="br_section">Smoking</a></li>
                    <li className="breadcrumb-item active" aria-current="page" id="br_name"></li>
                  </ol>
                </nav>
              </div>
            </div>
          }
        />
        <div className="content">
          <Row>
            <Col md="12">
              <Card>
                <CardHeader>
                  <CardTitle tag="h4" className="panelTitle">Details <a style={{border:"none"}} href="/admin/report/live-report/smoking" type="button" aria-haspopup="true" aria-expanded="false" className="pull-right btn-round btn-icon btn btn-outline-default"><i className="now-ui-icons cs-cancel_sm_yellow"></i></a></CardTitle>
                </CardHeader>
                <CardBody>
                  <Form name="formsubmit" id="formsubmit" action="/" className="form-horizontal" method="post">
                    <Row>
                      <Col sm="12">
                        <Row>
                          <Col md="6">
                            <FormGroup>
                              <div className="form-floating">
                                <Input id="hf_id" type="hidden"/>
                                <Input readonly="true" id="hf_family_name" style={{borderRadius: "5px"}} placeholder="ID" type="text" />
                                <label htmlFor="pwd">Family Name</label>
                              </div>
                            </FormGroup>
                          </Col>
                          <Col md="6">
                            <FormGroup>
                              <div className="form-floating">
                                <select className="form-control" id="hf_mother_smoker" style={{borderRadius: "5px"}} placeholder="ID" type="text">
                                  <option value="true">Yes</option>
                                  <option value="false">No</option>
                                </select>
                                <label htmlFor="pwd">Mother Smoker</label>
                              </div>
                            </FormGroup>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm="12">
                        <Row>
                          <Col md={12}>
                            <FormGroup>
                              <div className="form-floating">
                                <select className="form-control" id="hf_father_smoker" style={{borderRadius: "5px"}} placeholder="ID" type="text">
                                  <option value="true">Yes</option>
                                  <option value="false">No</option>
                                </select>
                                <label htmlFor="pwd">Father Smoker</label>
                              </div>
                            </FormGroup>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm="12">
                        <div className="pull-right">
                        	<button className="btn-csconfirm">Update Changes</button>
                        </div>
                      </Col>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </>
    );
  }

}