import React from "react";
import $ from "jquery";

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  Table
} from "reactstrap";

// core components
import PanelHeader from "components/PanelHeader/PanelHeader.js";
import axios from "axios";
import {base_api_url} from "variables/general.js";
import swal from 'sweetalert';
import Modals from "views/Components/Modals.js";

function submitData(id) {
  let user_login = JSON.parse(localStorage.getItem('user_login'));

  let requestData = {
    email: user_login.email,
    newpassword: $("#new_password").val(),
    oldpassword: $("#old_password").val()
  }

  return axios.post(base_api_url + "/api/v1/authentication/changepassword", requestData).then(res => {
    swal("Success","Successfully update password!","success");
    setTimeout(function(){
      window.location.reload();
    },500);
  }).catch(function (error) {
    swal("Failed!", "Something wrong!", "error");
  });
}

function deleteAccount(id) {
  let urlTarget = base_api_url + "/api/v1/student/deleteaccount/"+id;

  let requestData = {
    studentId:id,
    isactive: false
  }

  return axios.delete(urlTarget, requestData).then(res => {
    swal("Success","Successfully delete data!","success");
    localStorage.removeItem("user_login");
    
    setTimeout(function(){
      window.location.reload();
    },500);

  }).catch(function (error) {
    swal("Failed!", "Something wrong!", "error");
  });
}

class Setting extends React.Component {
  componentDidMount() {
    let user_login = JSON.parse(localStorage.getItem('user_login'));

    $(document).on("click",".btn-csconfirm-password",function(e){
      
      e.preventDefault();

      let notValid = 0;
      let msgval = "";
      let cv;

      $(".errorCs").remove();
      
      $.each($('#formpassword input'), function(index, formField) {
        if ($(formField).val()=="") {
          cv = $(formField).parent().find("label").text();
          if (cv!=="" && $("#contentValidation").text()=="") {
            msgval = cv + " is required! <br/>";
            $("#contentValidation").html(msgval);
            notValid += 1;
          }
        }
      });

      $.each($('#formpassword input'), function(index, formField) {
        if ($(formField).val()==="") {
          cv = $(formField).parent().find("label").text();
          if (cv!=="") {
            msgval = cv + " is required! <br/>";
            $(formField).parent().append("<span class='errorCs'>" + msgval + "</span>");
            notValid += 1;
          }
        }
      });

      let new_password = $("#new_password").val();

      if (new_password!==$("#confirm_pass").val()) {
        $("#confirm_pass").parent().append("<span class='errorCs'>New password is not match with confirm password!</span>");
        notValid += 1;
      }

      if (new_password.length<6) {
        $("#new_password").parent().append("<span class='errorCs'>Minimum 6 digit for new password!</span>");
        notValid += 1;
      }

      if (notValid>0) {
        //
      } else {
        submitData(user_login.reference_id);
      }

    });

    $(document).on("click",".btn-csconfirm-delete",function(e){
      e.preventDefault();
      $("#contentConfirm").html("Are you sure you want to delete the account ?");
      $("#msgConfirm").show();
    });

    $(document).on("click",".close",function(){
      $(this).parent().parent().hide();
    });

    $(document).on("click","#confirmYes",function(){
      deleteAccount(user_login.reference_id);
    });

    $(document).on("click","#confirmNo",function(){
      $(this).parent().parent().parent().hide();
    });

  }

  render() {
    return (
    <>
      <Modals/>
      <PanelHeader size="sm"
        content={
          <div className="content">
            <div className="header text-left">
              <span className="panelHeaderDesc">View and manage your settings.</span>
              <nav aria-label="breadcrumb" role="navigation">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item active" aria-current="page">Settings</li>
                </ol>
              </nav>
            </div>
          </div>
        }
      />
      <br/>
      <div className="content">
        <Row>
          <Col md="3">
            <Card className="card-chart">
              <CardBody>
                <Table responsive>
                  <tbody>
                    <tr>
                      <td className="td-noborder">
                        <a href="#c_change_password" className="anchorItemDetail">Change Password</a>
                        <a href="#c_delete_account" className="anchorItemDetail">Delete Account</a>
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </CardBody>
            </Card>
          </Col>
          <Col md="9">
            <Card id="c_change_password">
              <CardHeader>
                <CardTitle tag="h4" className="panelTitle">Change Password</CardTitle>
              </CardHeader>
              <CardBody>
                <Form id="formpassword" action="/" className="form-horizontal" method="post" name="formsubmit">
                  <Row>
                    <Col sm="12">
                      <Row>
                        <Col md="12">
                          <FormGroup>
                            <div className="form-floating">
                              <Input type="password" id="old_password" style={{"borderRadius": "5px"}} placeholder="ID"/>
                              <label htmlFor="pwd">Current Password</label>
                            </div>
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col md="12">
                          <FormGroup>
                            <div className="form-floating">
                              <Input type="password" id="new_password" style={{"borderRadius": "5px"}} placeholder="ID"/>
                              <label htmlFor="pwd">New Password</label>
                            </div>
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col md="12">
                          <FormGroup>
                            <div className="form-floating">
                              <Input type="password" id="confirm_pass" style={{"borderRadius": "5px"}} placeholder="ID"/>
                              <label htmlFor="pwd">Confirm New Password</label>
                            </div>
                          </FormGroup>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm="12">
                      <div className="pull-right">
                        <button className="btn-csconfirm btn-csconfirm-password">Update Changes</button>
                      </div>
                    </Col>
                  </Row>
                </Form>
              </CardBody>
            </Card>
            <Card id="c_delete_account">
              <CardHeader>
                <CardTitle tag="h4" className="panelTitle">Delete Account</CardTitle>
              </CardHeader>
              <CardBody>
                <Form id="formdelete" action="/" className="form-horizontal" method="get">
                  <Row>
                    <Col sm="12">
                      <Row>
                        <Col md="9">
                          <FormGroup>
                            <div className="form-floating">
                              <label htmlFor="pwd">Once you delete your account, there is no going back. Please be certain.</label>
                            </div>
                          </FormGroup>
                        </Col>
                      
                        <Col md="3">
                          <FormGroup>
                            <div className="form-floating" align="right">
                              <button className="btn-csconfirm btn-csconfirm-delete" style={{"background":"#E02B1D"}}>Delete Account</button>
                            </div>
                          </FormGroup>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Form>
              </CardBody>
            </Card>
        
          </Col>
        </Row>
  
      </div>
    </>
  );
  }
}

export default Setting;
