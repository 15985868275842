import React from "react";

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  Table
} from "reactstrap";
import swal from 'sweetalert';

// core components
import PanelHeader from "components/PanelHeader/PanelHeader.js";
import axios from "axios";
import {base_api_url} from "variables/general.js";
import $ from "jquery";
import datepickerFactory from 'jquery-datepicker';
import flatpickr from "flatpickr";
import 'flatpickr/dist/flatpickr.min.css';

function getData() {

  const params = new Proxy(new URLSearchParams(window.location.search), {
    get: (searchParams, prop) => searchParams.get(prop),
  });

  let urlTarget = base_api_url + '/api/v1/gv/getgvlistbyid?gardaVettingId=' + params.id; 
  return axios.get(urlTarget).then(res => {
    let data = res.data.response;
    localStorage.setItem("detailChild" + params.id, JSON.stringify(res.data.response));
    let dateofbirth;

    if (data.dob) {
      dateofbirth = new Date(data.dob);
      dateofbirth = dateofbirth.toString();
      dateofbirth = dateofbirth.split(" ");
      dateofbirth = dateofbirth[1] + " " + dateofbirth[2] + ", " + dateofbirth[3];
    }

    //$("#gv_status").val(String(data.approvalStatus)).trigger("change");
    $("#gv_family_name").val(data.hostfamilyname);
    $("#gv_name").val(data.name);
    $("#gv_id").val(data.id);
    $("#br_name").html(data.name);
    $("#emailaddress").val(data.emailaddress);
    $("#relationwithhostfamily").val(data.relationwithhostfamily);
    $("#mobile").val(data.mobile);
    $("#address").val(data.address);
    $("#eircode").val(data.eircode);
    $("#dob").val(dateofbirth);
    $("#age").val(data.age);
    $("#hobbies").val(data.hobbies);
    $("#gender").val(data.gender);
    $("#notes").val(data.notes);
  })
}

function formatDate(dateString) {
  const date = new Date(dateString);
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const day = date.getDate().toString().padStart(2, '0');
  return `${year}-${month}-${day}`;
}

function submitData() {

  const params = new Proxy(new URLSearchParams(window.location.search), {
    get: (searchParams, prop) => searchParams.get(prop),
  });

  var date = new Date();
  var timestamp = date.toISOString();
  let gv_status;

  gv_status = $("#gv_status").val();

  let requestData = JSON.parse(localStorage.getItem("detailChild" + params.id));
  requestData["name"] = $("#gv_name").val();
  requestData["approvalStatus"] = gv_status;
  requestData["hostfamilyname"] = $("#gv_family_name").val();
  requestData["timestamp"] = timestamp;
  requestData["emailaddress"] = $("#emailaddress").val();
  requestData["relationwithhostfamily"] = $("#relationwithhostfamily").val();
  requestData["mobile"] = $("#mobile").val();
  requestData["address"] = $("#address").val();
  requestData["eircode"] = $("#eircode").val();
  requestData["dob"] = formatDate($("#dob").val());
  requestData["age"] = $("#age").val();
  requestData["hobbies"] = $("#hobbies").val();
  requestData["gender"] = $("#gender").val();
  requestData["notes"] = $("#notes").val();

  return axios.put(base_api_url + "/api/v1/gv/updatebygardavettingid", requestData).then(res => {
    swal("Success","Successfully update data!","success");
    setTimeout(function(){
      window.location.reload();
    },500);
  }).catch(function (error) {
    swal("Failed!", "Something went wrong!", "error");
  });
}

class ChildDetail extends React.Component {
  componentDidMount(){

    datepickerFactory($);

    flatpickr("#dob",{dateFormat: 'F d, Y'});

    $(document).on("click",".btn-csconfirm",function(e){
      e.preventDefault();

      $(".errorCs").remove();
      
      let notValid = 0;
      let msgval = "";
      let cv;

      $.each($('form input'), function(index, formField) {
        if ($(formField).val()==="") {
          cv = $(formField).parent().find("label").text();
          if (cv!=="") {
            msgval = cv + " is required! <br/>";
            $(formField).parent().append("<span class='errorCs'>" + msgval + "</span>");
            notValid += 1;
          }
        }
      });

      if (notValid>0) {
        //$("#msgValidation").show();
      } else {
        submitData();
      }
      
    });

    $(document).on("click",".close",function(){
      $(this).parent().parent().hide();
    });

    $(document).on("change","#dob",function(){
      let dob = new Date($(this).val());
      var today = new Date();
      var age = Math.floor((today-dob) / (365.25 * 24 * 60 * 60 * 1000));
      console.log(age)
      $('#age').val(age);
    });

    getData()

  }

  render() {
    return (
      <>
        <PanelHeader size="sm"
          content={
            <div className="content">
              <div className="header text-left">
                <span className="panelHeaderDesc">View and manage Activity Permission Forms.</span>
                <nav aria-label="breadcrumb" role="navigation">
                  <ol class="breadcrumb">
                    <li class="breadcrumb-item"><a href="/admin/overview">Dashboard</a></li>
                    <li class="breadcrumb-item"><a href="/admin/report/standard-report">Standard Reports</a></li>
                    <li class="breadcrumb-item"><a href="/admin/report/standard-report/child">Child</a></li>
                    <li class="breadcrumb-item active" aria-current="page" id="br_name"></li>
                  </ol>
                </nav>
              </div>
            </div>
          }
        />
        <br/>
        <div className="content">
          <Form name="formsubmit" id="formsubmit" action="/" className="form-horizontal" method="post">
            <Row>
              <Col md="3">
                <Card className="card-chart">
                  <CardBody>
                    <Table responsive>
                      <tbody>
                        <tr>
                          <td className="td-noborder">
                            <a href="#c_garda_vetting" className="anchorItemDetail">Child</a>
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </CardBody>
                </Card>
              </Col>
              <Col md="9">
                <Card id="c_trip_information">
                  <CardHeader>
                    <CardTitle tag="h4" className="panelTitle">Child</CardTitle>
                  </CardHeader>
                  <CardBody>
                    <Row>
                      <Col sm="12">
                        <FormGroup>
                          <h4 className="subHeaderBlue">Child</h4>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm="12">
                        <Row>
                          <Col md="6">
                            <FormGroup>
                              <div class="form-floating">
                                <Input readonly="true" id="gv_name" style={{"borderRadius": "5px"}} placeholder="ID" type="text" />
                                <label for="pwd">Name</label>
                              </div>
                            </FormGroup>
                          </Col>
                          <Col md="6">
                            <FormGroup>
                              <div class="form-floating">
                                <Input readonly="true" id="gv_family_name" style={{"borderRadius": "5px"}} placeholder="ID" type="text" />
                                <label for="pwd">Family Name</label>
                              </div>
                            </FormGroup>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm="12">
                        <Row>
                          <Col md="6">
                            <FormGroup>
                              <div class="form-floating">
                                <Input id="relationwithhostfamily" style={{"borderRadius": "5px"}} placeholder="ID" type="text" />
                                <label for="pwd">Relationship With Host Family</label>
                              </div>
                            </FormGroup>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm="12">
                        <Row>
                          <Col md="6">
                            <FormGroup>
                              <div class="form-floating">
                                <Input id="mobile" style={{"borderRadius": "5px"}} placeholder="ID" type="text" />
                                <label for="pwd">Phone</label>
                              </div>
                            </FormGroup>
                          </Col>
                          <Col md="6">
                            <FormGroup>
                              <div class="form-floating">
                                <Input id="emailaddress" style={{"borderRadius": "5px"}} placeholder="ID" type="text" />
                                <label for="pwd">Email</label>
                              </div>
                            </FormGroup>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm="12">
                        <Row>
                          <Col md="6">
                            <FormGroup>
                              <div class="form-floating">
                                <textarea className="form-control" style={{"borderRadius": "5px",border:"1px solid #e3e3e3"}} id="address"></textarea>
                                <label for="pwd">Address</label>
                              </div>
                            </FormGroup>
                          </Col>
                          <Col md="6">
                            <FormGroup>
                              <div class="form-floating">
                                <Input id="eircode" style={{"borderRadius": "5px"}} placeholder="ID" type="text" />
                                <label for="pwd">Eircode</label>
                              </div>
                            </FormGroup>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm="12">
                        <Row>
                          <Col md="6">
                            <FormGroup>
                              <div class="form-floating">
                                <Input id="dob" style={{"borderRadius": "5px"}} placeholder="ID" type="text" />
                                <label for="pwd">Date Of Birth</label>
                              </div>
                            </FormGroup>
                          </Col>
                          <Col md="6">
                            <FormGroup>
                              <div class="form-floating">
                                <Input readonly="true" id="age" style={{"borderRadius": "5px"}} placeholder="ID" type="text" />
                                <label for="pwd">Age</label>
                              </div>
                            </FormGroup>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm="12">
                        <Row>
                          <Col md="6">
                            <FormGroup>
                              <div class="form-floating">
                                <Input id="hobbies" style={{"borderRadius": "5px"}} placeholder="ID" type="text" />
                                <label for="pwd">Hobbies</label>
                              </div>
                            </FormGroup>
                          </Col>
                          <Col md="6">
                            <FormGroup>
                              <div class="form-floating">
                                <Input id="gender" style={{"borderRadius": "5px"}} placeholder="ID" type="text" />
                                <label for="pwd">Gender</label>
                              </div>
                            </FormGroup>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm="12">
                        <Row>
                          <Col md="6">
                            <FormGroup>
                              <div class="form-floating">
                                <textarea className="form-control" id="notes" style={{"borderRadius": "5px",border:"1px solid #e3e3e3"}}></textarea>
                                <label for="pwd">Notes</label>
                              </div>
                            </FormGroup>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
                <Row>
                  <Col sm="12">
                    <div className="pull-right">
                      <button className="btn-csconfirm">Update Changes</button>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Form>
        </div>
      </>
    );
  }
}

export default ChildDetail;
