import React from "react";
import { Route, Switch } from "react-router-dom";
import routes from "routes_auth.js";

function Home(props) {
  const [filterColor] = React.useState("yellow");
  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return getRoutes(prop.views);
      }
      if (prop.layout === "/home") {
        let path = "/";
        if (prop.path!=="/") {
          path = prop.path;
        }
        return (
          <Route
            path={path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };

  return (
    <>
      <div>
        <div>
          <Switch>
            {getRoutes(routes)}
          </Switch>
        </div>
      </div>
    </>
  );
}

export default Home;
