import CoordinatorOverview from "views/Pages/Coordinator/Overview.js";
import StandardReport from "views/Pages/Coordinator/StandardReport/StandardReport.js";
import StandardReportListTpf from "views/Pages/Coordinator/StandardReport/StandardReportListTpf.js";
import StandardReportDetailTpf from "views/Pages/Coordinator/StandardReport/StandardReportDetailTpf.js";
import StandardReportListApf from "views/Pages/Coordinator/StandardReport/StandardReportListApf.js";
import StandardReportDetailApf from "views/Pages/Coordinator/StandardReport/StandardReportDetailApf.js";
import StandardReportListFir from "views/Pages/Coordinator/StandardReport/StandardReportListFir.js";
import StandardReportDetailFir from "views/Pages/Coordinator/StandardReport/StandardReportDetailFir.js";
import StandardReportListAgentReport from "views/Pages/Coordinator/StandardReport/StandardReportListAgentReport.js";
import StandardReportDetailAgentReport from "views/Pages/Coordinator/StandardReport/StandardReportDetailAgentReport.js";
import StandardReportListCoordinatorReport from "views/Pages/Coordinator/StandardReport/StandardReportListCoordinatorReport.js";
import StandardReportListStudentNote from "views/Pages/Coordinator/StandardReport/StandardReportListStudentNote.js";
import StandardReportDetailStudentNote from "views/Pages/Coordinator/StandardReport/StandardReportDetailStudentNote.js";
import StandardReportListGardaVetting from "views/Pages/Coordinator/StandardReport/StandardReportListGardaVetting.js";
import StandardReportDetailGardaVetting from "views/Pages/Coordinator/StandardReport/StandardReportDetailGardaVetting.js";
import StandardReportListHostFamilyNote from "views/Pages/Coordinator/StandardReport/StandardReportListHostFamilyNote.js";
import StandardReportDetailHostFamilyNote from "views/Pages/Coordinator/StandardReport/StandardReportDetailHostFamilyNote.js";
import StandardReportListPet from "views/Pages/Coordinator/StandardReport/StandardReportListPet.js";
import StandardReportDetailPet from "views/Pages/Coordinator/StandardReport/StandardReportDetailPet.js";
import StandardReportListSchoolLog from "views/Pages/Coordinator/StandardReport/StandardReportListSchoolLog.js";
import StandardReportDetailSchoolLog from "views/Pages/Coordinator/StandardReport/StandardReportDetailSchoolLog.js";
import StandardReportListSl from "views/Pages/Coordinator/StandardReport/StandardReportListSl.js";
import StandardReportDetailSl from "views/Pages/Coordinator/StandardReport/StandardReportDetailStudentLog.js";

import CoordinatorStudent from "views/Pages/Coordinator/Student/Student.js";
import CoordinatorStudentDetail from "views/Pages/Coordinator/Student/StudentDetail.js";

import Setting from "views/Pages/Coordinator/Setting/Setting.js";

import Notification from "views/Pages/Coordinator/Notification/Notification.js";
import NotificationDetail from "views/Pages/Coordinator/Notification/NotificationDetail.js";
import NotificationNew from "views/Pages/Coordinator/Notification/NotificationNew.js";

import LogNotes from "views/Pages/Coordinator/LogNotes/LogNotes.js";
import LogNotesDetail from "views/Pages/Coordinator/LogNotes/LogNotesDetail.js";
import LogNotesNew from "views/Pages/Coordinator/LogNotes/LogNotesNew.js";

import SchoolLog from "views/Pages/Coordinator/SchoolLog/SchoolLog.js";
import SchoolLogDetail from "views/Pages/Coordinator/SchoolLog/SchoolLogDetail.js";
import SchoolLogNew from "views/Pages/Coordinator/SchoolLog/SchoolLogNew.js";

import StudentLog from "views/Pages/Coordinator/StudentLog/StudentLog.js";
import StudentLogDetail from "views/Pages/Coordinator/StudentLog/StudentLogDetail.js";
import StudentLogNew from "views/Pages/Coordinator/StudentLog/StudentLogNew.js";

import CoordinatorRequest from "views/Pages/Coordinator/Request/Request.js";
import CoordinatorRequestStudentDetail from "views/Pages/Coordinator/Request/SubmissionDetail.js";
import CoordinatorRequestTpfDetail from "views/Pages/Coordinator/Request/Tpf/TpfDetail.js";
import CoordinatorRequestApfDetail from "views/Pages/Coordinator/Request/Apf/ApfDetail.js";
import CoordinatorRequestPhotograpicDetail from "views/Pages/Coordinator/Request/Photograpic/PhotograpicDetail.js";

import Profile from "views/Pages/Coordinator/Profile.js";

import HostFamily from "views/Pages/Coordinator/HostFamily/HostFamily.js";
import HostFamilyDetail from "views/Pages/Coordinator/HostFamily/HostFamilyDetail.js";
import HostFamilyAttachedStudent from "views/Pages/Coordinator/HostFamily/HostFamilyAttachedStudent.js";

import School from "views/Pages/Coordinator/School/School.js";
import SchoolDetail from "views/Pages/Coordinator/School/SchoolDetail.js";
import SchoolRegistration from "views/Pages/Coordinator/School/SchoolRegistration.js";
import SchoolAttachedStudent from "views/Pages/Coordinator/School/SchoolAttachedStudent.js";

import Notes from "views/Pages/Coordinator/Notes/Notes.js";
import NotesDetail from "views/Pages/Coordinator/Notes/NotesDetail.js";
import NotesNew from "views/Pages/Coordinator/Notes/NotesNew.js";

let routes_coordinator = [
  {
    path: "/user",
    name: "logo",
    icon: "now-ui-icons cs-menu",
    component: CoordinatorOverview,
    layout: "/coordinator"
  },
  {
    path: "/overview",
    name: "Overview",
    icon: "now-ui-icons cs-dashboard",
    component: CoordinatorOverview,
    layout: "/coordinator"
  },
  {
    path: "/report/standard-report/travel-permission-form/:id",
    name: "Travel Permission Form",
    component: StandardReportDetailTpf,
    layout: "/coordinator",
    exludeSidebar:true
  },
  {
    path: "/report/standard-report/activity-permission-form/:id",
    name: "Activity Permission Form",
    component: StandardReportDetailApf,
    layout: "/coordinator",
    exludeSidebar:true
  },
  {
    path: "/report/standard-report/agent-report/:id",
    name: "Agent Report",
    component: StandardReportDetailAgentReport,
    layout: "/coordinator",
    exludeSidebar:true
  },
  {
    path: "/report/standard-report/flight-information-report/:id",
    name: "Flight Information Report",
    component: StandardReportDetailFir,
    layout: "/coordinator",
    exludeSidebar:true
  },
  {
    path: "/report/standard-report/garda-vetting/:id",
    name: "Garda Vetting Detail",
    component: StandardReportDetailGardaVetting,
    layout: "/coordinator",
    exludeSidebar:true
  },
  {
    path: "/report/standard-report/host-family-note/:id",
    name: "Host Family Detail",
    component: StandardReportDetailHostFamilyNote,
    layout: "/coordinator",
    exludeSidebar:true
  },
  {
    path: "/report/standard-report/student-note/:id",
    name: "Student Notes Detail",
    component: StandardReportDetailStudentNote,
    layout: "/coordinator",
    exludeSidebar:true
  },
  {
    path: "/report/standard-report/school-logs/:id",
    name: "School Logs Detail",
    component: StandardReportDetailSchoolLog,
    layout: "/coordinator",
    exludeSidebar:true
  },
  {
    path: "/report/standard-report/student-logs/:id",
    name: "Student Logs Detail",
    component: StandardReportDetailSl,
    layout: "/coordinator",
    exludeSidebar:true
  },
  {
    path: "/report/standard-report/pets/:id",
    name: "Pets Detail",
    component: StandardReportDetailPet,
    layout: "/coordinator",
    exludeSidebar:true
  },
  {
    path: "/report/standard-report/travel-permission-form",
    name: "Travel Permission Form",
    component: StandardReportListTpf,
    layout: "/coordinator",
    exludeSidebar:true
  },
  {
    path: "/report/standard-report/activity-permission-form",
    name: "Activity Permission Form",
    component: StandardReportListApf,
    layout: "/coordinator",
    exludeSidebar:true
  },
  {
    path: "/report/standard-report/flight-information-report",
    name: "Flight Information Report",
    component: StandardReportListFir,
    layout: "/coordinator",
    exludeSidebar:true
  },
  {
    path: "/report/standard-report/agent-report",
    name: "Agent Report",
    component: StandardReportListAgentReport,
    layout: "/coordinator",
    exludeSidebar:true
  },
  {
    path: "/report/standard-report/pets",
    name: "Pets Report",
    component: StandardReportListPet,
    layout: "/coordinator",
    exludeSidebar:true
  },
  {
    path: "/report/standard-report/coordinator-report",
    name: "Coordinator Report",
    component: StandardReportListCoordinatorReport,
    layout: "/coordinator",
    exludeSidebar:true
  },
  {
    path: "/report/standard-report/host-family-note",
    name: "Host Family Notes",
    component: StandardReportListHostFamilyNote,
    layout: "/coordinator",
    exludeSidebar:true
  },
  {
    path: "/report/standard-report/student-note",
    name: "Student Notes",
    component: StandardReportListStudentNote,
    layout: "/coordinator",
    exludeSidebar:true
  },
  {
    path: "/report/standard-report/student-logs",
    name: "Student Logs",
    component: StandardReportListSl,
    layout: "/coordinator",
    exludeSidebar:true
  },
  {
    path: "/report/standard-report/school-logs",
    name: "School Logs",
    component: StandardReportListSchoolLog,
    layout: "/coordinator",
    exludeSidebar:true
  },
  {
    path: "/report/standard-report/garda-vetting",
    name: "Garda Vetting",
    component: StandardReportListGardaVetting,
    layout: "/coordinator",
    exludeSidebar:true
  },
  {
    path: "/report/standard-report",
    name: "Reporting",
    icon: "now-ui-icons cs-report",
    component: StandardReport,
    layout: "/coordinator",
  },
  {
    path: "/student/:id",
    name: "Student Detail",
    icon: "now-ui-icons cs-student",
    component: CoordinatorStudentDetail,
    layout: "/coordinator",
    exludeSidebar:true
  },
  {
    path: "/student",
    name: "Students",
    icon: "now-ui-icons cs-student",
    component: CoordinatorStudent,
    layout: "/coordinator"
  },
  {
    path: "/school/new/registration",
    name: "School Registration",
    icon: "now-ui-icons cs-student",
    component: SchoolRegistration,
    layout: "/coordinator",
    exludeSidebar:true
  },
  {
    path: "/school/attached/student",
    name: "Schools Attached Students",
    icon: "now-ui-icons cs-student",
    component: SchoolAttachedStudent,
    layout: "/coordinator",
    exludeSidebar:true
  },
  {
    path: "/school/:id",
    name: "School Detail",
    icon: "now-ui-icons cs-student",
    component: SchoolDetail,
    layout: "/coordinator",
    exludeSidebar:true
  },
  {
    path: "/school",
    name: "School",
    icon: "now-ui-icons cs-school",
    component: School,
    layout: "/coordinator"
  },
  {
    path: "/host-family/attached/student",
    name: "Host Family Attached Students",
    icon: "now-ui-icons cs-student",
    component: HostFamilyAttachedStudent,
    layout: "/coordinator",
    exludeSidebar:true
  },
  {
    path: "/host-family/:id",
    name: "Host Family Detail",
    icon: "now-ui-icons cs-student",
    component: HostFamilyDetail,
    layout: "/coordinator",
    exludeSidebar:true
  },
  {
    path: "/host-family",
    name: "Host Families",
    icon: "now-ui-icons cs-family",
    component: HostFamily,
    layout: "/coordinator"
  },
  {
    path: "/requests/activity-permission-form/:id",
    name: "Requests",
    icon: "now-ui-icons cs-submission",
    component: CoordinatorRequestApfDetail,
    layout: "/coordinator",
    exludeSidebar:true
  },
  {
    path: "/requests/photograpic/:id",
    name: "Requests",
    icon: "now-ui-icons cs-submission",
    component: CoordinatorRequestPhotograpicDetail,
    layout: "/coordinator",
    exludeSidebar:true
  },
  {
    path: "/requests/travel-permission-form/:id",
    name: "Requests",
    icon: "now-ui-icons cs-submission",
    component: CoordinatorRequestTpfDetail,
    layout: "/coordinator",
    exludeSidebar:true
  },
  {
    path: "/requests/:form_id/:id",
    name: "Activity Permission Form - Andy Mooruy",
    icon: "now-ui-icons cs-submission",
    component: CoordinatorRequestStudentDetail,
    layout: "/coordinator",
    exludeSidebar:true
  },
  {
    path: "/requests",
    name: "Requests",
    icon: "now-ui-icons cs-lognotes",
    component: CoordinatorRequest,
    layout: "/coordinator"
  },
  {
    path: "/logsschool/new/registration",
    name: "School Logs",
    icon: "now-ui-icons cs-student",
    component: SchoolLogNew,
    layout: "/coordinator",
    exludeSidebar:true
  },
  {
    path: "/logsschool/:id",
    name: "School Logs",
    icon: "now-ui-icons cs-student",
    component: SchoolLogDetail,
    layout: "/coordinator",
    exludeSidebar:true
  },
  {
    path: "/logsschool",
    name: "School Logs",
    icon: "now-ui-icons cs-report",
    component: SchoolLog,
    layout: "/coordinator",
  },
  {
    path: "/logs-student/new/registration",
    name: "Student Logs",
    icon: "now-ui-icons cs-student",
    component: StudentLogNew,
    layout: "/coordinator",
    exludeSidebar:true
  },
  {
    path: "/logs-student/:id",
    name: "Student Logs",
    icon: "now-ui-icons cs-student",
    component: StudentLogDetail,
    layout: "/coordinator",
    exludeSidebar:true
  },
  {
    path: "/logs-student",
    name: "Student Logs",
    icon: "now-ui-icons cs-report",
    component: StudentLog,
    layout: "/coordinator",
  },
  {
    path: "/notes/:id",
    name: "Notes.",
    icon: "now-ui-icons cs-student",
    component: NotesDetail,
    layout: "/coordinator",
    exludeSidebar:true
  },
  // {
  //   path: "/notes",
  //   name: "Notes",
  //   icon: "now-ui-icons cs-notes",
  //   component: Notes,
  //   layout: "/coordinator",
  // },
  {
    path: "/new-notification",
    name: "Notifications.",
    icon: "now-ui-icons cs-student",
    component: NotificationNew,
    layout: "/coordinator",
    exludeSidebar:true
  },
  {
    path: "/notification/:id",
    name: "Notifications.",
    icon: "now-ui-icons cs-student",
    component: NotificationDetail,
    layout: "/coordinator",
    exludeSidebar:true
  },
  {
    path: "/notifications",
    name: "Notifications",
    icon: "now-ui-icons cs-agent",
    component: Notification,
    layout: "/coordinator",
  },
  {
    path: "/setting",
    name: "Settings",
    icon: "now-ui-icons cs-setting",
    component: Setting,
    layout: "/coordinator"
  },
  {
    path: "/myaccount/detail/get",
    name: "My Account",
    icon: "now-ui-icons cs-student",
    component: Profile,
    layout: "/coordinator",
    exludeSidebar:true
  }
];

export default routes_coordinator;
