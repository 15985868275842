import React from "react";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Label,
  FormGroup,
  Form,
  Input,
  FormText,
  Row,
  Col,
  Table,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu
} from "reactstrap";

// core components
import PanelHeader from "components/PanelHeader/PanelHeader.js";
import Switch from "react-bootstrap-switch";
import profileImg from "assets/upload/profile.png";
import ImageUpload from "components/CustomUpload/ImageUpload.js";
import axios from "axios";
import {base_api_url, file_api_url} from "variables/general.js";
import $ from "jquery";
import swal from 'sweetalert';


function submitData(survey_name, survey_desc, survey_published, questions) {
  let user_login = JSON.parse(localStorage.getItem('user_login'));

  const params = new Proxy(new URLSearchParams(window.location.search), {
    get: (searchParams, prop) => searchParams.get(prop),
  });

  var tzoffset = (new Date()).getTimezoneOffset() * 60000; //offset in milliseconds
  var localISOTime = (new Date(Date.now() - tzoffset)).toISOString().slice(0, -1);
  var timestamp = localISOTime;

  let requestData = JSON.parse(localStorage.getItem("detailSurvey"+params.id))
  requestData["surveyname"] = survey_name;
  requestData["surveydesc"] = survey_desc;

  if (Number(survey_published)==1) {
    survey_published = true;
  } else {
    survey_published = false;
  }

  requestData["published"] = survey_published;
  requestData["timestamp"] = timestamp;

  return axios.post(base_api_url + "/api/v1/mainsurveycontroller/updatesurvey", requestData).then(res => {
    
    if (questions.length>0) {

      for (var i=0;i<questions.length;i++){
        if (questions[i].id!==0) {
          questions[i]["timestamp"] = timestamp
          questions[i]["questionorder"] = i+1;
          axios.post(base_api_url + "/api/v1/surveyquestions/updatesurveyquestion", questions[i]).then(res => {
            
          })
        } else {
          questions[i]["timestamp"] = timestamp
          questions[i]["questionorder"] = i+1;
          axios.post(base_api_url + "/api/v1/surveyquestions/registerquestion", questions[i]).then(res => {
            
          })
        }
      }

      setTimeout(function(){
        swal("Success","Successfully send survey!","success");
        setTimeout(function(){
          window.location.reload();
        },1000);
      },2000);

    }

  }).catch(function (error) {
    swal("Failed!", "Something went wrong!", "error");
  });
}

function getData() {
  const params = new Proxy(new URLSearchParams(window.location.search), {
    get: (searchParams, prop) => searchParams.get(prop),
  });

  let urlTarget = base_api_url + '/api/v1/mainsurveycontroller/getsurveybyid?id=' + params.id; 
  return axios.get(urlTarget).then(res => {
    let datas = res.data.response.survey;
    localStorage.setItem("detailSurvey"+params.id,JSON.stringify(datas))
    $("#survey_name").val(datas.surveyname);
    $("#survey_desc").val(datas.surveydesc);
    let timestamp = new Date(datas.timestamp);

    let options = {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    };

    let formattedDate = timestamp.toLocaleString('en-US', options);
    $("#timestamp").html(formattedDate);
    
    if (datas.published==false) {
      $("#survey_published").val(0);
    } else {
      $("#survey_published").val(1);
    }

    if (datas.published==true) {
      $.each($('form input'), function(index, formField) {
        $(formField).prop("disabled",true);
      });

      $("select.form-control").prop("disabled",true);
      $(".btn-csconfirm").prop("disabled",true).hide();
    }

  })
}

function populateQuestion() {
  const params = new Proxy(new URLSearchParams(window.location.search), {
    get: (searchParams, prop) => searchParams.get(prop),
  });

  let urlTarget = base_api_url + '/api/v1/surveyquestions/getsurveyquestionsbysurveyid?isactive=true&surveyid=' + params.id; 
  return axios.post(urlTarget).then(res => {
    let datas = res.data.response.content;
    let html = '';
    if (datas.length>0) {

      datas.sort((a, b) => {
          const questionA = a.question.toUpperCase();
          const questionB = b.question.toUpperCase();

          if (questionA > questionB) {
              return -1;
          }
          if (questionA < questionB) {
              return 1;
          }
          return 0;
      });

      for (var i=0;i<datas.length;i++) {
        $("#section_q").clone().prependTo("#questions");
        let last = $("#section_q").last();
        last.show();
        last.find("#question_id").val(datas[i].id);
        last.find(".customSwitches").prop("checked",datas[i].isrequired);
        last.find(".customSwitches").attr("id", "customSwitches"+datas[i].id);
        last.find(".custom-control-label").attr("for","customSwitches"+datas[i].id);
        if (datas[i].isdropdown==true) {
          last.find("#question_type").val("Dropdown").trigger("change");
          last.find("#question_title").val(datas[i].question);
          let pipe = datas[i].optionstobeseparatedbypipesymbol.split("|");
          if (pipe.length>0) {
            for (var pi=0;pi<pipe.length;pi++) {
              last.find("input.inputDd").last().val(pipe[pi]);
              last.find("#addDd").trigger("click");
            }
            last.find("input.inputDd").last().prev().remove();
            last.find("input.inputDd").last().prev().remove();
            last.find("input.inputDd").last().remove();
          }
        } else if (datas[i].isradio==true) {
          last.find("#question_type").val("Multiple Choice").trigger("change");
          last.find("#question_title").val(datas[i].question);

          let pipe = datas[i].optionstobeseparatedbypipesymbol.split("|");
          if (pipe.length>0) {
            for (var pi=0;pi<pipe.length;pi++) {
              last.find("input.inputMc").last().val(pipe[pi]);
              last.find("#addMc").trigger("click");
            }
            last.find("input.inputMc").last().prev().remove();
            last.find("input.inputMc").last().prev().remove();
            last.find("input.inputMc").last().remove();
          }
        } else if (datas[i].isrange==true) {
          last.find("#question_type").val("Linear Scale").trigger("change");
          last.find("#question_title").val(datas[i].question);
          last.find("#linearFrom").val(datas[i].rangestart);
          last.find("#linearTo").val(datas[i].rangeend);
        } else if (datas[i].ismulticheckbox==true) {
          last.find("#question_type").val("Checkbox").trigger("change");
          last.find("#question_title").val(datas[i].question);
          let pipe = datas[i].optionstobeseparatedbypipesymbol.split("|");
          if (pipe.length>0){
            for (var pi=0;pi<pipe.length;pi++) {
              last.find("input.inputCb").last().val(pipe[pi]);
              last.find("#addCb").trigger("click");
            }
            last.find("input.inputCb").last().prev().remove();
            last.find("input.inputCb").last().prev().remove();
            last.find("input.inputCb").last().remove();
          }
        } else if (datas[i].istextbox==true) {
          last.find("#question_type").val("Textbox").trigger("change");
          last.find("#question_title").val(datas[i].question);
          last.find("#textbox").val(datas[i].optionstobeseparatedbypipesymbol);
        }
      }

      setTimeout(function(){
        $("#questions > #section_q").each(function(i, obj){
          if (Number($(obj).find("#question_id").val())==0){
            $(obj).remove();
          }
        });
      },500);

    } else {
      $("#section_q").show();
    }
  })
}

class SurveyDetail extends React.Component {
  componentDidMount(){
    getData()
    populateQuestion();

    let numberofq = $('[id="section_q"]').length;

    if (numberofq>1) {
      $('[id="delThis"]').show();
    } else {
      $('[id="delThis"]').hide();
    }

    $(document).on("change","#linearFrom",function(e){

        let lto = Number($(this).parent().find("#linearTo").val());
        let ltf = Number($(this).val());
        console.log("lto"+lto);
        console.log("ltf"+ltf)

        if (ltf>=lto) {
          $(this).val(1);
        }
    });

    $(document).on("change","#linearTo",function(e){

        let ltf = Number($(this).parent().find("#linearFrom").val());
        let lto = Number($(this).val());
    
        if (ltf>=lto) {
          $(this).val(ltf+1);
        }
    });

    $(document).on("click",".sendSurvey",function(e){
      e.preventDefault();

      $(".errorCs").remove();
      
      let notValid = 0;
      let msgval = "";
      let cv;

      $.each($('form input'), function(index, formField) {
        if ($(formField).val()==="") {
          cv = $(formField).parent().find("label").text();
          if (cv!=="" && $(formField).attr("type")!=="file" && $(formField).attr("type")!=="hidden") {
            msgval = cv + " is required! <br/>";
            $(formField).parent().append("<span class='errorCs'>" + msgval + "</span>");
            notValid += 1;
          }
        }
      });

      if (notValid>0) {
        //
      } else {
        
        let requests = [];

        $(".frmQuestion").each(function(i, obj) {
          
          let frmSerial = $(obj).serializeArray();
          if (frmSerial.length>0) {
          let qtitle;
          let qtype;
          let qreq;
          let qDd = [];
          let question;
          let request = {};
          let linearFrom;
          let linearTo;
          let qCb = [];
          let qMc = [];
          let qTextbox;
          let qid = 0;

          const params = new Proxy(new URLSearchParams(window.location.search), {
            get: (searchParams, prop) => searchParams.get(prop),
          });

          for (var i=0;i<frmSerial.length;i++){
            if (frmSerial[i].name=="question_title") {
              qtitle = frmSerial[i].value;
            }

            if (frmSerial[i].name=="question_id") {
              qid = Number(frmSerial[i].value);
            }

            if (frmSerial[i].name=="question_type") {
              qtype = frmSerial[i].value;
            }

            if (frmSerial[i].name=="linearFrom") {
              linearFrom = frmSerial[i].value;
            }

            if (frmSerial[i].name=="linearTo") {
              linearTo = frmSerial[i].value;
            }

            if (frmSerial[i].name=="question_required") {
              qreq = frmSerial[i].value;
            }

            if (frmSerial[i].name=="inputDd") {
              qDd.push(frmSerial[i].value);
            }

            if (frmSerial[i].name=="inputCb") {
              qCb.push(frmSerial[i].value);
            }

            if (frmSerial[i].name=="inputMc") {
              qMc.push(frmSerial[i].value);
            }

            if (frmSerial[i].name=="textbox") {
              qTextbox = frmSerial[i].value;
            }

          }

          if (qreq=="on") {
            qreq = true
          } else {
            qreq = false
          }

          if (qtype=="Dropdown") {
            question = qDd.join("|");
            request = {
              id:qid,
              fksurveyid:params.id,
              isactive: true,
              isdropdown: true,
              ismulticheckbox: false,
              isradio: false,
              isrange: false,
              isrequired: qreq,
              istextbox: false,
              optionstobeseparatedbypipesymbol: question,
              question: qtitle,
              questionorder: 0,
              rangeend: 0,
              rangestart: 0
            }
          } else if (qtype=="Textbox") {
            question = qTextbox;
            request = {
              id:qid,
              fksurveyid:params.id,
              isactive: true,
              isdropdown: false,
              ismulticheckbox: false,
              isradio: false,
              isrange: false,
              isrequired: qreq,
              istextbox: true,
              optionstobeseparatedbypipesymbol: question,
              question: qtitle,
              questionorder: 0,
              rangeend: 0,
              rangestart: 0
            }
          } else if (qtype=="Checkbox") {
            question = qCb.join("|");
            request = {
              id:qid,
              fksurveyid:params.id,
              isactive: true,
              isdropdown: false,
              ismulticheckbox: true,
              isradio: false,
              isrange: false,
              isrequired: qreq,
              istextbox: false,
              optionstobeseparatedbypipesymbol: question,
              question: qtitle,
              questionorder: 0,
              rangeend: 0,
              rangestart: 0
            }
          } else if (qtype=="Linear Scale") {
            question = qCb.join("|");
            request = {
              id:qid,
              fksurveyid:params.id,
              isactive: true,
              isdropdown: false,
              ismulticheckbox: false,
              isradio: false,
              isrange: true,
              isrequired: qreq,
              istextbox: false,
              optionstobeseparatedbypipesymbol: "",
              question: qtitle,
              questionorder: 0,
              rangeend: linearTo,
              rangestart: linearFrom
            }
          } else if (qtype=="Multiple Choice") {
            question = qMc.join("|");
            request = {
              id:qid,
              fksurveyid:params.id,
              isactive: true,
              isdropdown: false,
              ismulticheckbox: false,
              isradio: true,
              isrange: false,
              isrequired: qreq,
              istextbox: false,
              optionstobeseparatedbypipesymbol: question,
              question: qtitle,
              questionorder: 0,
              rangeend: 0,
              rangestart: 0
            }
          }

          requests.push(request)
        }

        });

        if (requests.length>0) {
          submitData($("#survey_name").val(), $("#survey_desc").val(), $("#survey_published").val(), requests)
        }

      }
      
    });

    $(document).on("click","#delThis",function(e){
      e.preventDefault();
      let numberofq = $('[id="section_q"]').length;

      if (numberofq>1) {
        let parents = $(this).parent().parent().parent().parent().parent().parent().parent();
        let curid = parents.find("#question_id").val();

        const params = new Proxy(new URLSearchParams(window.location.search), {
          get: (searchParams, prop) => searchParams.get(prop),
        });

        if (Number(curid)!==0) {
          axios.post(base_api_url+"/api/v1/surveyquestions/deleteSurveyQuestionById?id="+Number(curid)).then(res => {
            parents.remove();
          })
        } else {
          parents.remove();
        }
      }

      numberofq = $('[id="section_q"]').length;

      if (numberofq>1) {
        $('[id="delThis"]').show();
      } else {
        $('[id="delThis"]').hide();
      }

    });

    $(document).on("click","#dupThis",function(e){
      e.preventDefault();
      let parents = $(this).parent().parent().parent().parent().parent().parent().parent();
      parents.clone().appendTo("#questions").find("#question_id").val(0);

    });

    $(document).on("click",".addQuestion",function(e){
      e.preventDefault();
      let cloned = $("#section_q").last().clone();
      let rand = Math.floor(Math.random() * 999999);
      cloned.find(".customSwitches").parent().find("label").attr("for","customSwitches"+rand);
      cloned.find("#customSwitches").attr("id","customSwitches"+rand);
      cloned.find(".customSwitches").attr("class","custom-control-input customSwitches"+rand);
      cloned.find("#question_type").val("Multiple Choice").trigger("change");
      cloned.find("#question_title").val("").trigger("change");
      cloned.find("#c_survey").html('<div class="form-group"><a id="addMc" style="color: blue; cursor: pointer;">Add option</a></div>');

      cloned.appendTo("#questions").find("#question_id").val(0);

      let numberofq = $('[id="section_q"]').length;

      if (numberofq>1) {
        $('[id="delThis"]').show();
      } else {
        $('[id="delThis"]').hide();
      }

    });

    $(document).on("click",".close",function(){
      $(this).parent().parent().hide();
    });

    $('#photo').change(function(){
      const file = this.files[0];
      if (file){
        $(".errorCs").remove();
        if (file.type=="image/jpeg" || file.type=="image/png") {
          let reader = new FileReader();
          reader.onload = function(event){
            $('#imgPreview').attr('src', event.target.result);
          }
          reader.readAsDataURL(file);
        } else {
          $('#photo').val("");
          $("#imgPreview").attr("src","");
          $('#photo').parent().parent().append("<span class='errorCs'>The image must be a file of type: jpg, jpeg, png.</span>");
        }
      }
    });

    $(document).on("click","#addMc",function(e){
      e.preventDefault();
      let curLen = $(this).parent().find("input.inputMc");
      curLen = curLen.length + 1;
      $('<input disabled="true" style="position:relative;top:2px;" type="radio"/> <input name="inputMc" class="inputMc" value="Option '+curLen+'"/><br/>').insertBefore($(this));
    });

    $(document).on("click","#addDd",function(e){
      e.preventDefault();
      let curLen = $(this).parent().find("input.inputDd");
      curLen = curLen.length + 1;
      $('<input disabled="true" style="position:relative;top:2px;" type="checkbox"/> <input name="inputDd" class="inputDd" value="Option '+curLen+'"/><br/>').insertBefore($(this));
    });

    $(document).on("click","#addCb",function(e){
      e.preventDefault();
      let curLen = $(this).parent().find("input.inputCb");
      curLen = curLen.length + 1;
      $('<input disabled="true" style="position:relative;top:2px;" type="checkbox"/> <input name="inputCb" class="inputCb" value="Option '+curLen+'"/><br/>').insertBefore($(this));
    });

    $(document).on("change","#question_type",function(){
      if ($(this).val()=="Checkbox") {
      $(this).parent().parent().parent().parent().parent().find("#c_survey").html(`<FormGroup>
                        <input disabled='true' style="position:relative;top:2px;" type="checkbox"/> <input type='text' id="inputCb" name="inputCb" class="inputCb" value="Option 1"/><br/>
                        <a style="color:blue;cursor: pointer;" id="addCb">Add option</a>
                      </FormGroup>`);
      } else if ($(this).val()=="Textbox") {
        $(this).parent().parent().parent().parent().parent().find("#c_survey").html(`<FormGroup>
                        <textarea id="textbox" name="textbox" class="form-control" placeholder="Text Answer"></textarea>
                      </FormGroup>`);
      } else if ($(this).val()=="Multiple Choice") {
        $(this).parent().parent().parent().parent().parent().find("#c_survey").html(`<FormGroup>
                        <input disabled='true' style="position:relative;top:2px;" type="radio"/> <input id="inputMc" name="inputMc" type='text' class="inputMc" value="Option 1"/><br/>
                        <a style="color:blue;cursor: pointer;" id="addMc">Add option</a>
                      </FormGroup>`);
      } else if ($(this).val()=="Dropdown") {
        $(this).parent().parent().parent().parent().parent().find("#c_survey").html(`<FormGroup>
                        <input disabled='true' style="position:relative;top:2px;" type="checkbox"/> <input id="inputDd" name="inputDd" type='text' class="inputDd" value="Option 1"/><br/>
                        <a style="color:blue;cursor: pointer;" id="addDd">Add option</a>
                      </FormGroup>`);
      } else if ($(this).val()=="Linear Scale") {
        $(this).parent().parent().parent().parent().parent().find("#c_survey").html(`<FormGroup>
                       <input name="linearFrom" id="linearFrom" class="numCs" type="number" value="1" min="1" max="2"/>&nbsp;&nbsp;&nbsp;To&nbsp;&nbsp;&nbsp;<input id="linearTo" name="linearTo" class="numCs" type="number" value="5" min="5" max="10"/> 
                      </FormGroup>`);
      } else {
        $("#c_survey").html("");
      }
    });

  }

  render(){
    return (
    <>
      <PanelHeader size="sm"
        content={
          <div className="content">
            <div className="header text-left">
              <span className="panelHeaderDesc">View and manage your Surveys.</span>
              <nav aria-label="breadcrumb" role="navigation">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item"><a href="/admin/overview">Dashboard</a></li>
                  <li className="breadcrumb-item"><a href="/admin/survey">Surveys</a></li>
                  <li className="breadcrumb-item active" aria-current="page">Detail Survey</li>
                </ol>
              </nav>
            </div>
          </div>
        }
      />
      <br/>
      <div className="content">
        <Row>
          <Col md="12">
            <Card className="card-chart">
              <CardHeader>
                <CardTitle id="timestamp" tag="h4" style={{"fontFamily": "Noto Sans","fontStyle": "normal","fontWeight": 400,"fontSize": "16px","lineHeight": "24px","color": "#70B2D8"}}></CardTitle>
                <a style={{float:"right", height:"30px"}} className="now-ui-icons cs-cancel_yellow" href="/admin/survey" />
                <br/>
              </CardHeader>
              <CardBody>
                <Input type="hidden" id="n_photourl"/>
                <Form action="/" className="form-horizontal" method="get">
                  <hr/>
                
                  <Row>
                    <Col sm="12">
                      <Row className="text-desc-notif">
                        <Col md="12">
                          <FormGroup>
                            <div className="form-floating">
                              <Input id="survey_name" style={{"borderRadius": "5px"}} placeholder="Country" type="text" />
                              <label htmlFor="pwd">Survey Name</label>
                            </div>
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row className="text-desc-notif">
                        <Col md="12">
                          <FormGroup>
                            <div className="form-floating">
                              <Input id="survey_desc" style={{"borderRadius": "5px"}} placeholder="Country" type="text" />
                              <label htmlFor="pwd">Survey Description</label>
                            </div>
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row className="text-desc-notif">
                        <Col md="12">
                          <FormGroup>
                            <div className="form-floating">
                              <select name="survey_published" className="form-control" id="survey_published" style={{"borderRadius": "5px"}} placeholder="Country">
                                <option value="1">Yes</option>
                                <option value="0">No</option>
                              </select>
                              <label htmlFor="pwd">Published</label>
                            </div>
                          </FormGroup>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Form>
              </CardBody>
            </Card>
            <div id="questions">
              <Card className="card-chart" id="section_q">
                <CardBody>
                  <Form action="/" className="form-horizontal frmQuestion" name="frmQuestion" method="get">
                    <hr/>
                  
                    <Row>
                      <Col md={6}>
                        <FormGroup>
                          <div className="form-floating">
                            <input value="0" name="question_id" id="question_id" type="hidden"/>
                            <input className="form-control" name="question_title" id="question_title" style={{"borderRadius": "5px"}} type="text" />
                            <label htmlFor="pwd">Question Title</label>
                          </div>
                        </FormGroup>
                      </Col>
                    
                      <Col md={6}>
                        <FormGroup>
                          <div className="form-floating">
                            <select name="question_type" className="form-control" id="question_type" style={{"borderRadius": "5px"}} placeholder="Country">
                              <option value="Multiple Choice">Multiple Choice</option>
                              <option value="Dropdown">Dropdown</option>
                              <option value="Linear Scale">Linear Scale</option>
                              <option value="Checkbox">Checkbox</option>
                            </select>
                            <label htmlFor="pwd">Question Type</label>
                          </div>
                        </FormGroup>
                      </Col>
                    </Row>

                    <Row>
                      <Col md={12}>
                        <div id="c_survey">
                          <FormGroup>
                            <a style={{"color":"blue","cursor": "pointer"}} id="addMc">Add option</a>
                          </FormGroup>
                        </div>
                      </Col>
                    </Row>
                    <hr/>
                    <Row>
                      <Col md={12}>
                        <FormGroup style={{float:"right"}}>
                          <div style={{position: "relative",right: "130px",top: "23px"}}>
                            <a style={{"cursor":"pointer"}} id="dupThis"><i className="fa fa-file"/></a>&nbsp;&nbsp;<a style={{"cursor":"pointer"}} id="delThis"><i className="fa fa-trash"/></a>&nbsp;&nbsp; | Required
                          </div>
                          <div className="custom-control custom-switch">
                            <input name="question_required" type="checkbox" className="custom-control-input customSwitches" id="customSwitches"/>
                            <label className="custom-control-label" for="customSwitches"></label>
                          </div>
                        </FormGroup>
                      </Col>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </div>

            <div align="center">
              <Row>
                <Col sm="12">
                  <div align="center">
                    <button className="btn-csconfirm addQuestion" style={{cursor:"pointer",background:"rgb(65, 109, 134)",color:"white"}}>Add Question</button>&nbsp;&nbsp;<button className="btn-csconfirm sendSurvey">Send Surveys</button>
                  </div>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
    
      </div>
    </>
  );
  }
}

export default SurveyDetail;
