function CalculateAge(dateOfBirth) {
    // Tạo một đối tượng Date từ ngày sinh
    const dob = new Date(dateOfBirth);
    console.log(dob)
    // Lấy ngày hiện tại
    const today = new Date();
    
    // Tính toán tuổi
    let age = today.getFullYear() - dob.getFullYear();
    
    // Kiểm tra nếu sinh nhật đã qua trong năm nay
    const monthDiff = today.getMonth() - dob.getMonth();
    if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < dob.getDate())) {
      age--;
    }
    
    return age;
  }
  export default CalculateAge;