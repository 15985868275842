import React from "react";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Label,
  FormGroup,
  Form,
  Input,
  FormText,
  Row,
  Col,
  Table,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu
} from "reactstrap";

// core components
import PanelHeader from "components/PanelHeader/PanelHeader.js";
import Switch from "react-bootstrap-switch";
import profileImg from "assets/upload/profile.png";
import ImageUpload from "components/CustomUpload/ImageUpload.js";
import axios from "axios";
import {base_api_url, file_api_url} from "variables/general.js";
import $ from "jquery";
import swal from 'sweetalert';

function getDataAllUser() {
  let selEl = "#rc_id";

  return axios.get(base_api_url + '/api/v1/authentication/getallusers').then(res => {
    let datas = res.data.response;

    $(selEl).empty();
    $(selEl).append("<option value=''>Select</option>");
    for (var i=0;i<datas.length;i++) {
      $(selEl).append("<option data-role="+datas[i].hasrole+" data-id="+datas[i].id+" data-email="+datas[i].emailaddress+" data-username="+datas[i].username+">"+datas[i].emailaddress+"</option>");
    }

  })

}

function submitData() {
  let user_login = JSON.parse(localStorage.getItem('user_login'));
  var tzoffset = (new Date()).getTimezoneOffset() * 60000; //offset in milliseconds
  var localISOTime = (new Date(Date.now() - tzoffset)).toISOString().slice(0, -1);
  var timestamp = localISOTime;

  let requestData = {
    creatorsrole: user_login.role,
    fkcreatedby: user_login.reference_id,
    isactive: true,
    roleofusertowhomitwillbedisplayed: $("#survey_for").val(),
    surveyname: $("#survey_name").val(),
    timestamp: timestamp
  }

  return axios.post(base_api_url + "/api/v1/mainsurveycontroller/registersurvey", requestData).then(res => {
    console.log(res)
    swal("Success","Successfully created data!","success");
    setTimeout(function(){
      window.location.href = window.location.origin + "/admin/survey";
    },500);
  }).catch(function (error) {
    swal("Failed!", "Something went wrong!", "error");
  });
}

class SurveyNew extends React.Component {
  componentDidMount(){
    getDataAllUser();
    $(document).on("click",".btn-csconfirm",function(e){
      e.preventDefault();
      
      let notValid = 0;
      let msgval = "";
      let cv;

      $(".errorCs").remove();

      $("#contentValidation").empty();

        $.each($('form input'), function(index, formField) {
          if ($(formField).val()==="") {
            cv = $(formField).parent().find("label").text();
            if (cv!=="" && $(formField).attr("type")!=="file" && $(formField).attr("type")!=="hidden") {
              msgval = cv + " is required! <br/>";
              $(formField).parent().append("<span class='errorCs'>" + msgval + "</span>");
              notValid += 1;
            }
          }
        });

        if (notValid>0) {

        } else {
          submitData()
        }
    });

    $(document).on("click",".close",function(){
      $(this).parent().parent().hide();
    });

    $('#photo').change(function(){
      const file = this.files[0];
      if (file){
        $(".errorCs").remove();
        if (file.type=="image/jpeg" || file.type=="image/png") {
          let reader = new FileReader();
          reader.onload = function(event){
            $('#imgPreview').attr('src', event.target.result);
          }
          reader.readAsDataURL(file);
        } else {
          $('#photo').val("");
          $("#imgPreview").attr("src","");
          $('#photo').parent().parent().append("<span class='errorCs'>The image must be a file of type: jpg, jpeg, png.</span>");
        }
      }
    });

  }

  render(){
    const currentDate = new Date();
    return (
    <>
      <PanelHeader size="sm"
        content={
          <div className="content">
            <div className="header text-left">
              <span className="panelHeaderDesc">View and manage your Surveys.</span>
              <nav aria-label="breadcrumb" role="navigation">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item"><a href="#">Dashboard</a></li>
                  <li className="breadcrumb-item"><a href="#">Surveys</a></li>
                  <li className="breadcrumb-item active" aria-current="page">New Survey</li>
                </ol>
              </nav>
            </div>
          </div>
        }
      />
      <br/>
      <div className="content">
        <Form method="post">
          <Row>
            <Col md="12">
              <Card className="card-chart">
                <CardHeader>
                  <CardTitle tag="h4" style={{"fontFamily": "Noto Sans","fontStyle": "normal","fontWeight": 400,"fontSize": "16px","lineHeight": "24px","color": "#70B2D8"}}>{currentDate.toUTCString()}</CardTitle>
                  <a style={{float:"right", height:"30px"}} className="now-ui-icons cs-cancel_yellow" href="/admin/survey" />
                  <br/>
                </CardHeader>
                <CardBody>
                  <hr/> 
                  <Row>
                    <Col sm="12">
                      <Row className="text-desc-notif">
                        <Col md="12">
                          <FormGroup>
                            <div className="form-floating">
                              <Input id="survey_name" style={{"borderRadius": "5px"}} placeholder="Country" type="text" />
                              <label htmlFor="pwd">Survey Name</label>
                            </div>
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row className="text-desc-notif">
                        <Col md="12">
                          <FormGroup>
                            <div className="form-floating">
                              <select className="form-control" id="survey_for" style={{"borderRadius": "5px"}}>
                                <option value="hostfamily">Host Family</option>
                                <option value="student">Student</option>
                              </select>
                              <label htmlFor="pwd">Survey Form Visible To</label>
                            </div>
                          </FormGroup>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
              <div style={{float:"right"}}>
                <Row>
                  <Col sm="12">
                    <div className="pull-left">
                      <button className="btn-csconfirm" style={{background:"rgb(65, 109, 134)",color:"white"}}>Add Survey</button>
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Form>
    
      </div>
    </>
  );
  }
}

export default SurveyNew;
