import React from "react";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Label,
  FormGroup,
  Form,
  Input,
  FormText,
  Row,
  Col,
  Table
} from "reactstrap";

// core components
import PanelHeader from "components/PanelHeader/PanelHeader.js";
import Switch from "react-bootstrap-switch";
import profileImg from "assets/upload/default_profile.png";
import ImageUpload from "components/CustomUpload/ImageUpload.js";
import axios from "axios";
import {base_api_url, file_api_url} from "variables/general.js";
import $ from "jquery";
import swal from 'sweetalert';

function uploadFile() {
  var bodyFormData = new FormData();
  let imageFile = $("#photo")[0].files[0];
  let user_login = JSON.parse(localStorage.getItem('user_login'));

  bodyFormData.append('file', imageFile);
  bodyFormData.append('username', user_login.email);

  axios({
    method: "post",
    url: file_api_url + "/api/v1/user/upload/file",
    data: bodyFormData,
    headers: { "Content-Type": "multipart/form-data" },
  }).then(function (response) {
    $("#a_photourl").val(response.data[0].path);
    submitData();
  });
}

function checkEmailExist(elem) {
  if (elem.val()) {
    var settings = {
      "url": base_api_url + "/api/v1/authentication/isemailtaken",
      "method": "POST",
      "timeout": 0,
      "headers": {
        "Content-Type": "text/plain"
      },
      "data": elem.val(),
    };

    $.ajax(settings).done(function (response) {
      if (response.response==true) {
        elem.parent().append("<span class='errorCs'>Email already taken!</span>")
      } else {
        uploadFile();
      }
    });
  }
  return 0;
}

function sendMail(name, email, password) {
  let data = JSON.stringify({
    "name": name,
    "email": email,
    "password":password,
    "link":window.location.origin + "/auth/login/agent",
    "contact":"spil@spil.ie"
  });

  let config = {
    method: 'post',
    maxBodyLength: Infinity,
    url: file_api_url + '/api/v1/agent/email/registration',
    headers: { 
      'Content-Type': 'application/json'
    },
    data : data
  };

  axios.request(config)
  .then((response) => {
    console.log(JSON.stringify(response.data));
  })
  .catch((error) => {
    console.log(error);
  });
}

function submitData() {
  let user_login = JSON.parse(localStorage.getItem('user_login'));
  var tzoffset = (new Date()).getTimezoneOffset() * 60000; //offset in milliseconds
  var localISOTime = (new Date(Date.now() - tzoffset)).toISOString().slice(0, -1);
  var timestamp = localISOTime;

  let requestData = {
    address: $("#a_address").val(),
    company: $("#a_company").val(),
    emailaddress: $("#a_email").val(),
    isactive: true,
    languages: $("#a_language").val(),
    mobile: $("#a_mobile").val(),
    name: $("#a_name").val(),
    photosharingagreedandread: 1,
    photourl: $("#a_photourl").val(),
    telephone: $("#a_telephone").val(),
    timestamp: timestamp
  }

  return axios.post(base_api_url + "/api/v1/agent/register", requestData).then(res => {
    sendMail($("#a_name").val(), $("#a_email").val(), res.data.response.credentials);
    swal("Success","Successfully create data!","success");
    setTimeout(function(){
      window.location.href = window.location.origin + "/" + user_login.role + "/agent";
    },500);
  }).catch(function (error) {
    swal("Failed!", "Something went wrong!", "error");
  });
}

function getDataCoordinator() {
  let selEl = "#coordinator_id";

  return axios.get(base_api_url + '/api/v1/coord').then(res => {
    let datas = res.data.response;

    $(selEl).empty();
    $(selEl).append("<option value=''>Select</option>");
    
    for (var i=0;i<datas.length;i++) {
      $(selEl).append("<option data-company="+datas[i].company+" data-name="+datas[i].name+" value="+datas[i].id+">"+datas[i].name+"</option>");
    }

  })
}

class AgentRegistration extends React.Component {

  componentDidMount(){
    getDataCoordinator();
    $(document).on("click",".btn-csconfirm",function(e){
      e.preventDefault();
      $(".errorCs").remove();
      
      let notValid = 0;
      let msgval = "";
      let cv;

      $("#contentValidation").empty();

      if ($("#photo").val()==="") {
        $("#photo").parent().parent().append("<span class='errorCs'>File required!</span>");
      } else {

        $.each($('form input'), function(index, formField) {
          if ($(formField).val()==="") {
            cv = $(formField).parent().find("label").text();
            if (cv!=="" && $(formField).attr("type")!=="file" && $(formField).attr("type")!=="hidden" && !$(formField).attr("readonly")) {
              msgval = cv + " is required! <br/>";
              $(formField).parent().append("<span class='errorCs'>" + msgval + "</span>");
              notValid += 1;
            }
          }
        });

        if (notValid>0) {
          //$("#msgValidation").show();
        } else {
          checkEmailExist($("#a_email"));
        }

      }
    });

    $(document).on("click",".close",function(){
      $(this).parent().parent().hide();
    });

    $('#photo').change(function(){
      const file = this.files[0];
      if (file){
        $(".errorCs").remove();
        if (file.type=="image/jpeg" || file.type=="image/png") {
          let reader = new FileReader();
          reader.onload = function(event){
            $('#imgPreview').attr('src', event.target.result);
          }
          reader.readAsDataURL(file);
        } else {
          $('#photo').val("");
          $("#imgPreview").attr("src","");
          $('#photo').parent().parent().append("<span class='errorCs'>The image must be a file of type: jpg, jpeg, png.</span>");
        }
      }
    });

  }

  render(){
    return (
      <>
        <PanelHeader size="sm"
          content={
            <div className="content">
              <div className="header text-left">
                <span className="panelHeaderDesc">View and manage the Standard Reports.</span>
                <nav aria-label="breadcrumb" role="navigation">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item"><a href="/admin/overview">Dashboard</a></li>
                    <li className="breadcrumb-item"><a href="/admin/agent">Agent</a></li>
                    <li className="breadcrumb-item active" aria-current="page" id="br_name">Agent Registration</li>
                  </ol>
                </nav>
              </div>
            </div>
          }
        />
        <br/>
        <div className="content">
          <Form name="formsubmit" id="formsubmit" action="/" className="form-horizontal" method="post">
            <Row>
              <Col md="3">
                <Card className="card-chart">
                  <CardBody>
                    <Table responsive>
                      <tbody>
                        <tr>
                          <td className="td-noborder">
                            <a href="#c_spil_details" className="anchorItemDetail">SPIL Details</a>
                            <a href="#c_agent_details" className="anchorItemDetail">Agent Details</a>
                            <a href="#c_submissions" className="anchorItemDetail">Submissions</a>
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </CardBody>
                </Card>
              </Col>
              <Col md="9">
                <Card id="c_spil_details">
                  <CardHeader>
                    <CardTitle tag="h4" className="panelTitle">SPIL Details</CardTitle>
                  </CardHeader>
                  <CardBody>
                
                    <Row>
                      <Col sm="12">
                        <FormGroup>
                          <h4 style={{"fontFamily": "Noto Sans","fontStyle": "normal","fontWeight": 400,"fontSize": "16px","lineHeight": "24px","color": "#70B2D8"}}>Agent</h4>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm="12">
                        <Row>
                          <Col md="12">
                            <FormGroup>
                              <div className="form-floating">
                                <Input readonly="true" id="a_id" style={{"borderRadius": "5px"}} placeholder="ID" type="text" />
                                <label htmlFor="pwd">ID</label>
                              </div>
                            </FormGroup>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
                <Card id="c_agent_details">
                  <CardHeader>
                    <CardTitle tag="h4" className="panelTitle">Agent Details</CardTitle>
                  </CardHeader>
                  <CardBody>
                    <Row>
                      <Col sm="12">
                        <FormGroup>
                          <h4 style={{"fontFamily": "Noto Sans","fontStyle": "normal","fontWeight": 400,"fontSize": "16px","lineHeight": "24px","color": "#70B2D8"}}>Agent</h4>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm="12">
                        <Row>
                          <Col md="6">
                            <FormGroup>
                              <div className="form-floating">
                                <Input id="a_name" style={{"borderRadius": "5px"}} placeholder="ID" type="text" />
                                <label htmlFor="pwd">Name</label>
                              </div>
                            </FormGroup>
                          </Col>
                          <Col md="6">
                            <FormGroup>
                              <div className="form-floating">
                                <Input id="a_company" style={{"borderRadius": "5px"}} placeholder="ID" type="text" />
                                <label htmlFor="pwd">Company</label>
                              </div>
                            </FormGroup>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm="12">
                        <Row>
                          <Col md="6">
                            <FormGroup>
                              <div className="form-floating">
                                <Input id="a_email" style={{"borderRadius": "5px"}} placeholder="ID" type="text" />
                                <label htmlFor="pwd">Email</label>
                              </div>
                            </FormGroup>
                          </Col>
                          <Col md="6">
                            <FormGroup>
                              <div className="form-floating">
                                <Input id="a_address" style={{"borderRadius": "5px"}} placeholder="ID" type="text" />
                                <label htmlFor="pwd">Address</label>
                              </div>
                            </FormGroup>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm="12">
                        <Row>
                          <Col md="6">
                            <FormGroup>
                              <div className="form-floating">
                                <Input id="a_telephone" style={{"borderRadius": "5px"}} placeholder="ID" type="text" />
                                <label htmlFor="pwd">Telephone</label>
                              </div>
                            </FormGroup>
                          </Col>
                          <Col md="6">
                            <FormGroup>
                              <div className="form-floating">
                                <Input id="a_mobile" style={{"borderRadius": "5px"}} placeholder="ID" type="text" />
                                <label htmlFor="pwd">Mobile</label>
                              </div>
                            </FormGroup>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm="12">
                        <Row>
                          <Col md="6">
                            <FormGroup>
                              <div className="form-floating">
                                <Input id="a_language" style={{"borderRadius": "5px"}} placeholder="ID" type="text" />
                                <label htmlFor="pwd">Language</label>
                              </div>
                            </FormGroup>
                          </Col>
                          <Col md="6">
                            <FormGroup>
                            </FormGroup>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
                <Card id="c_submissions">
                  <CardHeader>
                    <CardTitle tag="h4" className="panelTitle">Submissions</CardTitle>
                  </CardHeader>
                  <CardBody>
            
                    <Row>
                      <Col sm="12">
                        <FormGroup>
                          <h4 style={{"fontFamily": "Noto Sans","fontStyle": "normal","fontWeight": 400,"fontSize": "16px","lineHeight": "24px","color": "#70B2D8"}}>Agent</h4>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm="12">
                        <Row className="bundleBorder">
                          <Col md="2">
                            <FormGroup>
                              <div className="form-floating">             
                                <label htmlFor="pwd">Photos</label>
                              </div>
                            </FormGroup>
                          </Col>
                          <img id="imgPreview" style={{float: "left",width:"76px",height:"76px",position:"relative"}} src={profileImg}></img>
                          <input style={{marginLeft: "20px",marginTop: "24px"}} type="file" id="photo"/>
                          <input type="hidden" id="a_photourl"/>
                        </Row>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col md="3">
              </Col>
              <Col md="9">
                <Row>
                  <Col sm="12">
                    <div align="center">
                      <button style={{"background":"#416D86","color":"white"}} className="btn-csconfirm">Create Agent</button>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Form>
        </div>
      </>
    );
  }
}

export default AgentRegistration;
