import React from "react";

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  FormGroup,
  Form,
  Row,
  Col,
  Table
} from "reactstrap";

// core components
import PanelHeader from "components/PanelHeader/PanelHeader.js";
import Switch from "react-bootstrap-switch";
import profileImg from "assets/upload/default.png";
import ImageUpload from "components/CustomUpload/ImageUpload.js";
import axios from "axios";
import {base_api_url, file_api_url} from "variables/general.js";
import $ from "jquery";
import datepickerFactory from 'jquery-datepicker';
import swal from 'sweetalert';
import DateTime from 'react-datetime';

function getData() {

  const params = new Proxy(new URLSearchParams(window.location.search), {
    get: (searchParams, prop) => searchParams.get(prop),
  });

  let urlTarget = base_api_url + '/api/v1/student/getstudentbyid?studentId=' + params.formid; 
  return axios.get(urlTarget).then(res => {
    let datas = res.data.response;
    localStorage.setItem("detailStudent" + params.formid, JSON.stringify(datas.student));
    showData(datas);
  })
}

function checkEmailExist(elem) {
  if (elem.val()) {
    var settings = {
      "url": base_api_url + "/api/v1/authentication/isemailtaken",
      "method": "POST",
      "timeout": 0,
      "headers": {
        "Content-Type": "text/plain"
      },
      "data": elem.val(),
    };

    $.ajax(settings).done(function (response) {
      if (response.response==true) {
        elem.parent().append("<span class='errorCs'>Email already taken!</span>")
      } else {
        uploadFile();
      }
    });
  }
  return 0;
}

function showData(data) {
  $("#id").val(data.student.id);
  $("#reference").val(data.student.reference);
  $("#school_name").val();
  $("#school_country").val();
  $("#school_town").val();
  $("#school_type").val();
  $("#agent_name").val();
  $("#agent_company").val();
  $("#coordinator_name").val();
  $("#coordinator_company").val();
  $("#student_first_name").val(data.student.firstname);
  $("#student_last_name").val(data.student.lastname);
  $("#t_name").html(data.student.firstname + " " +data.student.lastname);
  $("#t_id").html(data.student.firstname + " " +data.student.lastname);
  $("#br_name").html(data.student.firstname + " " +data.student.lastname);
  $("#student_nationality").val(data.student.nationality);
  $("#student_email_address").val(data.student.emailaddress);

  let dateofbirth;

  if (data.student.dateofbirth) {
    dateofbirth = new Date(data.student.dateofbirth);
    dateofbirth = dateofbirth.toString();
    dateofbirth = dateofbirth.split(" ");
    dateofbirth = dateofbirth[1] + " " + dateofbirth[2] + ", " + dateofbirth[3];
  }
  
  $("#student_date_of_birth").val(dateofbirth);
  $("#student_age").val(data.student.age);
  $("#student_telephone").val(data.student.telephonenumer);
  $("#student_mobile").val(data.student.mobilenumber);
  $("#student_irish_mobile").val(data.student.irishmobilenumber);
  $("#student_religion").val(data.student.religion);
  $("#student_gender").val(data.student.gender);
  $("#student_passport_number").val(data.student.passportnumber);
  $("#student_length_of_stay").val(data.student.lengthofstay);
  $("#student_medical_condition").val(data.student.medicalcondition);
  $("#student_on_any_medication").val(data.student.onanymedication);
  $("#student_diatry_requirements").val(data.student.diatryrequirements);
  $("#student_allergies").val(data.student.allergies);
  $("#student_mother_maiden_name").val(data.student.mothersmaidenname);
  $("#student_family_brother_sister").val(data.student.familybrotherssisters);
  $("#student_school_year_student").val(data.student.schoolyearstudentwillstudyinireland);
  $("#student_hobbies").val(data.student.hoobies);
  $("#student_results").val(data.student.results);
  $("#student_special_requests").val(data.student.specialrequests);
  $("#student_notes").val(data.student.notes);

  let arrivaldate = new Date(data.student.flight_arrivaldate);
  arrivaldate = arrivaldate.toString();
  arrivaldate = arrivaldate.split(" ");
  arrivaldate = arrivaldate[1] + " " + arrivaldate[2] + ", " + arrivaldate[3];

  let departuredate = new Date(data.student.flight_departuredate);
  departuredate = departuredate.toString();
  departuredate = departuredate.split(" ");
  departuredate = departuredate[1] + " " + departuredate[2] + ", " + departuredate[3];

  let flight_time  = arrivaldate[4] + " " + arrivaldate[5];

  $("#student_arrival_date").val(arrivaldate);
  $("#student_departure_date").val(departuredate);
  $("#flight_type").val(data.student.flight_type);
  $("#flight_date").val(arrivaldate);
  $("#flight_time").val(flight_time);
  $("#flight_destination").val(data.student.flight_destination);
  $("#flight_pickup").val(data.student.flight_arrivalpickup);
  $("#flight_number").val(data.student.flight_number);
  $("#profile").attr("src",data.student.photourl);
  $("#s_photourl").val(data.student.photourl);
  $("#imgPreview").attr("src",data.student.photourl);

  $("#programme_rule_status").val(data.student.programmerulesstatus);
  $("#photo_sharing_agreed_and_read").val(data.student.photosharingagreedandread);

  $("#student_status").val(data.student.flightadminapproved);

  if (data.student.programmerulesstatus==="Approved") {
    let prsEl = $("#programme_rule_status").parent().parent().parent().parent().find(".bootstrap-switch");
    prsEl.removeClass("bootstrap-switch-off");
    prsEl.addClass("bootstrap-switch-on");
  }

  if (data.student.photosharingagreedandread==="Approved") {
    let psrEl = $("#photo_sharing_agreed_and_read").parent().parent().parent().parent().find(".bootstrap-switch");
    psrEl.removeClass("bootstrap-switch-off");
    psrEl.addClass("bootstrap-switch-on");
  }
 
  if (data.student.fkschoolid) {
    getDataSchool(data.student.fkschoolid);
  }

  if (data.student.fkagentid) {
    getDataAgent(data.student.fkagentid);
  }

  if (data.student.fkcoordinatorid) {
    getDataCoordinator(data.student.fkcoordinatorid);
  }

  if (data.student.fkhostfamilyid) {
    getDataHf(data.student.fkhostfamilyid);
  }

  if (data.submissions) {
    for (var is=0;is<data.submissions.length;is++) {
      $("#submissions_list").append(`
        <div class="bundleBorder row">
          <div class="col-md-3">
            <div class="form-group">
              <div class="form-floating">
                <label for="pwd">${data.submissions[is].reasonforjourney}</label>
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <div class="form-floating">
                <label for="pwd">${data.submissions[is].timestamp}</label>
              </div>
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-floating">
              <label>${data.submissions[is].approvalstatus}</label>
            </div>
          </div>
          <div class="col-md-2">
            <div class="form-group">
              <a href="#" style="color: rgb(113, 113, 113); font-style: normal; font-weight: 400; font-size: 12px; line-height: 16px;"><i class="now-ui-icons cs-ov-edit-pen-dark" style="position: relative; top: 8px;"></i>Views Detail</a>
              </div>
            </div>
          </div>
      `);
    }
  }

  if (data.TravelLogs) {
    for (var it=0;it<data.TravelLogs.length;it++) {
      $("#travel_logs_list").append(`
      <div class="bundleBorder row">
        <div class="col-md-7">
          <div class="form-group">
            <div class="form-floating">
              <label for="pwd">${data.TravelLogs[it].subject}</label>
            </div>
          </div>
        </div>
        <div class="col-md-3">
          <div class="form-group">
            <div class="form-floating">
              <label for="pwd">${data.TravelLogs[it].timestamp}</label>
            </div>
          </div>
        </div>
        <div class="col-md-2">
          <div class="form-group">
            <a href="#" style="color: rgb(113, 113, 113); font-style: normal; font-weight: 400; font-size: 12px; line-height: 16px;"><i class="now-ui-icons cs-ov-edit-pen-dark" style="position: relative; top: 8px;"></i>Views Detail</a>
            </div>
          </div>
        </div>
      </div>
      `);
    }
  }

}

function getDataHf(id) {
  let selEl = "#hf_id";

  return axios.get(base_api_url + '/api/v1/hf').then(res => {
    let datas = res.data.response;

    $(selEl).empty();
    $(selEl).append("<option value=''>Select</option>");
    for (var i=0;i<datas.length;i++) {
      $(selEl).append("<option data-familyname="+datas[i].familyname+" data-mothername="+datas[i].mothername+" data-datevisited="+datas[i].datevisited+" data-hostfamilychild="+datas[i].nohostfamilychild+" data-fathername="+datas[i].fathername+" data-address="+datas[i].address+" value="+datas[i].id+">"+datas[i].name+"</option>");
    }

    axios.get(base_api_url + '/api/v1/hf/gethostfamilybyid?hostFamilyId=' + id).then(res => {
      $(selEl).val(res.data.response.hfDetails.id).trigger("change");
    })

  })

}

function getDataAgent(id) {

  let selEl = "#agent_id";

  return axios.get(base_api_url + '/api/v1/agent').then(res => {
    let datas = res.data.response;

    $(selEl).empty();
    $(selEl).append("<option value=''>Select</option>");
    for (var i=0;i<datas.length;i++) {
      $(selEl).append("<option data-company="+datas[i].company+" data-name="+datas[i].name+" value="+datas[i].id+">"+datas[i].name+"</option>");
    }

    axios.get(base_api_url + '/api/v1/agent/getagentbyid?agentId=' + id).then(res => {
    let datas = res.data.response;
      $(selEl).val(res.data.response.id).trigger("change");
    })

  })
}

function getDataCoordinator(id) {
  let selEl = "#coordinator_id";

  return axios.get(base_api_url + '/api/v1/coord').then(res => {
    let datas = res.data.response;

    $(selEl).empty();
    $(selEl).append("<option value=''>Select</option>");
    
    for (var i=0;i<datas.length;i++) {
      $(selEl).append("<option data-company="+datas[i].company+" data-name="+datas[i].name+" value="+datas[i].id+">"+datas[i].name+"</option>");
    }

    axios.get(base_api_url + '/api/v1/coord/getcoordbyid?coordId=' + id).then(res => {
      $(selEl).val(res.data.response.id).trigger("change");
    })

  })
}

function getDataSchool(id) {
  let selEl = "#school_id"; 
  return axios.get(base_api_url + '/api/v1/school').then(res => {
    let datas = res.data.response;

    $(selEl).empty();
    $(selEl).append("<option value=''>Select</option>");

    for (var i=0;i<datas.length;i++) {
      $(selEl).append("<option data-type="+datas[i].type+" data-town="+datas[i].town+" data-country="+datas[i].country+" data-name="+datas[i].name+" value="+datas[i].id+">"+datas[i].name+"</option>");
    }

    axios.get(base_api_url + '/api/v1/school/getschoolbyid?schoolId=' + id).then(res => {
      $(selEl).val(res.data.response.id).trigger("change");
    })

  })
}

function uploadFile() {
  var bodyFormData = new FormData();
  let imageFile = $("#photo")[0].files[0];
  let user_login = JSON.parse(localStorage.getItem('user_login'));

  bodyFormData.append('file', imageFile);
  bodyFormData.append('username', user_login.email);

  axios({
    method: "post",
    url: file_api_url + "/api/v1/user/upload/file",
    data: bodyFormData,
    headers: { "Content-Type": "multipart/form-data" },
  }).then(function (response) {
    $("#s_photourl").val(response.data[0].path);
    submitData();
  });
}

function submitData() {

  const params = new Proxy(new URLSearchParams(window.location.search), {
    get: (searchParams, prop) => searchParams.get(prop),
  });

  let user_login = JSON.parse(localStorage.getItem('user_login'));
  var tzoffset = (new Date()).getTimezoneOffset() * 60000; //offset in milliseconds
  var localISOTime = (new Date(Date.now() - tzoffset)).toISOString().slice(0, -1);
  var timestamp = localISOTime;
  let isactive;

  let arrivaldateraw = new Date($("#student_arrival_date").val());
  let arrivaldate = arrivaldateraw.getTimezoneOffset() * 60000;
  arrivaldate = (new Date(arrivaldateraw - arrivaldate)).toISOString().slice(0, -1);

  let departuredateraw = new Date($("#student_departure_date").val())
  let departuredate = departuredateraw.getTimezoneOffset() * 60000;
  departuredate = (new Date(departuredateraw - departuredate)).toISOString().slice(0, -1);

  isactive = $("#student_status").val();

  let requestData = JSON.parse(localStorage.getItem("detailStudent" + params.formid));

  requestData["fkhostfamilyid"] = $("#hf_id").val();
  requestData["flight_arrivaldate"] = arrivaldate;
  requestData["flight_arrivalpickup"] = $("#flight_pickup").val();
  requestData["flight_departuredate"] = departuredate;
  requestData["flight_destination"] = $("#flight_destination").val();
  requestData["flight_number"] = $("#flight_number").val();
  requestData["flight_type"] = $("#flight_type").val();
  requestData["flightadminapproved"] = isactive;
  requestData["timestamp"] = timestamp;

  return axios.put(base_api_url + "/api/v1/student/updatebystudentid?role=" + user_login.role, requestData).then(res => {
    swal("Success","Successfully update data!","success");
    setTimeout(function(){
      window.location.reload();
    },500);
  }).catch(function (error) {
    swal("Failed!", "Something went wrong!", "error");
  });
}

class StudentDetail extends React.Component {

  constructor() {
    super();
    this.state = { checked: false };
    this.handleSwitchChangePrs = this.handleSwitchChangePrs.bind(this);
    this.handleSwitchChangePsa = this.handleSwitchChangePsa.bind(this);
    this.handleSwitch = this.handleSwitch.bind(this);
  }

  handleSwitchChangePrs(checked){
    if (checked.state.value==false){
      $("#programme_rule_status").val("Declined");
    } else {
      $("#programme_rule_status").val("Approved");
    }
  }

  handleSwitchChangePsa(checked){
    if (checked.state.value==false){
      $("#photo_sharing_agreed_and_read").val("Declined");
    } else {
      $("#photo_sharing_agreed_and_read").val("Approved");
    }
  }

  handleSwitch(checked) {
    if (checked.state.value==false){
      $("#student_status").val("REJECTED");
    } else {
      $("#student_status").val("APPROVED");
    }
  }
  
  componentDidMount(){

    datepickerFactory($);
    $('#student_arrival_date').datepicker({dateFormat: "MM dd, yyyy"});
    $('#student_departure_date').datepicker({dateFormat: "MM dd, yyyy"});
    $('#hf_date_visited').datepicker({dateFormat: "MM dd, yyyy"});
    $('#flight_date').datepicker({dateFormat: "MM dd, yyyy"});

    $(document).on("click",".btn-csconfirm",function(e){
      e.preventDefault();
      $(".errorCs").remove();
      
      let notValid = 0;
      let msgval = "";
      let cv;
      $.each($('form input'), function(index, formField) {
        if ($(formField).val()==="") {
          cv = $(formField).parent().find("label").text();
          if (cv!==""  && $(formField).attr("type")!=="file" && $(formField).attr("type")!=="hidden") {
            msgval = cv + " is required! <br/>";
            $(formField).parent().append("<span class='errorCs'>" + msgval + "</span>");
            notValid += 1;
          }
        }
      });

      if (notValid>0) {
        //$("#msgValidation").show();
      } else {
        submitData();
      }
    });

    $(document).on("click",".close",function(){
      $(this).parent().parent().hide();
    });

    $('#photo').change(function(){
      const file = this.files[0];
      if (file){
        $(".errorCs").remove();
        if (file.type=="image/jpeg" || file.type=="image/png") {
          let reader = new FileReader();
          reader.onload = function(event){
            $('#imgPreview').attr('src', event.target.result);
          }
          reader.readAsDataURL(file);
        } else {
          $('#photo').val("");
          $("#imgPreview").attr("src","");
          $('#photo').parent().parent().append("<span class='errorCs'>The image must be a file of type: jpg, jpeg, png.</span>");
        }
      }
    });

    getData()

    $(document).on("change","#agent_id",function(e){
      $("#agent_name").val($(this).find(':selected').data('name'));
      $("#agent_company").val($(this).find(':selected').data('company'));
    });

    $(document).on("change","#school_id",function(e){
      $("#school_name").val($(this).find(':selected').data('name'));
      $("#school_country").val($(this).find(':selected').data('country'));
      $("#school_town").val($(this).find(':selected').data('town'));
      $("#school_type").val($(this).find(':selected').data('type'));
    });

    $(document).on("change","#coordinator_id",function(e){
      $("#coordinator_name").val($(this).find(':selected').data('name'));
      $("#coordinator_company").val($(this).find(':selected').data('company'));
    });

    $(document).on("change","#hf_id",function(e){
      $("#hf_family_name").val($(this).find(':selected').data('familyname'));
      $("#hf_father_name").val($(this).find(':selected').data('fathername'));
      $("#hf_mother_name").val($(this).find(':selected').data('mothername'));
      $("#hf_address").val($(this).find(':selected').data('address'));

      let dvraw = $(this).find(':selected').data('datevisited');
      let dv = new Date(dvraw);
      dv = dv.toString().split(" ");
      dv = dv[1] + " " + dv[2] + ", " + dv[3];
      $("#hf_date_visited").val(dv);
      $("#hf_host_family_child").val($(this).find(':selected').data('hostfamilychild'));
    });

    $(document).on("change","#student_date_of_birth",function(){
      let dob = new Date($(this).val());
      console.log(dob)
      var today = new Date();
      var age = Math.floor((today-dob) / (365.25 * 24 * 60 * 60 * 1000));
      console.log(age)
      $('#student_age').val(age);
    });

  }

  render(){
    return (
    <>
      <PanelHeader size="sm"
        content={
          <div className="content">
            <div className="header text-left">
              <span className="panelHeaderDesc">View and manage the Flight information Detail.</span>
              <nav aria-label="breadcrumb" role="navigation">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item"><a href="/admin/overview">Dashboard</a></li>
                  <li className="breadcrumb-item"><a href="/admin/submission">Submissions</a></li>
                  <li className="breadcrumb-item active" aria-current="page" id="br_name"></li>
                </ol>
              </nav>
            </div>
          </div>
        }
      />
      <br/>
      <div className="content">
        <Form name="formsubmit" id="formsubmit" action="/" className="form-horizontal" method="post">
          <Row>
            <Col md="3">
              <Card className="card-chart">
                <CardBody>
                  <Table responsive>
                    <tbody>
                      <tr>
                        <td className="td-noborder">
                          <a href="#c_spil_details" className="anchorItemDetail">SPIL Details</a>
                          <a href="#c_student_details" className="anchorItemDetail">Student Details</a>
                          <a href="#c_trip_information" className="anchorItemDetail">Trip Information</a>
                          <a href="#c_submissions" className="anchorItemDetail">Submissions</a>
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </CardBody>
              </Card>
            </Col>
            <Col md="9">
              <Card>
                <CardHeader className="card-header-profile">
                  <Row>
                    <Col md={2}>
                      <img id="profile" style={{float: "left",width:"76px",height:"76px",position:"relative",bottom:"18px"}} src={profileImg}></img>
                    </Col>
                    <Col md={8}>
                      <p className="profile-title" id="t_name"></p>
                      <p className="profile-desc" id="t_id"></p>
                    </Col>
                    <Col md={2}>
                      <select className="form-control" id="student_status">
                        <option value="PENDING">PENDING</option>
                        <option value="APPROVED">APPROVED</option>
                        <option value="REJECTED">REJECTED</option>
                      </select>
                    </Col>
                  </Row>
                </CardHeader>
              </Card>
              <Card id="c_trip_information">
                <CardHeader>
                  <CardTitle tag="h4" className="panelTitle">Trip Information</CardTitle>
                </CardHeader>
                <CardBody>
                  <Row>
                    <Col sm="12">
                      <FormGroup>
                        <h4 style={{"fontFamily": "Noto Sans","fontStyle": "normal","fontWeight": 400,"fontSize": "16px","lineHeight": "24px","color": "#70B2D8"}}>Student</h4>
                      </FormGroup>
                    </Col>
                  </Row>
          
                  <Row>
                    <Col md="6">
                      <FormGroup>
                        <div className="input-group mb-3">
                          <div className="form-floating form-floating-group flex-grow-1">
                              <input id="student_arrival_date" className="form-control" style={{"borderRadius": "5px"}} name="code1" placeholder="Code 1"/>
                              <label>Arrival Date</label>
                          </div>
                          <span className="input-group-text input-group-text-custom"><i className="now-ui-icons cs-icon-calendar"></i></span>
                        </div>
                      </FormGroup>
                    </Col>
                    <Col md="6">
                      <FormGroup>
                        <div className="input-group mb-3">
                          <div className="form-floating form-floating-group flex-grow-1">
                            <input id="student_departure_date" className="form-control" style={{"borderRadius": "5px"}} name="code1" placeholder="Code 1"/>
                            <label>Departure Date</label>
                          </div>
                          <span className="input-group-text input-group-text-custom"><i className="now-ui-icons cs-icon-calendar"></i></span>
                        </div>
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row>
                    <Col sm="12">
                      <FormGroup>
                        <h4 style={{"fontFamily": "Noto Sans","fontStyle": "normal","fontWeight": 400,"fontSize": "16px","lineHeight": "24px","color": "#70B2D8"}}>Host Family</h4>
                      </FormGroup>
                    </Col>
                  </Row>
              
                  <Row>
                    <Col md={6}>
                      <FormGroup>
                        <div className="form-floating">
                          <select placeholder="s" id="hf_id" style={{borderRadius:"5px"}} className="form-control">
                            <option value="">Select</option>
                          </select>
                          <label htmlFor="pwd">Host Family</label>
                        </div>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="6">
                      <FormGroup>
                        <div className="form-floating">
                          <input readonly="true" id="hf_family_name" className="form-control" style={{"borderRadius": "5px"}} placeholder="ID" type="text" />
                          <label htmlFor="pwd">Family Name</label>
                        </div>
                      </FormGroup>
                    </Col>
                    <Col md="6">
                      <FormGroup>
                        <div className="form-floating">
                          <input readonly="true" id="hf_host_family_child" className="form-control" style={{"borderRadius": "5px"}} placeholder="ID" type="text" />
                          <label htmlFor="pwd">Host Family Child</label>
                        </div>
                      </FormGroup>
                    </Col>
                  </Row>
              
                  <Row>
                    <Col md="6">
                      <FormGroup>
                        <div className="form-floating">
                          <input readonly="true" id="hf_mother_name" className="form-control" style={{"borderRadius": "5px"}} placeholder="ID" type="text" />
                          <label htmlFor="pwd">Mother Name</label>
                        </div>
                      </FormGroup>
                    </Col>
                    <Col md="6">
                      <FormGroup>
                        <div className="form-floating">
                          <input readonly="true" id="hf_father_name" className="form-control" style={{"borderRadius": "5px"}} placeholder="ID" type="text" />
                          <label htmlFor="pwd">Father Name</label>
                        </div>
                      </FormGroup>
                    </Col>
                  </Row>
        
                  <Row>
                    <Col md="6">
                      <FormGroup>
                        <div className="input-group mb-3">
                          <div className="form-floating form-floating-group flex-grow-1">
                              <input readonly="true" id="hf_date_visited" className="form-control" style={{"borderRadius": "5px"}} name="code1" placeholder="Code 1"/>
                              <label>Date Visited</label>
                          </div>
                          <span className="input-group-text input-group-text-custom"><i className="now-ui-icons cs-icon-calendar"></i></span>
                        </div>
                      </FormGroup>
                    </Col>
                    <Col md="6">
                      <FormGroup>
                        <div className="form-floating">
                          <input readonly="true" id="hf_address" className="form-control" style={{"borderRadius": "5px"}} placeholder="ID" type="text" />
                          <label htmlFor="pwd">Address</label>
                        </div>
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row>
                    <Col sm="12">
                      <FormGroup>
                        <h4 style={{"fontFamily": "Noto Sans","fontStyle": "normal","fontWeight": 400,"fontSize": "16px","lineHeight": "24px","color": "#70B2D8"}}>Flight</h4>
                      </FormGroup>
                    </Col>
                  </Row>
   
                  <Row>
                    <Col md="6">
                      <FormGroup>
                        <div className="form-floating">
                          <input id="flight_type" className="form-control" style={{"borderRadius": "5px"}} placeholder="ID" type="text" />
                          <label htmlFor="pwd">Type</label>
                        </div>
                      </FormGroup>
                    </Col>
                    <Col md="6">
                      <FormGroup>
                        <div className="input-group mb-3">
                          <div className="form-floating form-floating-group flex-grow-1">
                            <input id="flight_date" className="form-control" style={{"borderRadius": "5px"}} name="code1" placeholder="Code 1"/>
                            <label>Date</label>
                          </div>
                          <span className="input-group-text input-group-text-custom"><i className="now-ui-icons cs-icon-calendar"></i></span>
                        </div>
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row>
                    <Col md="6">
                      <FormGroup>
                        <DateTime timeFormat="HH:mm"
                          dateFormat={false}
                          inputProps={{placeholder: "00:00",id: "flight_time" }}
                        />
                      </FormGroup>
                    </Col>
                    <Col md="6">
                      <FormGroup>
                        <div className="form-floating">
                          <input id="flight_destination" className="form-control" style={{"borderRadius": "5px"}} placeholder="ID" type="text" />
                          <label htmlFor="pwd">Destination</label>
                        </div>
                      </FormGroup>
                    </Col>
                  </Row>
               
                  <Row>
                    <Col md="6">
                      <FormGroup>
                        <div className="form-floating form-floating-group flex-grow-1">
                          <input id="flight_pickup" className="form-control" style={{"borderRadius": "5px"}} name="code1" placeholder="Code 1"/>
                          <label>Pickup</label>
                        </div>
                      </FormGroup>
                    </Col>
                    <Col md="6">
                      <FormGroup>
                        <div className="form-floating">
                          <input id="flight_number" className="form-control" style={{"borderRadius": "5px"}} placeholder="ID" type="text" />
                          <label htmlFor="pwd">Flight Number</label>
                        </div>
                      </FormGroup>
                    </Col>
                  </Row>
                    
                </CardBody>
              </Card>
              <Row>
                <Col sm="12">
                  <div align="center">
                    <button className="btn-csconfirm">Update Changes</button>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Form>
      </div>
    </>
  );
  }
}

export default StudentDetail;