import React from "react";
import { Container } from "reactstrap";
// used for making the prop types of this component
import PropTypes from "prop-types";

function FooterCoordinator(props) {
  return (
    <footer className={"footer" + (props.default ? " footer-default" : "")}>
      <Container fluid={props.fluid}>
        <nav style={{"float":"right","fontStyle": "normal","fontWeight": 400,"fontSize": "14px","lineHeight": "20px"}}>
          <ul>
            <li>
              <a
                href={window.location.origin + "/about"}
                className="mr-4-px"
              >
                © 2022 Student Programmes Ireland Ltd. All Rights Reserved.
              </a>
            </li>
            <li>
              <a
                href={window.location.origin + "/about"}
                className="mr-4-px"
              >
                Contact us
              </a>
            </li>
            <li>
              <a
                href={window.location.origin + "/privacy"}
              >
                Website by Speire
              </a>
            </li>
          </ul>
        </nav>
      </Container>
    </footer>
  );
}

FooterCoordinator.defaultProps = {
  default: false,
  fluid: false
};

FooterCoordinator.propTypes = {
  default: PropTypes.bool,
  fluid: PropTypes.bool
};

export default FooterCoordinator;
