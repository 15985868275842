import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import "bootstrap/dist/css/bootstrap.css";
import "assets/scss/now-ui-dashboard.scss?v=1.5.0";
import "assets/css/demo.css";
import "assets/css/datatable.css";
import "assets/css/custom.css";
import "@fontsource/noto-sans";
import "@fontsource/mulish";
import "assets/css/datepicker.css";

import AdminLayout from "layouts/Admin.js";
import AgentLayout from "layouts/Agent.js";
import StudentLayout from "layouts/Student.js";
import HostFamilyLayout from "layouts/HostFamily.js";
import CoordinatorLayout from "layouts/Coordinator.js";
import AuthLayout from "layouts/Auth.js";
import HomeLayout from "layouts/Home.js";
import SpilLayout from "layouts/Spil.js";
import $ from "jquery";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <BrowserRouter>
    <Switch>
      <Route
        path="/admin"
        render={(props) => {
          return <AdminLayout {...props} />;
        }}
      />
      <Route
        path="/agent"
        render={(props) => {
          return <AgentLayout {...props} />;
        }}
      />
      <Route
        path="/coordinator"
        render={(props) => {
          return <CoordinatorLayout {...props} />;
        }}
      />
      <Route
        path="/hostfamily"
        render={(props) => {
          return <HostFamilyLayout {...props} />;
        }}
      />
      <Route
        path="/student"
        render={(props) => {
          return <StudentLayout {...props} />;
        }}
      />
      <Route
        path="/auth"
        render={(props) => {
          return <AuthLayout {...props} />;
        }}
      />
      <Route
        path="/about"
        render={(props) => {
          return <SpilLayout {...props} />;
        }}
      />
      <Route
        path="/privacy"
        render={(props) => {
          return <SpilLayout {...props} />;
        }}
      />
      <Route
        path="/"
        render={(props) => {
          return <HomeLayout {...props} />;
        }}
      />
      <Redirect from="/now-ui-dashboard-pro-react" to="/"/>
    </Switch>
  </BrowserRouter>
);

$(document).ready(function(){
  $(".wrapper").show();
})