import React from "react";

import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Label,
  FormGroup,
  Form,
  Input,
  FormText,
  Row,
  Col,
  Table,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu
} from "reactstrap";

// core components
import PanelHeader from "components/PanelHeader/PanelHeader.js";
import Switch from "react-bootstrap-switch";
import profileImg from "assets/upload/profile.png";
import ImageUpload from "components/CustomUpload/ImageUpload.js";
import axios from "axios";
import {base_api_url, file_api_url} from "variables/general.js";
import $ from "jquery";
import Modals from "views/Components/Modals.js";

function getUrlAction() {
  let currentUrl;
  let baseUrl = base_api_url;

  currentUrl = baseUrl + "/api/v1/notification";

  return currentUrl;
}

function uploadFile() {
  var bodyFormData = new FormData();
  let imageFile = $("#photo")[0].files[0];
  let user_login = JSON.parse(localStorage.getItem('user_login'));

  bodyFormData.append('file', imageFile);
  bodyFormData.append('username', user_login.email);

  axios({
    method: "post",
    url: file_api_url + "/api/v1/user/upload/file",
    data: bodyFormData,
    headers: { "Content-Type": "multipart/form-data" },
  }).then(function (response) {
    $("#n_photourl").val(response.data[0].path);
    submitData();
  }).catch(function (response) {
    $("#contentValidation").html("Failed upload profile!");
    $("#msgValidation").show();
  });
}

function submitData() {
  let user_login = JSON.parse(localStorage.getItem('user_login'));

  let urlTarget = getUrlAction();
  var date = new Date();
  var timestamp = date.toISOString();

  let requestData = {
    fkformid: 0,
    formtype: $("#formType").val(),
    id: 0,
    isactive: true,
    isread: false,
    lastupdatedon: timestamp,
    message: $("#message").val(),
    method: "post",
    reason: $("#subject").val(),
    receiversid: 1,
    receiversrole: "admin",
    status: "string",
    statuschangedatetime: timestamp,
    submittersid: user_login.reference_id,
    submittersname: user_login.firstname + " " + user_login.lastname,
    submittersrole: user_login.role,
    threadid: 0,
    timestamp: timestamp
  }

  return axios.post(urlTarget, requestData).then(res => {
    $("#contentValidation").empty();
    $("#msgValidation").hide();
    $("#contentSuccess").html("Success!");
    $("#msgSuccess").show();
    setTimeout(function(){
      window.location.href = window.location.origin + "/" + user_login.role + "/admin/notifications";
    },500);
  }).catch(function (error) {
    $("#contentValidation").html(error.response.data.message);
    $("#msgValidation").show();
  });
}

class NotificationNew extends React.Component {
  componentDidMount(){
    $(document).on("click",".btn-csconfirm",function(e){
      e.preventDefault();
      
      let notValid = 0;
      let msgval = "";
      let cv;

      $("#contentValidation").empty();

      if ($("#photo").val()==="") {
        $("#contentValidation").html("File required!");
        $("#msgValidation").show();
      } else {

        $.each($('form input'), function(index, formField) {
          if ($(formField).val()==="") {
            cv = $(formField).parent().find("label").text();
            if (cv!=="" && $("#contentValidation").text()==="") {
              msgval = cv + " is required! <br/>";
              $("#contentValidation").html(msgval);
              notValid += 1;
            }
          }
        });

        if (notValid>0) {
          $("#msgValidation").show();
        } else {
          $("#msgValidation").hide();
          $("#contentValidation").empty();
          uploadFile()
        }

      }
    });

    $(document).on("click",".close",function(){
      $(this).parent().parent().hide();
    });

    $('#photo').change(function(){
      const file = this.files[0];
      if (file){
        let reader = new FileReader();
        reader.onload = function(event){
          $('#imgPreview').attr('src', event.target.result);
        }
        reader.readAsDataURL(file);
      }
    });

  }

  render(){
    const currentDate = new Date();
    return (
    <>
      <Modals/>
      <PanelHeader size="sm"
        content={
          <div className="content">
            <div className="header text-left">
              <span className="panelHeaderDesc">View and manage your Notifications.</span>
              <nav aria-label="breadcrumb" role="navigation">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item"><a href="/admin/overview">Dashboard</a></li>
                  <li className="breadcrumb-item"><a href="#">Notifications</a></li>
                  <li className="breadcrumb-item active" aria-current="page">New Notification</li>
                </ol>
              </nav>
            </div>
          </div>
        }
      />
      <br/>
      <div className="content">
        <Row>
          <Col md="12">
            <Card className="card-chart">
              <CardHeader>
                <CardTitle tag="h4" style={{"fontFamily": "Noto Sans","fontStyle": "normal","fontWeight": 400,"fontSize": "16px","lineHeight": "24px","color": "#70B2D8"}}>{currentDate.toUTCString()}</CardTitle>
                <a style={{float:"right", height:"30px"}} className="now-ui-icons cs-cancel_yellow" href="/admin/notifications" />
                <br/>
              </CardHeader>
              <CardBody>
                <Input type="hidden" id="n_photourl"/>
                <Form action="/" className="form-horizontal" method="get">
                  <hr/>
                
                  <Row>
                    <Col sm="12">
                      <Row className="text-desc-notif">
                        <Col md="12">
                          <FormGroup>
                            <div className="form-floating">
                              <Input id="receipent" style={{"borderRadius": "5px"}} placeholder="Country" type="text" />
                              <label htmlFor="pwd">Receipents</label>
                            </div>
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row className="text-desc-notif">
                        <Col md="12">
                          <FormGroup>
                            <div className="form-floating">
                              <Input id="subject" style={{"borderRadius": "5px"}} placeholder="Country" type="text" />
                              <label htmlFor="pwd">Subject</label>
                            </div>
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row className="text-desc-notif">
                        <Col md="12">
                          <FormGroup>
                            <div className="form-floating">
                              <textarea id="message" rows="8" className="form-control" style={{height:"auto",borderRadius: "5px", border: "1px solid #e3e3e3"}} placeholder="text"></textarea>
                              <label htmlFor="pwd">Message</label>
                            </div>
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row className="text-desc-notif">
                        <Col md="12">
                          <FormGroup>
                            <label style={{cursor:"pointer"}}><small>Upload document</small> <i className="now-ui-icons arrows-1_cloud-upload-94"></i><input className="form-control" type="file" id="photo"/></label>
                            <br/>
                            <img width="auto" style={{maxHeight:"81px"}} id="imgPreview"/>
                          </FormGroup>
                        </Col>
                      </Row>
                    </Col>
                  </Row>

                  <Row>
                    <Col sm="12">
                      <div className="pull-left">
                      	<button className="btn-csconfirm">Send Notifications</button>
                      </div>
                    </Col>
                  </Row>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
    
      </div>
    </>
  );
  }
}

export default NotificationNew;
