import React from "react";
import $ from "jquery";
import axios from "axios";
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  Table,
  Row,
  Col
} from "reactstrap";

// core components
import PanelHeader from "components/PanelHeader/PanelHeader.js";

import { notification_data, submission_data, report_data, base_api_url } from "variables/general.js";

import { Calendar as BigCalendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import SweetAlert from "react-bootstrap-sweetalert";
import { events } from "variables/general.js";
import bell_notif from "assets/svg/overview/bell_notif.svg";
const localizer = momentLocalizer(moment);

// Set up Axios interceptors
axios.interceptors.request.use(
    (config) => {
        // Get the token from localStorage
        const token = localStorage.getItem('token');
        
        // If token exists, add it to the headers
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        
        return config;
    },
    (error) => {
        // Handle request error
        return Promise.reject(error);
    }
);


function relative_time(date_str) {
    if (!date_str) {return;}
    date_str = $.trim(date_str);
    date_str = date_str.replace(/\.\d\d\d+/,""); // remove the milliseconds
    date_str = date_str.replace(/-/,"/").replace(/-/,"/"); //substitute - with /
    date_str = date_str.replace(/T/," ").replace(/Z/," UTC"); //remove T and substitute Z with UTC
    date_str = date_str.replace(/([\+\-]\d\d)\:?(\d\d)/," $1$2"); // +08:00 -> +0800
    var parsed_date = new Date(date_str);
    var relative_to = (arguments.length > 1) ? arguments[1] : new Date(); //defines relative to what ..default is now
    var delta = parseInt((relative_to.getTime()-parsed_date)/1000);
    delta=(delta<2)?2:delta;
    var r = '';
    if (delta < 60) {
    r = delta + ' seconds ago';
    } else if(delta < 120) {
    r = 'a minute ago';
    } else if(delta < (45*60)) {
    r = (parseInt(delta / 60, 10)).toString() + ' minutes ago';
    } else if(delta < (2*60*60)) {
    r = 'an hour ago';
    } else if(delta < (24*60*60)) {
    r = '' + (parseInt(delta / 3600, 10)).toString() + ' hours ago';
    } else if(delta < (48*60*60)) {
    r = 'a day ago';
    } else {
    r = (parseInt(delta / 86400, 10)).toString() + ' days ago';
    }
    return ' ' + r;
}

const CustomToolbar = (toolbar) => {
  const goToBack = () => {
    if (toolbar.view=="month"){
      toolbar.date.setMonth(toolbar.date.getMonth() - 1);
    } else {
      toolbar.date.setMonth(toolbar.date.getMonth(), toolbar.date.getDate()-7);
    }
    toolbar.onNavigate('prev');
  };

  const goToNext = () => {
    if (toolbar.view=="month"){
      toolbar.date.setMonth(toolbar.date.getMonth() + 1);
    } else {
      toolbar.date.setMonth(toolbar.date.getMonth(), toolbar.date.getDate()+7);
    }
    toolbar.onNavigate('next');
  };

  const goToWeekView = () => {
    toolbar.onView('week');
  };
  
  const goToMonthView = () => {
    toolbar.onView('month');
  };

  const goToCurrent = () => {
    const now = new Date();
    toolbar.date.setMonth(now.getMonth());
    toolbar.date.setYear(now.getFullYear());
    toolbar.onNavigate('current');
  };

  const label = () => {
    const date = moment(toolbar.date);
    return (
      <>
        <b>{date.format('MMMM')}</b> {date.format('YYYY')}
      </>
    );
  };

  return (
    <>
    <div style={{background: "#FCFCFC",borderRadius: "9px 12px 0px 0px"}}>
    <div align="center">
      <input defaultChecked={true} type="radio" name="viewx" onClick={goToWeekView}/><label className="label-view-session">&nbsp;&nbsp;Week View</label>
      &nbsp;&nbsp;&nbsp;<input type="radio" name="viewx" onClick={goToMonthView}/><label className="label-view-session">&nbsp;&nbsp;Month View</label>
    </div>
    <div className='toolbar-container'>

      <label style={{float:"left"}} className='label-date'>{label()}</label>

      <div style={{float:"right"}}  className='back-next-buttons'>
        <button className='btn-calendar-back' onClick={goToBack}><i className="now-ui-icons arrows-1_minimal-left"/></button>
        <button className='btn-calendar-current' onClick={goToCurrent}>Today</button>
        <button className='btn-calendar-next' onClick={goToNext}><i className="now-ui-icons arrows-1_minimal-right"/></button>
      </div>
    </div>
    </div>
    <br/><br/>
    </>
  );
};
var i = 0;
function CustomDateHeader({ label, drilldownView, onDrillDown }) {
    return (
      <div class="rbc-header"><span className="calTitle">{label}</span></div>
    )
}

function notifWrapper(el1,el2,el3,el4){
  return `<tr>
            <td style="width:10%"; class="td-noborder">
              <div class="flag">
                <div class="containerBell">
                    <img src="${el3}" alt="notif"/>
                </div>
              </div>
            </td>
            <td style="width:75%"; class="td-noborder">
              <h4 class="titleOverview">${el1}</h4>
              <p class="descOverview">${el2}</p>
            </td>
            <td style="width:10%"; class="td-noborder">
                <i class="now-ui-icons cs-ov-clock-gray"/> <small>${relative_time(el4)}</small>
            </td>
          </tr>`;
}

function submissionWrapper(el1,el2,el3){
  return `<tr>
            <td class="td-noborder">
              <div class="td-border-right-orange">
                <h4 class="ml-2 titleOverview">${el1}</h4>
                <p class="ml-2 descOverview">${el2}</p>
              </div>
            </td>
          </tr>`;
}

function Dashboard() {

  const [eventsState, setEventsState] = React.useState([]);
  const [alert, setAlert] = React.useState(null);
  const selectedEvent = (event) => {
    window.alert(event.title);
  };

  const addNewEventAlert = (slotInfo) => {
    setAlert(
      <SweetAlert
        input
        showCancel
        style={{ display: "block", marginTop: "-100px" }}
        title="Input something"
        onConfirm={(e) => addNewEvent(e, slotInfo)}
        onCancel={() => hideAlert()}
        confirmBtnBsStyle="info"
        cancelBtnBsStyle="danger"
      />
    );
  };

  React.useEffect(() => {
    axios.get("https://spilappireland.ie/api/v1/calendar/get").then(res => {
      let datas = res.data.data;
      let formating = [];
      for (var i=0;i<datas.length;i++) {
        let obj = {
          allDay:datas[i].allDay,
          end:datas[i].end.substr(0, 10),
          start:datas[i].start.substr(0, 10),
          title:datas[i].title
        }
        formating.push(obj)
      }
      setEventsState(formating)
    })

    axios.get(base_api_url + "/api/v1/admin/getAdminDashboard").then(res => {
      let resm = res.data.response;
      let notifs = resm.allnotification.content
      let subms = resm.allSubmissions.content
      let srcimg = {bell_notif};

      for (var i=0;i<notifs.length;i++){
        $("#notif").append(notifWrapper(notifs[i].method,notifs[i].message,srcimg.bell_notif,notifs[i].timestamp));
      }

      for (var i=0;i<subms.length;i++){
        $("#submission").append(submissionWrapper(subms[i].method,subms[i].message,srcimg.bell_notif));
      }

      $("#totalAgents").html(resm.totalAgents);
      $("#totalCoordinators").html(resm.totalCoordinators);
      $("#totalHostfamilies").html(resm.totalHostfamilies);
      $("#totalNotification").html(resm.totalNotification);
      $("#totalSchools").html(resm.totalSchools);
      $("#totalAgents").html(resm.totalAgents);
      $("#totalStudents").html(resm.totalStudents);
      $("#totalSubmissions").html(resm.totalSubmissions);
    })
  }, []);

  const addNewEvent = (e, slotInfo) => {
    var newEvents = eventsState;
    newEvents.push({
      title: e,
      start: slotInfo.start,
      end: slotInfo.end
    });
    setAlert(null);
    setEventsState(newEvents);
  };
  const hideAlert = () => {
    setAlert(null);
  };
  const eventColors = (event, start, end, isSelected) => {
    var backgroundColor = "event-";
    event.color
      ? (backgroundColor = backgroundColor + event.color)
      : (backgroundColor = backgroundColor + "default");
    return {
      className: backgroundColor
    };
  };
  return (
    <>
      <PanelHeader
        size="sm"
      />
      <div className="content">
        <Row>
          <Col xs={12} md={12}>
            <Card className="card-stats card-raised">
              <CardBody>
                <Row>
                  <div className="containerfive">
                    <div className="statistics">
                      <div className="info">
                        <div className="icon icon-primary headerComponentDashboard">
                          <i className="now-ui-icons cs-ov-student" />
                        </div>
                        <h3 className="info-title" id="totalStudents"></h3>
                        <h6 className="stats-title">Students</h6>
                        <a className="linkblue" href="/admin/student">View More</a>
                      </div>
                    </div>
                  </div>
                  <div className="containerfive">
                    <div className="statistics">
                      <div className="info">
                        <div className="icon icon-success headerComponentDashboard">
                          <i className="now-ui-icons cs-ov-family" />
                        </div>
                        <h3 className="info-title" id="totalHostfamilies"></h3>
                        <h6 className="stats-title">Host Families</h6>
                        <a className="linkblue" href="/admin/host-family">View More</a>
                      </div>
                    </div>
                  </div>
                  <div className="containerfive">
                    <div className="statistics">
                      <div className="info">
                        <div className="icon icon-info headerComponentDashboard">
                          <i className="now-ui-icons cs-ov-coordinator" />
                        </div>
                        <h3 className="info-title" id="totalCoordinators"></h3>
                        <h6 className="stats-title">Coordinators</h6>
                        <a className="linkblue" href="/admin/coordinator">View More</a>
                      </div>
                    </div>
                  </div>
                  <div className="containerfive">
                    <div className="statistics">
                      <div className="info">
                        <div className="icon icon-danger headerComponentDashboard">
                          <i className="now-ui-icons cs-ov-agent" />
                        </div>
                        <h3 className="info-title" id="totalAgents"></h3>
                        <h6 className="stats-title">Agents</h6>
                        <a className="linkblue" href="/admin/agent">View More</a>
                      </div>
                    </div>
                  </div>
                  <div className="containerfive">
                    <div className="statistics">
                      <div className="info">
                        <div className="icon icon-danger">
                          <i className="now-ui-icons cs-ov-school" />
                        </div>
                        <h3 className="info-title" id="totalSchools"></h3>
                        <h6 className="stats-title">Schools</h6>
                        <a className="linkblue" href="/admin/school">View More</a>
                      </div>
                    </div>
                  </div>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={6}>
            <Card className="card-chart" style={{height:"397.47px"}}>
              <CardHeader>
                <h5 className="card-category">Notifications</h5>
                <CardTitle tag="h2" id="totalNotification"></CardTitle>
                <UncontrolledDropdown>
                  <div
                    className="btn-round btn-icon"
                  >
                    <i className="now-ui-icons cs-ov-bell" />
                  </div>
                  <DropdownMenu right>
                    <DropdownItem>Action</DropdownItem>
                    <DropdownItem>Another Action</DropdownItem>
                    <DropdownItem>Something else here</DropdownItem>
                    <DropdownItem className="text-danger">
                      Remove data
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </CardHeader>
              <CardBody>
                <hr/>
                <Table responsive>
                  <tbody style={{ "display":"block","height":"195px",overflow:"auto"}} id="notif"></tbody>
                </Table>
                <br/>
                <a style={{position: "relative",top: "5px"}} className="pull-right linkblue" href="/admin/notifications">View All</a><br/>
              </CardBody>
            </Card>
          </Col>
          <Col xs={12} md={6}>
            <Card className="card-chart" style={{height:"397.47px"}}>
              <CardHeader>
                <h5 className="card-category">Submissions</h5>
                <CardTitle tag="h2" id="totalSubmissions"></CardTitle>
                <UncontrolledDropdown>
                  <div
                    className="btn-round btn-icon"
                   >
                    <i className="now-ui-icons cs-ov-submission" />
                  </div>
                  <DropdownMenu right>
                    <DropdownItem>Action</DropdownItem>
                    <DropdownItem>Another Action</DropdownItem>
                    <DropdownItem>Something else here</DropdownItem>
                    <DropdownItem className="text-danger">
                      Remove data
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </CardHeader>
              <CardBody>
                <hr/>
                <h4 className="dashboardMostRecent">Most Recent</h4>
                <Table responsive>
                  <tbody style={{ "display":"block","height":"195px",overflow:"auto"}} id="submission"></tbody>
                </Table>
                <a className="pull-right linkblue" href="/admin/submission">View All</a><br/>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={12}>
            <Card className="card-chart" style={{border: "2px solid #C6EAFF",borderRadius: "10px"}}>
              {alert}     
              <CardBody>
                <BigCalendar
                  selectable
                  localizer={localizer}
                  events={eventsState}
                  components={{
                    toolbar: CustomToolbar,
                    week:{
                      header:CustomDateHeader
                    }
                  }}
                  defaultView="week"
                  scrollToTime={new Date(1970, 1, 1, 6)}
                  defaultDate={new Date()}
                  onSelectEvent={(event) => selectedEvent(event)}
                  eventPropGetter={eventColors}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default Dashboard;
