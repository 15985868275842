import React from "react";
import axios from "axios";
import $ from "jquery";

// reactstrap components
import {
  Card,
  CardBody,
  CardHeader,
  Form,
  Container,
  Col,
  Input,
  InputGroup,
  Button
} from "reactstrap";
import {base_api_url, spil_url, file_api_url} from "variables/general.js";

// core components
import nowLogo from "assets/img/spill/logo.png";
import bgImage from "assets/img/spill/bg-login.svg";
import Modals from "views/Components/Modals.js";

class ChangePassword extends React.Component {
  componentDidMount(){
    const params = new Proxy(new URLSearchParams(window.location.search), {
      get: (searchParams, prop) => searchParams.get(prop),
    });

    let email = params.email;

    if (params.message) {
      $("#msgSuccess").hide();
      $("#msgFailed").hide();
      $("#msgValidation").show();
      $("#contentValidation").html("Unauthorized or session expired!");
    }

    $(document).on("click",".submitChange",function(e){
      e.preventDefault();

      let url = spil_url + "/api/auth/changepassword?email=" + $(".email").val()

      let email = params.email;
      let password = $(".password").val();
      let confpassword = $(".confirm_pass").val();
      let oldpassword = $(".oldpassword").val();

      let notValid = 0;
      let msgval = "";
      let cv;
      
      $.each($('form input'), function(index, formField) {
        if ($(formField).val()==="") {
          cv = $(formField).data("text");
          if (cv!=="") {
            msgval = cv + " is required! <br/>";
            $("#contentValidation").html(msgval);
            notValid += 1;
          }
        }
      });

      if (notValid>0) {
        $("#msgValidation").show();
      } else {

        axios.post(base_api_url + "/api/v1/authentication/changepassword",{
          email: email,
          newpassword: password,
          oldpassword: oldpassword
        }).then(res => {

          setTimeout(function(){
            window.location.href = window.location.origin + "/auth/login/admin";
          },500);

        }).catch(function (error) {
            $("#msgSuccess").hide();
            $("#msgFailed").fadeIn();
        })

      }
    })

    $(document).on("click",".close",function(){
      $(this).parent().parent().fadeOut();
    });

  }
  render(){
  return (
    <>
      <Modals messageSuccess="Success!" messageFailed="Failed!"/>
      <div className="content" style={{ backgroundSize: "cover", backgroundImage: "url(" + bgImage + ")" }}>
        <div className="login-page">
          <Container>
            <Col xs={12} md={8} lg={4} className="ml-auto mr-auto">
              <Form>
                <Card className="card-login card-plain" style={{height:"500px"}}>
                  <CardHeader>
                    <div className="logo-container" style={{margin:0}}>
                      <img src={nowLogo} alt="now-logo" />
                    </div>
                  </CardHeader>
                  <CardBody className="cs-card-body">
                    <div align="center">
                      <h3 style={{fontFamily: 'Noto Sans',fontStyle: "normal",fontWeight: 600,fontSize: "28px",color: "#416D86"}}>Forgot Password</h3>
                      <p>Please create new password.</p>
                    </div>
                    <Form method="post" name="formsubmit">
                      <InputGroup
                        className={"no-border form-control-lg"}
                      >
                        <Input data-text="Old Password" id="old_password"
                          type="password" className='oldpassword input-txt'
                          placeholder="Old Password"
                        />
                      </InputGroup>
                      <InputGroup
                        className={"no-border form-control-lg"}
                      >
                        <Input data-text="New Password" id="password"
                          type="password" className='password input-txt'
                          placeholder="New Password"
                        />
                      </InputGroup>
                      <InputGroup
                        className={"no-border form-control-lg"}
                      >
                        <Input data-text="Confirm New Password" id="confirm_pass"
                          type="password" className='confirm_pass input-txt'
                          placeholder="Confirm New Password"
                        />
                      </InputGroup>
                      <InputGroup align="center">
                      <Button
                        block
                        color="primary"
                        size="lg"
                        className="mb-3 btn-round submitChange"
                      >
                        Confirm
                      </Button>
                      </InputGroup>
                    </Form>
                  </CardBody>
                </Card>
              </Form>
            </Col>
          </Container>
        </div>
      </div>
    </>
  );

  }
}

export default ChangePassword;