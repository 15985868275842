import React from "react";
import axios from "axios";
import $ from "jquery";

// reactstrap components
import {
  Card,
  CardBody,
  CardHeader,
  Form,
  Container,
  Col,
  Input,
  InputGroup,
  Button
} from "reactstrap";
import {base_api_url} from "variables/general.js";

// core components
import nowLogo from "assets/img/spill/logo.png";
import bgImage from "assets/img/spill/bg-login.svg";
import swal from 'sweetalert';

function getHostFamilyData(id) {
  let currentUrl;
  let baseUrl = base_api_url;
  currentUrl = baseUrl + "/api/v1/hf/gethostfamilybyid?hostFamilyId=" + id;
  return currentUrl;
}

function getUrlAction() {
  let currentUrl;
  let baseUrl = base_api_url;
  currentUrl = baseUrl + "/api/v1/login";
  return currentUrl;
}

class HostFamilyLoginPage extends React.Component {
  componentDidMount(){

    localStorage.removeItem("user_login");

    const params = new Proxy(new URLSearchParams(window.location.search), {
      get: (searchParams, prop) => searchParams.get(prop),
    });

    if (params.message) {
      swal("Ops!", "Unauthorized or session expired", "error");
    }

    let rb_hostfamily = JSON.parse(localStorage.getItem("hostfamily_rb"));

    if (rb_hostfamily){
      $(".email").val(rb_hostfamily.username);
      $(".password").val(rb_hostfamily.password);
      $(".rememberMe").prop("checked",true);
    }

    $(document).on("click",".submitLogin",function(e){
      e.preventDefault();

      let remember = $(".rememberMe").is(":checked");

      if (remember) {
        let hostfamily_rb = {
          username:$(".email").val(),
          password:$(".password").val()
        }
        localStorage.setItem("hostfamily_rb", JSON.stringify(hostfamily_rb));
      } else {
        localStorage.removeItem("hostfamily_rb");
      }

      let urlTarget = getUrlAction(); 
        axios.post(urlTarget + "?username=" + $(".email").val() + "&password=" + $(".password").val() + "&role=hostfamily",{
          username: $(".email").val(),
          password: $(".password").val()
        }).then(res => {
        
        if (typeof res.data.access_token == "undefined" || res.data.is_active == "false" || res.data.role!=="hostfamily") {
          swal("Failed!", "Failed to login!", "error");
        } else {

          let user_role = res.data.role;
          let reference_id = res.data.reference_id;
          let access_token = res.data.access_token;
          let refresh_token = res.data.refresh_token;
      
          axios.get(getHostFamilyData(reference_id)).then(res => {
            const now = new Date()

            let rLogin = {
              email:$(".email").val(),
              expire:now.getTime() + 9000000,
              role:user_role,
              reference_id:reference_id,
              access_token:access_token,
              refresh_token:refresh_token
            };

            let resEnd = Object.assign(rLogin, res.data.response)
            localStorage.setItem("user_login",JSON.stringify(resEnd));

            swal("Success","Login successful!","success");

            setTimeout(function(){
              window.location.href = window.location.origin + "/" + user_role + "/setting";
            },500);
          });          
        }

      }).catch(
        function (error) {
          swal("Failed!", "Something went wrong!", "error");
        }
      )
    })
    $(document).on("click",".close",function(){
      $(this).parent().parent().fadeOut();
    });
  }
  render(){
  return (
    <>
      <div className="content" style={{ backgroundSize: "cover", backgroundImage: "url(" + bgImage + ")" }}>
        <div className="login-page">
          <Container>
            <Col xs={12} md={8} lg={4} className="ml-auto mr-auto">
              <Form>
                <Card className="card-login card-plain">
                  <CardHeader>
                    <div className="logo-container">
                      <img src={nowLogo} alt="now-logo" />
                    </div>
                  </CardHeader>
                  <CardBody className="cs-card-body">
                    <InputGroup
                      className={"no-border form-control-lg"}
                    >
                      <Input
                        type="text" className='email input-txt'
                        placeholder="Email"
                      />
                    </InputGroup>
                    <InputGroup
                      className={
                        "no-border form-control-lg"
                      }
                    >
                      <Input
                        type="password" className='password input-txt'
                        placeholder="Password"
                      />
                    </InputGroup>
                                        <InputGroup
                      className={
                        "no-border form-control-lg "
                      }
                    >
                      <Input type="checkbox" className='rememberMe input-checkbox'
                      /><span className='rememberMeLogin'>Remember me</span>
                    </InputGroup>
                    <InputGroup align="center">
                      <Button
                      block
                      color="primary"
                      size="lg"
                      className="mb-3 btn-round submitLogin"
                    >
                      Login
                    </Button>
                    </InputGroup>
                    <div align="center">
                      <div>
                        <h6>
                          <a style={{}} href="/auth/forgot-password?role=hostfamily" className="forgotPasswordFont link footer-link">
                            Forgot Password ?
                          </a>
                        </h6>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Form>
            </Col>
          </Container>
        </div>
      </div>
    </>
  );

  }
}

export default HostFamilyLoginPage;