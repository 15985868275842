import React from "react";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Label,
  FormGroup,
  Form,
  Input,
  FormText,
  Row,
  Col,
  Table,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu
} from "reactstrap";

// core components
import PanelHeader from "components/PanelHeader/PanelHeader.js";
import Switch from "react-bootstrap-switch";
import profileImg from "assets/upload/profile.png";
import ImageUpload from "components/CustomUpload/ImageUpload.js";
import axios from "axios";
import {base_api_url, file_api_url} from "variables/general.js";
import $ from "jquery";
import swal from 'sweetalert';

function getUrlAction() {
  let currentUrl;
  let baseUrl = base_api_url;

  currentUrl = baseUrl + "/api/v1/notification";

  return currentUrl;
}

function uploadFile() {
  var bodyFormData = new FormData();
  let imageFile = $("#photo")[0].files[0];
  let user_login = JSON.parse(localStorage.getItem('user_login'));

  bodyFormData.append('file', imageFile);
  bodyFormData.append('username', user_login.email);

  axios({
    method: "post",
    url: file_api_url + "/api/v1/user/upload/file",
    data: bodyFormData,
    headers: { "Content-Type": "multipart/form-data" },
  }).then(function (response) {
    $("#n_photourl").val(response.data[0].path);
    submitData();
  }).catch(function (response) {
    swal("Failed!", "Failed upload file!", "error");
  });
}

function getDataAllUser() {
  let selEl = "#rc_id";

  return axios.get(base_api_url + '/api/v1/authentication/getallusers').then(res => {
    let datas = res.data.response;

    $(selEl).empty();
    $(selEl).append("<option value=''>Select</option>");
    for (var i=0;i<datas.length;i++) {
      $(selEl).append("<option data-role="+datas[i].hasrole+" data-id="+datas[i].corespondanceId+" data-email="+datas[i].emailaddress+" data-username="+datas[i].username+">"+datas[i].emailaddress+" ("+datas[i].hasrole+")</option>");
    }

  })

}

function submitData() {
  let user_login = JSON.parse(localStorage.getItem('user_login'));

  let urlTarget = getUrlAction();
  var tzoffset = (new Date()).getTimezoneOffset() * 60000; //offset in milliseconds
  var localISOTime = (new Date(Date.now() - tzoffset)).toISOString().slice(0, -1);
  var timestamp = localISOTime;

  let requestData = {
    isactive: true,
    isread: false,
    formtype:"Admin Notification",
    lastupdatedon: timestamp,
    message: $("#message").val(),
    method: "Notification",
    reason: $("#subject").val(),
    receiversid: $("#rc_id").find(':selected').data('id'),
    receiversrole: $("#rc_id").find(':selected').data('role'),
    receiversname: $("#rc_id").find(':selected').data('username'),
    statuschangedatetime: timestamp,
    submittersid: user_login.reference_id,
    submittersname: user_login.firstname + " " + user_login.lastname,
    submittersrole: user_login.role,
    timestamp: timestamp
  }

  return axios.post(urlTarget, requestData).then(res => {
    swal("Success","Successfully created data!","success");
    setTimeout(function(){
      window.location.href = window.location.origin + "/admin/notifications";
    },500);
  }).catch(function (error) {
    swal("Failed!", "Something went wrong!", "error");
  });
}

class NotificationNew extends React.Component {
  componentDidMount(){
    getDataAllUser();
    $(document).on("click",".btn-csconfirm",function(e){
      e.preventDefault();
      
      let notValid = 0;
      let msgval = "";
      let cv;

      $(".errorCs").remove();

      $.each($('form input'), function(index, formField) {
        if ($(formField).val()==="") {
          cv = $(formField).parent().find("label").text();
          if (cv!=="" && $(formField).attr("type")!=="file" && $(formField).attr("type")!=="hidden"  && !$(formField).attr("readonly")) {
            msgval = cv + " is required! <br/>";
            $(formField).parent().append("<span class='errorCs'>" + msgval + "</span>");
            notValid += 1;
          }
        }
      });

      if (notValid>0) {
        //$("#msgValidation").show();
      } else {
        
        if ($("#photo").val()==="") {
          submitData();
        } else {
          uploadFile();
        }

      }

    });

    $(document).on("click",".close",function(){
      $(this).parent().parent().hide();
    });

    $('#photo').change(function(){
      const file = this.files[0];
      if (file){
        let reader = new FileReader();
        reader.onload = function(event){
          $('#imgPreview').attr('src', event.target.result);
        }
        reader.readAsDataURL(file);
      }
    });

  }

  render(){
    const currentDate = new Date();
    return (
    <>
      <PanelHeader size="sm"
        content={
          <div className="content">
            <div className="header text-left">
              <span className="panelHeaderDesc">View and manage your Notifications.</span>
              <nav aria-label="breadcrumb" role="navigation">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item"><a href="/admin/overview">Dashboard</a></li>
                  <li className="breadcrumb-item"><a href="/admin/notifications">Notifications</a></li>
                  <li className="breadcrumb-item active" aria-current="page">New Notification</li>
                </ol>
              </nav>
            </div>
          </div>
        }
      />
      <br/>
      <div className="content">
        <Row>
          <Col md="12">
            <Card className="card-chart">
              <CardHeader>
                <CardTitle tag="h4" style={{"fontFamily": "Noto Sans","fontStyle": "normal","fontWeight": 400,"fontSize": "16px","lineHeight": "24px","color": "#70B2D8"}}>{currentDate.toUTCString()}</CardTitle>
                <a style={{float:"right", height:"30px"}} className="now-ui-icons cs-cancel_yellow" href="/admin/notifications" />
                <br/>
              </CardHeader>
              <CardBody>
                <Input type="hidden" id="n_photourl"/>
                <Form action="/" className="form-horizontal" method="get">
                  <hr/>
                
                  <Row>
                    <Col sm="12">
                      <Row className="text-desc-notif">
                        <Col md="12">
                          <FormGroup>
                            <div className="form-floating">
                              <select className="form-control" id="rc_id" style={{"borderRadius": "5px"}} placeholder="Country" type="text" />
                              <label htmlFor="pwd">Receipents</label>
                            </div>
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row className="text-desc-notif">
                        <Col md="12">
                          <FormGroup>
                            <div className="form-floating">
                              <Input id="subject" style={{"borderRadius": "5px"}} placeholder="Country" type="text" />
                              <label htmlFor="pwd">Subject</label>
                            </div>
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row className="text-desc-notif">
                        <Col md="12">
                          <FormGroup>
                            <div className="form-floating">
                              <textarea id="message" rows="8" className="form-control" style={{height:"auto",borderRadius: "5px", border: "1px solid #e3e3e3"}} placeholder="text"></textarea>
                              <label htmlFor="pwd">Message</label>
                            </div>
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row className="text-desc-notif">
                        <Col md="12">
                          <FormGroup>
                            <label style={{cursor:"pointer"}}><small>Upload document</small> <i className="now-ui-icons arrows-1_cloud-upload-94"></i><input className="form-control" type="file" id="photo"/></label>
                            <br/>
                            <img width="auto" style={{maxHeight:"81px"}} id="imgPreview"/>
                          </FormGroup>
                        </Col>
                      </Row>
                    </Col>
                  </Row>

                  <Row>
                    <Col sm="12">
                      <div className="pull-left">
                      	<button className="btn-csconfirm">Send Notifications</button>
                      </div>
                    </Col>
                  </Row>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
    
      </div>
    </>
  );
  }
}

export default NotificationNew;
