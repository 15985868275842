import React from "react";

function Modals(props) {
  return (
    <>
      <div id="msgSuccess" className="modal" style={{display:"none"}}>
        <div className="modal-content">
          <span className="close">&times;</span>
          <p style={{fontWeight:600,color:"green",marginTop:"10px",marginLeft:"10px"}} id="contentSuccess">{props.messageSuccess}</p>
        </div>
      </div>
      <div id="msgFailed" className="modal" style={{display:"none"}}>
        <div className="modal-content">
          <span className="close">&times;</span>
          <p style={{fontWeight:600,color:"red",marginTop:"10px",marginLeft:"10px"}} id="contentFailed">{props.messageFailed}</p>
        </div>
      </div>
      <div id="msgConfirm" className="modal" style={{display:"none"}}>
        <div className="modal-content">
          <span className="close">&times;</span>
          <p style={{fontWeight:600,color:"red",marginTop:"10px",marginLeft:"10px"}} id="contentConfirm"></p>
          <div>
            <button style={{border: "none",margin: "10px",height: "30px",width: "42%"}} id="confirmYes">Yes</button>
            <button style={{border: "none",margin: "10px",height: "30px",width: "42%"}} id="confirmNo">No</button>
          </div>
        </div>
      </div>
      <div id="msgValidation" className="modal" style={{display:"none"}}>
        <div className="modal-content">
          <span className="close">&times;</span>
          <div align="center">
          <p style={{fontWeight:600,color:"red",marginTop:"10px",marginLeft:"10px"}} id="contentValidation"></p>
          </div>
        </div>
      </div>
    </>
  );
}

export default Modals;
