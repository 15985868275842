import React from "react";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Label,
  FormGroup,
  Form,
  Input,
  FormText,
  Row,
  Col,
  Table,
  Collapse
} from "reactstrap";

// core components
import PanelHeader from "components/PanelHeader/PanelHeader.js";
import Switch from "react-bootstrap-switch";
import profileImg from "assets/upload/default.png";
import ImageUpload from "components/CustomUpload/ImageUpload.js";
import axios from "axios";
import {base_api_url, file_api_url} from "variables/general.js";
import $ from "jquery";
import 'select2';
import 'select2/dist/css/select2.css';
import swal from 'sweetalert';

function getData() {

  const params = new Proxy(new URLSearchParams(window.location.search), {
    get: (searchParams, prop) => searchParams.get(prop),
  });

  let urlTarget = base_api_url + "/api/v1/school/getschoolbyid?schoolId=" + params.id; 
  return axios.get(urlTarget).then(res => {
    let datas = res.data.response;
    localStorage.setItem("schoolDetail"+params.id,JSON.stringify(datas.SchoolEntity));
    showData(datas);
  })
}

function uploadFile() {
  var bodyFormData = new FormData();
  let imageFile = $("#photo")[0].files[0];
  let user_login = JSON.parse(localStorage.getItem('user_login'));

  bodyFormData.append('file', imageFile);
  bodyFormData.append('username', user_login.email);

  axios({
    method: "post",
    url: file_api_url + "/api/v1/user/upload/file",
    data: bodyFormData,
    headers: { "Content-Type": "multipart/form-data" },
  }).then(function (response) {
    $("#s_photourl").val(response.data[0].path);
    submitData();
  });
}

function uploadDocuments() {
  var bodyFormData = new FormData();
  let imageFile = $("#document_upload")[0].files[0];
  const params = new Proxy(new URLSearchParams(window.location.search), {
    get: (searchParams, prop) => searchParams.get(prop),
  });

  bodyFormData.append('file', imageFile);
  bodyFormData.append('username', "school_id_"+params.id);

  axios({
    method: "post",
    url: file_api_url + "/api/v1/user/upload/file",
    data: bodyFormData,
    headers: { "Content-Type": "multipart/form-data" },
  }).then(function (response) {
    let pdfPath = response.data[0].path;

    if (pdfPath) {

      const parts = pdfPath.split("/");

      // Get the last part which contains the file name
      const fileNameWithExtension = parts[parts.length - 1];

      // Split the file name by "." to separate the name and extension
      const fileNameParts = fileNameWithExtension.split(".");
      let fileName = fileNameParts[0];
      const extension = fileNameParts[1];
      fileName = fileName + "." + extension;
      let user_login = JSON.parse(localStorage.getItem('user_login'));

      axios({
        method: "post",
        url: base_api_url + "/api/v1/file",
        data: {
          doctype: extension,
          filelocation: pdfPath,
          filename: fileName,
          fkassociation: 0,
          fkformtype: 0, 
          fkuserid: params.id, 
          fkuserrole: "school",
          id: null,
          isactive: true,
          notes: "File Upload",
          uploadbyrole:"coord",
          uploadbyid:user_login.reference_id
        }
      }).then(function (response) {

        let fileId = response.data.response.id;

          $("#documents_lists").append(`<div class="bundleBorder row">
            <div class="col-md-3">
              <div class="form-group">
                <div class="form-floating">
                  <label for="pwd">${fileName}</label>
                </div>
              </div>
            </div>
            <div class="col-md-9" align="right">
              <div class="form-group">
                <a style="color: rgb(113, 113, 113); font-style: normal; font-weight: 400; font-size: 12px; line-height: 16px;" target="_blank" href="${pdfPath}"><i style="position: relative; top: 8px;" class="now-ui-icons cs-ov-edit-pen-dark"></i> Show File</a>
                <a data-id=${fileId} style="color:red;cursor:pointer;font-style: normal; font-weight: 400; font-size: 12px; line-height: 16px;" class="delDoc"><i style="position: relative; top: 2px;" class="now-ui-icons ui-1_simple-remove"></i> Remove</a>
                </div>
              </div>
            </div>`);

      });

    }
    
  });
}

function getDocumentsByUserIdAndRole(userid, role) {

  let user_login = JSON.parse(localStorage.getItem('user_login'));
  let uploadById = user_login.reference_id;
  let uploadByRole = "coord";

  axios({
    method: "GET",
    url: base_api_url + `/api/v1/file/getfilelist?userId=${userid}&userRole=${role}&uploadById=${uploadById}&uploadByRole=${uploadByRole}`
  }).then(function (response) {
    let datas = response.data.response;
    for (var i=0;i<datas.length;i++) {

      const parts = datas[i].filelocation.split("/");

      // Get the last part which contains the file name
      const fileNameWithExtension = parts[parts.length - 1];

      // Split the file name by "." to separate the name and extension
      const fileNameParts = fileNameWithExtension.split(".");
      let fileName = fileNameParts[0];
      const extension = fileNameParts[1];
      fileName = fileName + "." + extension;

      $("#documents_lists").append(`
        <div class="bundleBorder row">
          <div class="col-md-3">
            <div class="form-group">
              <div class="form-floating">
                <label for="pwd">${datas[i].filename}</label>
              </div>
            </div>
          </div>
          <div class="col-md-9" align="right">
            <div class="form-group">
              <a style="color: rgb(113, 113, 113); font-style: normal; font-weight: 400; font-size: 12px; line-height: 16px;" target="_blank" href="${datas[i].filelocation}"><i style="position: relative; top: 8px;" class="now-ui-icons cs-ov-edit-pen-dark"></i> Show File</a>
              <a data-id=${datas[i].id} style="color:red;cursor:pointer;font-style: normal; font-weight: 400; font-size: 12px; line-height: 16px;" class="delDoc"><i style="position: relative; top: 2px;" class="now-ui-icons ui-1_simple-remove"></i> Remove</a>
              </div>
            </div>
          </div>
      `);

    }
  });
}

function showData(data) {
  let coords = data.Coord;
  let selCoord = [];

  if (coords) {
    for (var i=0;i<coords.length;i++) {
      selCoord.push(coords[i].id);
    }
  }

  setTimeout(function(){
    $("#coordinator_id").val(selCoord).trigger("change");
  },500);

  let hfs = data.Hostfamily;
  let selHf = [];

  if (hfs) {
    for (var j=0; j<hfs.length; j++) {
      selHf.push(hfs[j].id);
    }
  }
  setTimeout(function(){
    $("#hf_id").val(selHf).trigger("change");
  },2000);
  
  data = data.SchoolEntity;

  getDocumentsByUserIdAndRole(data.id, "school");

  $("#attachedStudent").attr("href","/admin/school/attached/student?id="+data.id)

  if (data.jackets==="yes") {
    $("#switch_school_jackets").prop("checked",true);
  } 

  if (data.tracksuits==="yes") {
    $("#switch_school_tracksuite").prop("checked",true);
  }

  $("#school_activities").val(data.activities.split(",")).trigger("change");
  $("#school_address").val(data.address)
  $("#school_country").val(data.country)
  $("#school_email").val(data.emailaddress)
  $("#school_id").val(data.id)
  $("#school_jackets").val(data.jackets)
  $("#school_languages").val(data.languages.split(",")).trigger("change");
  $("#school_mobile").val(data.mobile)
  $("#school_name").val(data.name)
  $("#br_name").html(data.name);
  $("#t_name").html(data.name)
  $("#t_id").html(data.id)
  if (data.photourl) {
    $("#s_photourl").val(data.photourl);
    $("#imgPreview").attr("src",data.photourl);
    $("#profile").attr("src",data.photourl);
  }
  $("#school_principal_name").val(data.principalname)
  $("#school_fifth_year").val(data.school5thyear)
  $("#school_fifth_year_classes").val(data.school5thyearclasses.split(",")).trigger("change");
  $("#school_fifth_year_cost").val(data.schoolpecentageyearcost)
  $("#school_town").val(data.town)
  $("#school_tracksuite").val(data.tracksuits)
  $("#school_transition_classes").val(data.transitionclasses.split(",")).trigger("change");
  $("#school_transition_cost").val(data.transitioncost)
  $("#school_transition_year").val(data.transitionyear)
  $("#school_type").val(data.type)
  $("#school_uniform_detail").val(data.uniformdetails)
  $("#school_website").val(data.website)
  $("#school_year_enrolement_fees").val(data.yearlyenrolementfees)
  $("#school_status").val(data.adminapprovalstatus)
  
}

function getDataHfOnly() {
  let selEl = "#hf_id";

  return axios.get(base_api_url + '/api/v1/hf').then(res => {
    let datas = res.data.response;

    $(selEl).empty();
    $(selEl).append("<option value=''>Select</option>");
    for (var i=0;i<datas.length;i++) {
      $(selEl).append("<option data-familyname="+datas[i].familyname+" data-mothername="+datas[i].mothername+" data-datevisited="+datas[i].datevisited+" data-hostfamilychild="+datas[i].nohostfamilychild+" data-fathername="+datas[i].fathername+" data-address="+datas[i].address+" value="+datas[i].id+">"+datas[i].familyname+"</option>");
    }

  })

}

function getDataCoordinatorOnly() {
  let selEl = "#coordinator_id";

  return axios.get(base_api_url + '/api/v1/coord').then(res => {
    let datas = res.data.response;

    $(selEl).empty();
    $(selEl).append("<option value=''>Select</option>");
    
    for (var i=0;i<datas.length;i++) {
      $(selEl).append("<option data-company="+datas[i].company+" data-name="+datas[i].name+" value="+datas[i].id+">"+datas[i].name+"</option>");
    }

  })
}

function submitData() {

  let user_login = JSON.parse(localStorage.getItem('user_login'));
  var date = new Date();
  var timestamp = date.toISOString();
  let pipeParam = "?id=" + user_login.reference_id + "&role=" + user_login.role;

  const params = new Proxy(new URLSearchParams(window.location.search), {
    get: (searchParams, prop) => searchParams.get(prop),
  });

  let requestData = JSON.parse(localStorage.getItem("schoolDetail"+params.id));
  requestData["activities"]= $("#school_activities").val().join(";");
  requestData["address"]= $("#school_address").val();
  requestData["adminapprovalstatus"]= $("#school_status").val();
  requestData["country"]= $("#school_country").val();
  requestData["emailaddress"]= $("#school_email").val();
  // requestData["fkhf"]= $("#hf_id").val();
  requestData["id"]= $("#school_id").val();
  requestData["jackets"]= $("#school_jackets").val();
  requestData["languages"]= $("#school_languages").val().join(";");
  requestData["mobile"]= $("#school_mobile").val();
  requestData["name"]= $("#school_name").val();
  requestData["photosharingagreedandread"]= 1;
  requestData["photourl"]= $("#s_photourl").val();
  requestData["principalname"]= $("#school_principal_name").val();
  requestData["school5thyear"]= $("#school_fifth_year").val();
  requestData["school5thyearclasses"]= $("#school_fifth_year_classes").val().join(";");
  requestData["schoolpecentageyearcost"]= $("#school_fifth_year_cost").val();
  requestData["timestamp"]= timestamp;
  requestData["town"]= $("#school_town").val();
  requestData["tracksuits"]= $("#school_tracksuite").val();
  requestData["transitionclasses"]= $("#school_transition_classes").val().join(";");
  requestData["transitioncost"]= $("#school_transition_cost").val();
  requestData["transitionyear"]= $("#school_transition_year").val();
  requestData["type"]= $("#school_type").val();
  requestData["uniformdetails"]= $("#school_uniform_detail").val();
  requestData["website"]= $("#school_website").val();
  requestData["yearlyenrolementfees"]= $("#school_year_enrolement_fees").val();

  let pipeCoord = $("#coordinator_id").val().join("%7C");

  let pipeHf = $("#hf_id").val().join("%7C");

  if (pipeCoord.length>0){
    pipeParam = pipeParam + "&pipeSepratedCoord=" + pipeCoord;
  }

  if (pipeHf.length>0){
    pipeParam = pipeParam + "&pipeSepratedHf=" + pipeHf;
  }

  return axios.put(base_api_url + "/api/v1/school/updatebyschoolid" + pipeParam, requestData).then(res => {
    swal("Success","Successfully update data!","success");
    setTimeout(function(){
      window.location.reload();
    },500);
  }).catch(function (error) {
    swal("Failed!", "Something went wrong!", "error");
  });
}

function getDataHf(id) {
  let selEl = "#hf_id";

  return axios.get(base_api_url + '/api/v1/hf').then(res => {
    let datas = res.data.response;

    $(selEl).empty();
    $(selEl).append("<option value=''>Select</option>");
    for (var i=0;i<datas.length;i++) {
      $(selEl).append("<option data-familyname="+datas[i].familyname+" data-mothername="+datas[i].mothername+" data-datevisited="+datas[i].datevisited+" data-hostfamilychild="+datas[i].nohostfamilychild+" data-fathername="+datas[i].fathername+" data-address="+datas[i].address+" value="+datas[i].id+">"+datas[i].familyname+"</option>");
    }

    axios.get(base_api_url + '/api/v1/hf/gethostfamilybyid?hostFamilyId=' + id).then(res => {
      $(selEl).val(res.data.response.hfDetails.id).trigger("change");
    })

  })

}

function getDataCoordinator(id) {
  let selEl = "#coordinator_id";

  return axios.get(base_api_url + '/api/v1/coord').then(res => {
    let datas = res.data.response;

    $(selEl).empty();
    $(selEl).append("<option value=''>Select</option>");
    
    for (var i=0;i<datas.length;i++) {
      $(selEl).append("<option data-company="+datas[i].company+" data-name="+datas[i].name+" value="+datas[i].id+">"+datas[i].name+"</option>");
    }

    axios.get(base_api_url + '/api/v1/coord/getcoordbyid?coordId=' + id).then(res => {
      $(selEl).val(res.data.response.id).trigger("change");
    })

  })
}

class SchoolDetail extends React.Component {
  componentDidMount(){

    getDataCoordinatorOnly();
    getDataHfOnly();

    $(document).on("click",".btn-csconfirm",function(e){
      e.preventDefault();
      
      let notValid = 0;
      let msgval = "";
      let cv;

      $(".errorCs").remove();
      $.each($('form input'), function(index, formField) {
        if ($(formField).val()==="") {
          cv = $(formField).parent().find("label").text();
          if (cv!==""  && $(formField).attr("type")!=="file" && $(formField).attr("type")!=="hidden") {
            msgval = cv + " is required! <br/>";
            $(formField).parent().append("<span class='errorCs'>" + msgval + "</span>");
            notValid += 1;
          }
        }
      });

      if (notValid>0) {
        //$("#msgValidation").show();
      } else {
        if ($("#photo").val()=="")  {
          submitData();
        } else {
          uploadFile();
        }
      }
    });

    $(document).on("click",".close",function(){
      $(this).parent().parent().hide();
    });

    getData()

    let select2Init = $(".select2-tc").select2({placeholder:"Transition Classes",closeOnSelect : false,
      allowHtml: true,
      allowClear: true,
      tags: false
    });

    $(document).on('change','select.select2-tc', function (e) {
      e.preventDefault(); 
      let counter = select2Init.select2('data').length;
      $(".select2Counter1").remove();
      if (counter>0) {
        $(this).parent().find(".select2-selection--multiple").append("<span class='select2Counter1'>"+counter+" selected</span>")
      }

      if ($(this).is(':selected')){
        //
      } else {
        //
      }
    });


    let select2Initfc = $(".select2-fc").select2({placeholder:"Fifth Classes",closeOnSelect : false,
      allowHtml: true,
      allowClear: true,
      tags: false
    });

    $(document).on('change','select.select2-fc', function (e) {
      e.preventDefault(); 
      let counter = select2Initfc.select2('data').length;
      $(".select2Counter2").remove();
      if (counter>0) {
        $(this).parent().find(".select2-selection--multiple").append("<span class='select2Counter2'>"+counter+" selected</span>")
      }

      if ($(this).is(':selected')){
        //
      } else {
        //
      }
    });


    let select2Initln = $(".select2-ln").select2({placeholder:"Languages",closeOnSelect : false,
      allowHtml: true,
      allowClear: true,
      tags: false
    });

    $(document).on('change','select.select2-ln', function (e) {
      e.preventDefault(); 
      let counter = select2Initln.select2('data').length;
      $(".select2Counter3").remove();
      if (counter>0) {
        $(this).parent().find(".select2-selection--multiple").append("<span class='select2Counter3'>"+counter+" selected</span>")
      }

      if ($(this).is(':selected')){
        //
      } else {
        //
      }
    });

    let select2Initac = $(".select2-ac").select2({placeholder:"Activies",closeOnSelect : false,
      allowHtml: true,
      allowClear: true,
      tags: false
    });

    $(document).on('change','select.select2-ac', function (e) {
      e.preventDefault(); 
      let counter = select2Initac.select2('data').length;
      $(".select2Counter4").remove();
      if (counter>0) {
        $(this).parent().find(".select2-selection--multiple").append("<span class='select2Counter4'>"+counter+" selected</span>")
      }

      if ($(this).is(':selected')){
        //
      } else {
        //
      }
    });

    $(document).on("change","#coordinator_id",function(e){
      $("#coordinator_name").val($(this).find(':selected').data('name'));
      $("#coordinator_company").val($(this).find(':selected').data('company'));
    });

    $(document).on("change","#hf_id",function(e){
      $("#hf_name").val($(this).find(':selected').data('familyname'));
    });

    $('#photo').change(function(){
      const file = this.files[0];
      if (file){
        $(".errorCs").remove();
        if (file.type=="image/jpeg" || file.type=="image/png") {
          let reader = new FileReader();
          reader.onload = function(event){
            $('#imgPreview').attr('src', event.target.result);
          }
          reader.readAsDataURL(file);
        } else {
          $('#photo').val("");
          $("#imgPreview").attr("src","");
          $('#photo').parent().parent().append("<span class='errorCs'>The image must be a file of type: jpg, jpeg, png.</span>");
        }
      }
    });

    let select2Initcoord = $(".select2-coord").select2({placeholder:"Coordinator",closeOnSelect : false,
      allowHtml: true,
      allowClear: true,
      tags: false
    });

    $(document).on('change','select.select2-coord', function (e) {
      e.preventDefault(); 
      let counter = select2Initcoord.select2('data').length;
      $(".select2Counter5").remove();
      if (counter>0) {
        $(this).parent().find(".select2-selection--multiple").append("<span class='select2Counter5'>"+counter+" selected</span>")
      }

      if ($(this).is(':selected')){
        //
      } else {
        //
      }
    });

    $(document).on("change","#switch_school_jackets",function(){
      let val = String($(this).is(':checked'));

      if ($(this).is(':checked')==true) {
        $("#school_jackets").val("yes").trigger("change");
      } else {
        $("#school_jackets").val("no").trigger("change");
      }
    });

    $(document).on("change","#switch_school_tracksuite",function(){
      let val = String($(this).is(':checked'));

      if ($(this).is(':checked')==true) {
        $("#school_tracksuite").val("yes").trigger("change");
      } else {
        $("#school_tracksuite").val("no").trigger("change");
      }
    });

    $(document).on("click",".uploadDoc",function(e){
      e.preventDefault();
      
      if ($("#document_upload").val()=="") {
        alert("Please upload the PDF/Image file!");
      } else {
        let ftype = $("#document_upload")[0].files[0].type;
        if (ftype!=="application/pdf"&&ftype!=="image/jpeg"&&ftype!=="image/png") {
          alert("Only PDF/Image file allowed!");
        } else {
          uploadDocuments();
        }
      }

    });

    $(document).on("click",".delDoc",function(e){
      e.preventDefault();

      let curPar = $(this).parent().parent().parent();
      let fileId = $(this).data("id");

      const params = new Proxy(new URLSearchParams(window.location.search), {
        get: (searchParams, prop) => searchParams.get(prop),
      });

      swal({
        title: "Are you sure?",
        text: "Are you sure that you want to delete this file ?",
        icon: "warning",
        buttons: ["Cancel", "Yes, delete it"],
      })
      .then(willDelete => {
        if (willDelete) {
          axios({
            method: "delete",
            url: base_api_url + `/api/v1/file/${fileId}/${params.id}/school`
          }).then(function (response) {
              $(curPar).remove();
          });
        }
      });

    });

    let select2InitHf = $(".select2-hf").select2({placeholder:"Host Family",closeOnSelect : false,
      allowHtml: true,
      allowClear: true,
      tags: false
    });

    $(document).on('change','select.select2-hf', function (e) {
      e.preventDefault(); 
      let counter = select2InitHf.select2('data').length;
      $(".select2Counter6").remove();
      if (counter>0) {
        $(this).parent().find(".select2-selection--multiple").append("<span class='select2Counter6'>"+counter+" selected</span>")
      }

      if ($(this).is(':selected')){
        //
      } else {
        //
      }
    });

  }

  render(){
    return (
      <>
        <PanelHeader size="sm"
          content={
            <div className="content">
              <div className="header text-left">
                <span className="panelHeaderDesc">View and manage the School Details.</span>
                <nav aria-label="breadcrumb" role="navigation">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item"><a href="/coordinator/overview">Dashboard</a></li>
                    <li className="breadcrumb-item"><a href="/coordinator/school">Schools</a></li>
                    <li className="breadcrumb-item active" aria-current="page" id="br_name"></li>
                  </ol>
                </nav>
              </div>
            </div>
          }
        />
        <br/>
        <div className="content">
          <Form name="formsubmit" id="formsubmit" action="/" className="form-horizontal" method="post">
            <Row>
              <Col md="3">
                <Card className="card-chart">
                  <CardBody>
                    <Table responsive>
                      <tbody>
                        <tr>
                          <td className="td-noborder">
                            <a href="#c_spil_details" className="anchorItemDetail">SPIL Details</a>
                            <a href="#c_school_details" className="anchorItemDetail">School Details</a>
                            <a href="#c_submissions" className="anchorItemDetail">Submissions</a>
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </CardBody>
                </Card>
              </Col>
              <Col md="9">
                <Card>
                  <CardHeader className="card-header-profile">
                    <Row>
                      <Col md={2}>
                        <img id="profile" style={{float: "left",width:"76px",height:"76px",position:"relative",bottom:"18px"}} src={profileImg}></img>
                      </Col>
                      <Col md={8}>
                        <p className="profile-title" id="t_name"></p>
                        <p className="profile-desc" id="t_id"></p>
                      </Col>
                      <Col md={2}>
                        <a className="viewAttached" id="attachedStudent">View attached Students</a>
                      </Col>
                    </Row>
                  </CardHeader>
                </Card>
                <Card id="c_spil_details">
                  <CardHeader>
                    <CardTitle tag="h4" className="panelTitle">SPIL Details</CardTitle>
                  </CardHeader>
                  <CardBody>
                    <Row>
                      <Col sm="12">
                        <FormGroup>
                          <h4 style={{"fontFamily": "Noto Sans","fontStyle": "normal","fontWeight": 400,"fontSize": "16px","lineHeight": "24px","color": "#70B2D8"}}>School</h4>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm="12">
                        <Row>
                          <Col md="12">
                            <FormGroup>
                              <div className="form-floating">
                                <Input readonly="true" id="school_id" style={{"borderRadius": "5px"}} placeholder="ID" type="text" />
                                <label htmlFor="pwd">ID</label>
                              </div>
                            </FormGroup>
                          </Col>
                        </Row>
                      </Col>
                    </Row>

                    <Row>
                      <Col sm="12">
                        <FormGroup>
                          <h4 style={{"fontFamily": "Noto Sans","fontStyle": "normal","fontWeight": 400,"fontSize": "16px","lineHeight": "24px","color": "#70B2D8"}}>Coordinator</h4>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={12}>
                        <FormGroup>
                          <select id="coordinator_id" className="select2-coord form-control" multiple style={{height:"58px",width:"50%","borderRadius":"5px"}}>
                          </select>
                        </FormGroup>
                      </Col>
                    </Row>
      
                    <Row>
                      <Col sm="12">
                        <FormGroup>
                          <h4 style={{"fontFamily": "Noto Sans","fontStyle": "normal","fontWeight": 400,"fontSize": "16px","lineHeight": "24px","color": "#70B2D8"}}>Host Family</h4>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={12}>
                        <FormGroup>
                          <select id="hf_id" className="select2-hf form-control" multiple style={{height:"58px",width:"50%","borderRadius":"5px"}}>
                          </select>
                        </FormGroup>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
                <Card id="c_school_details">
                  <CardHeader>
                    <CardTitle tag="h4" className="panelTitle">School Details</CardTitle>
                  </CardHeader>
                  <CardBody>
                    <Row>
                      <Col sm="12">
                        <FormGroup>
                          <h4 style={{"fontFamily": "Noto Sans","fontStyle": "normal","fontWeight": 400,"fontSize": "16px","lineHeight": "24px","color": "#70B2D8"}}>School</h4>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm="12">
                        <Row>
                          <Col md="6">
                            <FormGroup>
                              <div className="form-floating">
                                <Input id="school_name" style={{"borderRadius": "5px"}} placeholder="ID" type="text" />
                                <label htmlFor="pwd">Name</label>
                              </div>
                            </FormGroup>
                          </Col>
                          <Col md="6">
                            <FormGroup>
                              <div className="form-floating">
                                <Input id="school_country" style={{"borderRadius": "5px"}} placeholder="ID" type="text" />
                                <label htmlFor="pwd">Country</label>
                              </div>
                            </FormGroup>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm="12">
                        <Row>
                          <Col md="6">
                            <FormGroup>
                              <div className="form-floating">
                                <Input id="school_town" style={{"borderRadius": "5px"}} placeholder="ID" type="text" />
                                <label htmlFor="pwd">Town</label>
                              </div>
                            </FormGroup>
                          </Col>
                          <Col md="6">
                            <FormGroup>
                              <div className="form-floating">
                                <Input id="school_type" style={{"borderRadius": "5px"}} placeholder="ID" type="text" />
                                <label htmlFor="pwd">School Type</label>
                              </div>
                            </FormGroup>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm="12">
                        <Row>
                          <Col md="12">
                            <FormGroup>
                              <div className="form-floating">
                                <Input id="school_address" style={{"borderRadius": "5px"}} placeholder="ID" type="text" />
                                <label htmlFor="pwd">Address</label>
                              </div>
                            </FormGroup>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm="12">
                        <Row>
                          <Col md="6">
                            <FormGroup>
                              <div className="form-floating">
                                <Input readonly="true" id="school_email" style={{"borderRadius": "5px"}} placeholder="ID" type="text" />
                                <label htmlFor="pwd">Email</label>
                              </div>
                            </FormGroup>
                          </Col>
                          <Col md="6">
                            <FormGroup>
                              <div className="form-floating">
                                <Input id="school_mobile" style={{"borderRadius": "5px"}} placeholder="ID" type="text" />
                                <label htmlFor="pwd">Phone</label>
                              </div>
                            </FormGroup>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm="12">
                        <Row>
                          <Col md="6">
                            <FormGroup>
                              <div className="form-floating">
                                <Input id="school_website" style={{"borderRadius": "5px"}} placeholder="ID" type="text" />
                                <label htmlFor="pwd">Website</label>
                              </div>
                            </FormGroup>
                          </Col>
                          <Col md="6">
                            <FormGroup>
                              <div className="form-floating">
                                <Input id="school_principal_name" style={{"borderRadius": "5px"}} placeholder="ID" type="text" />
                                <label htmlFor="pwd">Principal Name</label>
                              </div>
                            </FormGroup>
                          </Col>
                        </Row>
                      </Col>
                    </Row>

                    <Row>
                      <Col sm="12">
                        <Row>
                          <Col md="6">
                            <FormGroup>
                              <div className="form-floating">
                                <Input id="school_year_enrolement_fees" style={{"borderRadius": "5px"}} placeholder="ID" type="text" />
                                <label htmlFor="pwd">Yearly Enrolement Fees</label>
                              </div>
                            </FormGroup>
                          </Col>
                          <Col md="6">
                            <FormGroup>
                              
                            </FormGroup>
                          </Col>
                        </Row>
                      </Col>
                    </Row>

                    <Row>
                      <Col sm="6">
                        <FormGroup>
                          <h4 style={{"fontFamily": "Noto Sans","fontStyle": "normal","fontWeight": 400,"fontSize": "16px","lineHeight": "24px","color": "#70B2D8"}}>Transition Year</h4>
                        </FormGroup>
                      </Col>
                      <Col sm="6">
                        <FormGroup>
                          <h4 style={{"fontFamily": "Noto Sans","fontStyle": "normal","fontWeight": 400,"fontSize": "16px","lineHeight": "24px","color": "#70B2D8"}}>Fifth Year</h4>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm="12">
                        <Row>
                          <Col md="6">
                            <FormGroup>
                              <div className="form-floating">
                                <Input id="school_transition_year" style={{"borderRadius": "5px"}} placeholder="ID" type="text" />
                                <label htmlFor="pwd">Transition Year</label>
                              </div>
                            </FormGroup>
                          </Col>
                          <Col md="6">
                            <FormGroup>
                              <div className="form-floating">
                                <Input id="school_fifth_year" style={{"borderRadius": "5px"}} placeholder="ID" type="text"/>
                                <label htmlFor="pwd">Fifth Year</label>
                              </div>
                            </FormGroup>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm="12">
                        <Row>
                          <Col md="6">
                            <FormGroup>
                              <div className="form-floating">
                                <Input id="school_transition_cost" style={{"borderRadius": "5px"}} placeholder="ID" type="number" />
                                <label htmlFor="pwd">Transition Year Cost</label>
                              </div>
                            </FormGroup>
                          </Col>
                          <Col md="6">
                            <FormGroup>
                              <div className="form-floating">
                                <Input id="school_fifth_year_cost" style={{"borderRadius": "5px"}} placeholder="ID" type="number" />
                                <label htmlFor="pwd">Fifth Year Cost</label>
                              </div>
                            </FormGroup>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm="12">
                        <Row>
                          <Col md="6">
                            <FormGroup>
                              <select id="school_transition_classes" className="select2-tc" multiple style={{width:"50%"}}>
                                <option>Math</option>
                                <option>English</option>
                                <option>Irish</option>
                                <option>Geopgraphy</option>
                                <option>History</option>
                                <option>French</option>
                                <option>German</option> 
                                <option>Spanish</option>
                                <option>Physics</option> 
                                <option>Biology</option> 
                                <option>Chemestry</option>
                                <option>Art</option> 
                                <option>Music</option> 
                                <option>Woodwork</option> 
                                <option>PE</option>
                                <option>Business Studies</option>
                                <option>Environmental studies</option>
                                <option>Chinese culture and language studies</option>
                                <option>Global development issues</option>
                                <option>Debating development</option>
                                <option>Tourism studies</option>
                                <option>Student safety in the workplace</option>
                                <option>Soap operas and popular culture</option>
                                <option>Food matters</option>
                                <option>Legal studies</option>
                                <option>Sports coaching</option>
                                <option>Introduction to genetics and biotechnology</option>
                                <option>Media and communications</option>
                                <option>Japanese studies</option>
                              </select>
                            </FormGroup>
                          </Col>
                          <Col md="6">
                            <FormGroup>
                              <select id="school_fifth_year_classes" className="select2-fc" multiple style={{width:"50%"}}>
                                <option>Maths</option>
                                <option>English</option>
                                <option>Irish</option>
                                <option>Geopgraphy</option>
                                <option>History</option>
                                <option>French</option>
                                <option>German</option>
                                <option>Spanish</option>
                                <option>Physics</option>
                                <option>Biology</option>
                                <option>Chemestry</option>
                                <option>Art</option>
                                <option>Music</option> 
                                <option>Woodwork</option> 
                                <option>PE</option>
                                <option>Business Studies</option>
                              </select>
                            </FormGroup>
                          </Col>
                        </Row>
                      </Col>
                    </Row>

                    <Row>
                      <Col sm="12">
                        <Row className="bundleBorder" style={{"height": "59px"}}>
                          <Col md="11">
                            <FormGroup>
                              <div className="form-floating">             
                                <label htmlFor="pwd">School Jackets</label>
                              </div>
                            </FormGroup>
                          </Col>
                          <Col md="1">
                            <div className="form-floating">    
                              <div class="material-switch">
                                  <input id="switch_school_jackets" type="checkbox"/>
                                  <label for="switch_school_jackets" className="label-primary"></label>
                              </div>
                              <Input type="hidden" id="school_jackets" value="no"/>
                            </div>
                          </Col>
                        </Row>
                      </Col>
                    </Row>

                    <Row>
                      <Col sm="12">
                        <Row className="bundleBorder" style={{"height": "59px"}}>
                          <Col md="11">
                            <FormGroup>
                              <div className="form-floating">             
                                <label htmlFor="pwd">Tracksuites</label>
                              </div>
                            </FormGroup>
                          </Col>
                          <Col md="1">
                            <div className="form-floating">    
                              <div class="material-switch">
                                  <input id="switch_school_tracksuite" type="checkbox"/>
                                  <label for="switch_school_tracksuite" className="label-primary"></label>
                              </div>
                              <Input type="hidden" id="school_tracksuite" value="no"/>
                            </div>
                          </Col>
                        </Row>
                      </Col>
                    </Row>

                    <Row>
                      <Col sm={12}>
                        <FormGroup>
                          <h4 style={{"fontFamily": "Noto Sans","fontStyle": "normal","fontWeight": 400,"fontSize": "16px","lineHeight": "24px","color": "#70B2D8"}}>Uniform</h4>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm="12">
                        <Row>
                          <Col md="12">
                            <FormGroup>
                              <div className="form-floating">
                                <Input id="school_uniform_detail" style={{"borderRadius": "5px"}} placeholder="ID" type="text" />
                                <label htmlFor="pwd">Uniform Details</label>
                              </div>
                            </FormGroup>
                          </Col>
                        </Row>
                      </Col>
                    </Row>

                    <Row>
                      <Col sm={6}>
                        <FormGroup>
                          <h4 style={{"fontFamily": "Noto Sans","fontStyle": "normal","fontWeight": 400,"fontSize": "16px","lineHeight": "24px","color": "#70B2D8"}}>Languages</h4>
                        </FormGroup>
                      </Col>
                      <Col sm={6}>
                        <FormGroup>
                          <h4 style={{"fontFamily": "Noto Sans","fontStyle": "normal","fontWeight": 400,"fontSize": "16px","lineHeight": "24px","color": "#70B2D8"}}>Activities</h4>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm="12">
                        <Row>
                          <Col md="6">
                            <FormGroup>
                              <select id="school_languages" className="select2-ln" multiple style={{width:"50%"}}>
                                <option>English</option>
                              </select>
                            </FormGroup>
                          </Col>
                          <Col md="6">
                            <FormGroup>
                              <select id="school_activities" className="select2-ac" multiple style={{width:"50%"}}>
                                <option>Sleep</option>
                              </select>
                            </FormGroup>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
                <Card id="c_submissions">
                  <CardHeader>
                    <CardTitle tag="h4" className="panelTitle">Submissions</CardTitle>
                  </CardHeader>
                  <CardBody>

                    <Row>
                      <Col sm="12">
                        <FormGroup>
                          <h4 style={{"fontFamily": "Noto Sans","fontStyle": "normal","fontWeight": 400,"fontSize": "16px","lineHeight": "24px","color": "#70B2D8"}}>Documents & Images</h4>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm="12" id="documents_lists">
                      </Col>
                      <Col sm="12">
                        <input style={{marginLeft: "20px",marginTop: "24px"}} type="file" id="document_upload"/> <button className="btn btn-primary uploadDoc">Upload</button>
                      </Col>
                    </Row>

                    <Row>
                      <Col sm="12">
                        <FormGroup>
                          <h4 style={{"fontFamily": "Noto Sans","fontStyle": "normal","fontWeight": 400,"fontSize": "16px","lineHeight": "24px","color": "#70B2D8"}}>Photo</h4>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm="12">
                        <Row className="bundleBorder">
                          <Col md="2">
                            <FormGroup>
                              <div className="form-floating">             
                                <label htmlFor="pwd">Thumbnail</label>
                              </div>
                            </FormGroup>
                          </Col>
                          <img id="imgPreview" style={{float: "left",width:"76px",height:"76px",position:"relative"}} src={profileImg}></img>
                          <input style={{marginLeft: "20px",marginTop: "24px"}} type="file" id="photo"/>
                          <input type="hidden" id="s_photourl"/>
                        </Row>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
                <Row>
                  <Col sm="12">
                    <div align="center">
                      <button className="btn-csconfirm">Update Changes</button>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Form>
        </div>
      </>
    );
  }
}

export default SchoolDetail;
