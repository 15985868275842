import React, { Component } from "react";
import $ from "jquery";

import datepickerFactory from 'jquery-datepicker';
import TableFilter from "views/Components/TableFilter.js";
import axios from "axios";
import { base_api_url, default_start_date, default_end_date, defPaginPage, defPaginPerpage, toPopulatePage, replaceEndTime } from "variables/general.js";
import Modals from "views/Components/Modals.js";
import { jsPDF } from "jspdf";
import autoTable from 'jspdf-autotable';
import CalculateAge from "components/CustomDateTime/CalculateAge";

import {
  Card,
  CardBody,
  CardTitle,
  Row,
  Col,
  Button
} from "reactstrap";

import PanelHeader from "components/PanelHeader/PanelHeader.js";

function overrideDtColor() {
  $(".paginate_button").attr("style", "border-radius: 30px;background: #B6C9D5;color: #FFFFFF!important;");
  $(".paginate_button.previous").html("<i class='now-ui-icons arrows-1_minimal-left'></i>").attr("style", "borderRadius: 0px;background: #fff;color: #000!important;font-weight:bold;");
  $(".paginate_button.next").html("<i class='now-ui-icons arrows-1_minimal-right'></i>").attr("style", "borderRadius: 0px;background: #fff;color: #000!important;font-weight:bold;");
  $(".dt-buttons").attr("style", "display:none");
}

function getData(page, perpage, searchKey = "",filterColumn1=false, filterColumn2=false, filterColumn3=false) {

  const params = new Proxy(new URLSearchParams(window.location.search), {
    get: (searchParams, prop) => searchParams.get(prop),
  });

  $("#pagLink").removeClass("active");
  let curpage = Number(page) - 1;
  let fromDate = $('#startDate').val();
  let toDate = $("#endDate").val();

  if ($('#startDate').val() !== "") {
    fromDate = fromDate.split("/")
    fromDate = fromDate[2].substring(0, 4) + "-" + fromDate[1] + "-" + fromDate[0]
    fromDate = new Date(fromDate);
    fromDate = fromDate.toISOString();
  }

  if ($("#endDate").val() !== "") {
    toDate = toDate.split("/")
    toDate = toDate[2].substring(0, 4) + "-" + toDate[1] + "-" + toDate[0]
    toDate = new Date(toDate);
    toDate = toDate.toISOString().replace("T00:00:00", replaceEndTime);
  }

  let urlTarget = base_api_url + "/api/v1/student/v2/filterbystudent?isArchive=true&page=" + curpage + '&size=' + perpage + "&searchTerm=" + encodeURIComponent(searchKey.trim()) + "&fromdate=" + fromDate + "&todate=" + toDate;
  return axios.get(urlTarget).then(res => {
    let datas = res.data.response.content;
    let totalPage = Number(res.data.response.totalPages);

    showTable(datas);
    loopPagination(Number(page), totalPage);

    let total = $("tbody tr").length;
    let checked = $(".selectorRow:checked").length;
    $("#rowSelectedLabel").attr("placeholder", checked + " of " + total + " selected");

    if (filterColumn1) {
      $("#filterColumn1").val(filterColumn1).trigger("change");
    }

    if (filterColumn2) {
      $("#filterColumn2").val(filterColumn2).trigger("change");
    } else {
      $("#filterColumn2").val([12,13,14,20,23]).trigger("change");
    }

    if (filterColumn3) {
      $("#filterColumn3").val(filterColumn3).trigger("change");
    }
  })
}

function loopPagination(curPage, sizePage) {
  let paginContent = "";
  let prevPage = curPage - 1;
  let nextPage = curPage + 1;

  if (sizePage > 0) {
    paginContent = paginContent.concat(`<a data-perpage="${defPaginPerpage}" data-page="${prevPage}" class="prevPag pagLink arrowpag" href="#"><i class="now-ui-icons arrows-1_minimal-left"></i></a>`);

    // Determine the start and end of the page range
    let startPage = Math.max(1, curPage - 4);
    let endPage = Math.min(sizePage, startPage + 8);

    // Expand the range if fewer than 9 pages are shown
    while ((endPage - startPage) < 8 && (startPage > 1 || endPage < sizePage)) {
      if (startPage > 1) {
        startPage--;
      }
      if (endPage < sizePage) {
        endPage++;
      }
    }

    // Display the page links within the range
    for (let i = startPage; i <= endPage; i++) {
      if (i == curPage) {
        paginContent = paginContent.concat(`<a href="#" data-perpage="${defPaginPerpage}" data-page="${i}" class="pagLink active">${i}</a>`);
      } else {
        paginContent = paginContent.concat(`<a href="#" data-perpage="${defPaginPerpage}" data-page="${i}" class="pagLink">${i}</a>`);
      }
    }

    let haveless = "";

    // Add "..." at the end if there are more pages
    if (endPage < sizePage) {
      haveless = "<a style='background: white;color: #416D86!important;'> ... </a>";
    }

    paginContent = paginContent.concat(`${haveless}<a data-perpage="${defPaginPerpage}" data-page="${nextPage}" class="nextPag arrowpag pagLink" href="#"><i class="now-ui-icons arrows-1_minimal-right"></i></a>`);
  }

  setTimeout(function () {
    if (curPage == 1) {
      $(".prevPag").hide();
    } else {
      $(".prevPag").show();
    }

    if (curPage == sizePage) {
      $(".nextPag").hide();
    } else {
      $(".nextPag").show();
    }
  }, toPopulatePage);

  $(".pagination").html(paginContent);
}

function showTable(datas) {

  let segments = window.location.pathname.split('/');
  let section = segments[4];

  let currentUrl = window.location.origin + "/admin/new-student-archive/";

  let tableContent = "";
  $("#tbody").empty();

  if (datas.length>0) {
    for (let i=0;i<datas.length;i++) {
      
      let agentName;
      let agentCompany;
      let coordinatorName; 
      let coordinatorCompany;
      let schoolName
      let schoolCountry;
      let schoolTown;
      let schoolType;
      let hfFamilyName;

      agentName = datas[i].agentname ? datas[i].agentname : "-";
      agentCompany = datas[i].agentcompany ? datas[i].agentcompany : "-";
      
      coordinatorName = datas[i].coordname ? datas[i].coordname : "-";
      coordinatorCompany = datas[i].coordcompany ? datas[i].coordcompany : "-";

      schoolName = datas[i].schoolname ? datas[i].schoolname : "-";
      schoolCountry = datas[i].schoolcountry ? datas[i].schoolcountry : "-";
      schoolTown = datas[i].schooltown ? datas[i].schooltown : "-";
      schoolType = datas[i].schooltype ? datas[i].schooltype : "-";

      hfFamilyName = datas[i].hostfamilyname? datas[i].hostfamilyname : "-" ;

      tableContent = tableContent.concat(`<tr>
            <td style="padding-left: 15px;padding-right:10px;width:4%;fontStyle: normal;fontWeight: 400;fontSize: 14px,lineHeight: 20px" class="td-noborder"><input type="checkbox" name="selectorRow" class="selectorRow"></td>
            <td style="display:none;" class="td-noborder">${datas[i].id}</td>
            <td style="display:none;" class="td-noborder">${datas[i].reference}</td>
            <td style="display:none;" class="td-noborder">${schoolName}</td>
            <td style="display:none;" class="td-noborder">${schoolCountry}</td>
            <td style="display:none;" class="td-noborder">${schoolTown}</td>
            <td style="display:none;" class="td-noborder">${schoolType}</td>
            <td style="display:none;" class="td-noborder">${agentName}</td>
            <td style="display:none;" class="td-noborder">${agentCompany}</td>
            <td style="display:none;" class="td-noborder">${coordinatorName}</td>
            <td style="display:none;" class="td-noborder">${coordinatorCompany}</td>
            <td style="display:none;" class="td-noborder">${datas[i].firstname}</td>
            <td style="display:none;" class="td-noborder">${datas[i].lastname}</td>
            <td style="display:none;" class="td-noborder">${datas[i].nationality}</td>
            <td style="display:none;" class="td-noborder">${datas[i].emailaddress}</td>
            <td style="display:none;" class="td-noborder">${datas[i].dateofbirth}</td>
            <td style="display:none;" class="td-noborder">${CalculateAge(datas[i].dateofbirth)}</td>
            <td style="display:none;" class="td-noborder">${datas[i].telephonenumer}</td>
            <td style="display:none;" class="td-noborder">${datas[i].irishmobilenumber}</td>
            <td style="display:none;" class="td-noborder">${datas[i].religion}</td>
            <td style="display:none;" class="td-noborder">${datas[i].gender}</td>
            <td style="display:none;" class="td-noborder">${datas[i].passportnumber}</td>
            <td style="display:none;" class="td-noborder">${datas[i].lengthofstay}</td>
            <td style="display:none;" class="td-noborder">${datas[i].medicalcondition}</td>
            <td style="display:none;" class="td-noborder">${datas[i].medicalcondition}</td>
            <td style="display:none;" class="td-noborder">${datas[i].diatryrequirements}</td>
            <td style="display:none;" class="td-noborder">${datas[i].allergies}</td>
            <td style="display:none;" class="td-noborder">${datas[i].mothermaidenname}</td>
            <td style="display:none;" class="td-noborder">${datas[i].familybrotherssisters}</td>
            <td style="display:none;" class="td-noborder">${datas[i].schoolyearstudentwillstudyinireland}</td>
            <td style="display:none;" class="td-noborder">${datas[i].flight_arrivaldate}</td>
            <td style="display:none;" class="td-noborder">${datas[i].flight_departuredate}</td>
            <td style="display:none;" class="td-noborder">${hfFamilyName}</td>
            <td style="display:none;" class="td-noborder">${datas[i].flight_type}</td>
            <td style="display:none;" class="td-noborder">${datas[i].flight_airline}</td>
            <td style="display:none;" class="td-noborder">${datas[i].flight_origin}</td>
            <td style="display:none;" class="td-noborder">${datas[i].flight_destination}</td>
            <td style="display:none;" class="td-noborder">${datas[i].flight_arrivalpickup}</td>
            <td style="display:none;" class="td-noborder">${datas[i].flight_number}</td>
            <td style="width:17%" class="td-noborder">
              <a style="color:#F9B933;fontStyle:normal;fontWeight: 400;fontSize: 12px;lineHeight: 16px" href=${currentUrl + datas[i].id + "?id=" + datas[i].id}><i style="position: relative;top:8px" class="now-ui-icons cs-ov-edit-pen"></i>Views Detail</a>
              <a data-id="${datas[i].id}" class="archiveRow" style="margin-left:10px" href="#"><i class="now-ui-icons ui-1_simple-remove"></i></a>
            </td>
          </tr>`);
    }
    $(".containerPagination").show();
  } else {
    tableContent = tableContent.concat(`<tr><td></td><td>Not found</td><td></td><td></td></tr>`);
    $(".containerPagination").hide();
  }

  $("#tbody").html(tableContent);

}

function archiveAccount(id) {

  var config = {
    method: 'put',
    maxBodyLength: Infinity,
    url: base_api_url + '/api/v1/student/archive/' + id,
    headers: {
      'Content-Type': 'application/json'
    },
    data: JSON.stringify(0)
  };

  return axios(config).then(res => {
    $("#msgConfirm").hide();
    $("#contentSuccess").html("Success!");
    $("#msgSuccess").fadeIn();

    setTimeout(function () {
      window.location.reload();
    }, 500);

  }).catch(function (error) {
    $("#contentValidation").html(error.response.data.message);
    $("#msgValidation").show();
  });
}

function downloadCSVFile(csv_data) {
  let CSVFile = new Blob([csv_data], { type: "text/csv" });
  var temp_link = document.createElement('a');
  let timestamp = new Date();
  temp_link.download = timestamp.toISOString() + ".csv";
  var url = window.URL.createObjectURL(CSVFile);
  temp_link.href = url;
  temp_link.style.display = "none";
  document.body.appendChild(temp_link);
  temp_link.click();
  document.body.removeChild(temp_link);
}

function tableToCSV() {
    var csv_data = [];

    var rows = $("#table > thead > tr");
    for (var i = 0; i < rows.length; i++) {
        var cols = rows[i].querySelectorAll('td,th');
        var csvrow = [];
        for (var j = 1; j < cols.length - 1; j++) {
          if (j!==cols.length-1) {
            if (!$(cols[j]).is(":hidden")){
              csvrow.push(escapeCSVValue(cols[j].innerHTML));
            }
          }
        }
        csv_data.push(csvrow.join(","));
    }

    var rows = $("#table > tbody > tr");
    for (var i = 0; i < rows.length; i++) {
      if ($(rows[i]).find('.selectorRow').is(':checked')) {
        var cols = rows[i].querySelectorAll('td,th');
        var csvrow = [];
        for (var j = 1; j < cols.length - 1; j++) {
          if (j!==cols.length-1) {
            if (!$(cols[j]).is(":hidden")){
              csvrow.push(escapeCSVValue(cols[j].innerHTML));
            }
          }
        }
        csv_data.push(csvrow.join(","));
      }
    }
    csv_data = csv_data.join('\n');
    downloadCSVFile(csv_data);
}

function escapeCSVValue(value) {
    // If the value contains a comma or new line, enclose it in double quotes and escape existing double quotes
    if (value.includes(',') || value.includes('\n')) {
        return '"' + value.replace(/"/g, '""') + '"';
    } else {
        return value;
    }
}

function replaceNullWithEmptyString(value) {
  if (value == null) {
    return '-';
  } else {
    if (value.length>0) {
      return value.replaceAll(",","");
    } else {
      return value;
    }
  }
}

function tableToCSVAll() {
    let user_login = JSON.parse(localStorage.getItem('user_login'));
    let urlTarget = base_api_url + "/api/v1/export/admin/hostfamilylist/csvall?isArchive=true&role=admin&userId="+user_login.reference_id;
    axios.get(urlTarget).then(res => {
      let datas = res.data.response;

      var csv_data = [];

      var rows = datas[0];
      var csvrow = [];
      for (let key in rows) {
        csvrow.push(key.replaceAll("_"," "));
      }

      csv_data.push(csvrow.join(","));

      var rows = datas;
      for (var i = 0; i < rows.length; i++) {
        var cols = rows[i];
        var csvrow = [];
        for (let key in cols) {
          let valCs = replaceNullWithEmptyString(cols[key]);
          csvrow.push(valCs);
        }
        csv_data.push(csvrow.join(","));  
      }

      csv_data = csv_data.join('\n');
      downloadCSVFile(csv_data);
    })

}

function tableToPdf() {
  const doc = new jsPDF();
  let fillHeads = [];
  let fillBodys = [];

  let theadel = $("table#table > thead > tr > th");

  theadel.each(function (index, el) {
    if (index == 0 || index == theadel.length - 1 || $(el).is(":hidden")) {

    } else {
      fillHeads.push($(el).text());
    }
  });

  let tbodyel = $("table#table > tbody > tr");
  let tds = [];

  tbodyel.each(function (index, el) {
    if ($(el).find('.selectorRow').is(':checked')) {
      let tds = [];
      let tdParents = $(el).find("td");
      tdParents.each(function (id, vd) {
        if (id == 0 || id == tdParents.length - 1 || $(vd).is(":hidden")) {

        } else {
          tds.push($(vd).text());
        }
      })
      fillBodys.push(tds)
    }
  });

  doc.autoTable({
    head: [fillHeads],
    body: fillBodys
  })
  let timestamp = new Date();
  doc.save(timestamp.toISOString() + ".pdf");
}

class HostFamily extends React.Component {

  state = {
    datas: [],
    page: defPaginPage,
    perpage: defPaginPerpage,
    url: "",
    totalPage: 0
  }

  componentDidMount() {
    overrideDtColor();
    datepickerFactory($);
    $('#startDate').datepicker({ dateFormat: "dd/mm/yyyy" });
    $('#endDate').datepicker({ dateFormat: "dd/mm/yyyy" });
    $("#startDate").val(default_start_date);
    $('#endDate').val(default_end_date);

    getData(defPaginPage, defPaginPerpage);

    $(document).on("click",".pagination a.pagLink",function(e){
      e.preventDefault();
      let page = $(this).data('page');
      let perpage = defPaginPerpage
      let searchKey = $("#dtSearchCs").val();
      getData(Number(page),Number(perpage), searchKey, $("#filterColumn1").val(), $("#filterColumn2").val(), $("#filterColumn3").val());
    });

    $(document).on("click","#submitSearch",function(e){
      e.preventDefault();
      let page = defPaginPage;
      let perpage = defPaginPerpage;
      let searchKey = $("#dtSearchCs").val();
      getData(Number(page),Number(perpage), searchKey, $("#filterColumn1").val(), $("#filterColumn2").val(), $("#filterColumn3").val());
    });

    $(document).on("change", ".selectorRowAll", function () {
      if (this.checked) {
        $(".selectorRow").prop("checked", true);
      } else {
        $(".selectorRow").prop("checked", false);
      }
      let total = $("tbody tr").length;
      let checked = $(".selectorRow:checked").length;
      $("#rowSelectedLabel").attr("placeholder", checked + " of " + total + " selected");
    });

    $(document).on("change", ".selectorRow", function () {
      let total = $("tbody tr").length;
      let checked = $(".selectorRow:checked").length;
      $("#rowSelectedLabel").attr("placeholder", checked + " of " + total + " selected");
    });

    $(document).on("click", ".archiveRow", function (e) {
      let id = $(this).data('id');
      e.preventDefault();
      localStorage.setItem('currId', id);
      $("#contentConfirm").html("Are you sure you want to unarchive the account ?");
      $("#msgConfirm").show();

    });

    $(document).on("click", ".close", function () {
      $(this).parent().parent().hide();
    });

    $(document).on("click", "#confirmYes", function () {
      archiveAccount(localStorage.getItem('currId'));
    });

    $(document).on("click", "#confirmNo", function () {
      $(this).parent().parent().parent().hide();
    });

    let select2Init1 = $("#filterColumn1").select2({
      placeholder: "SPIL Details", closeOnSelect: false,
      allowHtml: true,
      allowClear: true,
      tags: false
    });

    $(document).on('change', 'select#filterColumn1', function (e) {
      e.preventDefault();
      let counter = select2Init1.select2('data').length;
      let parents = $(this).parent();
      parents.find(".select2Counter").remove();
      if (counter > 0) {
        parents.find(".select2-selection--multiple").append("<span class='select2Counter'>" + counter + " selected</span>")
      }
      $("#filterColumn1 > option").each(function () {
        let inc = $(this).attr('data-column');
        if ($(this).is(':selected')) {
          $('table#table td:nth-child(' + inc + ')').show();
          $('table#table th:nth-child(' + inc + ')').show();
        } else {
          $('table#table td:nth-child(' + inc + ')').hide();
          $('table#table th:nth-child(' + inc + ')').hide();
        }
      });
    });

    let select2Init2 = $("#filterColumn2").select2({
      placeholder: "Host Family Details", closeOnSelect: false,
      allowHtml: true,
      allowClear: true,
      tags: false
    });

    $(document).on('change', 'select#filterColumn2', function (e) {
      e.preventDefault();
      let counter = select2Init2.select2('data').length;
      let parents = $(this).parent();
      parents.find(".select2Counter").remove();
      if (counter > 0) {
        parents.find(".select2-selection--multiple").append("<span class='select2Counter'>" + counter + " selected</span>")
      }
      $("#filterColumn2 > option").each(function () {
        let inc = $(this).attr('data-column');
        if ($(this).is(':selected')) {
          $('table#table td:nth-child(' + inc + ')').show();
          $('table#table th:nth-child(' + inc + ')').show();
        } else {
          $('table#table td:nth-child(' + inc + ')').hide();
          $('table#table th:nth-child(' + inc + ')').hide();
        }
      });
    });

    let select2Init3 = $("#filterColumn3").select2({placeholder:"Trip Information",closeOnSelect : false,
      allowHtml: true,
      allowClear: true,
      tags: false
    });

    $(document).on('change','select#filterColumn3', function (e) {
        e.preventDefault(); 

        let parents = $(this).parent();

        let counter = select2Init3.select2('data').length;
        parents.find(".select2Counter").remove();
        if (counter>0) {
          parents.find(".select2-selection--multiple").append("<span class='select2Counter'>"+counter+" selected</span>")
        }

        $("#filterColumn3 > option").each(function() {
            let inc = $(this).attr('data-column');
            if ($(this).is(':selected')){
              $('table#table td:nth-child('+inc+')').show();
              $('table#table th:nth-child('+inc+')').show();
            } else {
              $('table#table td:nth-child('+inc+')').hide();
              $('table#table th:nth-child('+inc+')').hide();
            }
        });
    });

    $(document).on("click", ".btnExportCsv", function () {
      tableToCSV();
    });

    $(document).on("click", ".btnExportPdf", function () {
      tableToPdf();
    })

    $(document).on("click",".btnExportCsvAll",function(){
      tableToCSVAll();
    });

  }

  render() {
    return (
      <>
        <Modals />
        <PanelHeader size="sm"
          content={
            <div className="content">
              <div className="header text-left">
                <span className="panelHeaderDesc">View and manage HostFamily details.</span>
                <nav aria-label="breadcrumb" role="navigation">
                  <ol class="breadcrumb">
                    <li class="breadcrumb-item"><a href="/admin/overview">Dashboard</a></li>
                    <li class="breadcrumb-item active" aria-current="page">HostFamily</li>
                  </ol>
                </nav>
              </div>
            </div>
          }
        />
        <br />
        <div className="content">
          <Row>
            <Col xs={12} md={12}>
              <Card>
                <CardBody>
                  <TableFilter />
                  <hr/>
                <Row>
                  <Col md={2}>
                    <div className="form-group">
                      <select className="form-control" id="filterColumn1" multiple="multiple">
                        <option value="2" className="toggle-vis" data-column="2">Student ID</option>
                        <option value="3" className="toggle-vis" data-column="3">Student Reference</option>
                        <option value="4" className="toggle-vis" data-column="4">School Name</option>
                        <option value="5" className="toggle-vis" data-column="5">School Country</option>
                        <option value="6" className="toggle-vis" data-column="6">School Town</option>
                        <option value="7" className="toggle-vis" data-column="7">School Type</option>
                        <option value="8" className="toggle-vis" data-column="8">Agent Name</option>
                        <option value="9" className="toggle-vis" data-column="9">Agent Company</option>
                        <option value="10" className="toggle-vis" data-column="10">Coordinator Name</option>
                        <option value="11" className="toggle-vis" data-column="11">Coordinator Company</option>
                      </select>
                    </div>
                  </Col>
                  <Col md={2}>
                    <div className="form-group">
                      <select className="form-control" id="filterColumn2" multiple="multiple">
                        <option value="12" className="toggle-vis" data-column="12">First Name</option>
                        <option value="13" className="toggle-vis" data-column="13">Last Name</option>
                        <option value="14" className="toggle-vis" data-column="14">Nationality</option>
                        <option value="15" className="toggle-vis" data-column="15">Email Address</option>
                        <option value="16" className="toggle-vis" data-column="16">Date of birth</option>
                        <option value="17" className="toggle-vis" data-column="17">Age</option>
                        <option value="18" className="toggle-vis" data-column="18">Telephone Number</option>
                        <option value="19" className="toggle-vis" data-column="19">Irish Mobile Number</option>
                        <option value="20" className="toggle-vis" data-column="20">Religion</option>
                        <option value="21" className="toggle-vis" data-column="21">Gender</option>
                        <option value="22" className="toggle-vis" data-column="22">Passport Number</option>
                        <option value="23" className="toggle-vis" data-column="23">Length of stay</option>
                        <option value="24" className="toggle-vis" data-column="24">Medical Condition</option>
                        <option value="25" className="toggle-vis" data-column="25">Medication</option>
                        <option value="26" className="toggle-vis" data-column="26">Diatry Requirements</option>
                        <option value="27" className="toggle-vis" data-column="27">Allergens</option>
                        <option value="28" className="toggle-vis" data-column="28">Mothers maiden name</option>
                        <option value="29" className="toggle-vis" data-column="29">Family brothers/sister</option>
                        <option value="30" className="toggle-vis" data-column="30">School year student will study in Ireland</option>
                      </select>
                    </div>
                  </Col>
                  <Col md={2}>
                    <div className="form-group">
                      <select className="form-control" id="filterColumn3" multiple="multiple">
                        <option value="31" className="toggle-vis" data-column="31">Arrival Date</option>
                        <option value="32" className="toggle-vis" data-column="32">Departure Date</option>
                        <option value="33" className="toggle-vis" data-column="33">Host Family Name</option>
                        <option value="34" className="toggle-vis" data-column="34">Flight Type</option>
                        <option value="35" className="toggle-vis" data-column="35">Flight Airline</option>
                        <option value="36" className="toggle-vis" data-column="36">Flight Origin</option>
                        <option value="37" className="toggle-vis" data-column="37">Destination</option>
                        <option value="38" className="toggle-vis" data-column="38">Pickup</option>
                        <option value="39" className="toggle-vis" data-column="39">Flight Number</option>
                      </select>
                    </div>
                  </Col>
                  <Col md={6}>
                    <div class="form-group pull-right">
                      <span style={{"marginRight":"10px"}}><i className="now-ui-icons arrows-1_share-66"></i> Export To</span>
                      <button className="btnExport btnExportCsv"><i className="now-ui-icons files_single-copy-04"></i> CSV</button>
                      <button className="btnExport btnExportPdf"><i className="now-ui-icons files_single-copy-04"></i> PDF</button>
                      <button className="btnExport btnExportCsvAll"><i className="now-ui-icons files_single-copy-04"></i> All CSV</button>
                    </div>
                  </Col>
                </Row>
                  <div className="containerTable">
                  <table id="table" style={{width:"100%"}} className="align-items-center justify-content-center mb-0">
                     <thead>
                        <tr>
                          <th style={{paddingLeft: "15px",borderTopLeftRadius:"30px",borderBottomLeftRadius:"30px"}}><input type="checkbox" name="selectorRow" className="selectorRowAll"/></th>
                          <th className="minWidthCs" style={{display:"none"}}>Student ID</th>
                          <th className="minWidthCs" style={{display:"none"}}>Student Reference</th>
                          <th className="minWidthCs" style={{display:"none"}}>School Name</th>
                          <th className="minWidthCs" style={{display:"none"}}>School Country</th>
                          <th className="minWidthCs" style={{display:"none"}}>School Town</th>
                          <th className="minWidthCs" style={{display:"none"}}>School Type</th>
                          <th className="minWidthCs" style={{display:"none"}}>Agent Name</th>
                          <th className="minWidthCs" style={{display:"none"}}>Agent Company</th>
                          <th className="minWidthCs" style={{display:"none"}}>Coordinator Name</th>
                          <th className="minWidthCs" style={{display:"none"}}>Coordinator Company</th>
                          <th className="minWidthCs" style={{display:"none"}}>First Name</th>
                          <th className="minWidthCs" style={{display:"none"}}>Last Name</th>
                          <th className="minWidthCs" style={{display:"none"}}>Nationality</th>
                          <th className="minWidthCs" style={{display:"none"}}>Email Address</th>
                          <th className="minWidthCs" style={{display:"none"}}>Date of birth</th>
                          <th className="minWidthCs" style={{display:"none"}}>Age</th>
                          <th className="minWidthCs" style={{display:"none"}}>Telephone Number</th>
                          <th className="minWidthCs" style={{display:"none"}}>Irish Mobile Number</th>
                          <th className="minWidthCs" style={{display:"none"}}>Religion</th>
                          <th className="minWidthCs" style={{display:"none"}}>Gender</th>
                          <th className="minWidthCs" style={{display:"none"}}>Passport Number</th>
                          <th className="minWidthCs" style={{display:"none"}}>Length of stay</th>
                          <th className="minWidthCs" style={{display:"none"}}>Medical Condition</th>
                          <th className="minWidthCs" style={{display:"none"}}>Medication</th>
                          <th className="minWidthCs" style={{display:"none"}}>Diatry Requirements</th>
                          <th className="minWidthCs" style={{display:"none"}}>Allergens</th>
                          <th className="minWidthCs" style={{display:"none"}}>Mothers maiden name</th>
                          <th className="minWidthCs" style={{display:"none"}}>Family brothers/sister</th>
                          <th className="minWidthCs" style={{display:"none"}}>School year student will study in Ireland</th>
                          <th className="minWidthCs" style={{display:"none"}}>Arrival Date</th>
                          <th className="minWidthCs" style={{display:"none"}}>Departure Date</th>
                          <th className="minWidthCs" style={{display:"none"}}>Host Family Name</th>
                          <th className="minWidthCs" style={{display:"none"}}>Flight Type</th>
                          <th className="minWidthCs" style={{display:"none"}}>Flight Airline</th>
                          <th className="minWidthCs" style={{display:"none"}}>Flight Origin</th>
                          <th className="minWidthCs" style={{display:"none"}}>Destination</th>
                          <th className="minWidthCs" style={{display:"none"}}>Pickup</th>
                          <th className="minWidthCs" style={{display:"none"}}>Flight Number</th>
                          <th className="minWidthCs"></th>
                        </tr>
                     </thead>

                     <tbody id="tbody" className="tbodycs">
                     </tbody>
                  </table>
                </div>
                  <div className="containerPagination" style={{ width: "100%" }} align="center">
                    <br />
                    <div className="pagination">
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </>
    )
  }
}

export default HostFamily;