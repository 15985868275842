import React from "react";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Label,
  FormGroup,
  Form,
  Input,
  FormText,
  Row,
  Col,
  Table
} from "reactstrap";
import swal from 'sweetalert';

// core components
import PanelHeader from "components/PanelHeader/PanelHeader.js";
import Switch from "react-bootstrap-switch";
import profileImg from "assets/upload/default.png";
import defaultmedia from "assets/upload/defaultmedia.png";
import ImageUpload from "components/CustomUpload/ImageUpload.js";
import axios from "axios";
import {base_api_url} from "variables/general.js";
import $ from "jquery";

function getUrlAction() {
  let currentUrl;
  let baseUrl = base_api_url;
  currentUrl = baseUrl + "/api/v1/pc/getPhotoById";

  return currentUrl;
}

function getData() {

  const params = new Proxy(new URLSearchParams(window.location.search), {
    get: (searchParams, prop) => searchParams.get(prop),
  });

  let urlTarget = getUrlAction() + '?phototId=' + params.formid; 
  return axios.get(urlTarget, {phototId:params.id}).then(res => {
    let datas = res.data.response;
    showData(datas);
    localStorage.setItem("detailPhotograpic" + params.id, JSON.stringify(datas));
  })
}

function showData(data) {
  $("#message").html(data.notes);
  $("#from").html(data.uploadedby);
  $("#to").html(data.submittersname);
  $("#subject").html(data.reason);
  $("#br_subject").html(data.reason);
  if (data.imagelocation){
    if (data.imagelocation.match(/\.(jpg|jpeg|gif|png)$/)) {
      $("#imgcontent").attr("src", data.imagelocation);
    } else {
      $("#imgcontent").attr("src", defaultmedia);
    }
    $("#downloadLink").attr("href",data.imagelocation);
  } else {
    $("#imgcontent").attr("src",defaultmedia);
    $("#downloadLink").hide();
  }
  if (data.coordapproved=="APPROVED") {
    $(".approveStatus").show();
    $(".approve").hide();
    $(".decline").hide();
    $(".declineStatus").hide();
  } else if (data.coordapproved=="REJECTED") {
    $(".approveStatus").hide();
    $(".approve").hide();
    $(".decline").hide();
    $(".declineStatus").show();
  } else {
    $(".approveStatus").hide();
    $(".approve").show();
    $(".decline").show();
    $(".declineStatus").hide();
  }

  if (data.timestamp) {
    let rd = new Date(data.timestamp);

    rd = rd.toUTCString();
    $("#timestamp").html(rd);
  }
}

class PhotograpicDetail extends React.Component {

  componentDidMount(){
    getData()

    $(document).on("click",".approve",function(){
      const params = new Proxy(new URLSearchParams(window.location.search), {
        get: (searchParams, prop) => searchParams.get(prop),
      });

      let requestData = JSON.parse(localStorage.getItem("detailPhotograpic" + params.id));
      requestData["coordapproved"] = "APPROVED";
      return axios.put(base_api_url + "/api/v1/pc/updatebyphotoid", requestData).then(res => {
        swal("Success","Successfully update data!","success");
        setTimeout(function(){
          window.location.reload();
        },2000);
      }).catch(function (error) {
        swal("Failed!", "Something went wrong!", "error");
      });
    });

    $(document).on("click",".decline",function(){
      const params = new Proxy(new URLSearchParams(window.location.search), {
        get: (searchParams, prop) => searchParams.get(prop),
      });
      let requestData = JSON.parse(localStorage.getItem("detailPhotograpic" + params.id));
      requestData["coordapproved"] = "REJECTED";
      return axios.put(base_api_url + "/api/v1/pc/updatebyphotoid", requestData).then(res => {
        swal("Success","Successfully update data!","success");
        setTimeout(function(){
          window.location.reload();
        },2000);
      }).catch(function (error) {
        swal("Failed!", "Something went wrong!", "error");
      });
    });

  }

  render(){
    const currentDate = new Date();
    return (
    <>
      <PanelHeader size="sm"
        content={
          <div className="content">
            <div className="header text-left">
              <span className="panelHeaderDesc">View and manage your Photograpic.</span>
              <nav aria-label="breadcrumb" role="navigation">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item"><a href="/coordinator/overview">Dashboard</a></li>
                  <li className="breadcrumb-item"><a href="/coordinator/requests">Requests</a></li>
                  <li className="breadcrumb-item active" aria-current="page" id="br_subject">Photograpic Details</li>
                </ol>
              </nav>
            </div>
          </div>
        }
      />
      <br/>
      <div className="content">
        <Row>
          <Col md="12">
            <Card>
              <CardHeader>
                <h3 style={{lineHeight:1,color:"#416D86",fontFamily: "Noto Sans",fontStyle: "normal",fontWeight: 600,fontSize: "28px"}}>Media</h3>
                <CardTitle tag="h4" className="text-header-notif" id="subject"></CardTitle>
                <a style={{float:"right", height:"30px"}} className="now-ui-icons cs-cancel_yellow" href="/coordinator/requests" />
                <button className="declineBtn declineStatus" style={{marginRight:"10px",display:"none"}}>Declined</button>
                <button className="approveBtn approveStatus" style={{marginRight:"10px",display:"none"}}>Approved</button>
                <button className="declineBtn decline" style={{marginRight:"10px",display:"none"}}><i className="now-ui-icons checkred"/> Decline</button>
                <button className="approveBtn approve" style={{marginRight:"10px",display:"none"}}><i className="now-ui-icons checkgreen"/> Approve</button>
                <h4 style={{"fontFamily": "Noto Sans","fontStyle": "normal","fontWeight": 400,"fontSize": "16px","lineHeight": "24px","color": "#70B2D8"}} id="timestamp">{currentDate.toUTCString()}</h4>
              </CardHeader>
              <CardBody>
                <Form action="/" className="form-horizontal" method="get">
                  <hr/>
                  <Row className="text-header-info-notif">
                    <Col sm="12">
                      <Row>
                        <Col md="2">
                          <FormGroup>
                            <label htmlFor="pwd">From : <span id="from"></span></label>
                          </FormGroup>
                        </Col>
                        <Col md="10">
                          <FormGroup>
                            <label htmlFor="pwd">Receipent: <span id="to">Coordinator</span></label>
                          </FormGroup>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <hr/>
    
                  <Row>
                    <Col sm="12">
                      <Row className="text-desc-notif">
                        <Col md="12">
                          <FormGroup>
                            <p>RE: <span id="subject"></span></p>
                            <p><img id="imgcontent" width="80" height="80" src={profileImg}/><p id="message"></p></p>
                            <p><a target="_blank" id="downloadLink">Download</a></p>
                          </FormGroup>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Form>
              </CardBody>
              <br/>
            </Card>
          </Col>
        </Row>
    
      </div>
    </>
  );
  }
}

export default PhotograpicDetail;
