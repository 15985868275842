import React from "react";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Label,
  FormGroup,
  Form,
  Input,
  FormText,
  Row,
  Col,
  Table,
  Collapse
} from "reactstrap";

// core components
import PanelHeader from "components/PanelHeader/PanelHeader.js";
import Switch from "react-bootstrap-switch";
import profileImg from "assets/upload/profile.png";
import Select from "react-select";

var selectOptions = [
  { value: "Transition Classes", label: "Transition Classes" },
  { value: "two", label: "Two" },
  { value: "three", label: "Three" },
  { value: "four", label: "Four" },
  { value: "five", label: "Five" },
  { value: "six", label: "Six" }
];

var selectOptionstwo = [
  { value: "Fifth Classes", label: "Fifth Classes" },
  { value: "two", label: "Two" },
  { value: "three", label: "Three" },
  { value: "four", label: "Four" },
  { value: "five", label: "Five" },
  { value: "six", label: "Six" }
];

function SchoolDetail() {
  const [singleSelect, setSingleSelect] = React.useState(null);
  const [openedCollapses, setOpenCollapses] = React.useState(["collapseOne"]);
  const collapsesToggle = (collapse) => {
    if (openedCollapses.includes(collapse)) {
      setOpenCollapses([]);
    } else {
      setOpenCollapses([collapse]);
    }
  };
  return (
    <>
      <PanelHeader size="sm"
        content={
          <div className="content">
            <div className="header text-left">
              <span className="panelHeaderDesc">View and manage your Submissions.</span>
              <nav aria-label="breadcrumb" role="navigation">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item"><a href="#">Dashboard</a></li>
                  <li className="breadcrumb-item"><a href="#">Submissions</a></li>
                  <li className="breadcrumb-item active" aria-current="page">Activity Permission Form - Andy Mooruy</li>
                </ol>
              </nav>
            </div>
          </div>
        }
      />
      <br/>
      <div className="content">
        <Row>
          <Col md="3">
            <Card className="card-chart">
              <CardBody>
                <Table responsive>
                  <tbody>
                    <tr>
                      <td className="td-noborder">
                        <h4 className="student-left-text-header">SPIL Details</h4>
                        <h4 className="student-left-text-header">Submissions</h4>
                        <h4 className="student-left-text-header">Activity Information</h4>
                        <h4 className="student-left-text-header">Forms</h4>
                        <h4 className="student-left-text-header">Disclaimer</h4>
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </CardBody>
            </Card>
          </Col>
          <Col md="9">
            <Card>
              <CardHeader className="card-header-profile">
                <Row>
                  <Col md={2}>
                    <img style={{float: "left",width:"76px",height:"76px",position:"relative",bottom:"18px"}} src={profileImg}></img>
                  </Col>
                  <Col md={6}>
                    <p className="profile-title">Andi Mooroy</p>
                    <p className="profile-desc">R00123456</p>
                  </Col>
                  <Col md={4}>
                    <button style={{backgroundColor:"#DDECE7",color:"#009262",borderRadius:"10px"}} className="btn btn-primary btn-sm"><i className="now-ui-icons ui-1_check"></i>Approve</button><button style={{backgroundColor:"#F3E1E0",color:"#E02B1D",borderRadius:"10px"}} className="btn btn-primary btn-sm"><i className="now-ui-icons ui-1_simple-remove"></i>Decline</button>
                  </Col>
                </Row>
              </CardHeader>
            </Card>
            <Card>
              <CardHeader>
                <CardTitle tag="h4" className="panelTitle">SPIL Details</CardTitle>
              </CardHeader>
              <CardBody>
                <Form action="/" className="form-horizontal" method="get">
                  <Row>
                    <Col sm="12">
                      <FormGroup>
                        <h4 style={{"fontFamily": "Noto Sans","fontStyle": "normal","fontWeight": 400,"fontSize": "16px","lineHeight": "24px","color": "#70B2D8"}}>Student</h4>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm="12">
                      <Row>
                        <Col md="6">
                          <FormGroup>
                            <div className="form-floating">
                              <Input style={{"borderRadius": "5px"}} placeholder="ID" type="text" />
                              <label htmlFor="pwd">Name</label>
                            </div>
                          </FormGroup>
                        </Col>
                        <Col md="6">
                          <FormGroup>
                            <div className="form-floating">
                              <Input style={{"borderRadius": "5px"}} placeholder="ID" type="text" />
                              <label htmlFor="pwd">Contact Number</label>
                            </div>
                          </FormGroup>
                        </Col>
                      </Row>
                    </Col>
                  </Row>

                  <Row>
                    <Col sm="12">
                      <FormGroup>
                        <h4 style={{"fontFamily": "Noto Sans","fontStyle": "normal","fontWeight": 400,"fontSize": "16px","lineHeight": "24px","color": "#70B2D8"}}>Host Family</h4>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm="12">
                      <Row>
                        <Col md="12">
                          <FormGroup>
                            <div className="form-floating">
                              <Input style={{"borderRadius": "5px"}} placeholder="Name" type="text" />
                              <label htmlFor="pwd">Family Name</label>
                            </div>
                          </FormGroup>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
    
                  <Row>
                    <Col sm="12">
                      <FormGroup>
                        <h4 style={{"fontFamily": "Noto Sans","fontStyle": "normal","fontWeight": 400,"fontSize": "16px","lineHeight": "24px","color": "#70B2D8"}}>Agent</h4>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm="12">
                      <Row>
                        <Col md="12">
                          <FormGroup>
                            <div className="form-floating">
                              <Input style={{"borderRadius": "5px"}} placeholder="ID" type="text" />
                              <label htmlFor="pwd">ID</label>
                            </div>
                          </FormGroup>
                        </Col>
                      </Row>
                    </Col>
                  </Row>

                  <Row>
                    <Col sm="12">
                      <FormGroup>
                        <h4 style={{"fontFamily": "Noto Sans","fontStyle": "normal","fontWeight": 400,"fontSize": "16px","lineHeight": "24px","color": "#70B2D8"}}>Coordinator</h4>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm="12">
                      <Row>
                        <Col md="12">
                          <FormGroup>
                            <div className="form-floating">
                              <Input style={{"borderRadius": "5px"}} placeholder="ID" type="text" />
                              <label htmlFor="pwd">Name</label>
                            </div>
                          </FormGroup>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Form>
              </CardBody>
            </Card>
            <Card>
              <CardHeader>
                <CardTitle tag="h4" className="panelTitle">Submissions</CardTitle>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col sm="12">
                    <FormGroup>
                      <h4 style={{"fontFamily": "Noto Sans","fontStyle": "normal","fontWeight": 400,"fontSize": "16px","lineHeight": "24px","color": "#70B2D8"}}>Submissions</h4>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col sm="12">
                    <Row>
                      <Col md="12">
                        <FormGroup>
                          <div className="form-floating">
                            <Input style={{"borderRadius": "5px"}} placeholder="ID" type="text" />
                            <label htmlFor="pwd">Form Type</label>
                          </div>
                        </FormGroup>
                      </Col>
                      <Col md="12">
                        <FormGroup>
                          <div className="form-floating">
                            <Input style={{"borderRadius": "5px"}} placeholder="ID" type="text" />
                            <label htmlFor="pwd">Status</label>
                          </div>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="12">
                        <FormGroup>
                          <div className="form-floating">
                            <Input style={{"borderRadius": "5px"}} placeholder="ID" type="text" />
                            <label htmlFor="pwd">Submissions Date</label>
                          </div>
                        </FormGroup>
                      </Col>
                      <Col md="12">
                        <FormGroup>
                          <div className="form-floating">
                            <Input style={{"borderRadius": "5px"}} placeholder="ID" type="text" />
                            <label htmlFor="pwd">Destination</label>
                          </div>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="6">
                        <FormGroup>
                          <div className="input-group mb-3">
                            <div className="form-floating form-floating-group flex-grow-1">
                                <input className="form-control input-right-radius" name="code1" placeholder="Code 1"/>
                                <label>Date From</label>
                            </div>
                            <span className="input-group-text input-group-text-custom"><i className="now-ui-icons cs-icon-calendar"></i></span>
                          </div>
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup>
                          <div className="input-group mb-3">
                            <div className="form-floating form-floating-group flex-grow-1">
                                <input className="form-control input-right-radius" name="code1" placeholder="Code 1"/>
                                <label>Date To</label>
                            </div>
                            <span className="input-group-text input-group-text-custom"><i className="now-ui-icons cs-icon-calendar"></i></span>
                          </div>
                        </FormGroup>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </CardBody>
            </Card>
            <Card>
              <CardHeader>
                <CardTitle tag="h4" className="panelTitle">Activity Information</CardTitle>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col sm="12">
                    <Row>
                      <Col md="12">
                        <FormGroup>
                          <div className="form-floating">
                            <Input style={{"borderRadius": "5px"}} placeholder="ID" type="text" />
                            <label htmlFor="pwd">Activity Type</label>
                          </div>
                        </FormGroup>
                      </Col>
                      <Col md="12">
                        <FormGroup>
                          <div className="form-floating">
                            <Input style={{"borderRadius": "5px"}} placeholder="ID" type="text" />
                            <label htmlFor="pwd">Student to AY Destination</label>
                          </div>
                        </FormGroup>
                      </Col>
                      <Col md="12">
                        <FormGroup>
                          <div className="form-floating">
                            <Input style={{"borderRadius": "5px"}} placeholder="ID" type="text" />
                            <label htmlFor="pwd">Notes</label>
                          </div>
                        </FormGroup>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </CardBody>
            </Card>
             <Card>
              <CardHeader>
                <CardTitle tag="h4" className="panelTitle">Forms</CardTitle>
              </CardHeader>
              <CardBody>
                <Form action="/" className="form-horizontal" method="get">
                  <Row>
                    <Col sm="12">
                      <Row className="bundleBorder">
                        <Col md="3">
                          <FormGroup>
                            <div className="form-floating">             
                              <label htmlFor="pwd">Reason For Journey</label>
                            </div>
                          </FormGroup>
                        </Col>
                        <Col md="11">
                          <FormGroup className="pull-right">
                              <a href="#" style={{"color": "#717171","fontStyle": "normal","fontWeight": 400,"fontSize": "12px", "lineHeight": "16px"}}><i className="now-ui-icons arrows-1_cloud-download-93" style={{"position": "relative", "top": "8px"}}></i></a>
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row className="bundleBorder">
                        <Col md="3">
                          <FormGroup>
                            <div className="form-floating">             
                              <label htmlFor="pwd">Traveling With</label>
                            </div>
                          </FormGroup>
                        </Col>
                        <Col md="11">
                          <FormGroup className="pull-right">
                              <a href="#" style={{"color": "#717171","fontStyle": "normal","fontWeight": 400,"fontSize": "12px", "lineHeight": "16px"}}><i className="now-ui-icons arrows-1_cloud-download-93" style={{"position": "relative", "top": "8px"}}></i></a>
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row className="bundleBorder">
                        <Col md="3">
                          <FormGroup>
                            <div className="form-floating">             
                              <label htmlFor="pwd">Accomodation Detail</label>
                            </div>
                          </FormGroup>
                        </Col>
                        <Col md="11">
                          <FormGroup className="pull-right">
                              <a href="#" style={{"color": "#717171","fontStyle": "normal","fontWeight": 400,"fontSize": "12px", "lineHeight": "16px"}}><i className="now-ui-icons arrows-1_cloud-download-93" style={{"position": "relative", "top": "8px"}}></i></a>
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row className="bundleBorder">
                        <Col md="3">
                          <FormGroup>
                            <div className="form-floating">             
                              <label htmlFor="pwd">Timestamp</label>
                            </div>
                          </FormGroup>
                        </Col>
                        <Col md="11">
                          <FormGroup className="pull-right">
                              <a href="#" style={{"color": "#717171","fontStyle": "normal","fontWeight": 400,"fontSize": "12px", "lineHeight": "16px"}}><i className="now-ui-icons arrows-1_cloud-download-93" style={{"position": "relative", "top": "8px"}}></i></a>
                          </FormGroup>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Form>
              </CardBody>
            </Card>
            <Card>
              <CardHeader>
                <CardTitle tag="h4" className="panelTitle">Disclaimer</CardTitle>
              </CardHeader>
              <CardBody>
                <Form action="/" className="form-horizontal" method="get">
                  <Row>
                    <Col sm="12">
                      <Row>
                        <Col md="9">
                          <FormGroup>
                            <div class="form-floating">
                              <Input style={{"borderRadius": "5px"}} value="Active" placeholder="ID" type="text" />
                              <label for="pwd">Status</label>
                            </div>
                          </FormGroup>
                        </Col>
                        <Col md="1">
                          <FormGroup>
                            <div class="form-floating">
                              <Switch defaultValue={false} />
                            </div>
                          </FormGroup>
                        </Col>
                        <Col md="2">
                          <FormGroup>
                            <div class="form-floating">
                              <label for="pwd">Active</label>
                            </div>
                          </FormGroup>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default SchoolDetail;
