import React from "react";
import { Container } from "reactstrap";
// used for making the prop types of this component
import PropTypes from "prop-types";

function Footer(props) {
  return (
    <footer align="center" className={"footer" + (props.default ? " footer-default" : "")}>
      <Container fluid={props.fluid}>
        <nav style={{float:"none"}}>
          <ul>
            <li>
              <a
                href={window.location.origin + "/about"}
                className="mr-4-px"
                target="_blank"
              >
                © 2022 Student Programmes Ireland Ltd. All Rights Reserved.
              </a>
            </li>
            <li>
              <a
                href={window.location.origin + "/about"}
                className="mr-4-px"
                target="_blank"
              >
                Contact us
              </a>
            </li>
            <li>
              <a
                href={window.location.origin + "/privacy"}
                target="_blank"
              >
                Website by Speire
              </a>
            </li>
          </ul>
        </nav>
      </Container>
    </footer>
  );
}

Footer.defaultProps = {
  default: false,
  fluid: false
};

Footer.propTypes = {
  default: PropTypes.bool,
  fluid: PropTypes.bool
};

export default Footer;
