import React from "react";
import axios from "axios";
import {base_api_url} from "variables/general.js";
import $ from "jquery";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Label,
  FormGroup,
  Form,
  Input,
  FormText,
  Row,
  Col
} from "reactstrap";

// core components
import PanelHeader from "components/PanelHeader/PanelHeader.js";
import Modals from "views/Components/Modals.js";

function submitData() {

  let insideoroutside;
  if ($("#location_status").val()=="Indoor") {
    insideoroutside = "inside";
  } else {
    insideoroutside = "outside";
  }

  var date = new Date();
  var timestamp = date.toISOString();

  let stData = base_api_url + "/api/v1/pl/updatebypetListid";
  let dataReq = {
    fkfamilyname: $("#fkfamilyname").val(),
    id: $("#attr_id").val(),
    insideoroutside: insideoroutside,
    isactive: true,
    notes: $("#notes").val(),
    pettype: $("#pet_type").val(),
    timestamp: timestamp
  }
 
  return axios.put(stData, dataReq).then(res => {
    if (res.data=="OK"){
      $("#msgSuccess").show();
      $("#contentSuccess").html("Success!");
    }
  }).catch(function (error) {
    alert(error)
  });
}

export default class StandardReportDetail extends React.Component {

  state = {
    data:{}
  }

  async getData () {
    
    const params = new Proxy(new URLSearchParams(window.location.search), {
      get: (searchParams, prop) => searchParams.get(prop),
    });

    return await axios.get(base_api_url + "/api/v1/pl/getpetlistbyid?petListId=" + params.id).then(res => {
      const data = res.data.response;
      this.setState({ data });
    })
  }

  componentDidMount(){
    this.getData();
    
    $(document).on("click",".close",function(){
      $(this).parent().parent().hide();
    });

    $(document).on("click",".btn-csconfirm",function(e){
      e.preventDefault();
      let notValid = 0;
      let cv;
      let msgval;

      $.each($('form input'), function(index, formField) {
        if ($(formField).val()=="") {
          if (index==0) {
            cv = $(formField).parent().find("label").text();
            if (cv!=="") {
              msgval = cv + " is required! <br/>";
              $("#contentValidation").html(msgval);
              notValid += 1;
            }
          }
        }
      });

      if (notValid>0) {
        $("#msgValidation").show();
      } else {
        $("#msgValidation").hide();
        $("#contentValidation").empty();
        submitData();
      }

    });
  }

  render() {

    let location;
    let notes;

    if (this.state.data.notes) {
      notes = this.state.data["notes"];
    }

    if (this.state.data.insideoroutside=="inside") {
        location = "Indoor";
    } else if (this.state.data.insideoroutside=="outside") {
        location = "Outdoor";
    }

    let petType = this.state.data.pettype;

    return (
      <>
        <Modals/>
        <PanelHeader size="sm"
          content={
            <div className="content">
              <div className="header text-left">
                <span className="panelHeaderDesc">View and manage Pet details.</span>
                <nav aria-label="breadcrumb" role="navigation">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item"><a href="/coordinator/overview">Dashboard</a></li>
                    <li className="breadcrumb-item"><a href="/coordinator/report/standard-report">Standard Report</a></li>
                    <li className="breadcrumb-item"><a href="/coordinator/report/standard-report/pets" id="br_section">Pets</a></li>
                    <li className="breadcrumb-item active" aria-current="page" id="br_name">{this.state.data.pettype}</li>
                  </ol>
                </nav>
              </div>
            </div>
          }
        />
        <div className="content">
          <Row>
            <Col md="12">
              <Card>
                <CardHeader>
                  <CardTitle tag="h4" className="panelTitle">Details <a style={{border:"none"}} href="/admin/report/live-report/pets" type="button" aria-haspopup="true" aria-expanded="false" className="pull-right btn-round btn-icon btn btn-outline-default"><i className="now-ui-icons cs-cancel_sm_yellow"></i></a></CardTitle>
                </CardHeader>
                <CardBody>
                  <Form name="formsubmit" id="formsubmit" action="/" className="form-horizontal" method="post">
                    <Row>
                      <Col sm="12">
                        <FormGroup>
                          <div className="form-floating">
                            <Input id="attr_id" value={this.state.data.id} type="hidden" />
                            <Input readonly="true" id="family_name" defaultValue={this.state.data.fkfamilyname} style={{borderRadius: "5px"}} placeholder="ID" type="text" />
                            <label htmlFor="pwd">Family Name</label>
                          </div>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm="12">
                        <Row>
                          <Col md="6">
                            <FormGroup>
                              <div className="form-floating">
                                <Input id="pet_type" defaultValue={petType} style={{borderRadius: "5px"}} placeholder="ID" type="text" />
                                <label htmlFor="pwd">Pet Type</label>
                              </div>
                            </FormGroup>
                          </Col>
                          <Col md="6">
                            <FormGroup>
                              <div className="form-floating">
                                <Input id="location_status" defaultValue={location} style={{borderRadius: "5px"}} placeholder="ID" type="text" />
                                <label htmlFor="pwd">Location Status</label>
                              </div>
                            </FormGroup>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm="12">
                        <FormGroup>
                          <div className="form-floating">
                            <textarea id="notes" rows="4" placeholder="Notes" className="form-control" style={{border: "1px solid #e3e3e3",borderRadius: "5px"}} defaultValue={notes}/>
                            <label htmlFor="pwd">Notes</label>
                          </div>
                          <label className="pull-right">0/200</label>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm="12">
                        <div className="pull-right">
                          <button className="btn-csconfirm">Update Changes</button>
                        </div>
                      </Col>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </>
    );
  }

}