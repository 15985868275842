import React from "react";

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  Table
} from "reactstrap";
import swal from 'sweetalert';

// core components
import PanelHeader from "components/PanelHeader/PanelHeader.js";
import Switch from "react-bootstrap-switch";
import profileImg from "assets/upload/default.png";
import axios from "axios";
import {base_api_url, file_api_url} from "variables/general.js";
import $ from "jquery";
import datepickerFactory from 'jquery-datepicker';

function getData() {

  const params = new Proxy(new URLSearchParams(window.location.search), {
    get: (searchParams, prop) => searchParams.get(prop),
  });

  let urlTarget = base_api_url + '/api/v1/fc/getformbyid?formsId=' + params.id; 
  return axios.get(urlTarget).then(res => {
    let datas = res.data.response;
    let check = JSON.stringify(datas);
    localStorage.setItem("detailTpf" + params.id, check);
    showData(datas);
  })
}

function showData(data) {
  $("#ac_traveling_with").val(data.travellingwith);
  $("#ac_accomodation").val(data.accomodationaddressdetails);
  $("#ac_reason_for_journey").val(data.reasonforactivity);
  $("#ac_activity_type").val(data.activitytype);
  $("#ac_notes").val(data.additionalnotes);
  $("#ac_student_ay_destination").val(data.studenttoay);
  $("#ac_destination").val(data.destination);
  $("#student_status").val(data.approvalstatus);

  if (data.disclaimer===true) {
    $("#someSwitchOptionPrimary").prop("checked",true).trigger("change");
  } else {
    $("#someSwitchOptionPrimary").prop("checked",false).trigger("change");
  }
 
  if (data.fkstudentid) {
    getDataStudent(data.fkstudentid);
  } else {
    getDataStudentOnly();
  }

  if (data.fkschoolid) {
    getDataSchool(data.fkschoolid);
  } else {
    getDataSchoolOnly();
  }

  if (data.fkagentid) {
    getDataAgent(data.fkagentid);
  } else {
    getDataAgentOnly();
  }

  if (data.fkcoordinatorid) {
    getDataCoordinator(data.fkcoordinatorid);
  } else {
    getDataCoordinatorOnly();
  }

  if (data.fkhostfamily) {
    getDataHf(data.fkhostfamily);
  } else {
    getDataHfOnly();
  }

}

function getDataHf(id) {
  let selEl = "#hf_id";

  return axios.get(base_api_url + '/api/v1/hf').then(res => {
    let datas = res.data.response;

    $(selEl).empty();
    $(selEl).append("<option value=''>Select</option>");
    for (var i=0;i<datas.length;i++) {
      $(selEl).append("<option data-familyname="+datas[i].familyname+" data-mothername="+datas[i].mothername+" data-datevisited="+datas[i].datevisited+" data-hostfamilychild="+datas[i].nohostfamilychild+" data-fathername="+datas[i].fathername+" data-address="+datas[i].address+" value="+datas[i].id+">"+datas[i].familyname+"</option>");
    }

    axios.get(base_api_url + '/api/v1/hf/gethostfamilybyid?hostFamilyId=' + id).then(res => {
      $(selEl).val(res.data.response.hfDetails.id).trigger("change");
    })

  })

}

function getDataHfOnly() {
  let selEl = "#hf_id";

  return axios.get(base_api_url + '/api/v1/hf').then(res => {
    let datas = res.data.response;

    $(selEl).empty();
    $(selEl).append("<option value=''>Select</option>");
    for (var i=0;i<datas.length;i++) {
      $(selEl).append("<option data-familyname="+datas[i].familyname+" data-mothername="+datas[i].mothername+" data-datevisited="+datas[i].datevisited+" data-hostfamilychild="+datas[i].nohostfamilychild+" data-fathername="+datas[i].fathername+" data-address="+datas[i].address+" value="+datas[i].id+">"+datas[i].familyname+"</option>");
    }
  })

}

function getDataAgent(id) {

  let selEl = "#agent_id";

  return axios.get(base_api_url + '/api/v1/agent').then(res => {
    let datas = res.data.response;

    $(selEl).empty();
    $(selEl).append("<option value=''>Select</option>");
    for (var i=0;i<datas.length;i++) {
      $(selEl).append("<option data-company="+datas[i].company+" data-name="+datas[i].name+" value="+datas[i].id+">"+datas[i].name+"</option>");
    }

    axios.get(base_api_url + '/api/v1/agent/getagentbyid?agentId=' + id).then(res => {
      $(selEl).val(res.data.response.id).trigger("change");
    })

  })
}

function getDataAgentOnly() {

  let selEl = "#agent_id";

  return axios.get(base_api_url + '/api/v1/agent').then(res => {
    let datas = res.data.response;

    $(selEl).empty();
    $(selEl).append("<option value=''>Select</option>");
    for (var i=0;i<datas.length;i++) {
      $(selEl).append("<option data-company="+datas[i].company+" data-name="+datas[i].name+" value="+datas[i].id+">"+datas[i].name+"</option>");
    }

  })
}

function getDataCoordinator(id) {
  let selEl = "#coordinator_id";

  return axios.get(base_api_url + '/api/v1/coord').then(res => {
    let datas = res.data.response;

    $(selEl).empty();
    $(selEl).append("<option value=''>Select</option>");
    
    for (var i=0;i<datas.length;i++) {
      $(selEl).append("<option data-company="+datas[i].company+" data-name="+datas[i].name+" value="+datas[i].id+">"+datas[i].name+"</option>");
    }

    axios.get(base_api_url + '/api/v1/coord/getcoordbyid?coordId=' + id).then(res => {
      $(selEl).val(res.data.response.id).trigger("change");
    })

  })
}

function getDataCoordinatorOnly() {
  let selEl = "#coordinator_id";

  return axios.get(base_api_url + '/api/v1/coord').then(res => {
    let datas = res.data.response;

    $(selEl).empty();
    $(selEl).append("<option value=''>Select</option>");
    
    for (var i=0;i<datas.length;i++) {
      $(selEl).append("<option data-company="+datas[i].company+" data-name="+datas[i].name+" value="+datas[i].id+">"+datas[i].name+"</option>");
    }

  })
}

function getDataStudent(id) {
  let selEl = "#student_id"; 
  return axios.get(base_api_url + '/api/v1/student').then(res => {
    let datas = res.data.response;

    $(selEl).empty();
    $(selEl).append("<option value=''>Select</option>");

    for (var i=0;i<datas.length;i++) {
      $(selEl).append("<option data-id="+datas[i].id+" data-img="+datas[i].photourl+" data-firstname="+datas[i].firstname+" data-lastname="+datas[i].lastname+" value="+datas[i].id+">" + datas[i].firstname + " " + datas[i].lastname + "</option>");
    }

    axios.get(base_api_url + '/api/v1/student/getstudentbyid?studentId=' + id).then(res => {
      $(selEl).val(res.data.response.student.id).trigger("change");
      $("#br_name").html("Activity Permission Form - " + res.data.response.student.firstname + " " + res.data.response.student.lastname);
      $("#imgPreview").attr("src",res.data.response.student.photourl);
    })

  })
}

function getDataStudentOnly() {
  let selEl = "#student_id"; 
  return axios.get(base_api_url + '/api/v1/student').then(res => {
    let datas = res.data.response;
    $(selEl).empty();
    $(selEl).append("<option value=''>Select</option>");
    for (var i=0;i<datas.length;i++) {
      $(selEl).append("<option data-id="+datas[i].id+" data-img="+datas[i].photourl+" data-firstname="+datas[i].firstname+" data-lastname="+datas[i].lastname+" value="+datas[i].id+">" + datas[i].firstname + " " + datas[i].lastname + "</option>");
    }
  })
}

function getDataSchool(id) {
  let selEl = "#school_id"; 
  return axios.get(base_api_url + '/api/v1/school').then(res => {
    let datas = res.data.response;

    $(selEl).empty();
    $(selEl).append("<option value=''>Select</option>");

    for (var i=0;i<datas.length;i++) {
      $(selEl).append("<option data-type="+datas[i].type+" data-town="+datas[i].town+" data-country="+datas[i].country+" data-name="+datas[i].name+" value="+datas[i].id+">"+datas[i].name+"</option>");
    }

    axios.get(base_api_url + '/api/v1/school/getschoolbyid?schoolId=' + id).then(res => {
      $(selEl).val(res.data.response.id).trigger("change");
    })

  })
}

function getDataSchoolOnly() {
  let selEl = "#school_id"; 
  return axios.get(base_api_url + '/api/v1/school').then(res => {
    let datas = res.data.response;

    $(selEl).empty();
    $(selEl).append("<option value=''>Select</option>");

    for (var i=0;i<datas.length;i++) {
      $(selEl).append("<option data-type="+datas[i].type+" data-town="+datas[i].town+" data-country="+datas[i].country+" data-name="+datas[i].name+" value="+datas[i].id+">"+datas[i].name+"</option>");
    }

  })
}

function submitData() {

  const params = new Proxy(new URLSearchParams(window.location.search), {
    get: (searchParams, prop) => searchParams.get(prop),
  });

  var date = new Date();
  var timestamp = date.toISOString();
  let disclaimer = $("#someSwitchOptionPrimary").is(':checked');

  let requestData = JSON.parse(localStorage.getItem("detailTpf" + params.id));

  requestData["approvalstatus"] = $("#student_status").val();

  //requestData["fkagentid"] = $("#agent_id").val();
  requestData["fkcoordinatorid"] = $("#coordinator_id").val();
  requestData["fkhostfamily"] = $("#hf_id").val();
  requestData["fkstudentid"] = $("#student_id").val();

  requestData["activitytype"] = $("#ac_activity_type").val();
  requestData["destination"] = $("#ac_destination").val();
  requestData["studenttoay"] = $("#ac_student_ay_destination").val();
  requestData["additionalnotes"] = $("#ac_notes").val();
  requestData["reasonforactivity"] = $("#ac_reason_for_journey").val();
  requestData["travellingwith"] = $("#ac_traveling_with").val();
  requestData["accomodationaddressdetails"] = $("#ac_accomodation").val();

  requestData["disclaimer"] = disclaimer;
  
  requestData["timestamp"] = timestamp;

  let user_login = JSON.parse(localStorage.getItem('user_login'));
  let paramRole = "?role=" + user_login.role;

  return axios.put(base_api_url + "/api/v1/fc/updatebyformsid" + paramRole, requestData).then(res => {
    swal("Success","Successfully update data!","success");
    setTimeout(function(){
      window.location.reload();
    },500);
  }).catch(function (error) {
    swal("Failed!", "Something went wrong!", "error");
  });
}

class ApfDetail extends React.Component {

  constructor() {
    super();
    this.state = { checked: false };
  }

  handleSwitchDisclaimer(checked) {
    if (checked.state.value===false){
      $(".disclaimer-lbl").val("Inactive");
      $(".disclaimer-lbl-lbl").html("Inactive");
    } else {
      $(".disclaimer-lbl").val("Active");
      $(".disclaimer-lbl-lbl").html("Active");
    }
  }

  componentDidMount(){

    datepickerFactory($);
    $('#flight_arrivaldate').datepicker({dateFormat: "dd MM, yy"});
    $('#flight_departuredate').datepicker({dateFormat: "dd MM, yy"});

    $(document).on("change","#someSwitchOptionPrimary",function(){
      let val = String($(this).is(':checked'));

      if (val=="false") {
        $(".disclaimer-lbl").val("Inactive").trigger("change");
        $(".disclaimer-lbl-lbl").html("Inactive").trigger("change");
      } else {
        $(".disclaimer-lbl").val("Active").trigger("change");
        $(".disclaimer-lbl-lbl").html("Active").trigger("change");
      }
    });

    $(document).on("click",".btn-csconfirm",function(e){
      e.preventDefault();
      $(".errorCs").remove();
      
      let notValid = 0;
      let msgval = "";
      let cv;

      $.each($('form input'), function(index, formField) {
        if ($(formField).val()==="") {
          cv = $(formField).parent().find("label").text();
          if (cv!=="") {
            msgval = cv + " is required! <br/>";
            $(formField).parent().append("<span class='errorCs'>" + msgval + "</span>");
            notValid += 1;
          }
        }
      });

      if (notValid>0) {
        //$("#msgValidation").show();
      } else {
        submitData();
      }
      
    });

    $(document).on("click",".close",function(){
      $(this).parent().parent().hide();
    });

    $('#photo').change(function(){
      const file = this.files[0];
      if (file){
        let reader = new FileReader();
        reader.onload = function(event){
          $('#imgPreview').attr('src', event.target.result);
        }
        reader.readAsDataURL(file);
      }
    });

    getData()

    $(document).on("change","#agent_id",function(e){
      $("#agent_name").val($(this).find(':selected').data('name'));
      $("#agent_company").val($(this).find(':selected').data('company'));
    });

    $(document).on("change","#school_id",function(e){
      $("#school_name").val($(this).find(':selected').data('name'));
      $("#school_country").val($(this).find(':selected').data('country'));
      $("#school_town").val($(this).find(':selected').data('town'));
      $("#school_type").val($(this).find(':selected').data('type'));
    });

    $(document).on("change","#coordinator_id",function(e){
      $("#coordinator_name").val($(this).find(':selected').data('name'));
      $("#coordinator_company").val($(this).find(':selected').data('company'));
    });

    $(document).on("change","#hf_id",function(e){
      $("#hf_family_name").val($(this).find(':selected').data('familyname'));
      $("#hf_father_name").val($(this).find(':selected').data('fathername'));
      $("#hf_mother_name").val($(this).find(':selected').data('mothername'));
      $("#hf_address").val($(this).find(':selected').data('address'));
      $("#hf_date_visited").val($(this).find(':selected').data('datevisited'));
      $("#hf_host_family_child").val($(this).find(':selected').data('hostfamilychild'));
    });

    $(document).on("change","#student_id",function(e){
      $("#student_first_name").val($(this).find(':selected').data('firstname'));
      $("#student_last_name").val($(this).find(':selected').data('lastname'));
      $("#t_id").html($(this).find(':selected').data('id'));
      $("#t_name").html($(this).find(':selected').data('firstname') + " " + $(this).find(':selected').data('lastname'));
      $("#imgPreview").attr("src",$(this).find(':selected').data('img'));
    });

  }

  render() {
    return (
      <>
        <PanelHeader size="sm"
          content={
            <div className="content">
              <div className="header text-left">
                <span className="panelHeaderDesc">View and manage Activity Permission Forms.</span>
                <nav aria-label="breadcrumb" role="navigation">
                  <ol class="breadcrumb">
                    <li class="breadcrumb-item"><a href="/admin/overview">Dashboard</a></li>
                    <li class="breadcrumb-item"><a href="/admin/standard-report">Standard Reports</a></li>
                    <li class="breadcrumb-item"><a href="/admin/standard-report/activity-permission-form">Activity Permission Forms</a></li>
                    <li class="breadcrumb-item active" aria-current="page" id="br_name"></li>
                  </ol>
                </nav>
              </div>
            </div>
          }
        />
        <br/>
        <div className="content">
          <Form name="formsubmit" id="formsubmit" action="/" className="form-horizontal" method="post">
            <Row>
              <Col md="3">
                <Card className="card-chart">
                  <CardBody>
                    <Table responsive>
                      <tbody>
                        <tr>
                          <td className="td-noborder">
                            <a href="#c_spil_details" className="anchorItemDetail">SPIL Details</a>
                            <a href="#c_activity_information" className="anchorItemDetail">Activity Information</a>
                            <a href="#c_disclaimer" className="anchorItemDetail">Disclaimer</a>
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </CardBody>
                </Card>
              </Col>
              <Col md="9">
              <Card>
                  <CardHeader className="card-header-profile">
                    <Row>
                      <Col md={2}>
                        <img id="imgPreview" style={{float: "left",width:"76px",height:"76px",position:"relative",bottom:"18px"}} src={profileImg}></img>
                      </Col>
                      <Col md={8}>
                        <p className="profile-title" id="t_name"></p>
                        <p className="profile-desc" id="t_id"></p>
                      </Col>
                      <Col md={2}>
                        <select className="form-control" id="student_status">
                          <option value="PENDING">PENDING</option>
                          <option value="APPROVED">APPROVED</option>
                          <option value="REJECTED">REJECTED</option>
                        </select>
                      </Col>
                    </Row>
                  </CardHeader>
                </Card>
                <Card id="c_spil_details">
                  <CardHeader>
                    <CardTitle tag="h4" className="panelTitle">SPIL Details</CardTitle>
                  </CardHeader>
                  <CardBody>
                    <Row>
                      <Col sm="12">
                        <FormGroup>
                          <h4 style={{"font-family": "Noto Sans","font-style": "normal","font-weight": 400,"font-size": "16px","line-height": "24px","color": "#70B2D8"}}>Student</h4>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={6}>
                        <FormGroup>
                          <div className="form-floating">
                            <select placeholder="s" id="student_id" style={{borderRadius:"5px"}} className="form-control">
                              <option value="">Select Student</option>
                            </select>
                            <label htmlFor="pwd">Student</label>
                          </div>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm="12">
                        <Row>
                          <Col md="6">
                            <FormGroup>
                              <div class="form-floating">
                                <Input readonly="true" id="student_first_name" style={{"borderRadius": "5px"}} placeholder="ID" type="text" />
                                <label for="pwd">First Name</label>
                              </div>
                            </FormGroup>
                          </Col>
                          <Col md="6">
                            <FormGroup>
                              <div class="form-floating">
                                <Input readonly="true" id="student_last_name" style={{"borderRadius": "5px"}} placeholder="ID" type="text" />
                                <label for="pwd">Last Name</label>
                              </div>
                            </FormGroup>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm="12">
                        <FormGroup>
                          <h4 style={{"font-family": "Noto Sans","font-style": "normal","font-weight": 400,"font-size": "16px","line-height": "24px","color": "#70B2D8"}}>Host Family</h4>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={6}>
                        <FormGroup>
                          <div className="form-floating">
                            <select placeholder="s" id="hf_id" style={{borderRadius:"5px"}} className="form-control">
                              <option value="">Select</option>
                            </select>
                            <label htmlFor="pwd">Host Family</label>
                          </div>
                        </FormGroup>
                      </Col>
                      <Col md={6}>
                        <FormGroup>
                          <div class="form-floating">
                            <Input readonly="true" id="hf_family_name" style={{"borderRadius": "5px"}} placeholder="ID" type="text" />
                            <label for="pwd">Family Name</label>
                          </div>
                        </FormGroup>
                      </Col>
                    </Row>
                 
                    <Row>
                      <Col sm="12">
                        <FormGroup>
                          <h4 style={{"font-family": "Noto Sans","font-style": "normal","font-weight": 400,"font-size": "16px","line-height": "24px","color": "#70B2D8"}}>Coordinator</h4>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={6}>
                        <FormGroup>
                          <div className="form-floating">
                            <select placeholder="s" id="coordinator_id" style={{borderRadius:"5px"}} className="form-control">
                              <option value="">Select</option>
                            </select>
                            <label htmlFor="pwd">Coordinator</label>
                          </div>
                        </FormGroup>
                      </Col>
                      <Col md={6}>
                        <FormGroup>
                          <div class="form-floating">
                            <Input readonly="true" id="coordinator_name" style={{"borderRadius": "5px"}} placeholder="ID" type="text" />
                            <label for="pwd">Name</label>
                          </div>
                        </FormGroup>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col md="3">
              </Col>
              <Col md="9">
                <Card id="c_activity_information">
                  <CardHeader>
                    <CardTitle tag="h4" className="panelTitle">Activity Information</CardTitle>
                  </CardHeader>
                  <CardBody>
                    <Row>
                      <Col sm="12">
                        <Row>
                          <Col md="6">
                            <FormGroup>
                              <div class="form-floating">
                                <Input id="ac_activity_type" style={{"borderRadius": "5px"}} placeholder="ID" type="text" />
                                <label for="pwd">Activity Type</label>
                              </div>
                            </FormGroup>
                          </Col>
                          <Col md="6">
                            <FormGroup>
                              <div class="form-floating">
                                <Input id="ac_destination" style={{"borderRadius": "5px"}} placeholder="ID" type="text" />
                                <label for="pwd">Destination</label>
                              </div>
                            </FormGroup>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm="12">
                        <Row>
                          <Col md="6">
                            <FormGroup>
                              <div class="form-floating">
                                <Input id="ac_student_ay_destination" style={{"borderRadius": "5px"}} placeholder="ID" type="text" />
                                <label for="pwd">Student to AY Destination</label>
                              </div>
                            </FormGroup>
                          </Col>
                          <Col md="6">
                            
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm="12">
                        <Row>
                          <Col md="12">
                            <FormGroup>
                              <div class="form-floating">
                                <textarea className="form-control" id="ac_notes" style={{"borderRadius": "5px","border":"1px solid #e3e3e3"}}></textarea>
                                <label for="pwd">Notes</label>
                              </div>
                            </FormGroup>
                          </Col>
                          <Col md="6">
                            
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm="12">
                        <Row>
                          <Col md="12">
                            <FormGroup>
                              <div class="form-floating">
                                <Input id="ac_reason_for_journey" style={{"borderRadius": "5px"}} placeholder="ID" type="text" />
                                <label for="pwd">Reason for Activity</label>
                              </div>
                            </FormGroup>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm="12">
                        <Row>
                          <Col md="12">
                            <FormGroup>
                              <div class="form-floating">
                                <Input id="ac_traveling_with" style={{"borderRadius": "5px"}} placeholder="ID" type="text" />
                                <label for="pwd">Traveling with</label>
                              </div>
                            </FormGroup>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm="12">
                        <FormGroup>
                          <div class="form-floating">
                            <textarea id="ac_accomodation" className="form-control" style={{border: "1px solid #e3e3e3","borderRadius": "5px"}} placeholder="ID" type="text"></textarea>
                            <label for="pwd">Accomodation Address Details</label>
                          </div>
                        </FormGroup>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col md="3">
              </Col>
              <Col md="9">
                <Card id="c_disclaimer">
                  <CardHeader>
                    <CardTitle tag="h4" className="panelTitle">Disclaimer</CardTitle>
                  </CardHeader>
                  <CardBody>             
                    <Row>
                      <Col sm="12">
                        <Row>
                          <Col md="9">
                            <FormGroup>
                              <div class="form-floating">
                                <Input className="disclaimer-lbl" style={{"borderRadius": "5px"}} value="Inactive" placeholder="ID" type="text" />
                                <label for="pwd">Status</label>
                              </div>
                            </FormGroup>
                          </Col>
                          <Col md="1">
                            <FormGroup>
                              <div class="form-floating">
                                <div class="material-switch">
                                    <input id="someSwitchOptionPrimary" name="someSwitchOption001" type="checkbox"/>
                                    <label for="someSwitchOptionPrimary" class="label-primary"></label>
                                </div>
                              </div>
                            </FormGroup>
                          </Col>
                          <Col md="2">
                            <FormGroup>
                              <div class="form-floating">
                                <label for="pwd" className="disclaimer-lbl-lbl">Inactive</label>
                              </div>
                            </FormGroup>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
                <Row>
                  <Col sm="12">
                    <div className="pull-right">
                      <button className="btn-csconfirm">Update Changes</button>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Form>
        </div>
      </>
    );
  }
}

export default ApfDetail;
