import React from "react";
import axios from "axios";
import {base_api_url} from "variables/general.js";
import $ from "jquery";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Label,
  FormGroup,
  Form,
  Input,
  FormText,
  Row,
  Col
} from "reactstrap";

// core components
import PanelHeader from "components/PanelHeader/PanelHeader.js";
import Modals from "views/Components/Modals.js";

function submitData() {
  const params = new Proxy(new URLSearchParams(window.location.search), {
    get: (searchParams, prop) => searchParams.get(prop),
  });
  var date = new Date();
  var timestamp = date.toISOString();
  let user_login = JSON.parse(localStorage.getItem('user_login'));

  let stData = base_api_url + "/api/v1/student/updatebystudentid?role="+user_login.role;
  let requestData = JSON.parse(localStorage.getItem("detailDiatry" + params.id));
  requestData["allergies"] = $("#student_allergies").val();
  requestData["diatryrequirements"] = $("#student_diatry_requirements").val();
  requestData["firstname"] = $("#student_first_name").val();
  requestData["fkagentid"] = $("#agent_id").val();
  requestData["fkcoordinatorid"] = $("#coordinator_id").val();
  requestData["fkhostfamilyid"] = $("#hf_id").val();
  requestData["lastname"] = $("#student_last_name").val();
  requestData["medicalcondition"] = $("#student_medical_condition").val();
  requestData["onanymedication"] = $("#student_on_any_medication").val();
  requestData["timestamp"] = timestamp;
 
  return axios.put(stData, requestData).then(res => {
    if (res.data=="OK"){
      $("#msgSuccess").show();
      $("#contentSuccess").html("Success!");
    }
  }).catch(function (error) {
    alert(error)
  });
}

function getDataHf(id) {
  let selEl = "#hf_id";

  return axios.get(base_api_url + '/api/v1/hf').then(res => {
    let datas = res.data.response;

    $(selEl).empty();
    $(selEl).append("<option value=''>Select</option>");
    for (var i=0;i<datas.length;i++) {
      $(selEl).append("<option data-familyname="+datas[i].familyname+" data-mothername="+datas[i].mothername+" data-datevisited="+datas[i].datevisited+" data-hostfamilychild="+datas[i].nohostfamilychild+" data-fathername="+datas[i].fathername+" data-address="+datas[i].address+" value="+datas[i].id+">"+datas[i].name+"</option>");
    }

    axios.get(base_api_url + '/api/v1/hf/gethostfamilybyid?hostFamilyId=' + id).then(res => {
    let datas = res.data.response;
      $(selEl).val(res.data.response.id).trigger("change");
    })

  })

}

function getDataAgent(id) {

  let selEl = "#agent_id";

  return axios.get(base_api_url + '/api/v1/agent').then(res => {
    let datas = res.data.response;

    $(selEl).empty();
    $(selEl).append("<option value=''>Select</option>");
    for (var i=0;i<datas.length;i++) {
      $(selEl).append("<option data-company="+datas[i].company+" data-name="+datas[i].name+" value="+datas[i].id+">"+datas[i].name+"</option>");
    }

    axios.get(base_api_url + '/api/v1/agent/getagentbyid?agentId=' + id).then(res => {
    let datas = res.data.response;
      $(selEl).val(res.data.response.id).trigger("change");
    })

  })
}

function getDataCoordinator(id) {
  let selEl = "#coordinator_id";

  return axios.get(base_api_url + '/api/v1/coord').then(res => {
    let datas = res.data.response;

    $(selEl).empty();
    $(selEl).append("<option value=''>Select</option>");
    
    for (var i=0;i<datas.length;i++) {
      $(selEl).append("<option data-company="+datas[i].company+" data-name="+datas[i].name+" value="+datas[i].id+">"+datas[i].name+"</option>");
    }

    axios.get(base_api_url + '/api/v1/coord/getcoordbyid?coordId=' + id).then(res => {
      $(selEl).val(res.data.response.id).trigger("change");
    })

  })
}

export default class LiveReportDetail extends React.Component {

  state = {
    data:{}
  }

  async getData () {
    
    const params = new Proxy(new URLSearchParams(window.location.search), {
      get: (searchParams, prop) => searchParams.get(prop),
    });

    return await axios.get(base_api_url + "/api/v1/student/getstudentbyid?studentId=" + params.id).then(res => {
      const data = res.data.response;
      localStorage.setItem("detailDiatry" + params.id, JSON.stringify(data.student));
      $("#attr_id").val(data.student.id);
      $("#student_allergies").val(data.student.allergies);
      $("#student_diatry_requirements").val(data.student.diatryrequirements);
      $("#student_medical_condition").val(data.student.medicalcondition);
      $("#student_on_any_modification").val(data.student.onanymedication);
      $("#student_first_name").val(data.student.firstname);
      $("#student_last_name").val(data.student.lastname);

      if (data.student.fkagentid) {
        getDataAgent(data.student.fkagentid);
      }

      if (data.student.fkcoordinatorid) {
        getDataCoordinator(data.student.fkcoordinatorid);
      }

      if (data.student.fkhostfamilyid) {
        getDataHf(data.student.fkhostfamilyid);
      }

    })
  }

  componentDidMount(){
    this.getData();
    
    $(document).on("click",".close",function(){
      $(this).parent().parent().hide();
    });

    $(document).on("click",".btn-csconfirm",function(e){
      e.preventDefault();
      let notValid = 0;
      let cv;
      let msgval;

      $.each($('form input'), function(index, formField) {
        if ($(formField).val()=="") {
          if (index==0) {
            cv = $(formField).parent().find("label").text();
            if (cv!=="") {
              msgval = cv + " is required! <br/>";
              $("#contentValidation").html(msgval);
              notValid += 1;
            }
          }
        }
      });

      if (notValid>0) {
        $("#msgValidation").show();
      } else {
        $("#msgValidation").hide();
        $("#contentValidation").empty();
        submitData();
      }

    });

    $(document).on("change","#agent_id",function(e){
      $("#agent_name").val($(this).find(':selected').data('name'));
      $("#agent_company").val($(this).find(':selected').data('company'));
    });

    $(document).on("change","#coordinator_id",function(e){
      $("#coordinator_name").val($(this).find(':selected').data('name'));
      $("#coordinator_company").val($(this).find(':selected').data('company'));
    });

    $(document).on("change","#hf_id",function(e){
      $("#hf_family_name").val($(this).find(':selected').data('familyname'));
      $("#hf_father_name").val($(this).find(':selected').data('fathername'));
      $("#hf_mother_name").val($(this).find(':selected').data('mothername'));
      $("#hf_address").val($(this).find(':selected').data('address'));
      $("#hf_date_visited").val($(this).find(':selected').data('datevisited'));
      $("#hf_host_family_child").val($(this).find(':selected').data('hostfamilychild'));
    });

  }

  render() {
    return (
      <>
        <Modals/>
        <PanelHeader size="sm"
          content={
            <div className="content">
              <div className="header text-left">
                <span className="panelHeaderDesc">View and manage diatry requirements details.</span>
                <nav aria-label="breadcrumb" role="navigation">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item"><a href="/admin/overview">Dashboard</a></li>
                    <li className="breadcrumb-item"><a href="/admin/report/live-report">Live Reports</a></li>
                    <li className="breadcrumb-item"><a href="#" id="br_section">Diatry Requirements</a></li>
                    <li className="breadcrumb-item active" aria-current="page" id="br_name"></li>
                  </ol>
                </nav>
              </div>
            </div>
          }
        />
        <div className="content">
          <Row>
            <Col md="12">
              <Card>
                <CardHeader>
                  <CardTitle tag="h4" className="panelTitle">Details <a style={{border:"none"}} href="/admin/report/live-report/diatry-requirements" type="button" aria-haspopup="true" aria-expanded="false" className="pull-right btn-round btn-icon btn btn-outline-default"><i className="now-ui-icons cs-cancel_sm_yellow"></i></a></CardTitle>
                </CardHeader>
                <CardBody>
                  <Form name="formsubmit" id="formsubmit" action="/" className="form-horizontal" method="post">
                    <Row>
                      <Col md={6}>
                        <FormGroup>
    	                    <div className="form-floating">
                            <Input id="attr_id" value={this.state.data.id} type="hidden" />
                            <Input id="student_first_name" style={{borderRadius: "5px"}} placeholder="ID" type="text" />
                            <label htmlFor="pwd">Student First Name</label>
                          </div>
                        </FormGroup>
                      </Col>
                      <Col md={6}>
                        <FormGroup>
                          <div className="form-floating">
                            <Input id="student_last_name" style={{borderRadius: "5px"}} placeholder="ID" type="text" />
                            <label htmlFor="pwd">Student Last Name</label>
                          </div>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={6}>
                        <FormGroup>
                          <div className="form-floating">
                            <select placeholder="s" id="hf_id" style={{borderRadius:"5px"}} className="form-control">
                              <option value="">Select</option>
                            </select>
                            <label htmlFor="pwd">Host Family</label>
                          </div>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={6}>
                        <FormGroup>
                          <div className="form-floating">
                            <Input readonly="true" id="hf_family_name" style={{borderRadius: "5px"}} placeholder="ID" type="text" />
                            <label htmlFor="pwd">Host Family - Family Name</label>
                          </div>
                        </FormGroup>
                      </Col>
                      <Col md={6}>
                        <FormGroup>
                          <div className="form-floating">
                            <Input id="student_diatry_requirements" style={{borderRadius: "5px"}} placeholder="ID" type="text" />
                            <label htmlFor="pwd">Student Diatry Requirements</label>
                          </div>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={6}>
                        <FormGroup>
                          <div className="form-floating">
                            <Input id="student_medical_condition" style={{borderRadius: "5px"}} placeholder="ID" type="text" />
                            <label htmlFor="pwd">Student Medical Condition</label>
                          </div>
                        </FormGroup>
                      </Col>
                      <Col md={6}>
                        <FormGroup>
                          <div className="form-floating">
                            <Input id="student_on_any_modification" style={{borderRadius: "5px"}} placeholder="ID" type="text" />
                            <label htmlFor="pwd">Student on any medication</label>
                          </div>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={6}>
                        <FormGroup>
                          <div className="form-floating">
                            <Input id="student_allergies" style={{borderRadius: "5px"}} placeholder="ID" type="text" />
                            <label htmlFor="pwd">Student Allergies</label>
                          </div>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={6}>
                        <FormGroup>
                          <div className="form-floating">
                            <select placeholder="s" id="coordinator_id" style={{borderRadius:"5px"}} className="form-control">
                              <option value="">Select</option>
                            </select>
                            <label htmlFor="pwd">Coordinator</label>
                          </div>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={6}>
                        <FormGroup>
                          <div className="form-floating">
                            <Input readonly="true" id="coordinator_name" style={{borderRadius: "5px"}} placeholder="ID" type="text" />
                            <label htmlFor="pwd">Coordinator Name</label>
                          </div>
                        </FormGroup>
                      </Col>
                      <Col md={6}>
                        <FormGroup>
                          <div className="form-floating">
                            <Input readonly="true" id="coordinator_company" style={{borderRadius: "5px"}} placeholder="ID" type="text" />
                            <label htmlFor="pwd">Coordinator Company</label>
                          </div>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={6}>
                        <FormGroup>
                          <div className="form-floating">
                            <select placeholder="s" id="agent_id" style={{borderRadius:"5px"}} className="form-control">
                              <option value="">Select Agent</option>
                            </select>
                            <label htmlFor="pwd">Agents</label>
                          </div>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={6}>
                        <FormGroup>
                          <div className="form-floating">
                            <Input readonly="true" id="agent_name" style={{borderRadius: "5px"}} placeholder="ID" type="text" />
                            <label htmlFor="pwd">Agent Name</label>
                          </div>
                        </FormGroup>
                      </Col>
                      <Col md={6}>
                        <FormGroup>
                          <div className="form-floating">
                            <Input readonly="true" id="agent_company" style={{borderRadius: "5px"}} placeholder="ID" type="text" />
                            <label htmlFor="pwd">Agent Company</label>
                          </div>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm="12">
                        <div className="pull-right">
                        	<button className="btn-csconfirm">Update Changes</button>
                        </div>
                      </Col>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </>
    );
  }

}