import React from "react";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Label,
  FormGroup,
  Form,
  Input,
  FormText,
  Row,
  Col,
  Table
} from "reactstrap";

// core components
import PanelHeader from "components/PanelHeader/PanelHeader.js";
import Switch from "react-bootstrap-switch";
import profileImg from "assets/upload/profile.png";
import ImageUpload from "components/CustomUpload/ImageUpload.js";

function NotificationDetail() {
  const currentDate = new Date();
  return (
    <>
      <PanelHeader size="sm"
        content={
          <div className="content">
            <div className="header text-left">
              <span className="panelHeaderDesc">View and manage your Notifications.</span>
              <nav aria-label="breadcrumb" role="navigation">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item"><a href="#">Dashboard</a></li>
                  <li className="breadcrumb-item"><a href="#">Log Notes</a></li>
                  <li className="breadcrumb-item active" aria-current="page">Log 1</li>
                </ol>
              </nav>
            </div>
          </div>
        }
      />
      <br/>
      <div className="content">
        <Row>
          <Col md="12">
            <Card>
              <CardHeader>
                <h4 style={{"fontFamily": "Noto Sans","fontStyle": "normal","fontWeight": 400,"fontSize": "16px","lineHeight": "24px","color": "#70B2D8"}}>{currentDate.toUTCString()}</h4>
              </CardHeader>
              <CardBody>
                <Form action="/" className="form-horizontal" method="get">
                  <hr/>
                  <Row>
                    <Col sm="12">
                      <Row>
                        <Col md="12">
                          <FormGroup>
                            <div className="form-floating">
                              <Input style={{"borderRadius": "5px"}} placeholder="ID" type="text" />
                              <label htmlFor="pwd">Log Status</label>
                            </div>
                          </FormGroup>
                        </Col>
                        <Col md="12">
                          <FormGroup>
                            <div className="form-floating">
                              <Input style={{"borderRadius": "5px"}} placeholder="Reference" type="text" />
                              <label htmlFor="pwd">School</label>
                            </div>
                          </FormGroup>
                        </Col>
                        <Col md="12">
                          <FormGroup>
                            <div className="form-floating">
                              <Input style={{"borderRadius": "5px"}} placeholder="Reference" type="text" />
                              <label htmlFor="pwd">Student</label>
                            </div>
                          </FormGroup>
                        </Col>
                        <Col md="12">
                          <FormGroup>
                            <div className="form-floating">
                              <Input style={{"borderRadius": "5px"}} placeholder="Reference" type="text" />
                              <label htmlFor="pwd">Message</label>
                            </div>
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col md="2">
                          <FormGroup>
                            <ImageUpload avatar />
                          </FormGroup>
                        </Col>
                        <Col md="10">
                        </Col>
                      </Row>
                      <Row>
                        <Col sm="12">
                          <div className="pull-left">
                            <button className="btn-csconfirm">Submit Log</button>
                          </div>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
    
      </div>
    </>
  );
}

export default NotificationDetail;
