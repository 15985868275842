import React from "react";

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  Table
} from "reactstrap";
import swal from 'sweetalert';

// core components
import PanelHeader from "components/PanelHeader/PanelHeader.js";
import axios from "axios";
import {base_api_url} from "variables/general.js";
import $ from "jquery";
import datepickerFactory from 'jquery-datepicker';

function getData() {

  const params = new Proxy(new URLSearchParams(window.location.search), {
    get: (searchParams, prop) => searchParams.get(prop),
  });

  let urlTarget = base_api_url + '/api/v1/gv/getgvlistbyid?gardaVettingId=' + params.id; 
  return axios.get(urlTarget).then(res => {
    let data = res.data.response;
    localStorage.setItem("detailGardaVetting" + params.id, JSON.stringify(res.data.response));
    $("#gv_status").val(String(data.approvalStatus)).trigger("change");
    $("#gv_family_name").val(data.hostfamilyname);
    $("#gv_name").val(data.name);
    $("#gv_id").val(data.id);
    $("#br_name").html(data.name);
  })
}

function submitData() {

  const params = new Proxy(new URLSearchParams(window.location.search), {
    get: (searchParams, prop) => searchParams.get(prop),
  });

  var date = new Date();
  var timestamp = date.toISOString();
  let gv_status;

  gv_status = $("#gv_status").val();

  let requestData = JSON.parse(localStorage.getItem("detailGardaVetting" + params.id));
  requestData["name"] = $("#gv_name").val();
  requestData["approvalStatus"] = gv_status;
  requestData["hostfamilyname"] = $("#gv_family_name").val();
  requestData["timestamp"] = timestamp;

  return axios.put(base_api_url + "/api/v1/gv/updatebygardavettingid", requestData).then(res => {
    swal("Success","Successfully update data!","success");
    setTimeout(function(){
      window.location.reload();
    },500);
  }).catch(function (error) {
    swal("Failed!", "Something went wrong!", "error");
  });
}

class GardaVettingDetail extends React.Component {
  componentDidMount(){

    datepickerFactory($);
    $(document).on("click",".btn-csconfirm",function(e){
      e.preventDefault();

      $(".errorCs").remove();
      
      let notValid = 0;
      let msgval = "";
      let cv;

      $.each($('form input'), function(index, formField) {
        if ($(formField).val()==="") {
          cv = $(formField).parent().find("label").text();
          if (cv!=="") {
            msgval = cv + " is required! <br/>";
            $(formField).parent().append("<span class='errorCs'>" + msgval + "</span>");
            notValid += 1;
          }
        }
      });

      if (notValid>0) {
        //$("#msgValidation").show();
      } else {
        submitData();
      }
      
    });

    $(document).on("click",".close",function(){
      $(this).parent().parent().hide();
    });

    getData()

  }

  render() {
    return (
      <>
        <PanelHeader size="sm"
          content={
            <div className="content">
              <div className="header text-left">
                <span className="panelHeaderDesc">View and manage Activity Permission Forms.</span>
                <nav aria-label="breadcrumb" role="navigation">
                  <ol class="breadcrumb">
                    <li class="breadcrumb-item"><a href="/coordinator/overview">Dashboard</a></li>
                    <li class="breadcrumb-item"><a href="/coordinator/report/standard-report">Standard Reports</a></li>
                    <li class="breadcrumb-item"><a href="/coordinator/report/standard-report/garda-vetting">Garda Vetting</a></li>
                    <li class="breadcrumb-item active" aria-current="page" id="br_name"></li>
                  </ol>
                </nav>
              </div>
            </div>
          }
        />
        <br/>
        <div className="content">
          <Form name="formsubmit" id="formsubmit" action="/" className="form-horizontal" method="post">
            <Row>
              <Col md="3">
                <Card className="card-chart">
                  <CardBody>
                    <Table responsive>
                      <tbody>
                        <tr>
                          <td className="td-noborder">
                            <a href="#c_garda_vetting" className="anchorItemDetail">Garda Vetting</a>
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </CardBody>
                </Card>
              </Col>
              <Col md="9">
                <Card id="c_trip_information">
                  <CardHeader>
                    <CardTitle tag="h4" className="panelTitle">Garda Vetting</CardTitle>
                  </CardHeader>
                  <CardBody>
                    <Row>
                      <Col sm="12">
                        <FormGroup>
                          <h4 className="subHeaderBlue">Garda Vetting</h4>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm="12">
                        <Row>
                          <Col md="6">
                            <FormGroup>
                              <div class="form-floating">
                                <Input readonly="true" id="gv_name" style={{"borderRadius": "5px"}} placeholder="ID" type="text" />
                                <label for="pwd">Name</label>
                              </div>
                            </FormGroup>
                          </Col>
                          <Col md="6">
                            <FormGroup>
                              <div class="form-floating">
                                <Input id="gv_family_name" style={{"borderRadius": "5px"}} placeholder="ID" type="text" />
                                <label for="pwd">Family Name</label>
                              </div>
                            </FormGroup>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm="12">
                        <Row>
                          <Col md="6">
                            <FormGroup>
                              <div class="form-floating">
                                <select className="form-control" id="gv_status" placeholder="ID" style={{"borderRadius": "5px"}}>
                                  <option value="APPROVED">APPROVED</option>
                                  <option value="REJECTED">REJECTED</option>
                                  <option value="PENDING">PENDING</option>
                                </select>
                                <label for="pwd">Status</label>
                              </div>
                            </FormGroup>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
                <Row>
                  <Col sm="12">
                    <div className="pull-right">
                      <button className="btn-csconfirm">Update Changes</button>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Form>
        </div>
      </>
    );
  }
}

export default GardaVettingDetail;
