import React from "react";

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  FormGroup,
  Form,
  Row,
  Col,
  Table
} from "reactstrap";

import flatpickr from "flatpickr";
import 'flatpickr/dist/flatpickr.min.css';

// core components
import PanelHeader from "components/PanelHeader/PanelHeader.js";
import Switch from "react-bootstrap-switch";
import profileImg from "assets/upload/default.png";
import ImageUpload from "components/CustomUpload/ImageUpload.js";
import axios from "axios";
import {base_api_url, file_api_url} from "variables/general.js";
import $ from "jquery";
import datepickerFactory from 'jquery-datepicker';
import swal from 'sweetalert';
import DateTime from 'react-datetime';
import CalculateAge from "components/CustomDateTime/CalculateAge";

function getData() {

  const params = new Proxy(new URLSearchParams(window.location.search), {
    get: (searchParams, prop) => searchParams.get(prop),
  });

  let urlTarget = base_api_url + '/api/v1/student/getstudentbyid?studentId=' + params.id; 
  return axios.get(urlTarget).then(res => {
    let datas = res.data.response;
    localStorage.setItem("detailStudent" + params.id, JSON.stringify(datas.student));
    showData(datas);
  })
}

function checkEmailExist(elem) {
  if (elem.val()) {
    var settings = {
      "url": base_api_url + "/api/v1/authentication/isemailtaken",
      "method": "POST",
      "timeout": 0,
      "headers": {
        "Content-Type": "text/plain"
      },
      "data": elem.val(),
    };

    $.ajax(settings).done(function (response) {
      if (response.response==true) {
        elem.parent().append("<span class='errorCs'>Email already taken!</span>")
      } else {
        uploadFile();
      }
    });
  }
  return 0;
}

function showData(data) {
  setTimeout(function(){
    $("#id").val(data.student.id);

    getDocumentsByUserIdAndRole(data.student.id, "student");

    $("#reference").val(data.student.reference);
    $("#school_name").val();
    $("#school_country").val();
    $("#school_town").val();
    $("#school_type").val();
    $("#agent_name").val();
    $("#agent_company").val();
    $("#coordinator_name").val();
    $("#coordinator_company").val();
    $("#student_first_name").val(data.student.firstname);
    $("#student_last_name").val(data.student.lastname);
    $("#t_name").html(data.student.firstname + " " +data.student.lastname);
    $("#t_id").html(data.student.firstname + " " +data.student.lastname);
    $("#br_name").html(data.student.firstname + " " +data.student.lastname);
    $("#student_nationality").val(data.student.nationality);
    $("#student_email_address").val(data.student.emailaddress);

    let dateofbirth;

    if (data.student.dateofbirth) {
      dateofbirth = new Date(data.student.dateofbirth);
      dateofbirth = dateofbirth.toString();
      dateofbirth = dateofbirth.split(" ");
      dateofbirth = dateofbirth[1] + " " + dateofbirth[2] + ", " + dateofbirth[3];
    }
    
    $("#student_date_of_birth").val(dateofbirth);
    $("#student_age").val(CalculateAge(dateofbirth));
    $("#student_telephone").val(data.student.telephonenumer);
    $("#student_mobile").val(data.student.mobilenumber);
    $("#student_irish_mobile").val(data.student.irishmobilenumber);
    $("#student_religion").val(data.student.religion);
    $("#student_gender").val(data.student.gender);
    $("#student_passport_number").val(data.student.passportnumber);
    $("#student_length_of_stay").val(data.student.lengthofstay);
    $("#student_medical_condition").val(data.student.medicalcondition);
    $("#student_on_any_medication").val(data.student.onanymedication);
    $("#student_diatry_requirements").val(data.student.diatryrequirements);
    $("#student_allergies").val(data.student.allergies);
    $("#student_mother_maiden_name").val(data.student.mothersmaidenname);
    $("#student_family_brother_sister").val(data.student.familybrotherssisters);
    $("#student_school_year_student").val(data.student.schoolyearstudentwillstudyinireland);
    $("#student_hobbies").val(data.student.hobbies);
    $("#student_results").val(data.student.results);
    $("#student_special_requests").val(data.student.specialrequests);
    $("#student_notes").val(data.student.notes);

    let arrivaldate;
    let arrivaltime;

    let departuredate_view;
    let arrivaldate_view;

    if (data.student.flight_arrivaldate) {
      let arrivaldateraw = data.student.flight_arrivaldate;
      arrivaldateraw = arrivaldateraw.split("T");
      
      arrivaldate = arrivaldateraw[0];
      arrivaltime = arrivaldateraw[1].split(":")
      arrivaltime = arrivaltime[0] + ":" + arrivaltime[1];

      arrivaldate_view = new Date(arrivaldate);
      const options = { year: 'numeric', month: 'long', day: '2-digit' };
      arrivaldate_view = arrivaldate_view.toLocaleDateString('en-US', options);
    }

    let departuredate = new Date(data.student.flight_departuredate);
    let departuretime;
    if (data.student.flight_departuredate) {
      let departuredateraw = data.student.flight_departuredate;
      departuredateraw = departuredateraw.split("T");
      
      departuredate = departuredateraw[0];
      departuretime = departuredateraw[1].split(":")
      departuretime = departuretime[0] + ":" + departuretime[1];

      departuredate_view = new Date(departuredate);
      const options = { year: 'numeric', month: 'long', day: '2-digit' };
      departuredate_view = departuredate_view.toLocaleDateString('en-US', options);
    }

    $("#flight_arrivaldate").val(arrivaldate_view);
    $("#flight_arrivaldate2").val(arrivaldate_view);
    $("#flight_departuredate").val(departuredate_view);
    $("#flight_type").val(data.student.flight_type);
    $("#flight_arrivaltime").val(arrivaltime);
    $("#flight_departuretime").val(departuretime);
    $("#flight_airline").val(data.student.flight_airline);
    $("#flight_arrivalpickup").val(data.student.flight_arrivalpickup);
    $("#flight_origin").val(data.student.flight_origin);
    $("#flight_destination").val(data.student.flight_destination);
    $("#flight_reasonforjourney").val(data.student.flight_reasonforjourney);
    $("#flight_number").val(data.student.flight_number);

    $("#profile").attr("src",data.student.photourl);
    $("#s_photourl").val(data.student.photourl);
    $("#imgPreview").attr("src",data.student.photourl);

    $("#student_status").val(data.student.adminapprovalstatus);

    if (data.student.programmerulesstatus===true) {
      $("#switch_programme_rule_status").prop("checked",true);
      $("#programme_rule_status").val("Approved");
    } else {
      $("#switch_programme_rule_status").prop("checked",false);
      $("#programme_rule_status").val("Declined");
    }

    if (data.student.photosharingagreedandread===true) {
      $("#photo_sharing_agreed_and_read").val("Approved");
      $("#switch_photo_sharing_agreed_and_read").prop("checked",true);
    } else {
      $("#photo_sharing_agreed_and_read").val("Declined");
      $("#switch_photo_sharing_agreed_and_read").prop("checked",false);
    }

    setTimeout(function(){
      $("#school_id").val(data.student.fkschoolid).trigger("change");
      $("#agent_id").val(data.student.fkagentid).trigger("change");
      $("#coordinator_id").val(data.student.fkcoordinatorid).trigger("change");
      $("#hf_id").val(data.student.fkhostfamilyid).trigger("change");
    },1500);

    if (data.submissions) {
      for (var is=0;is<data.submissions.length;is++) {

        let detailPage;
        if (data.submissions[is].formtype=="tpf") {
          detailPage = window.location.origin + "/admin/submission/travel-permission-form/"+ data.submissions[is].id + "?id=" + data.submissions[is].id + "&formid=" + data.submissions[is].id;
        } else if (data.submissions[is].formtype=="apf") {
          detailPage = window.location.origin + "/admin/submission/activity-permission-form/"+ data.submissions[is].id + "?id=" + data.submissions[is].id + "&formid=" + data.submissions[is].id;
        } else if (data.submissions[is].formtype=="agentstudentregistration"||data.submissions[is].formtype=="studentregistration") {
          detailPage = window.location.origin + "/admin/submission/student/"+ data.submissions[is].id + "?id=" + data.submissions[is].id + "&formid=" + data.submissions[is].id;
        } else if (data.submissions[is].formtype=="flightinformation" || data.submissions[is].formtype=="flihtInformation" ) {
          detailPage = window.location.origin + "/admin/submission/flight-information/"+ data.submissions[is].id + "?id=" + data.submissions[is].id + "&formid=" + data.submissions[is].id;
        } else if (data.submissions[is].formtype=="gventry") {
          detailPage = window.location.origin + "/admin/submission/gv/"+ data.submissions[is].id + "?id=" + data.submissions[is].id + "&formid=" + data.submissions[is].id;
        } else if (data.submissions[is].formtype=="hfregistration") { 
          detailPage = window.location.origin + "/admin/submission/host-family/"+ data.submissions[is].id + "?id=" + data.submissions[is].id + "&formid=" + data.submissions[is].id;
        } else if (data.submissions[is].formtype=="schoolbyCoord") { 
          detailPage = window.location.origin + "/admin/submission/school/"+ data.submissions[is].id + "?id=" + data.submissions[is].id + "&formid=" + data.submissions[is].id;
        } else if (data.submissions[is].formtype=="coordSchoolLogs") {
          detailPage = window.location.origin + "/admin/report/standard-report/school-logs/"+ data.submissions[is].id + "?id=" + data.submissions[is].id;
        } else {
          detailPage = window.location.origin + "/admin/submission/"+data.submissions[is].formtype+"/" + data.submissions[is].id + "?id=" + data.submissions[is].id + "&formid=" + data.submissions[is].id;
        }

        let labelSub;
        let csTime;

        csTime = new Date(data.submissions[is].timestamp);
        csTime = csTime.toLocaleString();

        if (data.submissions[is].formtype=="apf") {
          labelSub = "Activity Permission Form";
        } else if (data.submissions[is].formtype=="tpf") {
          labelSub = "Travel Permission Form";
        } else {
          labelSub = data.submissions[is].formtype;
        }

        $("#submissions_list").append(`
          <div class="bundleBorder row">
            <div class="col-md-3">
              <div class="form-group">
                <div class="form-floating">
                  <label for="pwd">${labelSub}</label>
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group">
                <div class="form-floating">
                  <label for="pwd">${csTime}</label>
                </div>
              </div>
            </div>
            <div class="col-md-3">
              <div class="form-floating">
                <label>${data.submissions[is].approvalstatus}</label>
              </div>
            </div>
            <div class="col-md-2">
              <div class="form-group">
                <a href="${detailPage}" style="color: rgb(113, 113, 113); font-style: normal; font-weight: 400; font-size: 12px; line-height: 16px;"><i class="now-ui-icons cs-ov-edit-pen-dark" style="position: relative; top: 8px;"></i>Views Detail</a>
                </div>
              </div>
            </div>
        `);
      }
    }

    if (data.TravelLogs) {
      for (var it=0;it<data.TravelLogs.length;it++) {

        let ttime;

        ttime = new Date(data.TravelLogs[it].timestamp);
        ttime = ttime.toLocaleString();

        $("#travel_logs_list").append(`
        <div class="bundleBorder row">
          <div class="col-md-7">
            <div class="form-group">
              <div class="form-floating">
                <label for="pwd">${data.TravelLogs[it].notes}</label>
              </div>
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <div class="form-floating">
                <label for="pwd">${ttime}</label>
              </div>
            </div>
          </div>
          <div class="col-md-2">
            <div class="form-group">
              <a href="#" style="color: rgb(113, 113, 113); font-style: normal; font-weight: 400; font-size: 12px; line-height: 16px;"><i class="now-ui-icons cs-ov-edit-pen-dark" style="position: relative; top: 8px;"></i>Views Detail</a>
              </div>
            </div>
          </div>
        </div>
        `);
      }
    }

  },1000);

}

function getDataHf() {
  let selEl = "#hf_id";

  return axios.get(base_api_url + '/api/v1/hf').then(res => {
    let datas = res.data.response;

    $(selEl).empty();
    $(selEl).append("<option value=''>Select</option>");
    for (var i=0;i<datas.length;i++) {
      $(selEl).append(`<option data-familyname='${datas[i].familyname}' data-mothername='${datas[i].mothername}' data-datevisited='${datas[i].datevisited}' data-hostfamilychild='${datas[i].nohostfamilychild}' data-fathername='${datas[i].fathername}' data-address='${datas[i].address}' value='${datas[i].id}'>${datas[i].familyname}</option>`);
    }

    getData();

  })

}

function getDataAgent() {

  let selEl = "#agent_id";

  return axios.get(base_api_url + '/api/v1/agent').then(res => {
    let datas = res.data.response;

    $(selEl).empty();
    $(selEl).append("<option value=''>Select</option>");
    for (var i=0;i<datas.length;i++) {
      $(selEl).append(`<option data-company='${datas[i].company}' data-name='${datas[i].name}' value='${datas[i].id}'>${datas[i].name}</option>`);
    }

  })
}

function getDataCoordinator() {
  let selEl = "#coordinator_id";

  return axios.get(base_api_url + '/api/v1/coord').then(res => {
    let datas = res.data.response;

    $(selEl).empty();
    $(selEl).append("<option value=''>Select</option>");
    
    for (var i=0;i<datas.length;i++) {
      $(selEl).append(`<option data-company="${datas[i].company}" data-name="${datas[i].name}" value="${datas[i].id}">${datas[i].name}</option>`);
    }
    
  })
}

function getDataSchool() {
  let selEl = "#school_id"; 
  return axios.get(base_api_url + '/api/v1/school').then(res => {
    let datas = res.data.response;

    $(selEl).empty();
    $(selEl).append("<option value=''>Select</option>");

    for (var i=0;i<datas.length;i++) {
      $(selEl).append(`<option data-type="${datas[i].type}" data-town="${datas[i].town}" data-country="${datas[i].country}" data-name="${datas[i].name}" value="${datas[i].id}">${datas[i].name}</option>`);
    }

  })
}

function uploadFile() {
  var bodyFormData = new FormData();
  let imageFile = $("#photo")[0].files[0];
  let user_login = JSON.parse(localStorage.getItem('user_login'));

  bodyFormData.append('file', imageFile);
  bodyFormData.append('username', user_login.email);

  axios({
    method: "post",
    url: file_api_url + "/api/v1/user/upload/file",
    data: bodyFormData,
    headers: { "Content-Type": "multipart/form-data" },
  }).then(function (response) {
    $("#s_photourl").val(response.data[0].path);
    submitData();
  });
}

function uploadDocuments() {
  var bodyFormData = new FormData();
  let imageFile = $("#document_upload")[0].files[0];
  const params = new Proxy(new URLSearchParams(window.location.search), {
    get: (searchParams, prop) => searchParams.get(prop),
  });

  bodyFormData.append('file', imageFile);
  bodyFormData.append('username', "student_id_"+params.id);

  axios({
    method: "post",
    url: file_api_url + "/api/v1/user/upload/file",
    data: bodyFormData,
    headers: { "Content-Type": "multipart/form-data" },
  }).then(function (response) {
    let pdfPath = response.data[0].path;

    if (pdfPath) {

      const parts = pdfPath.split("/");

      // Get the last part which contains the file name
      const fileNameWithExtension = parts[parts.length - 1];

      // Split the file name by "." to separate the name and extension
      const fileNameParts = fileNameWithExtension.split(".");
      let fileName = fileNameParts[0];
      const extension = fileNameParts[1];
      fileName = fileName + "." + extension;
      let user_login = JSON.parse(localStorage.getItem('user_login'));

      axios({
        method: "post",
        url: base_api_url + "/api/v1/file",
        data: {
          doctype: extension,
          filelocation: pdfPath,
          filename: fileName,
          fkassociation: 0,
          fkformtype: 0, 
          fkuserid: params.id, 
          fkuserrole: "student",
          id: null,
          isactive: true,
          notes: "File Upload",
          uploadbyrole:"admin",
          uploadbyid:user_login.reference_id
        }
      }).then(function (response) {

        let fileId = response.data.response.id;

          $("#documents_lists").append(`<div class="bundleBorder row">
            <div class="col-md-3">
              <div class="form-group">
                <div class="form-floating">
                  <label for="pwd">${fileName}</label>
                </div>
              </div>
            </div>
            <div class="col-md-9" align="right">
              <div class="form-group">
                <a style="color: rgb(113, 113, 113); font-style: normal; font-weight: 400; font-size: 12px; line-height: 16px;" target="_blank" href="${pdfPath}"><i style="position: relative; top: 8px;" class="now-ui-icons cs-ov-edit-pen-dark"></i> Show File</a>
                <a data-id=${fileId} style="color:red;cursor:pointer;font-style: normal; font-weight: 400; font-size: 12px; line-height: 16px;" class="delDoc"><i style="position: relative; top: 2px;" class="now-ui-icons ui-1_simple-remove"></i> Remove</a>
                </div>
              </div>
            </div>`);

      });

    }
    
  });
}

function getDocumentsByUserIdAndRole(userid, role) {
  let user_login = JSON.parse(localStorage.getItem('user_login'));
  let uploadById = user_login.reference_id;
  let uploadByRole = "admin";

  axios({
    method: "GET",
    url: base_api_url + `/api/v1/file/getfilelist?userId=${userid}&userRole=${role}&uploadById=${uploadById}&uploadByRole=${uploadByRole}`
  }).then(function (response) {
    let datas = response.data.response;
    for (var i=0;i<datas.length;i++) {

      const parts = datas[i].filelocation.split("/");

      // Get the last part which contains the file name
      const fileNameWithExtension = parts[parts.length - 1];

      // Split the file name by "." to separate the name and extension
      const fileNameParts = fileNameWithExtension.split(".");
      let fileName = fileNameParts[0];
      const extension = fileNameParts[1];
      fileName = fileName + "." + extension;

      $("#documents_lists").append(`
        <div class="bundleBorder row">
          <div class="col-md-3">
            <div class="form-group">
              <div class="form-floating">
                <label for="pwd">${datas[i].filename}</label>
              </div>
            </div>
          </div>
          <div class="col-md-9" align="right">
            <div class="form-group">
              <a style="color: rgb(113, 113, 113); font-style: normal; font-weight: 400; font-size: 12px; line-height: 16px;" target="_blank" href="${datas[i].filelocation}"><i style="position: relative; top: 8px;" class="now-ui-icons cs-ov-edit-pen-dark"></i> Show File</a>
              <a data-id=${datas[i].id} style="color:red;cursor:pointer;font-style: normal; font-weight: 400; font-size: 12px; line-height: 16px;" class="delDoc"><i style="position: relative; top: 2px;" class="now-ui-icons ui-1_simple-remove"></i> Remove</a>
              </div>
            </div>
          </div>
      `);

    }
  });
}

function sendMail(name, email) {
  let data = JSON.stringify({
    "name": name,
    "email": email,
    "link":window.location.origin + "/auth/forgot-password?role=admin",
    "contact":"spil@spil.ie"
  });

  let config = {
    method: 'post',
    maxBodyLength: Infinity,
    url: file_api_url + '/api/v1/student/email/registration-approved',
    headers: { 
      'Content-Type': 'application/json'
    },
    data : data
  };

  axios.request(config)
  .then((response) => {
    console.log(JSON.stringify(response.data));
  })
  .catch((error) => {
    console.log(error);
  });
}

function submitData() {

  const params = new Proxy(new URLSearchParams(window.location.search), {
    get: (searchParams, prop) => searchParams.get(prop),
  });

  let user_login = JSON.parse(localStorage.getItem('user_login'));
  var tzoffset = (new Date()).getTimezoneOffset() * 60000; //offset in milliseconds
  var localISOTime = (new Date(Date.now() - tzoffset)).toISOString().slice(0, -1);
  var timestamp = localISOTime;
  let isactive;

  let arrivaldateraw = new Date($("#flight_arrivaldate").val());
  let arrivaldate = arrivaldateraw.getTimezoneOffset() * 60000;
  arrivaldate = (new Date(arrivaldateraw - arrivaldate)).toISOString().slice(0, -1);

  let defTime = "00:00:00.000";

  let ftime = $("#flight_arrivaltime").val();
  let dtime = $("#flight_departuretime").val();

  if (!ftime) {
    ftime = defTime;
  }

  if (!dtime) {
    dtime = defTime;
  }

  arrivaldate = arrivaldate.split("T")
  arrivaldate = arrivaldate[0] +"T"+ ftime;

  let departuredateraw = new Date($("#flight_departuredate").val())
  let departuredate = departuredateraw.getTimezoneOffset() * 60000;
  departuredate = (new Date(departuredateraw - departuredate)).toISOString().slice(0, -1);

  departuredate = departuredate.split("T")
  departuredate = departuredate[0] +"T"+ dtime;


  let dateofbirthraw = new Date($("#student_date_of_birth").val());
  let dateofbirth = dateofbirthraw.getTimezoneOffset() * 60000;
  dateofbirth = (new Date(dateofbirthraw - dateofbirth)).toISOString().slice(0, -1);

  isactive = $("#student_status").val();

  let programmerulesstatus = false;
  let photosharingagreedandread = false;

  if ($("#programme_rule_status").val()=="Approved") {
    programmerulesstatus = true;
  }

  if ($("#photo_sharing_agreed_and_read").val()=="Approved") {
    photosharingagreedandread = true;
  }

  let requestData = JSON.parse(localStorage.getItem("detailStudent" + params.id));

  requestData["adminapprovalstatus"] = isactive;
  requestData["age"] = CalculateAge(dateofbirth);
  requestData["allergies"] = $("#student_allergies").val();
  requestData["dateofbirth"] = dateofbirth;
  requestData["diatryrequirements"] = $("#student_diatry_requirements").val();
  requestData["emailaddress"] = $("#student_email_address").val();
  requestData["familybrotherssisters"] = $("#student_family_brother_sister").val();
  requestData["firstname"] = $("#student_first_name").val();
  requestData["fkagentid"] = $("#agent_id").val();
  requestData["fkcoordinatorid"] = $("#coordinator_id").val();
  requestData["fkhostfamilyid"] = $("#hf_id").val();

  requestData["flight_type"] = $("#flight_type").val();
  requestData["flight_airline"] = $("#flight_airline").val();
  requestData["flight_arrivaldate"] = arrivaldate;
  requestData["flight_arrivalpickup"] = $("#flight_arrivalpickup").val();
  requestData["flight_departuredate"] = departuredate;
  requestData["flight_origin"] = $("#flight_origin").val();
  requestData["flight_destination"] = $("#flight_destination").val();
  requestData["flight_reasonforjourney"] = $("#flight_reasonforjourney").val();
  requestData["flight_number"] = $("#flight_number").val();
  
  requestData["fkschoolid"] = $("#school_id").val();
  requestData["gender"] = $("#student_gender").val();
  requestData["hobbies"] = $("#student_hobbies").val();
  requestData["id"] = $("#id").val();
  requestData["irishmobilenumber"] = $("#student_irish_mobile").val();
  requestData["lastname"] = $("#student_last_name").val();
  requestData["lengthofstay"] = $("#student_length_of_stay").val();
  requestData["medicalcondition"] = $("#student_medical_condition").val();
  requestData["meetings"] = $("#student_meeting").val();
  requestData["mobilenumber"] = $("#student_mobile").val();
  requestData["mothersmaidenname"] = $("#student_mother_maiden_name").val();
  requestData["nationality"] = $("#student_nationality").val();
  requestData["onanymedication"] = $("#student_on_any_medication").val();
  requestData["passportnumber"] = $("#student_passport_number").val();
  requestData["photosharingagreedandread"] = photosharingagreedandread;
  requestData["photourl"] = $("#s_photourl").val();
  requestData["programmerulesstatus"] = programmerulesstatus;
  requestData["reference"] = $("#reference").val();
  requestData["religion"] = $("#student_religion").val();
  requestData["results"] = $("#student_results").val();
  requestData["schoolyearstudentwillstudyinireland"] = $("#student_school_year_student").val();
  requestData["specialrequests"] = $("#student_special_requests").val();
  requestData["telephonenumer"] = $("#student_telephone").val();
  requestData["timestamp"] = timestamp;
  requestData["notes"] = $("#student_notes").val();

  return axios.put(base_api_url + "/api/v1/student/updatebystudentid?role="+user_login.role, requestData).then(res => {

    if ($("#student_status").val()=="APPROVED") {
      sendMail($("#student_first_name").val() + " " + $("#student_last_name").val(), $("#student_email_address").val());
    }

    swal("Success","Successfully update data!","success");
    setTimeout(function(){
      window.location.reload();
    },500);
  }).catch(function (error) {
    swal("Failed!", "Something went wrong!", "error");
  });
}

class StudentDetail extends React.Component {
  
  componentDidMount(){

    datepickerFactory($);
    flatpickr("#flight_arrivaldate",{dateFormat: 'F d, Y'});
    flatpickr("#flight_arrivaldate2",{dateFormat: 'F d, Y'});
    flatpickr("#flight_departuredate",{dateFormat: 'F d, Y'});
    //$('#flight_pickuplocation').datepicker({dateFormat: "MM dd, yyyy"});

    flatpickr("#hf_date_visited",{dateFormat: 'F d, Y'});
    flatpickr("#student_date_of_birth",{dateFormat: 'F d, Y'});

    getDataSchool();
    getDataAgent();
    getDataCoordinator();
    getDataHf();

    $(document).on("click",".uploadDoc",function(e){
      e.preventDefault();
      
      if ($("#document_upload").val()=="") {
        alert("Please upload the PDF/Image file!");
      } else {
        let ftype = $("#document_upload")[0].files[0].type;
        if (ftype!=="application/pdf"&&ftype!=="image/jpeg"&&ftype!=="image/png") {
          alert("Only PDF/Image file allowed!");
        } else {
          uploadDocuments();
        }
      }

    });

    $(document).on("click",".delDoc",function(e){
      e.preventDefault();

      let curPar = $(this).parent().parent().parent();
      let fileId = $(this).data("id");

      const params = new Proxy(new URLSearchParams(window.location.search), {
        get: (searchParams, prop) => searchParams.get(prop),
      });

      swal({
        title: "Are you sure?",
        text: "Are you sure that you want to delete this file ?",
        icon: "warning",
        buttons: ["Cancel", "Yes, delete it"],
      })
      .then(willDelete => {
        if (willDelete) {
          axios({
            method: "delete",
            url: base_api_url + `/api/v1/file/${fileId}/${params.id}/student`
          }).then(function (response) {
              $(curPar).remove();
          });
        }
      });

    });

    $(document).on("click",".btn-csconfirm",function(e){
      e.preventDefault();
      $(".errorCs").remove();
      
      let notValid = 0;
      let msgval = "";
      let cv;
      $.each($('form input'), function(index, formField) {
        if ($(formField).val()==="") {
          cv = $(formField).parent().find("label").text();

          let hffield = $(formField).attr("id") == "hf_family_name" || $(formField).attr("id") == "hf_host_family_child" || $(formField).attr("id") == "hf_mother_name" || $(formField).attr("id") == "hf_father_name" || $(formField).attr("id") == "hf_date_visited" || $(formField).attr("id") == "hf_address";

          if ($(formField).attr("id")!=="student_notes" && cv!==""  && $(formField).attr("type")!=="file" && $(formField).attr("type")!=="hidden" && !hffield) {
            msgval = cv + " is required! <br/>";
            $(formField).parent().append("<span class='errorCs'>" + msgval + "</span>");
            notValid += 1;
          }
        }
      });

      if (notValid>0) {
        //$("#msgValidation").show();
      } else {
        if ($("#photo").val()=="")  {
          submitData();
        } else {
          uploadFile();
        }
      }
    });

    $(document).on("click",".close",function(){
      $(this).parent().parent().hide();
    });

    $('#photo').change(function(){
      const file = this.files[0];
      if (file){
        $(".errorCs").remove();
        if (file.type=="image/jpeg" || file.type=="image/png") {
          let reader = new FileReader();
          reader.onload = function(event){
            $('#imgPreview').attr('src', event.target.result);
          }
          reader.readAsDataURL(file);
        } else {
          $('#photo').val("");
          $("#imgPreview").attr("src","");
          $('#photo').parent().parent().append("<span class='errorCs'>The image must be a file of type: jpg, jpeg, png.</span>");
        }
      }
    });

    $(document).on("change","#agent_id",function(e){
      $("#agent_name").val($(this).find(':selected').data('name'));
      $("#agent_company").val($(this).find(':selected').data('company'));
    });

    $(document).on("change","#school_id",function(e){
      $("#school_name").val($(this).find(':selected').data('name'));
      $("#school_country").val($(this).find(':selected').data('country'));
      $("#school_town").val($(this).find(':selected').data('town'));
      $("#school_type").val($(this).find(':selected').data('type'));
    });

    $(document).on("change","#coordinator_id",function(e){
      $("#coordinator_name").val($(this).find(':selected').data('name'));
      $("#coordinator_company").val($(this).find(':selected').data('company'));
    });

    $(document).on("change","#hf_id",function(e){
      $("#hf_family_name").val($(this).find(':selected').data('familyname'));
      $("#hf_father_name").val($(this).find(':selected').data('fathername'));
      $("#hf_mother_name").val($(this).find(':selected').data('mothername'));
      $("#hf_address").val($(this).find(':selected').data('address'));

      let dvraw = $(this).find(':selected').data('datevisited');
      let dv = new Date(dvraw);
      dv = dv.toString().split(" ");
      dv = dv[1] + " " + dv[2] + ", " + dv[3];
      $("#hf_date_visited").val(dv);
      $("#hf_host_family_child").val($(this).find(':selected').data('hostfamilychild'));
    });

    $(document).on("change","#student_date_of_birth",function(){
      let dob = new Date($(this).val());
      console.log(dob)
      var today = new Date();
      var age = Math.floor((today-dob) / (365.25 * 24 * 60 * 60 * 1000));
      console.log(age)
      $('#student_age').val(age);
    });

    $(document).on("change","#switch_programme_rule_status",function(){
      let val = String($(this).is(':checked'));

      if ($(this).is(':checked')==true) {
        $("#programme_rule_status").val("Approved").trigger("change");
      } else {
        $("#programme_rule_status").val("Declined").trigger("change");
      }
    });

    $(document).on("change","#switch_photo_sharing_agreed_and_read",function(){
      let val = String($(this).is(':checked'));

      if ($(this).is(':checked')==true) {
        $("#photo_sharing_agreed_and_read").val("Approved").trigger("change");
      } else {
        $("#photo_sharing_agreed_and_read").val("Declined").trigger("change");
      }
    });

  }

  render(){
    return (
    <>
      <PanelHeader size="sm"
        content={
          <div className="content">
            <div className="header text-left">
              <span className="panelHeaderDesc">View and manage the Student Detail.</span>
              <nav aria-label="breadcrumb" role="navigation">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item"><a href="/admin/overview">Dashboard</a></li>
                  <li className="breadcrumb-item"><a href="/admin/student">Student</a></li>
                  <li className="breadcrumb-item active" aria-current="page" id="br_name"></li>
                </ol>
              </nav>
            </div>
          </div>
        }
      />
      <br/>
      <div className="content">
        <Form name="formsubmit" id="formsubmit" action="/" className="form-horizontal" method="post">
          <Row>
            <Col md="3">
              <Card className="card-chart">
                <CardBody>
                  <Table responsive>
                    <tbody>
                      <tr>
                        <td className="td-noborder">
                          <a href="#c_spil_details" className="anchorItemDetail">SPIL Details</a>
                          <a href="#c_student_details" className="anchorItemDetail">Student Details</a>
                          <a href="#c_trip_information" className="anchorItemDetail">Trip Information</a>
                          <a href="#c_submissions" className="anchorItemDetail">Submissions</a>
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </CardBody>
              </Card>
            </Col>
            <Col md="9">
              <Card>
                <CardHeader className="card-header-profile">
                  <Row>
                    <Col className="dheaderProfile">
                      <img id="profile" style={{float: "left",width:"76px",height:"76px",position:"relative",bottom:"18px"}} src={profileImg}></img>
                    </Col>
                    <Col className="dheaderMiddle">
                      <p className="profile-title" id="t_name"></p>
                      <p className="profile-desc" id="t_id"></p>
                    </Col>
                    <Col className="dheaderRight">
                      <select className="form-control" id="student_status">
                        <option value="PENDING">PENDING</option>
                        <option value="APPROVED">APPROVED</option>
                        <option value="REJECTED">REJECTED</option>
                      </select>
                    </Col>
                  </Row>
                </CardHeader>
              </Card>
              <Card id="c_spil_details">
                <CardHeader>
                  <CardTitle tag="h4" className="panelTitle">SPIL Details</CardTitle>
                </CardHeader>
                <CardBody>
                  <Row>
                    <Col sm="12">
                      <FormGroup>
                        <h4 style={{"fontFamily": "Noto Sans","fontStyle": "normal","fontWeight": 400,"fontSize": "16px","lineHeight": "24px","color": "#70B2D8"}}>Student</h4>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6}>
                      <FormGroup>
                        <div className="form-floating">
                          <input readonly="true" id="id" className="form-control" style={{"borderRadius": "5px"}} placeholder="ID" type="text" />
                          <label htmlFor="pwd">ID</label>
                        </div>
                      </FormGroup>
                    </Col>
                    <Col md={6}>
                      <FormGroup>
                        <div className="form-floating">
                          <input id="reference" className="form-control" style={{"borderRadius": "5px"}} placeholder="Reference" type="text" />
                          <label htmlFor="pwd">Reference</label>
                        </div>
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row>
                    <Col sm="12">
                      <FormGroup>
                        <h4 style={{"fontFamily": "Noto Sans","fontStyle": "normal","fontWeight": 400,"fontSize": "16px","lineHeight": "24px","color": "#70B2D8"}}>School</h4>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6}>
                      <FormGroup>
                        <div className="form-floating">
                          <select placeholder="s" id="school_id" style={{borderRadius:"5px"}} className="form-control">
                            <option value="">Select</option>
                          </select>
                          <label htmlFor="pwd">School</label>
                        </div>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6}>
                      <FormGroup>
                        <div className="form-floating">
                          <input readonly="true" id="school_name" className="form-control" style={{"borderRadius": "5px"}} placeholder="Name" type="text" />
                          <label htmlFor="pwd">Name</label>
                        </div>
                      </FormGroup>
                    </Col>
                    <Col md={6}>
                      <FormGroup>
                        <div className="form-floating">
                          <input readonly="true" id="school_country" className="form-control" style={{"borderRadius": "5px"}} placeholder="Country" type="text" />
                          <label htmlFor="pwd">Country</label>
                        </div>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6}>
                      <FormGroup>
                        <div className="form-floating">
                          <input readonly="true" id="school_town" className="form-control" style={{"borderRadius": "5px"}} placeholder="Town" type="text" />
                          <label htmlFor="pwd">Town</label>
                        </div>
                      </FormGroup>
                    </Col>
                    <Col md={6}>
                      <FormGroup>
                        <div className="form-floating">
                          <input readonly="true" id="school_type" className="form-control" style={{"borderRadius": "5px"}} placeholder="Type" type="text" />
                          <label htmlFor="pwd">Type</label>
                        </div>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm="12">
                      <FormGroup>
                        <h4 style={{"fontFamily": "Noto Sans","fontStyle": "normal","fontWeight": 400,"fontSize": "16px","lineHeight": "24px","color": "#70B2D8"}}>Agent</h4>
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row>
                    <Col md={6}>
                      <FormGroup>
                        <div className="form-floating">
                          <select placeholder="s" id="agent_id" style={{borderRadius:"5px"}} className="form-control">
                            <option value="">Select Agent</option>
                          </select>
                          <label htmlFor="pwd">Agents</label>
                        </div>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="6">
                      <FormGroup>
                        <div className="form-floating">
                          <input readonly="true" id="agent_name" className="form-control" style={{"borderRadius": "5px"}} placeholder="ID" type="text" />
                          <label htmlFor="pwd">Name</label>
                        </div>
                      </FormGroup>
                    </Col>
                    <Col md="6">
                      <FormGroup>
                        <div className="form-floating">
                          <input readonly="true" id="agent_company" className="form-control" style={{"borderRadius": "5px"}} placeholder="Company" type="text" />
                          <label htmlFor="pwd">Company</label>
                        </div>
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row>
                    <Col sm="12">
                      <FormGroup>
                        <h4 style={{"fontFamily": "Noto Sans","fontStyle": "normal","fontWeight": 400,"fontSize": "16px","lineHeight": "24px","color": "#70B2D8"}}>Coordinator</h4>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6}>
                      <FormGroup>
                        <div className="form-floating">
                          <select placeholder="s" id="coordinator_id" style={{borderRadius:"5px"}} className="form-control">
                            <option value="">Select</option>
                          </select>
                          <label htmlFor="pwd">Coordinator</label>
                        </div>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6}>
                      <FormGroup>
                        <div className="form-floating">
                          <input readonly="true" id="coordinator_name" className="form-control" style={{"borderRadius": "5px"}} placeholder="Name" type="text" />
                          <label htmlFor="pwd">Name</label>
                        </div>
                      </FormGroup>
                    </Col>
                    <Col md={6}>
                      <FormGroup>
                        <div className="form-floating">
                          <input readonly="true" id="coordinator_company" className="form-control" style={{"borderRadius": "5px"}} placeholder="Company" type="text" />
                          <label htmlFor="pwd">Company</label>
                        </div>
                      </FormGroup>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
              <Card id="c_student_details">
                <CardHeader>
                  <CardTitle tag="h4" className="panelTitle">Student Details</CardTitle>
                </CardHeader>
                <CardBody>
                  <Row>
                    <Col sm="12">
                      <FormGroup>
                        <h4 style={{"fontFamily": "Noto Sans","fontStyle": "normal","fontWeight": 400,"fontSize": "16px","lineHeight": "24px","color": "#70B2D8"}}>Student</h4>
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row>
                    <Col md="6">
                      <FormGroup>
                        <div className="form-floating">
                          <input id="student_first_name" className="form-control" style={{"borderRadius": "5px"}} placeholder="ID" type="text" />
                          <label htmlFor="pwd">First Name</label>
                        </div>
                      </FormGroup>
                    </Col>
                    <Col md="6">
                      <FormGroup>
                        <div className="form-floating">
                          <input id="student_last_name" className="form-control" style={{"borderRadius": "5px"}} placeholder="ID" type="text" />
                          <label htmlFor="pwd">Last Name</label>
                        </div>
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row>
                    <Col md="6">
                      <FormGroup>
                        <div className="form-floating">
                          <input id="student_nationality" className="form-control" style={{"borderRadius": "5px"}} placeholder="ID" type="text" />
                          <label htmlFor="pwd">Nationality</label>
                        </div>
                      </FormGroup>
                    </Col>
                    <Col md="6">
                      <FormGroup>
                        <div className="form-floating">
                          <input id="student_email_address" className="form-control" style={{"borderRadius": "5px"}} placeholder="ID" type="text" />
                          <label htmlFor="pwd">Email Address</label>
                        </div>
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row>
                    <Col md="6">
                      <FormGroup>
                        <div className="input-group mb-3">
                          <div className="form-floating form-floating-group flex-grow-1">
                              <input readOnly style={{cursor: "default"}} id="student_date_of_birth" className="form-control input-right-radius" name="code1" placeholder="Code 1"/>
                              <label>Date of Birth</label>
                          </div>
                          <span className="input-group-text input-group-text-custom"><i className="now-ui-icons cs-icon-calendar"></i></span>
                        </div>
                      </FormGroup>
                    </Col>
                    <Col md="6">
                      <FormGroup>
                        <div className="form-floating">
                          <input readonly="true" id="student_age" className="form-control" style={{"borderRadius": "5px"}} placeholder="ID" type="text" />
                          <label htmlFor="pwd">Age</label>
                        </div>
                      </FormGroup>
                    </Col>
                  </Row>
 
                  <Row>
                    <Col md="6">
                      <FormGroup>
                        <div className="form-floating">
                          <input id="student_telephone" className="form-control" style={{"borderRadius": "5px"}} placeholder="ID" type="text" />
                          <label htmlFor="pwd">Telephone Number</label>
                        </div>
                      </FormGroup>
                    </Col>
                    <Col md="6">
                      <FormGroup>
                        <div className="form-floating">
                          <input id="student_mobile" className="form-control" style={{"borderRadius": "5px"}} placeholder="ID" type="text" />
                          <label htmlFor="pwd">Mobile Number</label>
                        </div>
                      </FormGroup>
                    </Col>
                  </Row>
      
                  <Row>
                    <Col md="6">
                      <FormGroup>
                        <div className="form-floating">
                          <input id="student_irish_mobile" className="form-control" style={{"borderRadius": "5px"}} placeholder="ID" type="text" />
                          <label htmlFor="pwd">Irish Mobile Number</label>
                        </div>
                      </FormGroup>
                    </Col>
                    <Col md="6">
                      <FormGroup>
                        <div className="form-floating">
                          <input id="student_religion" className="form-control" style={{"borderRadius": "5px"}} placeholder="ID" type="text" />
                          <label htmlFor="pwd">Religion</label>
                        </div>
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row>
                    <Col md="6">
                      <FormGroup>
                        <div className="form-floating">
                          <select id="student_gender" className="form-control" style={{"borderRadius": "5px"}} placeholder="ID">
                            <option>Male</option>
                            <option>Female</option>
                          </select>
                          <label htmlFor="pwd">Gender</label>
                        </div>
                      </FormGroup>
                    </Col>
                    <Col md="6">
                      <FormGroup>
                        <div className="form-floating">
                          <input id="student_passport_number" className="form-control" style={{"borderRadius": "5px"}} placeholder="ID" type="text" />
                          <label htmlFor="pwd">Passport Number</label>
                        </div>
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row>
                    <Col md="6">
                      <FormGroup>
                        <div className="form-floating">
                          <input id="student_length_of_stay" className="form-control" style={{"borderRadius": "5px"}} placeholder="ID" type="text" />
                          <label htmlFor="pwd">Length of Stay</label>
                        </div>
                      </FormGroup>
                    </Col>
                    <Col md="6">
                      <FormGroup>
                        <div className="form-floating">
                          <input id="student_medical_condition" className="form-control" style={{"borderRadius": "5px"}} placeholder="ID" type="text" />
                          <label htmlFor="pwd">Medical Condition</label>
                        </div>
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row>
                    <Col md="6">
                      <FormGroup>
                        <div className="form-floating">
                          <input id="student_on_any_medication" className="form-control" style={{"borderRadius": "5px"}} placeholder="ID" type="text" />
                          <label htmlFor="pwd">On any medication</label>
                        </div>
                      </FormGroup>
                    </Col>
                    <Col md="6">
                      <FormGroup>
                        <div className="form-floating">
                          <input id="student_diatry_requirements" className="form-control" style={{"borderRadius": "5px"}} placeholder="ID" type="text" />
                          <label htmlFor="pwd">Diatry Requirements</label>
                        </div>
                      </FormGroup>
                    </Col>
                  </Row>

  
                  <Row>
                    <Col md="6">
                      <FormGroup>
                        <div className="form-floating">
                          <input id="student_allergies" className="form-control" style={{"borderRadius": "5px"}} placeholder="ID" type="text" />
                          <label htmlFor="pwd">Allergies</label>
                        </div>
                      </FormGroup>
                    </Col>
                    <Col md="6">
                      <FormGroup>
                        <div className="form-floating">
                          <input id="student_mother_maiden_name" className="form-control" style={{"borderRadius": "5px"}} placeholder="ID" type="text" />
                          <label htmlFor="pwd">Mother Maiden Name</label>
                        </div>
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row>
                    <Col md="6">
                      <FormGroup>
                        <div className="form-floating">
                          <input id="student_family_brother_sister" className="form-control" style={{"borderRadius": "5px"}} placeholder="ID" type="text" />
                          <label htmlFor="pwd">Family Brother/Sister</label>
                        </div>
                      </FormGroup>
                    </Col>
                    <Col md="6">
                      <FormGroup>
                        <div className="form-floating">
                          <input id="student_school_year_student" className="form-control" style={{"borderRadius": "5px"}} placeholder="ID" type="text" />
                          <label htmlFor="pwd">School year student will study in ireland</label>
                        </div>
                      </FormGroup>
                    </Col>
                  </Row>
 
    
                  <Row>
                    <Col md="6">
                      <FormGroup>
                        <div className="form-floating">
                          <input id="student_hobbies" className="form-control" style={{"borderRadius": "5px"}} placeholder="ID" type="text" />
                          <label htmlFor="pwd">Hobbies</label>
                        </div>
                      </FormGroup>
                    </Col>
                    <Col md="6">
                      <FormGroup>
                        <div className="form-floating">
                          <input id="student_results" className="form-control" style={{"borderRadius": "5px"}} placeholder="ID" type="text" />
                          <label htmlFor="pwd">Results</label>
                        </div>
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row>
                    <Col md="12">
                      <FormGroup>
                        <div className="form-floating">
                          <input id="student_special_requests" className="form-control" style={{"borderRadius": "5px"}} placeholder="Special Requests" type="text" />
                          <label htmlFor="pwd">Special Requests</label>
                        </div>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="12">
                      <FormGroup>
                        <div className="form-floating">
                          <input id="student_notes" className="form-control" style={{"borderRadius": "5px"}} placeholder="Notes" type="text" />
                          <label htmlFor="pwd">Notes</label>
                        </div>
                      </FormGroup>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
              <Card id="c_trip_information">
                <CardHeader>
                  <CardTitle tag="h4" className="panelTitle">Trip Information</CardTitle>
                </CardHeader>
                <CardBody>
                  <Row>
                    <Col sm="12">
                      <FormGroup>
                        <h4 style={{"fontFamily": "Noto Sans","fontStyle": "normal","fontWeight": 400,"fontSize": "16px","lineHeight": "24px","color": "#70B2D8"}}>Student</h4>
                      </FormGroup>
                    </Col>
                  </Row>
          
                  <Row>
                    <Col md="6">
                      <FormGroup>
                        <div className="input-group mb-3">
                          <div className="form-floating form-floating-group flex-grow-1">
                              <input autocomplete="off" readonly id="flight_arrivaldate" className="form-control" style={{"borderRadius": "5px"}} name="code1" placeholder="Code 1"/>
                              <label>Arrival Date</label>
                          </div>
                          <span className="input-group-text input-group-text-custom"><i className="now-ui-icons cs-icon-calendar"></i></span>
                        </div>
                      </FormGroup>
                    </Col>
                    <Col md="6">
                      <FormGroup>
                        <div className="input-group mb-3">
                          <div className="form-floating form-floating-group flex-grow-1">
                            <input autocomplete="off" id="flight_departuredate" className="form-control" style={{"borderRadius": "5px"}} name="code1" placeholder="Code 1"/>
                            <label>Departure Date</label>
                          </div>
                          <span className="input-group-text input-group-text-custom"><i className="now-ui-icons cs-icon-calendar"></i></span>
                        </div>
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row>
                    <Col sm="12">
                      <FormGroup>
                        <h4 style={{"fontFamily": "Noto Sans","fontStyle": "normal","fontWeight": 400,"fontSize": "16px","lineHeight": "24px","color": "#70B2D8"}}>Host Family</h4>
                      </FormGroup>
                    </Col>
                  </Row>
              
                  <Row>
                    <Col md={6}>
                      <FormGroup>
                        <div className="form-floating">
                          <select placeholder="s" id="hf_id" style={{borderRadius:"5px"}} className="form-control">
                            <option value="">Select</option>
                          </select>
                          <label htmlFor="pwd">Host Family</label>
                        </div>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="6">
                      <FormGroup>
                        <div className="form-floating">
                          <input readonly="true" id="hf_family_name" className="form-control" style={{"borderRadius": "5px"}} placeholder="ID" type="text" />
                          <label htmlFor="pwd">Family Name</label>
                        </div>
                      </FormGroup>
                    </Col>
                    <Col md="6">
                      <FormGroup>
                        <div className="form-floating">
                          <input readonly="true" id="hf_host_family_child" className="form-control" style={{"borderRadius": "5px"}} placeholder="ID" type="text" />
                          <label htmlFor="pwd">Host Family Child</label>
                        </div>
                      </FormGroup>
                    </Col>
                  </Row>
              
                  <Row>
                    <Col md="6">
                      <FormGroup>
                        <div className="form-floating">
                          <input readonly="true" id="hf_mother_name" className="form-control" style={{"borderRadius": "5px"}} placeholder="ID" type="text" />
                          <label htmlFor="pwd">Mother Name</label>
                        </div>
                      </FormGroup>
                    </Col>
                    <Col md="6">
                      <FormGroup>
                        <div className="form-floating">
                          <input readonly="true" id="hf_father_name" className="form-control" style={{"borderRadius": "5px"}} placeholder="ID" type="text" />
                          <label htmlFor="pwd">Father Name</label>
                        </div>
                      </FormGroup>
                    </Col>
                  </Row>
        
                  <Row>
                    <Col md="6">
                      <FormGroup>
                        <div className="input-group mb-3">
                          <div className="form-floating form-floating-group flex-grow-1">
                              <input readonly="true" id="hf_date_visited" className="form-control" style={{"borderRadius": "5px"}} name="code1" placeholder="Code 1"/>
                              <label>Date Visited</label>
                          </div>
                          <span className="input-group-text input-group-text-custom"><i className="now-ui-icons cs-icon-calendar"></i></span>
                        </div>
                      </FormGroup>
                    </Col>
                    <Col md="6">
                      <FormGroup>
                        <div className="form-floating">
                          <input readonly="true" id="hf_address" className="form-control" style={{"borderRadius": "5px"}} placeholder="ID" type="text" />
                          <label htmlFor="pwd">Address</label>
                        </div>
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row>
                    <Col sm="12">
                      <FormGroup>
                        <h4 style={{"fontFamily": "Noto Sans","fontStyle": "normal","fontWeight": 400,"fontSize": "16px","lineHeight": "24px","color": "#70B2D8"}}>Flight</h4>
                      </FormGroup>
                    </Col>
                  </Row>
   
                  <Row>
                    <Col md="6">
                      <FormGroup>
                        <div className="form-floating">
                          <input id="flight_type" className="form-control" style={{"borderRadius": "5px"}} placeholder="ID" type="text" />
                          <label htmlFor="pwd">Type</label>
                        </div>
                      </FormGroup>
                    </Col>
                    <Col md="6">
                      <FormGroup>
                        <div className="input-group mb-3">
                          <div className="form-floating form-floating-group flex-grow-1">
                            <input readonly="true" id="flight_arrivaldate2" className="form-control" style={{"borderRadius": "5px"}} name="code1" placeholder="Code 1"/>
                            <label>Date</label>
                          </div>
                          <span className="input-group-text input-group-text-custom"><i className="now-ui-icons cs-icon-calendar"></i></span>
                        </div>
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row>
                    <Col md="6">
                      <FormGroup>
                        <div>
                          <label style={{position: "relative",top: "28px",left: "14px",fontSize: "10px!important"}}>Arrival Time</label>
                          <DateTime timeFormat="HH:mm"
                            dateFormat={false}
                            inputProps={{id: "flight_arrivaltime" }}
                          />
                        </div>
                      </FormGroup>
                    </Col>
                    <Col md="6">
                      <FormGroup>
                        <div>
                          <label style={{position: "relative",top: "28px",left: "14px",fontSize: "10px!important"}}>Departure Time</label>
                        <DateTime timeFormat="HH:mm"
                          dateFormat={false}
                          inputProps={{id: "flight_departuretime" }}
                        />
                        </div>
                      </FormGroup>
                    </Col>
                  </Row>

                   <Row>
                    <Col md="6">
                      <FormGroup>
                        <div className="form-floating">
                          <input id="flight_airline" className="form-control" style={{"borderRadius": "5px"}} placeholder="ID" type="text" />
                          <label htmlFor="pwd">Airline Name</label>
                        </div>
                      </FormGroup>
                    </Col>
                    <Col md="6">
                      <FormGroup>
                        <div className="form-floating form-floating-group flex-grow-1">
                          <input id="flight_destination" className="form-control" style={{"borderRadius": "5px"}} name="code1" placeholder="Code 1"/>
                          <label>Arrival Airport</label>
                        </div>
                      </FormGroup>
                    </Col>
                  </Row>

                   <Row>
                    <Col md="6">
                      <FormGroup>
                        <div className="form-floating">
                          <input id="flight_origin" className="form-control" style={{"borderRadius": "5px"}} placeholder="ID" type="text" />
                          <label htmlFor="pwd">Origin Airport</label>
                        </div>
                      </FormGroup>
                    </Col>
                    <Col md="6">
                      <FormGroup>
                        <div className="form-floating form-floating-group flex-grow-1">
                          <input id="flight_arrivalpickup" className="form-control" style={{"borderRadius": "5px"}} name="code1" placeholder="Code 1"/>
                          <label>Pickup Location</label>
                        </div>
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row>
                    <Col md="6">
                      <FormGroup>
                        <div className="form-floating">
                          <input id="flight_number" className="form-control" style={{"borderRadius": "5px"}} placeholder="ID" type="text" />
                          <label htmlFor="pwd">Flight Number</label>
                        </div>
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row>
                    <Col md="12">
                      <FormGroup>
                        <div className="form-floating">
                          <input id="flight_reasonforjourney" className="form-control" style={{"borderRadius": "5px"}} placeholder="ID" type="text" />
                          <label htmlFor="pwd">Reason For Journey</label>
                        </div>
                      </FormGroup>
                    </Col>
                  </Row>

                    
                </CardBody>
              </Card>
              <Card id="c_submissions">
                <CardHeader>
                  <CardTitle tag="h4" className="panelTitle">Submissions</CardTitle>
                </CardHeader>
                <CardBody>
                  <Row>
                    <Col sm="12">
                      <FormGroup>
                        <h4 style={{"fontFamily": "Noto Sans","fontStyle": "normal","fontWeight": 400,"fontSize": "16px","lineHeight": "24px","color": "#70B2D8"}}>Student</h4>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm="12">
                      <Row className="bundleBorder">
                        <Col md="2">
                          <FormGroup>
                            <div className="form-floating">             
                              <label htmlFor="pwd">Photos</label>
                            </div>
                          </FormGroup>
                        </Col>
                        <img id="imgPreview" style={{float: "left",width:"76px",height:"76px",position:"relative"}} src={profileImg}></img>
                        <input style={{marginLeft: "20px",marginTop: "24px"}} type="file" id="photo"/>
                        <input type="hidden" id="s_photourl"/>
                      </Row>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm="12">
                      <FormGroup>
                        <h4 style={{"fontFamily": "Noto Sans","fontStyle": "normal","fontWeight": 400,"fontSize": "16px","lineHeight": "24px","color": "#70B2D8"}}>Submissions</h4>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm="12" id="submissions_list">
                    </Col>
                  </Row>
                  <Row>
                    <Col sm="12">
                      <FormGroup>
                        <h4 style={{"fontFamily": "Noto Sans","fontStyle": "normal","fontWeight": 400,"fontSize": "16px","lineHeight": "24px","color": "#70B2D8"}}>Documents & Images</h4>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm="12" id="documents_lists">
                    </Col>
                    <Col sm="12">
                      <input style={{marginLeft: "20px",marginTop: "24px"}} type="file" id="document_upload"/> <button className="btn btn-primary uploadDoc">Upload</button>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm="12">
                      <FormGroup>
                        <h4 style={{"fontFamily": "Noto Sans","fontStyle": "normal","fontWeight": 400,"fontSize": "16px","lineHeight": "24px","color": "#70B2D8"}}>Travel Logs</h4>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm="12" id="travel_logs_list">
                    </Col>
                  </Row>
                    <Row>
                      <Col sm="12">
                        <FormGroup>
                          <h4 style={{"fontFamily": "Noto Sans","fontStyle": "normal","fontWeight": 400,"fontSize": "16px","lineHeight": "24px","color": "#70B2D8"}}>Programme Rule</h4>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm="12">
                        <Row className="bundleBorder">
                          <Col md="11">
                            <FormGroup>
                              <div className="form-floating">     
                                <input value="Declined" id="programme_rule_status" style={{border:"0px"}} type="text" className="form-control" placeholder="ID"/>                     
                                <label htmlFor="pwd">Status</label>
                              </div>
                            </FormGroup>
                          </Col>
                          <Col md="1">
                            <div className="form-floating">    
                              <div className="material-switch">
                                  <input id="switch_programme_rule_status" type="checkbox"/>
                                  <label for="switch_programme_rule_status" class="label-primary"></label>
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
        
                    <Row>
                      <Col sm="12">
                        <FormGroup>
                          <h4 style={{"fontFamily": "Noto Sans","fontStyle": "normal","fontWeight": 400,"fontSize": "16px","lineHeight": "24px","color": "#70B2D8"}}>Photographic + Media Conscent </h4>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm="12">
                        <Row className="bundleBorder">
                          <Col md="11">
                            <FormGroup>
                              <div className="form-floating">
                                <input value="Declined" id="photo_sharing_agreed_and_read" style={{border:"0px"}} type="text" className="form-control" placeholder="ID"/>             
                                <label htmlFor="pwd">Status</label>
                              </div>
                            </FormGroup>
                          </Col>
                          <Col md="1">
                            <div className="form-floating">    
                              <div className="material-switch">
                                  <input id="switch_photo_sharing_agreed_and_read" type="checkbox"/>
                                  <label for="switch_photo_sharing_agreed_and_read" className="label-primary"></label>
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                </CardBody>
              </Card>
              <Row>
                <Col sm="12">
                  <div align="center">
                    <button className="btn-csconfirm">Update Changes</button>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Form>
      </div>
    </>
  );
  }
}

export default StudentDetail;