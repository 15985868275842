import Overview from "views/Pages/Admin/Overview.js";
import AgentOverview from "views/Pages/Admin/Overview.js";

import LiveReport from "views/Pages/Admin/LiveReport/LiveReport.js";
import LiveReportListPet from "views/Pages/Admin/LiveReport/LiveReportListPet.js";
import LiveReportDetailPet from "views/Pages/Admin/LiveReport/LiveReportDetailPet.js";
import LiveReportListMusic from "views/Pages/Admin/LiveReport/LiveReportListMusic.js";
import LiveReportDetailMusic from "views/Pages/Admin/LiveReport/LiveReportDetailMusic.js";
import LiveReportListSmoking from "views/Pages/Admin/LiveReport/LiveReportListSmoking.js";
import LiveReportDetailSmoking from "views/Pages/Admin/LiveReport/LiveReportDetailSmoking.js";
import LiveReportListDiatry from "views/Pages/Admin/LiveReport/LiveReportListDiatry.js";
import LiveReportDetailDiatry from "views/Pages/Admin/LiveReport/LiveReportDetailDiatry.js";

import StandardReport from "views/Pages/Admin/StandardReport/StandardReport.js";
import StandardReportListTpf from "views/Pages/Admin/StandardReport/StandardReportListTpf.js";
import StandardReportDetailTpf from "views/Pages/Admin/StandardReport/StandardReportDetailTpf.js";
import StandardReportListApf from "views/Pages/Admin/StandardReport/StandardReportListApf.js";
import StandardReportListSl from "views/Pages/Admin/StandardReport/StandardReportListSl.js";
import StandardReportListSchoolLog from "views/Pages/Admin/StandardReport/StandardReportListSchoolLog.js";
import StandardReportDetailStudentLog from "views/Pages/Admin/StandardReport/StandardReportDetailStudentLog.js";
import StandardReportDetailSchoolLog from "views/Pages/Admin/StandardReport/StandardReportDetailSchoolLog.js";
import StandardReportDetailApf from "views/Pages/Admin/StandardReport/StandardReportDetailApf.js";
import StandardReportListFir from "views/Pages/Admin/StandardReport/StandardReportListFir.js";
import StandardReportDetailFir from "views/Pages/Admin/StandardReport/StandardReportDetailFir.js";
import StandardReportListAgentReport from "views/Pages/Admin/StandardReport/StandardReportListAgentReport.js";
import StandardReportDetailAgentReport from "views/Pages/Admin/StandardReport/StandardReportDetailAgentReport.js";
import StandardReportListCoordinatorReport from "views/Pages/Admin/StandardReport/StandardReportListCoordinatorReport.js";
import StandardReportListStudentNote from "views/Pages/Admin/StandardReport/StandardReportListStudentNote.js";
import StandardReportDetailStudentNote from "views/Pages/Admin/StandardReport/StandardReportDetailStudentNote.js";
import StandardReportListGardaVetting from "views/Pages/Admin/StandardReport/StandardReportListGardaVetting.js";
import StandardReportDetailGardaVetting from "views/Pages/Admin/StandardReport/StandardReportDetailGardaVetting.js";
import StandardReportListChild from "views/Pages/Admin/StandardReport/StandardReportListChild.js";
import StandardReportDetailChild from "views/Pages/Admin/StandardReport/StandardReportDetailChild.js";
import StandardReportListHostFamilyNote from "views/Pages/Admin/StandardReport/StandardReportListHostFamilyNote.js";
import StandardReportDetailHostFamilyNote from "views/Pages/Admin/StandardReport/StandardReportDetailHostFamilyNote.js";

import Student from "views/Pages/Admin/Student/Student.js";
import StudentDetail from "views/Pages/Admin/Student/StudentDetail.js";
import StudentRegistration from "views/Pages/Admin/Student/StudentRegistration.js";

import Coordinator from "views/Pages/Admin/Coordinator/Coordinator.js";
import CoordinatorDetail from "views/Pages/Admin/Coordinator/CoordinatorDetail.js";
import CoordinatorRegistration from "views/Pages/Admin/Coordinator/CoordinatorRegistration.js";
import CoordinatorAttachedSchool from "views/Pages/Admin/Coordinator/CoordinatorAttachedSchool.js";
import CoordinatorAttachedHostFamily from "views/Pages/Admin/Coordinator/CoordinatorAttachedHostFamily.js";

import Agent from "views/Pages/Admin/Agent/Agent.js";
import AgentDetail from "views/Pages/Admin/Agent/AgentDetail.js";
import AgentRegistration from "views/Pages/Admin/Agent/AgentRegistration.js";
import AgentAttachedStudent from "views/Pages/Admin/Agent/AgentAttachedStudent.js";

import HostFamily from "views/Pages/Admin/HostFamily/HostFamily.js";
import HostFamilyDetail from "views/Pages/Admin/HostFamily/HostFamilyDetail.js";
import HostFamilyAttachedStudent from "views/Pages/Admin/HostFamily/HostFamilyAttachedStudent.js";

import School from "views/Pages/Admin/School/School.js";
import SchoolDetail from "views/Pages/Admin/School/SchoolDetail.js";
import SchoolRegistration from "views/Pages/Admin/School/SchoolRegistration.js";
import SchoolAttachedStudent from "views/Pages/Admin/School/SchoolAttachedStudent.js";

import Setting from "views/Pages/Admin/Setting/Setting.js";
import Archive from "views/Pages/Admin/Archive/Archive.js";
import ArchiveDetail from "views/Pages/Admin/Archive/ArchiveDetail.js";
import ArchiveStudent from "views/Pages/Admin/ArchiveStudent/Archive.js";
import ArchiveDetailStudent from "views/Pages/Admin/ArchiveStudent/ArchiveDetail.js";
import Survey from "views/Pages/Admin/Survey/Survey.js";
import SurveyNew from "views/Pages/Admin/Survey/SurveyNew.js";
import SurveyDetail from "views/Pages/Admin/Survey/SurveyDetail.js";
import ResponseDetail from "views/Pages/Admin/Survey/ResponseDetail.js";

import Profile from "views/Pages/Admin/Profile.js";

import Notification from "views/Pages/Admin/Notification/Notification.js";
import NotificationDetail from "views/Pages/Admin/Notification/NotificationDetail.js";
import NotificationNew from "views/Pages/Admin/Notification/NotificationNew.js";

import Notes from "views/Pages/Admin/Notes/Notes.js";
import NotesDetail from "views/Pages/Admin/Notes/NotesDetail.js";
import NotesNew from "views/Pages/Admin/Notes/NotesNew.js";

import Submission from "views/Pages/Admin/Submission/Submission.js";
import SubmissionCoordinatorDetail from "views/Pages/Admin/Submission/Coordinator/CoordinatorDetail.js";
import SubmissionHostFamilyDetail from "views/Pages/Admin/Submission/HostFamily/HostFamilyDetail.js";
import SubmissionStudentDetail from "views/Pages/Admin/Submission/Detail/StudentDetail.js";
import SubmissionFirDetail from "views/Pages/Admin/Submission/Detail/FirDetail.js";
import SubmissionHfDetail from "views/Pages/Admin/Submission/Detail/HfDetail.js";
import SubmissionGvDetail from "views/Pages/Admin/Submission/Detail/GvDetail.js";
import SubmissionTpfDetail from "views/Pages/Admin/Submission/Tpf/TpfDetail.js";
import SubmissionApfDetail from "views/Pages/Admin/Submission/Apf/ApfDetail.js";
import SubmissionSchoolDetail from "views/Pages/Admin/Submission/Detail/SchoolDetail.js";

let routes = [
  {
    path: "/user",
    name: "logo",
    icon: "now-ui-icons cs-menu",
    component: Overview,
    layout: "/admin"
  },
  {
    path: "/overview",
    name: "Overview",
    icon: "now-ui-icons cs-dashboard",
    component: AgentOverview,
    layout: "/agent"
  },
  {
    path: "/overview",
    name: "Overview",
    icon: "now-ui-icons cs-dashboard",
    component: Overview,
    layout: "/admin"
  },
  {
    collapse: true,
    path: "/report",
    name: "Reporting",
    state: "openPages",
    icon: "now-ui-icons cs-report",
    views: [
      {
        path: "/report/live-report/pets/:id",
        name: "Pets",
        component: LiveReportDetailPet,
        layout: "/admin",
        exludeSidebar:true
      },
      {
        path: "/report/live-report/smoking/:id",
        name: "Smoking",
        component: LiveReportDetailSmoking,
        layout: "/admin",
        exludeSidebar:true
      },
      {
        path: "/report/live-report/music-instruments/:id",
        name: "Musics",
        component: LiveReportDetailMusic,
        layout: "/admin",
        exludeSidebar:true
      },
      {
        path: "/report/live-report/diatry-requirements/:id",
        name: "Diatry Requirements",
        component: LiveReportDetailDiatry,
        layout: "/admin",
        exludeSidebar:true
      },
      {
        path: "/report/live-report/diatry-requirements",
        name: "Diatry Requirements",
        component: LiveReportListDiatry,
        layout: "/admin",
        exludeSidebar:true
      },
      {
        path: "/report/live-report/smoking",
        name: "Smoking",
        component: LiveReportListSmoking,
        layout: "/admin",
        exludeSidebar:true
      },
      {
        path: "/report/live-report/music-instruments",
        name: "Musics",
        component: LiveReportListMusic,
        layout: "/admin",
        exludeSidebar:true
      },
      {
        path: "/report/live-report/pets",
        name: "Pets",
        component: LiveReportListPet,
        layout: "/admin",
        exludeSidebar:true
      },
      {
        path: "/report/live-report",
        name: "Live Reports",
        mini: "LR",
        component: LiveReport,
        layout: "/admin"
      },
      {
        path: "/report/standard-report/travel-permission-form/:id",
        name: "Travel Permission Form",
        component: StandardReportDetailTpf,
        layout: "/admin",
        exludeSidebar:true
      },
      {
        path: "/report/standard-report/activity-permission-form/:id",
        name: "Activity Permission Form",
        component: StandardReportDetailApf,
        layout: "/admin",
        exludeSidebar:true
      },
      {
        path: "/report/standard-report/agent-report/:id",
        name: "Agent Report",
        component: StandardReportDetailAgentReport,
        layout: "/admin",
        exludeSidebar:true
      },
      {
        path: "/report/standard-report/flight-information-report/:id",
        name: "Flight Information Report",
        component: StandardReportDetailFir,
        layout: "/admin",
        exludeSidebar:true
      },
      {
        path: "/report/standard-report/garda-vetting/:id",
        name: "Garda Vetting Detail",
        component: StandardReportDetailGardaVetting,
        layout: "/admin",
        exludeSidebar:true
      },
      {
        path: "/report/standard-report/child/:id",
        name: "Child Detail",
        component: StandardReportDetailChild,
        layout: "/admin",
        exludeSidebar:true
      },
      {
        path: "/report/standard-report/host-family-note/:id",
        name: "Host Family Detail",
        component: StandardReportDetailHostFamilyNote,
        layout: "/admin",
        exludeSidebar:true
      },
      {
        path: "/report/standard-report/student-note/:id",
        name: "Student Notes Detail",
        component: StandardReportDetailStudentNote,
        layout: "/admin",
        exludeSidebar:true
      },
      {
        path: "/report/standard-report/student-logs/:id",
        name: "Student Logs Detail",
        component: StandardReportDetailStudentLog,
        layout: "/admin",
        exludeSidebar:true
      },
      {
        path: "/report/standard-report/school-logs/:id",
        name: "School Logs Detail",
        component: StandardReportDetailSchoolLog,
        layout: "/admin",
        exludeSidebar:true
      },
      {
        path: "/report/standard-report/travel-permission-form",
        name: "Travel Permission Form",
        component: StandardReportListTpf,
        layout: "/admin",
        exludeSidebar:true
      },
      {
        path: "/report/standard-report/activity-permission-form",
        name: "Activity Permission Form",
        component: StandardReportListApf,
        layout: "/admin",
        exludeSidebar:true
      },
      {
        path: "/report/standard-report/flight-information-report",
        name: "Flight Information Report",
        component: StandardReportListFir,
        layout: "/admin",
        exludeSidebar:true
      },
      {
        path: "/report/standard-report/agent-report",
        name: "Agent Report",
        component: StandardReportListAgentReport,
        layout: "/admin",
        exludeSidebar:true
      },
      {
        path: "/report/standard-report/coordinator-report",
        name: "Coordinator Report",
        component: StandardReportListCoordinatorReport,
        layout: "/admin",
        exludeSidebar:true
      },
      {
        path: "/report/standard-report/host-family-note",
        name: "Host Family Notes",
        component: StandardReportListHostFamilyNote,
        layout: "/admin",
        exludeSidebar:true
      },
      {
        path: "/report/standard-report/student-logs",
        name: "Student Logs",
        component: StandardReportListSl,
        layout: "/admin",
        exludeSidebar:true
      },
      {
        path: "/report/standard-report/school-logs",
        name: "School Logs",
        component: StandardReportListSchoolLog,
        layout: "/admin",
        exludeSidebar:true
      },
      {
        path: "/report/standard-report/student-note",
        name: "Student Notes",
        component: StandardReportListStudentNote,
        layout: "/admin",
        exludeSidebar:true
      },
      {
        path: "/report/standard-report/garda-vetting",
        name: "Garda Vetting",
        component: StandardReportListGardaVetting,
        layout: "/admin",
        exludeSidebar:true
      },
      {
        path: "/report/standard-report/child",
        name: "Child",
        component: StandardReportListChild,
        layout: "/admin",
        exludeSidebar:true
      },
      {
        path: "/report/standard-report",
        name: "Standard Reports",
        mini: "SR",
        component: StandardReport,
        layout: "/admin"
      },
    ]
  },
  {
    path: "/student/new/registration",
    name: "Student Registration",
    icon: "now-ui-icons cs-student",
    component: StudentRegistration,
    layout: "/admin",
    exludeSidebar:true
  },
  {
    path: "/student/:id",
    name: "Student Detail",
    icon: "now-ui-icons cs-student",
    component: StudentDetail,
    layout: "/admin",
    exludeSidebar:true
  },
  {
    path: "/student",
    name: "Student",
    icon: "now-ui-icons cs-student",
    component: Student,
    layout: "/admin"
  },
  {
    path: "/coordinator/attached/host-family",
    name: "Coordinator Attached Host Family",
    icon: "now-ui-icons cs-student",
    component: CoordinatorAttachedHostFamily,
    layout: "/admin",
    exludeSidebar:true
  },
  {
    path: "/coordinator/attached/school",
    name: "Coordinator Attached School",
    icon: "now-ui-icons cs-student",
    component: CoordinatorAttachedSchool,
    layout: "/admin",
    exludeSidebar:true
  },
  {
    path: "/coordinator/new/registration",
    name: "Coordinator Registration",
    icon: "now-ui-icons cs-student",
    component: CoordinatorRegistration,
    layout: "/admin",
    exludeSidebar:true
  },
  {
    path: "/coordinator/:id",
    name: "Coordinator Detail",
    icon: "now-ui-icons cs-student",
    component: CoordinatorDetail,
    layout: "/admin",
    exludeSidebar:true
  },
  {
    path: "/coordinator",
    name: "Coordinator",
    icon: "now-ui-icons cs-relation",
    component: Coordinator,
    layout: "/admin"
  },
  {
    path: "/agent/attached/student",
    name: "Agent Attached Students",
    icon: "now-ui-icons cs-student",
    component: AgentAttachedStudent,
    layout: "/admin",
    exludeSidebar:true
  },
  {
    path: "/agent/new/registration",
    name: "Agent Registration",
    icon: "now-ui-icons cs-student",
    component: AgentRegistration,
    layout: "/admin",
    exludeSidebar:true
  },
  {
    path: "/agent/:id",
    name: "Agent Detail",
    icon: "now-ui-icons cs-student",
    component: AgentDetail,
    layout: "/admin",
    exludeSidebar:true
  },
  {
    path: "/myaccount/detail/get",
    name: "My Account",
    icon: "now-ui-icons cs-student",
    component: Profile,
    layout: "/admin",
    exludeSidebar:true
  },
  {
    path: "/agent",
    name: "Agents",
    icon: "now-ui-icons cs-agent",
    component: Agent,
    layout: "/admin"
  },
  {
    path: "/host-family/attached/student",
    name: "Host Family Attached Students",
    icon: "now-ui-icons cs-student",
    component: HostFamilyAttachedStudent,
    layout: "/admin",
    exludeSidebar:true
  },
  {
    path: "/host-family/:id",
    name: "Host Family Detail",
    icon: "now-ui-icons cs-student",
    component: HostFamilyDetail,
    layout: "/admin",
    exludeSidebar:true
  },
  {
    path: "/archive/:id",
    name: "Host Family Archive Detail",
    icon: "now-ui-icons cs-student",
    component: ArchiveDetail,
    layout: "/admin",
    exludeSidebar:true
  },
  {
    path: "/new-student-archive/:id",
    name: "Student Archive Detail",
    icon: "now-ui-icons cs-student",
    component: ArchiveDetailStudent,
    layout: "/admin",
    exludeSidebar:true
  },
  {
    path: "/host-family",
    name: "Host Families",
    icon: "now-ui-icons cs-family",
    component: HostFamily,
    layout: "/admin"
  },
  {
    path: "/school/new/registration",
    name: "School Registration",
    icon: "now-ui-icons cs-student",
    component: SchoolRegistration,
    layout: "/admin",
    exludeSidebar:true
  },
  {
    path: "/school/attached/student",
    name: "Schools Attached Students",
    icon: "now-ui-icons cs-student",
    component: SchoolAttachedStudent,
    layout: "/admin",
    exludeSidebar:true
  },
  {
    path: "/school/:id",
    name: "School Detail",
    icon: "now-ui-icons cs-student",
    component: SchoolDetail,
    layout: "/admin",
    exludeSidebar:true
  },
  {
    path: "/notification/:id",
    name: "Notification Detail",
    icon: "now-ui-icons cs-student",
    component: NotificationDetail,
    layout: "/admin",
    exludeSidebar:true
  },
  {
    path: "/new-notification",
    name: "New Notification",
    icon: "now-ui-icons cs-student",
    component: NotificationNew,
    layout: "/admin",
    exludeSidebar:true
  },
  {
    path: "/notes/:id",
    name: "Notes.",
    icon: "now-ui-icons cs-student",
    component: NotesDetail,
    layout: "/admin",
    exludeSidebar:true
  },
  {
    path: "/survey/:id",
    name: "Survey",
    icon: "now-ui-icons cs-student",
    component: SurveyDetail,
    layout: "/admin",
    exludeSidebar:true
  },
  {
    path: "/response/:id",
    name: "Response Detail",
    icon: "now-ui-icons cs-student",
    component: ResponseDetail,
    layout: "/admin",
    exludeSidebar:true
  },
  {
    path: "/new-notes",
    name: "Notes.",
    icon: "now-ui-icons cs-student",
    component: NotesNew,
    layout: "/admin",
    exludeSidebar:true
  },
  {
    path: "/new-survey",
    name: "Survey",
    icon: "now-ui-icons cs-student",
    component: SurveyNew,
    layout: "/admin",
    exludeSidebar:true
  },
  {
    path: "/school",
    name: "School",
    icon: "now-ui-icons cs-school",
    component: School,
    layout: "/admin"
  },
  {
    path: "/submission/student/:id",
    name: "Submissions",
    icon: "now-ui-icons cs-submission",
    component: SubmissionStudentDetail,
    layout: "/admin",
    exludeSidebar:true
  },
  {
    path: "/submission/flight-information/:id",
    name: "Submissions",
    icon: "now-ui-icons cs-submission",
    component: SubmissionFirDetail,
    layout: "/admin",
    exludeSidebar:true
  },
  {
    path: "/submission/host-family/:id",
    name: "Submissions",
    icon: "now-ui-icons cs-submission",
    component: SubmissionHfDetail,
    layout: "/admin",
    exludeSidebar:true
  },
  {
    path: "/submission/gv/:id",
    name: "Submissions",
    icon: "now-ui-icons cs-submission",
    component: SubmissionGvDetail,
    layout: "/admin",
    exludeSidebar:true
  },
  {
    path: "/submission/coordinator/:id",
    name: "Submissions",
    icon: "now-ui-icons cs-submission",
    component: SubmissionCoordinatorDetail,
    layout: "/admin",
    exludeSidebar:true
  },
  {
    path: "/submission/host_family/:id",
    name: "Submissions",
    icon: "now-ui-icons cs-submission",
    component: SubmissionHostFamilyDetail,
    layout: "/admin",
    exludeSidebar:true
  },
  {
    path: "/submission/activity-permission-form/:id",
    name: "Submissions",
    icon: "now-ui-icons cs-submission",
    component: SubmissionApfDetail,
    layout: "/admin",
    exludeSidebar:true
  },
  {
    path: "/submission/school/:id",
    name: "Submissions",
    icon: "now-ui-icons cs-submission",
    component: SubmissionSchoolDetail,
    layout: "/admin",
    exludeSidebar:true
  },
  {
    path: "/submission/travel-permission-form/:id",
    name: "Submissions",
    icon: "now-ui-icons cs-submission",
    component: SubmissionTpfDetail,
    layout: "/admin",
    exludeSidebar:true
  },
  {
    path: "/submission",
    name: "Submissions",
    icon: "now-ui-icons cs-submission",
    component: Submission,
    layout: "/admin"
  },
  // {
  //   path: "/notes",
  //   name: "Notes",
  //   icon: "now-ui-icons cs-notes",
  //   component: Notes,
  //   layout: "/admin",
  // },
  {
    path: "/notifications",
    name: "Notifications",
    icon: "now-ui-icons cs-bell-white",
    component: Notification,
    layout: "/admin",
  },
  {
    path: "/archive",
    name: "Host Family Archive",
    icon: "now-ui-icons cs-archive",
    component: Archive,
    layout: "/admin",
  },
  {
    path: "/new-student-archive",
    name: "Student Archive",
    icon: "now-ui-icons cs-archive",
    component: ArchiveStudent,
    layout: "/admin",
  },
  {
    path: "/survey",
    name: "Survey",
    icon: "now-ui-icons cs-report",
    component: Survey,
    layout: "/admin",
  },
  {
    path: "/setting",
    name: "Settings",
    icon: "now-ui-icons cs-setting",
    component: Setting,
    layout: "/admin"
  }
];

export default routes;
