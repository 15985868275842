import React from "react";

import {
  Card,
  CardBody,
  Table,
  Row,
  Col
} from "reactstrap";

// core components
import PanelHeader from "components/PanelHeader/PanelHeader.js";

function StandardReport() {
  return (
    <>
      <PanelHeader size="sm"
        content={
          <div className="content">
            <div className="header text-left">
              <span className="panelHeaderDesc">View and manage the Standard Reports.</span>
              <nav aria-label="breadcrumb" role="navigation">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item"><a href="/coordinator/overview">Dashboard</a></li>
                  <li className="breadcrumb-item active" aria-current="page">Standard Reports</li>
                </ol>
              </nav>
            </div>
          </div>
        }
      />
      <div className="content">
        <Row>
          <Col xs={12} md={4}>
            <Card className="card-chart" style={{width: "329px",borderRadius: "20px"}}>
              <div className="containerThumbnail">
                <h4 className="ml-2 liveReportTitle">Travel Permission Forms</h4>
                <p className="ml-2 liveReportDesc">Submitted by Student</p>
                <p><a href="/coordinator/report/standard-report/travel-permission-form" className="btn btn-md btn-livereport-detail">View details</a></p>
              </div>
            </Card>
          </Col>
          <Col xs={12} md={4}>
            <Card className="card-chart" style={{width: "329px",borderRadius: "20px"}}>
              <div className="containerThumbnail">
                <h4 className="ml-2 liveReportTitle">Activity Permission Forms</h4>
                <p className="ml-2 liveReportDesc">Submitted by Student</p>
                <p><a href="/coordinator/report/standard-report/activity-permission-form" className="btn btn-md btn-livereport-detail">View details</a></p>
              </div>
            </Card>
          </Col>
          <Col xs={12} md={4}>
            <Card className="card-chart" style={{width: "329px",borderRadius: "20px"}}>
              <div className="containerThumbnail">
                <h4 className="ml-2 liveReportTitle">Flight Information Report</h4>
                <p className="ml-2 liveReportDesc">Submitted by Student</p>
                <p><a href="/coordinator/report/standard-report/flight-information-report" className="btn btn-md btn-livereport-detail">View details</a></p>
              </div>
            </Card>
          </Col>
        </Row>
        <br/>
        <br/>
        <Row>
          <Col xs={12} md={4}>
            <Card className="card-chart" style={{width: "329px",borderRadius: "20px"}}>
              <div className="containerThumbnail">
                <h4 className="ml-2 liveReportTitle">Student Logs</h4>
                <p className="ml-2 liveReportDesc">Submitted by Coordinator</p>
                <p><a href="/coordinator/report/standard-report/student-logs" className="btn btn-md btn-livereport-detail">View details</a></p>
              </div>
            </Card>
          </Col>
          <Col xs={12} md={4}>
            <Card className="card-chart" style={{width: "329px",borderRadius: "20px"}}>
             <div className="containerThumbnail">
                <h4 className="ml-2 liveReportTitle">School Logs</h4>
                <p className="ml-2 liveReportDesc">Submitted by Coordinator</p>
                <p><a href="/coordinator/report/standard-report/school-logs" className="btn btn-md btn-livereport-detail">View details</a></p>
              </div>
            </Card>
          </Col>
          <Col xs={12} md={4}>
            <Card className="card-chart" style={{width: "329px",borderRadius: "20px"}}>
              <div className="containerThumbnail">
                <h4 className="ml-2 liveReportTitle">Garda Vetting</h4>
                <p className="ml-2 liveReportDesc">Submitted by Host Family</p>
                <p><a href="/coordinator/report/standard-report/garda-vetting" className="btn btn-md btn-livereport-detail">View details</a></p>
              </div>
            </Card>
          </Col>
        </Row>
        <br/>
        <br/>
        <Row>
          <Col xs={12} md={4}>
            <Card className="card-chart" style={{width: "329px",borderRadius: "20px"}}>
              <div className="containerThumbnail">
                <h4 className="ml-2 liveReportTitle">Host Family Notes</h4>
                <p className="ml-2 liveReportDesc">Submitted by Host Family</p>
                <p><a href="/coordinator/report/standard-report/host-family-note" className="btn btn-md btn-livereport-detail">View details</a></p>
              </div>
            </Card>
          </Col>
          <Col xs={12} md={4}>
            <Card className="card-chart" style={{width: "329px",borderRadius: "20px"}}>
              <div className="containerThumbnail">
                <h4 className="ml-2 liveReportTitle">Student Notes</h4>
                <p className="ml-2 liveReportDesc">Submitted by Student</p>
                <p><a href="/coordinator/report/standard-report/student-note" className="btn btn-md btn-livereport-detail">View details</a></p>
              </div>
            </Card>
          </Col>
          <Col xs={12} md={4}>
            <Card className="card-chart" style={{width: "329px",borderRadius: "20px"}}>
              <div className="containerThumbnail">
                <h4 className="ml-2 liveReportTitle">Pets</h4>
                <p className="ml-2 liveReportDesc">Submitted by Host Family</p>
                <p><a href="/coordinator/report/standard-report/pets" className="btn btn-md btn-livereport-detail">View details</a></p>
              </div>
            </Card>
          </Col>
          <Col xs={12} md={4}>
            <Card className="card-chart" style={{width: "329px",borderRadius: "20px"}}>
              
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default StandardReport;
