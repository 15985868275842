import React, {Component} from "react";
import "jquery/dist/jquery.min.js";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-buttons/js/dataTables.buttons.js";
import "datatables.net-buttons/js/buttons.colVis.js";
import "datatables.net-buttons/js/buttons.flash.js";
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import "jquery-datatables-checkboxes";
import $ from "jquery";

import datepickerFactory from 'jquery-datepicker';
import 'jquery-ui/themes/base/core.css';
import 'jquery-ui/themes/base/theme.css';
import 'jquery-ui/themes/base/selectable.css';
import 'jquery-ui/ui/core';
import 'jquery-ui/ui/widgets/selectable';

import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Row,
  Col,
  Button
} from "reactstrap";

import PanelHeader from "components/PanelHeader/PanelHeader.js";
import TableFilter from "views/Components/TableFilter.js";

const names = [
  {
    "id":"1231",
    "title" : "Log 1",
    "date" : "01/05/2022",
    "status":"Status Update",
    "content":"Lorem Ipsum Dolor Sit Amet"
  },
  {
    "id":"1231",
    "title" : "Log 2",
    "date" : "01/05/2022",
    "status":"Status Update",
    "content":"Lorem Ipsum Dolor Sit Amet"
  }
]


function overrideDtColor(){
  $(".paginate_button").attr("style","border-radius: 30px;background: #B6C9D5;color: #FFFFFF!important;");
  $(".paginate_button.previous").html("<i class='now-ui-icons arrows-1_minimal-left'></i>").attr("style","borderRadius: 0px;background: #fff;color: #000!important;font-weight:bold;");
  $(".paginate_button.next").html("<i class='now-ui-icons arrows-1_minimal-right'></i>").attr("style","borderRadius: 0px;background: #fff;color: #000!important;font-weight:bold;");
  $(".dt-buttons").attr("style","display:none");
}

class LogNotes extends React.Component {
  componentDidMount(){
     
    let table = $("#table").DataTable({
      ordering:false,
      language: {
        paginate: {
          next: ">"
        }
      },
      pagingType: "simple_numbers",
      pageLength: 10,
      processing: true,
      dom: "Bfrtip",
      buttons: [
          {
              text: 'My button',
              action: function ( e, dt, node, config ) {
                  alert( 'Button activated' );
              }
          }
      ],
      columnDefs: [
         {
            targets: 0,
            checkboxes: {
               selectRow: true
            }
         }
      ],
      select: {
         style: 'multi'
      },
      order: [[1, 'desc']],
      buttons: [
                    {
                      extend: "pageLength",
                      className: "btn btn-secondary bg-secondary",
                    },
                    {
                      extend: "copy",
                      className: "btn btn-secondary bg-secondary",
                    },
                    {
                      extend: "csv",
                      className: "btn btn-secondary bg-secondary",
                    },
                    {
                      extend: "print",
                      customize: function (win) {
                        $(win.document.body).css("font-size", "10pt");
                        $(win.document.body)
                          .find("table")
                          .addClass("compact")
                          .css("font-size", "inherit");
                      },
                      className: "btn btn-secondary bg-secondary",
                    },
                  ]
    });

    overrideDtColor();
    datepickerFactory($);
    $('#startDate').datepicker();
    $('#endDate').datepicker();

    $(document).on("click","#addLog",function(){
      window.location.href = "/coordinator/log-notes/new/registration";
    });

    $(document).ready(function () {  
        
        $(".first.paginate_button, .last.paginate_button").hide(); 
        $(".dataTables_info").hide();
        
        $('.dataTables_filter').attr('style','float:left;');

        table.on('click', 'tr', function(){
          $("tr").css("background","white");
          $(this).css("background","#DAE8EF").css("border-radius","20px");
        });

        $(document).on("click",".paginate_button",function(){
            overrideDtColor();
        });

        $(document).on("keyup",'#dtSearchCs',function(){
          table.search($(this).val()).draw();
          overrideDtColor();
        })

    });
  }

  showTable = () => {
    const aStyle = {
      "color":"#F9B933",
      "fontStyle": "normal",
      "fontWeight": 400,
      "fontSize": "12px",
      "lineHeight": "16px"
    };
    try {
      return names.map((item, index) => {
        let currentUrl = window.location.origin + "/coordinator/log-notes/";
        return (
            <tr>
              <td style={{"fontStyle": "normal","fontWeight": 400,"fontSize": "14px","lineHeight": "20px"}} style={{"width":"2%"}} className="td-noborder"></td>
              <td className="td-noborder">{item.title}</td>
              <td className="td-noborder">{item.date}</td>
              <td className="td-noborder">{item.status}</td>
              <td className="td-noborder">{item.content}</td>
              <td className="td-noborder"><a style={aStyle} href={currentUrl + item.id}><i style={{"position": "relative","top":"8px"}} className="now-ui-icons cs-ov-edit-pen" />Views Detail</a></td>
            </tr>
        );
      });
    } catch (e) {
      alert(e.message);
    }
  };

  render(){
    return(
    <>
      <PanelHeader size="sm"
        content={
          <div className="content">
            <div className="header text-left">
              <span className="panelHeaderDesc">View and manage LogNotes details.</span>
              <nav aria-label="breadcrumb" role="navigation">
                <ol class="breadcrumb">
                  <li class="breadcrumb-item"><a href="#">Dashboard</a></li>
                  <li class="breadcrumb-item active" aria-current="page">Log Notes</li>
                </ol>
              </nav>
            </div>
          </div>
        }
      />
      <br/>
      <div className="content">
        <Row>
          <Col xs={12} md={12}>
            <Card>
              <CardHeader>
                <CardTitle tag="h4"></CardTitle>
              </CardHeader>
              <CardBody>
                <div className="container-fluid py-4">
                  <div className="table-responsive p-0 pb-2">
                    <TableFilter extraButton="yes" btnTitle="New Log" btnId="addLog"/>
                    <hr/>
                    <table id="table" className="align-items-center justify-content-center mb-0">
                       <thead>
                          <tr>
                            <th></th>
                            <th>Title</th>
                            <th>Date</th>
                            <th>Status</th>
                            <th>Content</th>
                            <th></th>
                          </tr>
                       </thead>

                       <tbody>
                               {this.showTable()}
                       </tbody>
                    </table>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
    )
  }
}

export default LogNotes;