import React from "react";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Label,
  FormGroup,
  Form,
  Input,
  FormText,
  Row,
  Col,
  Table,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu
} from "reactstrap";

// core components
import PanelHeader from "components/PanelHeader/PanelHeader.js";
import Switch from "react-bootstrap-switch";
import profileImg from "assets/upload/profile.png";
import ImageUpload from "components/CustomUpload/ImageUpload.js";
import axios from "axios";
import {base_api_url, file_api_url} from "variables/general.js";
import $ from "jquery";
import swal from 'sweetalert';
import { Pie } from 'react-chartjs-2';
import { useState, useEffect } from "react";

function SurveyDetail () {

  const [dataChart, setDataChart] = useState({
    labels: ['Red', 'Blue', 'Yellow', 'Green', 'Purple', 'Orange'],
    datasets: [
      {
        label: '# of Votes',
        data: [12, 19, 3, 5, 2, 3],
        backgroundColor: [
          'rgba(255, 99, 132, 0.2)',
          'rgba(54, 162, 235, 0.2)',
          'rgba(255, 206, 86, 0.2)',
          'rgba(75, 192, 192, 0.2)',
          'rgba(153, 102, 255, 0.2)',
          'rgba(255, 159, 64, 0.2)',
        ],
        borderColor: [
          'rgba(255, 99, 132, 1)',
          'rgba(54, 162, 235, 1)',
          'rgba(255, 206, 86, 1)',
          'rgba(75, 192, 192, 1)',
          'rgba(153, 102, 255, 1)',
          'rgba(255, 159, 64, 1)',
        ],
        borderWidth: 1,
      },
    ],
  });

  const [dataApis, setDataApis] = useState([]);
  const [dataApisRes, setDataApisRes] = useState([]);


  function submitData(survey_name, survey_desc, survey_published, questions) {
    let user_login = JSON.parse(localStorage.getItem('user_login'));

    const params = new Proxy(new URLSearchParams(window.location.search), {
      get: (searchParams, prop) => searchParams.get(prop),
    });

    var tzoffset = (new Date()).getTimezoneOffset() * 60000; //offset in milliseconds
    var localISOTime = (new Date(Date.now() - tzoffset)).toISOString().slice(0, -1);
    var timestamp = localISOTime;

    let requestData = JSON.parse(localStorage.getItem("detailSurvey"+params.id))
    requestData["surveyname"] = survey_name;
    requestData["surveydesc"] = survey_desc;

    if (Number(survey_published)==1) {
      survey_published = true;
    } else {
      survey_published = false;
    }

    requestData["published"] = survey_published;
    requestData["timestamp"] = timestamp;

    return axios.post(base_api_url + "/api/v1/mainsurveycontroller/updatesurvey", requestData).then(res => {
      
      if (questions.length>0) {

        for (var i=0;i<questions.length;i++){
          if (questions[i].id!==0) {
            questions[i]["timestamp"] = timestamp
            questions[i]["questionorder"] = i+1;
            axios.post(base_api_url + "/api/v1/surveyquestions/updatesurveyquestion", questions[i]).then(res => {
              
            })
          } else {
            questions[i]["timestamp"] = timestamp
            questions[i]["questionorder"] = i+1;
            axios.post(base_api_url + "/api/v1/surveyquestions/registerquestion", questions[i]).then(res => {
              
            })
          }
        }

        setTimeout(function(){
          swal("Success","Successfully send survey!","success");
          setTimeout(function(){
            window.location.reload();
          },1000);
        },2000);

      }

    }).catch(function (error) {
      swal("Failed!", "Something went wrong!", "error");
    });
  }

  function getData() {
    const params = new Proxy(new URLSearchParams(window.location.search), {
      get: (searchParams, prop) => searchParams.get(prop),
    });

    let urlTarget = base_api_url + '/api/v1/mainsurveycontroller/getsurveybyid?id=' + params.id; 
    return axios.get(urlTarget).then(res => {
      let datas = res.data.response.survey;
      localStorage.setItem("detailSurvey"+params.id,JSON.stringify(datas))
      $("#survey_name").val(datas.surveyname);
      $("#survey_desc").val(datas.surveydesc);
      let timestamp = new Date(datas.timestamp);

      let options = {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        hour12: true,
      };

      let formattedDate = timestamp.toLocaleString('en-US', options);
      $("#timestamp").html(formattedDate);

      if (datas.published==false) {
        $("#survey_published").val(0);
      } else {
        $("#survey_published").val(1);
      }

      if (datas.published==true) {
        $.each($('form input'), function(index, formField) {
          $(formField).prop("disabled",true);
        });

        $("select.form-control").prop("disabled",true);
        $(".btn-csconfirm").prop("disabled",true);
      }

    })
  }

  function populateQuestion() {
    const params = new Proxy(new URLSearchParams(window.location.search), {
      get: (searchParams, prop) => searchParams.get(prop),
    });
    let urlTarget = base_api_url + '/api/v1/surveyquestions/getsurveyquestionsbysurveyid?isactive=true&surveyid=' + params.id; 
    return axios.post(urlTarget).then(res => {
      let datas = res.data.response.content;
      setDataApis(datas);
    })
  }

  function populateAnswer() {
    const params = new Proxy(new URLSearchParams(window.location.search), {
      get: (searchParams, prop) => searchParams.get(prop),
    });
    let urlTarget = base_api_url + '/api/v1/surveyresponse/getresponsebysubmittersidrolesurveyidisactive?isactive=true&surveyid=' + params.id; 
    return axios.post(urlTarget).then(res => {
      let datas = res.data.response.content;
      setDataApisRes(datas);
      populateQuestion();
    })
  }

  function transformString(inputString) {
      let inputArray = [];

      // Extracting key-value pairs from the input string
      inputString.replace(/\{([^}]+)\}/g, function(match, contents) {
          let pair = contents.split(":").map(function(item) {
              return item.trim().replace(/[\s{}]/g, '');
          });

          let key = pair[0];
          let value = pair[1];

          // Handling multiple options
          if (value.includes(',')) {
              let options = value.split(',');
              options.forEach(function(option) {
                  let obj = {};
                  obj[key] = option.trim();
                  inputArray.push(obj);
              });
          } else {
              let obj = {};
              obj[key] = value;
              inputArray.push(obj);
          }
      });

      return inputArray;
  }

  useEffect(() => {  

    getData()
    populateAnswer();

    $(document).on("click",".close",function(){
      $(this).parent().parent().hide();
    });

  }, []);

  let dataChartValue = [];

  let stud = 1;
  let mapper = [];

  const resp = dataApisRes.map((item, index) => {
    let resMapping = [];
    var str = item.responsebypipesepration

    str = transformString(str);

    // Replacing single quotes with double quotes for keys and values
   // str = str.replace(/(['"])?([a-zA-Z0-9_]+)(['"])?:/g, '"$2":');
    // Enclosing the values in double quotes
   // str = str.replace(/:\s*([^,\}\]]+)/g, ': "$1"');
    let idc = 0; 
    str.forEach(obj => {
      for (let [key, value] of Object.entries(obj)) {
          if (!dataChartValue[key]) {
            dataChartValue[key] = [];
          }

          if (!dataChartValue[key][value]) {
            dataChartValue[key][value] =1;
          } else {
            dataChartValue[key][value] +=1;
          }

          if (!mapper[idc]) {
            mapper[idc] = [];
          }

          mapper[idc] = key;
          idc++;
      }
    });

    stud++;

  });

  console.log(mapper)

  if (dataApis.length>0) {
    dataApis.sort((a, b) => {
        const questionA = a.question.toUpperCase();
        const questionB = b.question.toUpperCase();

        if (questionA < questionB) {
            return -1;
        }
        if (questionA > questionB) {
            return 1;
        }
        return 0;
    });
  }

  const chartList = dataApis.map((item, index) => {

    if (!item.istextbox) {
    
      let labelQuestion = [];
      let labelValue = [];
      let question = mapper[index].toLowerCase();
      let options = [];

      if (!item.isrange) {
        options = item.optionstobeseparatedbypipesymbol.split("|");
      } else {
        for (var i=item.rangestart;i<=item.rangeend;i++) {
          options.push(String(i)); 
        }
      }

      if (dataChartValue[question]) {
        for (let [key, value] of Object.entries(dataChartValue[question])) {
          labelQuestion.push(key);
          labelValue.push(value);
        }
      }

      for (var i=0;i<options.length;i++) {
        if (!labelQuestion.includes(options[i])) {
          labelQuestion.push(options[i]);
          labelValue.push(0);
        }
      } 
      
      let labels = labelQuestion;
      let datas = [];
      let colors = [];
      let availColors = [
        'rgba(255, 99, 132, 0.2)',
        'rgba(54, 162, 235, 0.2)',
        'rgba(255, 206, 86, 0.2)',
        'rgba(75, 192, 192, 0.2)',
        'rgba(153, 102, 255, 0.2)',
        'rgba(255, 159, 64, 0.2)',
        'rgba(255, 99, 132, 0.2)',
        'rgba(54, 162, 235, 0.2)',
        'rgba(255, 206, 86, 0.2)',
        'rgba(75, 192, 192, 0.2)',
        'rgba(153, 102, 255, 0.2)',
        'rgba(255, 159, 64, 0.2)',
        'rgba(255, 99, 132, 0.2)',
        'rgba(54, 162, 235, 0.2)',
        'rgba(255, 206, 86, 0.2)',
        'rgba(75, 192, 192, 0.2)',
        'rgba(153, 102, 255, 0.2)',
        'rgba(255, 159, 64, 0.2)',
      ];

      for(var i=0;i<labelQuestion.length;i++) {
        colors.push(availColors[i]);
      }

      let data = {
          labels: labelQuestion,
          datasets: [
            {
              data: labelValue,
              backgroundColor: colors,
            },
          ],
          tooltips: {
            callbacks: {
              label: function (tooltipItem, data) {
                const dataset = data.datasets[tooltipItem.datasetIndex];
                const total = dataset.data.reduce((previousValue, currentValue) => previousValue + currentValue);
                const currentValue = dataset.data[tooltipItem.index];
                const percentage = ((currentValue / total) * 100).toFixed(2) + '%';
                return percentage;
              },
            },
          },
          legend: {
            display: false,
          },
          plugins: {
            labels: {
              render: 'percentage',
              precision: 2,
              fontSize: 12,
              fontColor: '#fff',
              fontStyle: 'bold',
              fontFamily: "'Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
              textShadow: true,
            },
          },
        }

      const opt = {
        plugins: {
          tooltip: {
            callbacks: {
              label: function (context) {
                const label = context.label || '';
                if (label) {
                  const total = context.dataset.data.reduce((acc, val) => acc + val, 0);
                  const value = context.parsed;
                  const percentage = ((value / total) * 100) + '%';
                  return `${label}: ${percentage}`;
                }
                return null;
              },
            },
          },
          datalabels: {
            display: true,
            color: '#fff',
            backgroundColor: 'rgba(0, 0, 0, 0.7)',
            borderRadius: 4,
            formatter: function (value, context) {
              const label = context.chart.data.labels[context.dataIndex];
              return label+"ages";
            },
          },
        },
      };


      return (
        <>
          <div className="card-chart card">
            <div style={{padding:"20px"}}>
            <h3>{item.question}</h3>
            <label>{dataApisRes.length} Response</label>
            <div align="center">
            <br/>
            <div className="col-md-2">
              <Pie data={data} options={opt}/>
            </div>
            </div>
            </div>
            <br/>
            <br/>
          </div>
        </>
      );

    }

  });

  return (
    <>
      <PanelHeader size="sm"
        content={
          <div className="content">
            <div className="header text-left">
              <span className="panelHeaderDesc">View and manage your Response.</span>
              <nav aria-label="breadcrumb" role="navigation">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item"><a href="/admin/overview">Dashboard</a></li>
                  <li className="breadcrumb-item"><a href="/admin/survey">Survey</a></li>
                  <li className="breadcrumb-item active" aria-current="page">Detail Response</li>
                </ol>
              </nav>
            </div>
          </div>
        }
      />
      <br/>
      <div className="content">
        <Row>
          <Col md="12">
            <Card className="card-chart">
              <CardHeader>
                <CardTitle id="timestamp" tag="h4" style={{"fontFamily": "Noto Sans","fontStyle": "normal","fontWeight": 400,"fontSize": "16px","lineHeight": "24px","color": "#70B2D8"}}></CardTitle>
                <a style={{float:"right", height:"30px"}} className="now-ui-icons cs-cancel_yellow" href="/admin/survey" />
                <br/>
              </CardHeader>
              <CardBody>
                <Input type="hidden" id="n_photourl"/>
                <Form action="/" className="form-horizontal" method="get">
                  <hr/>
                
                  <Row>
                    <Col sm="12">
                      <Row className="text-desc-notif">
                        <Col md="12">
                          <FormGroup>
                            <div className="form-floating">
                              <Input id="survey_name" style={{"borderRadius": "5px"}} placeholder="Country" type="text" />
                              <label htmlFor="pwd">Survey Name</label>
                            </div>
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row className="text-desc-notif">
                        <Col md="12">
                          <FormGroup>
                            <div className="form-floating">
                              <Input id="survey_desc" style={{"borderRadius": "5px"}} placeholder="Country" type="text" />
                              <label htmlFor="pwd">Survey Description</label>
                            </div>
                          </FormGroup>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Form>
              </CardBody>
            </Card>
            <div id="questions">
              {chartList}
            </div>

          </Col>
        </Row>
    
      </div>
    </>
  );
  
}

export default SurveyDetail;
