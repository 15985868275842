import React from "react";

// reactstrap components
import {
  Card,
  CardBody,
  Table,
  Row,
  Col
} from "reactstrap";

// core components
import PanelHeader from "components/PanelHeader/PanelHeader.js";

function LiveReport() {

  return (
    <>
      <PanelHeader size="sm"
        content={
          <div className="content">
            <div className="header text-left">
              <span className="panelHeaderDesc">View and manage the Live Reports.</span>
              <nav aria-label="breadcrumb" role="navigation">
                <ol class="breadcrumb">
                  <li class="breadcrumb-item"><a href="/admin/overview">Dashboard</a></li>
                  <li class="breadcrumb-item active" aria-current="page">Live Reports</li>
                </ol>
              </nav>
            </div>
          </div>
        }
      />
      <div className="content">
        <Row>
          <Col xs={12} md={4}>
            <Card className="card-chart" style={{width: "329px",borderRadius: "20px"}}>
              <div className="containerThumbnail">
                <h4 class="ml-2 liveReportTitle">Pets</h4>
                <p class="ml-2 liveReportDesc">Submitted by Host Family</p>
                <p><a href="/admin/report/live-report/pets" class="btn btn-md btn-livereport-detail">View details</a></p>
              </div>
            </Card>
          </Col>
          <Col xs={12} md={4}>
            <Card className="card-chart" style={{width: "329px",borderRadius: "20px"}}>
              <div className="containerThumbnail">
                <h4 class="ml-2 liveReportTitle">Smoking</h4>
                <p class="ml-2 liveReportDesc">Submitted by Host Family</p>
                <p><a href="/admin/report/live-report/smoking" class="btn btn-md btn-livereport-detail">View details</a></p>
              </div>
            </Card>
          </Col>
          <Col xs={12} md={4}>
            <Card className="card-chart" style={{width: "329px",borderRadius: "20px"}}>
              <div className="containerThumbnail">
                <h4 class="ml-2 liveReportTitle">Music Instruments</h4>
                <p class="ml-2 liveReportDesc">Submitted by Host Family</p>
                <p><a href="/admin/report/live-report/music-instruments" class="btn btn-md btn-livereport-detail">View details</a></p>
              </div>
            </Card>
          </Col>
        </Row>
        <br/>
        <br/>
        <Row>
          <Col xs={12} md={4}>
            <Card className="card-chart" style={{width: "329px",borderRadius: "20px"}}>
              <div className="containerThumbnail">
                <h4 class="ml-2 liveReportTitle">Dietry Requirements/Allergins</h4>
                <p class="ml-2 liveReportDesc">Submitted by Student</p>
                <p><a href="/admin/report/live-report/diatry-requirements" class="btn btn-md btn-livereport-detail">View details</a></p>
              </div>
            </Card>
          </Col>
          <Col xs={12} md={4}>
            <Card className="card-chart" style={{width: "329px",borderRadius: "20px"}}>
              <div className="containerThumbnail">
              </div>
            </Card>
          </Col>
          <Col xs={12} md={4}>
            <Card className="card-chart" style={{width: "329px",borderRadius: "20px"}}>
              <div className="containerThumbnail">
              </div>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default LiveReport;
