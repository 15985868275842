import React from "react";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Label,
  FormGroup,
  Form,
  Input,
  FormText,
  Row,
  Col,
  Table
} from "reactstrap";

// core components
import PanelHeader from "components/PanelHeader/PanelHeader.js";
import Switch from "react-bootstrap-switch";
import profileImg from "assets/upload/profile.png";
import ImageUpload from "components/CustomUpload/ImageUpload.js";
import axios from "axios";
import {base_api_url} from "variables/general.js";
import $ from "jquery";

function getData() {

  const params = new Proxy(new URLSearchParams(window.location.search), {
    get: (searchParams, prop) => searchParams.get(prop),
  });

  let urlTarget = base_api_url + "/api/v1/nc/getnotebyid?notesId=" + params.id; 
  return axios.get(urlTarget).then(res => {
    let datas = res.data.response;
    showData(datas);
  })
}

function showData(data) {
  $("#message").html(data.message);
  $("#from").html(data.submittersname);
  $("#to").html(data.receiversrole);
  $("#subject").html(data.reason);
  $("#br_subject").html(data.reason);
  if (data.timestamp) {
    let rd = new Date(data.timestamp);

    rd = rd.toUTCString();
    $("#timestamp").html(rd);
  }
}

class NotesDetail extends React.Component {

  componentDidMount(){
    getData()
  }

  render(){
    const currentDate = new Date();
    return (
    <>
      <PanelHeader size="sm"
        content={
          <div className="content">
            <div className="header text-left">
              <span className="panelHeaderDesc">View and manage your Notifications.</span>
              <nav aria-label="breadcrumb" role="navigation">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item"><a href="#">Dashboard</a></li>
                  <li className="breadcrumb-item"><a href="#">Notifications</a></li>
                  <li className="breadcrumb-item active" aria-current="page" id="br_subject"></li>
                </ol>
              </nav>
            </div>
          </div>
        }
      />
      <br/>
      <div className="content">
        <Row>
          <Col md="12">
            <Card>
              <CardHeader>
                <CardTitle tag="h4" className="text-header-notif" id="subject"></CardTitle>
                <h4 style={{"fontFamily": "Noto Sans","fontStyle": "normal","fontWeight": 400,"fontSize": "16px","lineHeight": "24px","color": "#70B2D8"}} id="timestamp">{currentDate.toUTCString()}</h4>
              </CardHeader>
              <CardBody>
                <Form action="/" className="form-horizontal" method="get">
                  <hr/>
                  <Row className="text-header-info-notif">
                    <Col sm="12">
                      <Row>
                        <Col md="2">
                          <FormGroup>
                            <label htmlFor="pwd">From : <span id="from"></span></label>
                          </FormGroup>
                        </Col>
                        <Col md="10">
                          <FormGroup>
                            <label htmlFor="pwd">Receipent: <span id="to"></span></label>
                          </FormGroup>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <hr/>
    
                  <Row>
                    <Col sm="12">
                      <Row className="text-desc-notif">
                        <Col md="12">
                          <FormGroup>
                            <p>RE: <span id="subject"></span></p>
                            <p id="message"></p>
                          </FormGroup>
                        </Col>
                      </Row>
                    </Col>
                  </Row>

                  <Row>
                    <Col sm="12">
                      <div className="pull-left">
                      	<button style={{"background":"#416D86"}} className="btn-csconfirm">View Form</button>
                      </div>
                    </Col>
                  </Row>
                </Form>
              </CardBody>
              <br/>
            </Card>
          </Col>
        </Row>
    
      </div>
    </>
  );
  }
}

export default NotesDetail;
