import React from "react";
import { Route, Switch, Redirect, useLocation } from "react-router-dom";
// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from "perfect-scrollbar";
// react plugin for creating notifications
import NotificationAlert from "react-notification-alert";

// core components
import HostFamilyNavbar from "components/Navbars/HostFamilyNavbar.js";
import FooterHostFamily from "components/Footer/FooterHostFamily.js";
import SidebarHostFamily from "components/Sidebar/SidebarHostFamily.js";

import routes from "routes_hostfamily.js";

var ps;

function HostFamily(props) {
  const location = useLocation();
  const [setSidebarHostFamilyMini] = React.useState(true);
  const [backgroundColor] = React.useState("blue");
  const notificationAlert = React.useRef();
  const mainPanel = React.useRef();
  React.useEffect(() => {
    if (navigator.platform.indexOf("Win") > -1) {
      document.documentElement.className += " perfect-scrollbar-on";
      document.documentElement.classList.remove("perfect-scrollbar-off");
      ps = new PerfectScrollbar(mainPanel.current);
    }
    return function cleanup() {
      if (navigator.platform.indexOf("Win") > -1) {
        ps.destroy();
        document.documentElement.className += " perfect-scrollbar-off";
        document.documentElement.classList.remove("perfect-scrollbar-on");
      }
    };
  });
  React.useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    mainPanel.current.scrollTop = 0;
  }, [location]);
  const minimizeSidebarHostFamily = () => {
    var message = "SidebarHostFamily mini ";
    if (document.body.classList.contains("sidebar-mini")) {
      setSidebarHostFamilyMini(false);
      message += "deactivated...";
    } else {
      setSidebarHostFamilyMini(true);
      message += "activated...";
    }
    document.body.classList.toggle("sidebar-mini");
    var options = {};
    options = {
      place: "tr",
      message: message,
      type: "info",
      icon: "now-ui-icons ui-1_bell-53",
      autoDismiss: 7
    };
    notificationAlert.current.notificationAlert(options);
  };
  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return getRoutes(prop.views);
      }
      if (prop.layout === "/hostfamily") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };
  const getActiveRoute = (routes) => {
    let activeRoute = "SPIL";
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse) {
        let collapseActiveRoute = getActiveRoute(routes[i].views);
        if (collapseActiveRoute !== activeRoute) {
          return collapseActiveRoute;
        }
      } else {
        if (
          window.location.pathname.indexOf(
            routes[i].layout + routes[i].path
          ) !== -1
        ) {
          return routes[i].name;
        }
      }
    }
    return activeRoute;
  };

  let user_login = localStorage.getItem("user_login");

  if (user_login) {
      let session = JSON.parse(user_login);
      const now = new Date();
      if (now.getTime() > session.expire) {
        if (session.expire) {
          localStorage.removeItem("user_login")
          user_login = null;
        }
      }

      if (session.role!=="hostfamily") {
        localStorage.removeItem("user_login")
        user_login = null;
      }
  }

  if (!user_login) {
    window.location.href = window.location.origin + "/auth/login/hostfamily?message='unauthorize'";
  } else {

    return (
      <>
        <div className="wrapper">
          <NotificationAlert ref={notificationAlert} />
          <SidebarHostFamily
            {...props}
            routes={routes}
            minimizeSidebarHostFamily={minimizeSidebarHostFamily}
            backgroundColor={backgroundColor}
          />
          <div className="main-panel" ref={mainPanel}>
            <HostFamilyNavbar {...props} brandText={getActiveRoute(routes)} />
            <Switch>
              {getRoutes(routes)}
              <Redirect from="/hostfamily" to="/hostfamily/overview" />
            </Switch>
            {
              // we don't want the Footer to be rendered on full screen maps page
              window.location.href.indexOf("full-screen-maps") !== -1 ? null : (
                <div className="col-12 col-md-12">
                <FooterHostFamily fluid />
                </div>
              )
            }
          </div>
        </div>
      </>
    );

  }
}

export default HostFamily;
