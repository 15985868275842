import React from "react";
import $ from "jquery";
// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
  Form,
  Input,
  FormGroup
} from "reactstrap";

// core components
import PanelHeader from "components/PanelHeader/PanelHeader.js";
import axios from "axios";
import {base_api_url, file_api_url} from "variables/general.js";
import swal from 'sweetalert';

function getData(id) {

  const params = new Proxy(new URLSearchParams(window.location.search), {
    get: (searchParams, prop) => searchParams.get(prop),
  });

  let urlTarget = base_api_url + "/api/v1/admin/getadminbyid?adminId=" + id; 
  return axios.get(urlTarget).then(res => {
    let datas = res.data.response;
    showData(datas);
  })
}

function uploadFile(refid) {
  var bodyFormData = new FormData();
  let imageFile = $("#photo")[0].files[0];
  let user_login = JSON.parse(localStorage.getItem('user_login'));

  bodyFormData.append('file', imageFile);
  bodyFormData.append('username', user_login.email);

  axios({
    method: "post",
    url: file_api_url + "/api/v1/user/upload/file",
    data: bodyFormData,
    headers: { "Content-Type": "multipart/form-data" },
  }).then(function (response) {
    $("#p_photourl").val(response.data[0].path);
    submitData(refid);
  });
}

function showData(data) {
  if (data.photourl) {
    $("#profile").attr("src",data.photourl);
  }
  $("#username").val(data.username);
  $("#email").val(data.emailaddress);
  $("#company").val(data.companyname);
  $("#address").val(data.address);
  $("#city").val(data.city);
  $("#country").val(data.country);
  $("#zip").val(data.postalcode);
  $("#first_name").val(data.firstname);
  $("#last_name").val(data.lastname);
  $("#t_username").html(data.username);
  $("#t_name").html(data.firstname + " " + data.lastname);
  if (data.photourl) {
    $("#p_photourl").val(data.photourl);
  }
}

function submitData(id) {
  var tzoffset = (new Date()).getTimezoneOffset() * 60000; //offset in milliseconds
  var localISOTime = (new Date(Date.now() - tzoffset)).toISOString().slice(0, -1);
  var timestamp = localISOTime;
  let user_login = JSON.parse(localStorage.getItem('user_login'));

  let requestData = {
    address: $("#address").val(),
    city: $("#city").val(),
    companyname: $("#company").val(),
    country: $("#country").val(),
    emailaddress: $("#email").val(),
    firstname: $("#first_name").val(),
    id: id,
    isactive: 1,
    lastname: $("#last_name").val(),
    mobile: $("#mobile").val(),
    photosharingagreedandread: true,
    photourl: $("#p_photourl").val(),
    postalcode: $("#zip").val(),
    timestamp: timestamp,
    username: user_login.username
  }

  return axios.put(base_api_url + "/api/v1/admin/updatebyadminid", requestData).then(res => {
    swal("Success","Successfully update data!","success");
    setTimeout(function(){
      window.location.reload();
    },1000);
  }).catch(function (error) {
    swal("Failed","Failed update data!","error");
  });
}

class Profile extends React.Component {

  componentDidMount() {
    let user_login = JSON.parse(localStorage.getItem('user_login'));
    
    getData(user_login.reference_id);

    $('#photo').change(function(){
      const file = this.files[0];
      if (file){
        $(".errorCs").remove();
        if (file.type=="image/jpeg" || file.type=="image/png") {
          let reader = new FileReader();
          reader.onload = function(event){
            $('#imgPreview').attr('src', event.target.result);
          }
          reader.readAsDataURL(file);
        } else {
          $('#photo').val("");
          $("#imgPreview").attr("src","");
          $('#photo').parent().parent().append("<span class='errorCs'>The image must be a file of type: jpg, jpeg, png.</span>");
        }
      }
    });

    $(document).on("click",".submitUpdate",function(e){
      
      e.preventDefault();
      $(".errorCs").remove();

      let notValid = 0;
      let msgval = "";
      let cv;

      if ($("#s_photourl").val()=="") {
        $("#photo").parent().parent().append("<span class='errorCs'>File required!</span>");
      } else {

        $.each($('form input'), function(index, formField) {
          if ($(formField).val()==="") {
            cv = $(formField).parent().find("label").text();
            if (cv!==""  && $(formField).attr("type")!=="file") {
              msgval = cv + " is required! <br/>";
              $(formField).parent().append("<span class='errorCs'>" + msgval + "</span>");
              notValid += 1;
            }
          }
        });

        if (notValid>0) {
          
        } else {
          if ($("#photo").val()=="")  {
            submitData(user_login.reference_id);
          } else {
            uploadFile(user_login.reference_id);
          }
        }
      }

    });

    $(document).on("click",".close",function(){
      $(this).parent().parent().hide();
    });

  }

  render() {
    return (
      <>
        <PanelHeader size="sm"
          content={
            <div className="content">
              <div className="header text-left">
                <span className="panelHeaderDesc"></span>
                <nav aria-label="breadcrumb" role="navigation">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item"><a href="/admin/overview">Dashboard</a></li>
                    <li className="breadcrumb-item active" aria-current="page">My Account</li>
                  </ol>
                </nav>
              </div>
            </div>
          }
        />
        <div className="content">
          <Row>
            <Col md="8">
              <Card>
                <CardHeader>
                  <h5 className="title">Edit Profile</h5>
                </CardHeader>
                <CardBody>
                  <Form>
                    <Row>
                      <Col className="pr-1" md="6">
                        <FormGroup>
                          <label>Company</label>
                          <Input
                            id="company"
                            placeholder="Company"
                            type="text"
                          />
                        </FormGroup>
                      </Col>
                      <Col className="pl-1" md="6">
                        <FormGroup>
                          <label htmlFor="exampleInputEmail1">
                            Email address
                          </label>
                          <Input id="email" placeholder="Email" type="email" />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col className="pr-1" md="6">
                        <FormGroup>
                          <label>First Name</label>
                          <Input id="first_name"
                            placeholder="Company"
                            type="text"
                          />
                        </FormGroup>
                      </Col>
                      <Col className="pl-1" md="6">
                        <FormGroup>
                          <label>Last Name</label>
                          <Input id="last_name"
                            placeholder="Last Name"
                            type="text"
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="12">
                        <FormGroup>
                          <label>Address</label>
                          <Input id="address"
                            placeholder="Home Address"
                            type="text"
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col className="pr-1" md="4">
                        <FormGroup>
                          <label>City</label>
                          <Input id="city"
                            placeholder="City"
                            type="text"
                          />
                        </FormGroup>
                      </Col>
                      <Col className="px-1" md="4">
                        <FormGroup>
                          <label>Country</label>
                          <Input id="country"
                            placeholder="Country"
                            type="text"
                          />
                        </FormGroup>
                      </Col>
                      <Col className="pl-1" md="4">
                        <FormGroup>
                          <label>Postal Code</label>
                          <Input id="zip" placeholder="ZIP Code" type="number" />
                        </FormGroup>
                      </Col>
                    </Row>
                    <button style={{float: "right",width: "140px"}} class="mb-3 btn-round submitUpdate btn btn-primary btn-lg btn-block">Update</button>
                  </Form>
                </CardBody>
              </Card>
            </Col>
            <Col md="4">
              <Card className="card-user">
                <div className="image">
                  <img style={{width:"100%"}} alt="..." src={require("assets/upload/bg_my_profile.png")} />
                </div>
                <CardBody>
                  <div className="author">
                    <img id="profile"
                      alt="..."
                      className="avatar border-gray"
                      src={require("assets/upload/default.png")}
                    />
                    <input type="file" name="photo" id="photo" className="photo custom-file-input"/>
                    <input type="hidden" id="p_photourl"/>
                    <h5 style={{color:"#F9B933"}} className="title" id="t_name"></h5>
                    <p className="description" id="t_username"></p>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

export default Profile;
