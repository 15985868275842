import LoginAdmin from "views/Pages/Admin/LoginAdmin.js";
import ForgotPassword from "views/Pages/Admin/ForgotPassword.js";
import ChangePassword from "views/Pages/Admin/ChangePassword.js";
import LoginAgent from "views/Pages/Agent/LoginAgent.js";
import LoginCoordinator from "views/Pages/Coordinator/LoginCoordinator.js";
import LoginStudent from "views/Pages/Student/LoginStudent.js";
import LoginHostFamily from "views/Pages/HostFamily/LoginHostFamily.js";
import Home from "views/Pages/Home.js";
import Privacy from "views/Pages/Privacy.js";
import About from "views/Pages/About.js";

let routes = [
  {
    path: "/privacy",
    name: "Privacy",
    component: Privacy,
    layout: "/home",
    exludeSidebar:true
  },
  {
    path: "/about",
    name: "About",
    component: About,
    layout: "/home",
    exludeSidebar:true
  },
  {
    collapse: true,
    path: "/pages",
    name: "Pages",
    state: "openPages",
    icon: "now-ui-icons design_image",
    views: [
      {
        path: "/login/admin",
        name: "Login Page",
        short: "Login",
        mini: "LP",
        component: LoginAdmin,
        layout: "/auth"
      },
      {
        path: "/forgot-password",
        name: "Forgot Password Page",
        short: "Forgot Password",
        mini: "FP",
        component: ForgotPassword,
        layout: "/auth"
      },
      {
        path: "/changepassword",
        name: "Change Password Page",
        short: "Change Password",
        mini: "CP",
        component: ChangePassword,
        layout: "/auth"
      },
      {
        path: "/login/agent",
        name: "Login Page",
        short: "Login",
        mini: "LP",
        component: LoginAgent,
        layout: "/auth"
      },
      {
        path: "/login/coordinator",
        name: "Login Page",
        short: "Login",
        mini: "LP",
        component: LoginCoordinator,
        layout: "/auth"
      },
      {
        path: "/login/student",
        name: "Login Page",
        short: "Login",
        mini: "LP",
        component: LoginStudent,
        layout: "/auth"
      },
      {
        path: "/login/hostfamily",
        name: "Login Page",
        short: "Login",
        mini: "LP",
        component: LoginHostFamily,
        layout: "/auth"
      },
      {
        path: "/",
        name: "Home",
        component: Home,
        layout: "/home"
      }
    ]
  }
];

export default routes;
