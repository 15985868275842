import React from "react";
import $ from "jquery";
import datepickerFactory from 'jquery-datepicker';
import axios from "axios";
import { base_api_url, default_start_date, default_end_date, defPaginPage, defPaginPerpage, toPopulatePage, replaceEndTime } from "variables/general.js";
import { jsPDF } from "jspdf";
import autoTable from 'jspdf-autotable';

import {
  Card,
  CardBody,
  Row,
  Col
} from "reactstrap";

import PanelHeader from "components/PanelHeader/PanelHeader.js";
import TableFilter from "views/Components/TableFilter.js";

function overrideDtColor() {
  $(".paginate_button").attr("style", "border-radius: 30px;background: #B6C9D5;color: #FFFFFF!important;");
  $(".paginate_button.previous").html("<i class='now-ui-icons arrows-1_minimal-left'></i>").attr("style", "borderRadius: 0px;background: #fff;color: #000!important;font-weight:bold;");
  $(".paginate_button.next").html("<i class='now-ui-icons arrows-1_minimal-right'></i>").attr("style", "borderRadius: 0px;background: #fff;color: #000!important;font-weight:bold;");
  $(".dt-buttons").attr("style", "display:none");
}

function getData(page, perpage, searchKey = "") {

  let fromDate = $('#startDate').val();
  let toDate = $("#endDate").val();

  if ($('#startDate').val() !== "") {
    fromDate = fromDate.split("/")
    fromDate = fromDate[2].substring(0, 4) + "-" + fromDate[1] + "-" + fromDate[0]
    fromDate = new Date(fromDate);
    fromDate = fromDate.toISOString();
  }

  if ($("#endDate").val() !== "") {
    toDate = toDate.split("/")
    toDate = toDate[2].substring(0, 4) + "-" + toDate[1] + "-" + toDate[0]
    toDate = new Date(toDate);
    toDate = toDate.toISOString().replace("T00:00:00", replaceEndTime);
  }

  $("#pagLink").removeClass("active");
  let curpage = Number(page) - 1;
  let user_login = JSON.parse(localStorage.getItem('user_login'));
  let urlTarget = base_api_url + "/api/v1/nc/v2/filterbynotesbyreceiver?page=" + curpage + '&size=' + perpage + "&searchTerm=" + encodeURIComponent(searchKey.trim()) + "&fromdate=" + fromDate + "&todate=" + toDate + "&notescreen=mhostfamilyLogs" + "&receiversfkuserid=" + user_login.reference_id + "&receiversfkuserrole=" + user_login.role;
  return axios.get(urlTarget).then(res => {
    let datas = res.data.response.content;
    let totalPage = Number(res.data.response.totalPages);

    showTable(datas);
    loopPagination(Number(page), totalPage);

    let total = $("tbody tr").length;
    let checked = $(".selectorRow:checked").length;
    $("#rowSelectedLabel").attr("placeholder", checked + " of " + total + " selected");

  })
}

function loopPagination(curPage, sizePage) {
  let paginContent = "";
  let prevPage = curPage - 1;
  let nextPage = curPage + 1;

  if (sizePage > 0) {
    paginContent = paginContent.concat(`<a data-perpage="${defPaginPerpage}" data-page="${prevPage}" class="prevPag pagLink arrowpag" href="#"><i class="now-ui-icons arrows-1_minimal-left"></i></a>`);

    // Determine the start and end of the page range
    let startPage = Math.max(1, curPage - 4);
    let endPage = Math.min(sizePage, startPage + 8);

    // Expand the range if fewer than 9 pages are shown
    while ((endPage - startPage) < 8 && (startPage > 1 || endPage < sizePage)) {
      if (startPage > 1) {
        startPage--;
      }
      if (endPage < sizePage) {
        endPage++;
      }
    }

    // Display the page links within the range
    for (let i = startPage; i <= endPage; i++) {
      if (i == curPage) {
        paginContent = paginContent.concat(`<a href="#" data-perpage="${defPaginPerpage}" data-page="${i}" class="pagLink active">${i}</a>`);
      } else {
        paginContent = paginContent.concat(`<a href="#" data-perpage="${defPaginPerpage}" data-page="${i}" class="pagLink">${i}</a>`);
      }
    }

    let haveless = "";

    // Add "..." at the end if there are more pages
    if (endPage < sizePage) {
      haveless = "<a style='background: white;color: #416D86!important;'> ... </a>";
    }

    paginContent = paginContent.concat(`${haveless}<a data-perpage="${defPaginPerpage}" data-page="${nextPage}" class="nextPag arrowpag pagLink" href="#"><i class="now-ui-icons arrows-1_minimal-right"></i></a>`);
  }

  setTimeout(function () {
    if (curPage == 1) {
      $(".prevPag").hide();
    } else {
      $(".prevPag").show();
    }

    if (curPage == sizePage) {
      $(".nextPag").hide();
    } else {
      $(".nextPag").show();
    }
  }, toPopulatePage);

  $(".pagination").html(paginContent);
}

function showTable(datas) {

  let segments = window.location.pathname.split('/');
  let section = segments[4];

  let currentUrl = window.location.origin + "/coordinator/report/standard-report/host-family-note/";

  let tableContent = "";
  $("#tbody").empty();

  if (datas.length > 0) {
    for (let i = 0; i < datas.length; i++) {
      tableContent = tableContent.concat(`<tr>
            <td style="padding-left: 15px;padding-right:10px;width:4%;fontStyle: normal;fontWeight: 400;fontSize: 14px,lineHeight: 20px" className="td-noborder"><input type="checkbox" name="selectorRow" class="selectorRow"></td>
            <td className="td-noborder">${datas[i].submittersname}</td>
            <td className="td-noborder">${datas[i].reason}</td>
            <td className="td-noborder">${datas[i].studentname}</td>
            <td className="td-noborder">${datas[i].notes}</td>
            <td style="width:17%" className="td-noborder"><a style="color:#F9B933;fontStyle:normal;fontWeight: 400;fontSize: 12px;lineHeight: 16px" href=${currentUrl + datas[i].id + "?id=" + datas[i].id}><i style="position: relative;top:8px" class="now-ui-icons cs-ov-edit-pen"></i>Views Detail</a></td>
          </tr>`);
    }
    $(".containerPagination").show();
  } else {
    tableContent = tableContent.concat(`<tr><td></td><td>Not found</td><td></td><td></td></tr>`);
    $(".containerPagination").hide();
  }

  $("#tbody").html(tableContent);

}

function downloadCSVFile(csv_data) {
  let CSVFile = new Blob([csv_data], { type: "text/csv" });
  var temp_link = document.createElement('a');
  let timestamp = new Date();
  temp_link.download = timestamp.toISOString() + ".csv";
  var url = window.URL.createObjectURL(CSVFile);
  temp_link.href = url;
  temp_link.style.display = "none";
  document.body.appendChild(temp_link);
  temp_link.click();
  document.body.removeChild(temp_link);
}

function tableToCSV() {
    var csv_data = [];

    var rows = $("#table > thead > tr");
    for (var i = 0; i < rows.length; i++) {
        var cols = rows[i].querySelectorAll('td,th');
        var csvrow = [];
        for (var j = 1; j < cols.length - 1; j++) {
          if (j!==cols.length-1) {
            if (!$(cols[j]).is(":hidden")){
              csvrow.push(escapeCSVValue(cols[j].innerHTML));
            }
          }
        }
        csv_data.push(csvrow.join(","));
    }

    var rows = $("#table > tbody > tr");
    for (var i = 0; i < rows.length; i++) {
      if ($(rows[i]).find('.selectorRow').is(':checked')) {
        var cols = rows[i].querySelectorAll('td,th');
        var csvrow = [];
        for (var j = 1; j < cols.length - 1; j++) {
          if (j!==cols.length-1) {
            if (!$(cols[j]).is(":hidden")){
              csvrow.push(escapeCSVValue(cols[j].innerHTML));
            }
          }
        }
        csv_data.push(csvrow.join(","));
      }
    }
    csv_data = csv_data.join('\n');
    downloadCSVFile(csv_data);
}

function escapeCSVValue(value) {
    // If the value contains a comma or new line, enclose it in double quotes and escape existing double quotes
    if (value.includes(',') || value.includes('\n')) {
        return '"' + value.replace(/"/g, '""') + '"';
    } else {
        return value;
    }
}

function tableToPdf() {
  const doc = new jsPDF();
  let fillHeads = [];
  let fillBodys = [];

  let theadel = $("table#table > thead > tr > th");

  theadel.each(function (index, el) {
    if (index == 0 || index == theadel.length - 1 || $(el).is(":hidden")) {

    } else {
      fillHeads.push($(el).text());
    }
  });

  let tbodyel = $("table#table > tbody > tr");
  let tds = [];

  tbodyel.each(function (index, el) {
    if ($(el).find('.selectorRow').is(':checked')) {
      let tds = [];
      let tdParents = $(el).find("td");
      tdParents.each(function (id, vd) {
        if (id == 0 || id == tdParents.length - 1 || $(vd).is(":hidden")) {

        } else {
          tds.push($(vd).text());
        }
      })
      fillBodys.push(tds)
    }
  });

  doc.autoTable({
    head: [fillHeads],
    body: fillBodys
  })
  let timestamp = new Date();
  doc.save(timestamp.toISOString() + ".pdf");
}

function replaceNullWithEmptyString(value) {
  if (value == null) {
    return '-';
  } else {
    if (value.length>0) {
      return value.replaceAll(",","");
    } else {
      return value;
    }
  }
}

function tableToCSVAll() {
    let user_login = JSON.parse(localStorage.getItem('user_login'));
    let urlTarget = base_api_url + "/api/v1/export/coord/notehostfamilylist/csvall?notescreen=mhostfamilyLogs&role=coord&userId="+user_login.reference_id;
    axios.get(urlTarget).then(res => {
      let datas = res.data.response;

      var csv_data = [];

      var rows = datas[0];
      var csvrow = [];
      for (let key in rows) {
        csvrow.push(key.replaceAll("_"," "));
      }

      csv_data.push(csvrow.join(","));

      var rows = datas;
      for (var i = 0; i < rows.length; i++) {
        var cols = rows[i];
        var csvrow = [];
        for (let key in cols) {
          let valCs = replaceNullWithEmptyString(cols[key]);
          csvrow.push(valCs);
        }
        csv_data.push(csvrow.join(","));  
      }

      csv_data = csv_data.join('\n');
      downloadCSVFile(csv_data);
    })

}

class StandardReportListHfNote extends React.Component {

  state = {
    datas: [],
    page: defPaginPage,
    perpage: defPaginPerpage,
    url: "",
    totalPage: 0
  }

  componentDidMount() {
    overrideDtColor();
    datepickerFactory($);
    $('#startDate').datepicker({ dateFormat: "dd/mm/yyyy" });
    $('#endDate').datepicker({ dateFormat: "dd/mm/yyyy" });
    $("#startDate").val(default_start_date);
    $('#endDate').val(default_end_date);

    getData(defPaginPage, defPaginPerpage);

    $(document).on("click", ".pagination a.pagLink", function (e) {
      e.preventDefault();
      let page = $(this).data('page');
      let perpage = $(this).data('perpage')
      getData(Number(page), Number(perpage));
    });

    $(document).on("click", "#submitSearch", function (e) {
      e.preventDefault();
      let page = defPaginPage;
      let perpage = defPaginPerpage;
      let searchKey = $("#dtSearchCs").val();
      getData(Number(page), Number(perpage), searchKey);
    });

    $(document).on("change", ".selectorRowAll", function () {
      if (this.checked) {
        $(".selectorRow").prop("checked", true);
      } else {
        $(".selectorRow").prop("checked", false);
      }
      let total = $("tbody tr").length;
      let checked = $(".selectorRow:checked").length;
      $("#rowSelectedLabel").attr("placeholder", checked + " of " + total + " selected");
    });

    $(document).on("change", ".selectorRow", function () {
      let total = $("tbody tr").length;
      let checked = $(".selectorRow:checked").length;
      $("#rowSelectedLabel").attr("placeholder", checked + " of " + total + " selected");
    });

    $(document).on("click", ".btnExportPdf", function () {
      tableToPdf();
    });

    $(document).on("click", ".btnExportCsv", function () {
      tableToCSV();
    });

    $(document).on("click",".btnExportCsvAll",function(){
      tableToCSVAll();
    });

  }

  render() {
    return (
      <>
        <PanelHeader size="sm"
          content={
            <div className="content">
              <div className="header text-left">
                <span className="panelHeaderDesc">View and manage host family details.</span>
                <nav aria-label="breadcrumb" role="navigation">
                  <ol class="breadcrumb">
                    <li class="breadcrumb-item"><a href="/coordinator/overview">Dashboard</a></li>
                    <li class="breadcrumb-item"><a href="/coordinator/report/standard-report">Standard Reports</a></li>
                    <li class="breadcrumb-item active" aria-current="page">Host Family Notes</li>
                  </ol>
                </nav>
              </div>
            </div>
          }
        />
        <div className="content">
          <Row>
            <Col xs={12} md={12}>
              <Card>
                <CardBody>
                  <div className="table-responsive">
                    <TableFilter/>
                    <Row>
                      <Col md={12}>
                        <div class="form-group pull-right">
                          <span style={{"marginRight":"10px"}}><i className="now-ui-icons arrows-1_share-66"></i> Export To</span>
                          <button className="btnExport btnExportCsv"><i className="now-ui-icons files_single-copy-04"></i> CSV</button>
                          <button className="btnExport btnExportPdf"><i className="now-ui-icons files_single-copy-04"></i> PDF</button>
                          <button className="btnExport btnExportCsvAll"><i className="now-ui-icons files_single-copy-04"></i> All CSV</button>
                        </div>
                      </Col>
                    </Row>
                    <hr/>
                    <div className="containerTable">
                      <table id="table" style={{ width: "100%" }} className="align-items-center justify-content-center mb-0">
                        <thead>
                          <tr>
                            <th style={{ paddingLeft: "15px", borderTopLeftRadius: "30px", borderBottomLeftRadius: "30px" }}><input type="checkbox" name="selectorRow" className="selectorRowAll" /></th>
                            <th>Family Name</th>
                            <th>Reason</th>
                            <th>Student Name</th>
                            <th>Note</th>
                            <th style={{ borderTopRightRadius: "30px", borderBottomRightRadius: "30px" }}></th>
                          </tr>
                        </thead>
                        <tbody id="tbody" className="tbodycs">
                        </tbody>
                      </table>
                    </div>
                    <div className="containerPagination" style={{ width: "100%" }} align="center">
                      <br />
                      <div className="pagination">
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </>
    )
  }
}

export default StandardReportListHfNote;