import React from "react";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Label,
  FormGroup,
  Form,
  Input,
  FormText,
  Row,
  Col,
  Table,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu
} from "reactstrap";

// core components
import PanelHeader from "components/PanelHeader/PanelHeader.js";
import Switch from "react-bootstrap-switch";
import profileImg from "assets/upload/profile.png";
import ImageUpload from "components/CustomUpload/ImageUpload.js";
import axios from "axios";
import {base_api_url, file_api_url} from "variables/general.js";
import $ from "jquery";
import swal from 'sweetalert';

function uploadFile() {
  var bodyFormData = new FormData();
  let imageFile = $("#photo")[0].files[0];
  let user_login = JSON.parse(localStorage.getItem('user_login'));

  bodyFormData.append('file', imageFile);
  bodyFormData.append('username', user_login.email);

  axios({
    method: "post",
    url: file_api_url + "/api/v1/user/upload/file",
    data: bodyFormData,
    headers: { "Content-Type": "multipart/form-data" },
  }).then(function (response) {
    $("#n_photourl").val(response.data[0].path);
    submitData();
  }).catch(function (response) {
    swal("Failed!", "Failed upload file!", "error");
  });
}

function getDataAllUser() {
  let selEl = "#rc_id";
  let user_login = JSON.parse(localStorage.getItem('user_login'));

  return axios.get(base_api_url + '/api/v1/school/getSchoolsByCoordId?coordId=' + user_login.reference_id).then(res => {
    let datas = res.data.response;

    $(selEl).empty();
    $(selEl).append("<option value=''>Select</option>");
    for (var i=0;i<datas.length;i++) {
      $(selEl).append("<option data-role='school' data-id="+datas[i].id+" data-email="+datas[i].emailaddress+" data-username='"+datas[i].name+"'>"+datas[i].name+"</option>");
    }

  })

}

function submitData() {
  let user_login = JSON.parse(localStorage.getItem('user_login'));

  let urlTarget = base_api_url + "/api/v1/nc";
  var date = new Date();
  var timestamp = date.toISOString();

  let requestData = {
    isactive: true,
    isread: false,
    fkschoolid:$("#rc_id").find(':selected').data('id'),
    notes: $("#message").val(),
    notescreen: "coordSchoolLogs",
    reason: $("#reason").val(),
    receiversfkuserid: $("#rc_id").find(':selected').data('id'),
    receiversfkuserrole: $("#rc_id").find(':selected').data('role'),
    receiversname: $("#rc_id").find(':selected').data('username'),
    submittersfkuserid: user_login.reference_id,
    submittersfkuserrole: user_login.role,
    submittersname: user_login.name,
    uploadedfile:$("#n_photourl").val(),
    timestamp: timestamp
  }

  return axios.post(urlTarget, requestData).then(res => {
    swal("Success","Successfully created data!","success");
    setTimeout(function(){
      window.location.href = window.location.origin + "/coordinator/logsschool";
    },500);
  }).catch(function (error) {
    swal("Failed!", "Something went wrong!", "error");
  });
}

class NotificationNew extends React.Component {
  componentDidMount(){
    getDataAllUser();
    $(document).on("click",".btn-csconfirm",function(e){
      e.preventDefault();
      $(".errorCs").remove();
      
      let notValid = 0;
      let msgval = "";
      let cv;

      $("#contentValidation").empty();

        $.each($('form input'), function(index, formField) {
          if ($(formField).val()==="") {
            cv = $(formField).parent().find("label").text();
            if (cv!=="" && $(formField).attr("type")!=="file" && $(formField).attr("type")!=="hidden") {
              msgval = cv + " is required! <br/>";
              $(formField).parent().append("<span class='errorCs'>" + msgval + "</span>");
              notValid += 1;
            }
          }
        });

        $.each($('form select'), function(index, formField) {
          if ($(formField).val()==="") {
            cv = $(formField).parent().find("label").text();
            if (cv!=="" && $(formField).attr("type")!=="file" && $(formField).attr("type")!=="hidden") {
              msgval = cv + " is required! <br/>";
              $(formField).parent().append("<span class='errorCs'>" + msgval + "</span>");
              notValid += 1;
            }
          }
        });

        if (notValid>0) {
          //
        } else {
          if ($("#photo").val()==="") {
            submitData();
          } else {
            uploadFile();
          }
        }

    });

    $(document).on("click",".close",function(){
      $(this).parent().parent().hide();
    });

    $('#photo').change(function(){
      const file = this.files[0];
      if (file){
        $(".errorCs").remove();
        if (file.type=="image/jpeg" || file.type=="image/png") {
          let reader = new FileReader();
          reader.onload = function(event){
            $('#imgPreview').attr('src', event.target.result);
          }
          reader.readAsDataURL(file);
        } else {
          $('#photo').val("");
          $("#imgPreview").attr("src","");
          $('#photo').parent().parent().append("<span class='errorCs'>The image must be a file of type: jpg, jpeg, png.</span>");
        }
      }
    });

  }

  render(){
    const currentDate = new Date();
    return (
    <>
      <PanelHeader size="sm"
        content={
          <div className="content">
            <div className="header text-left">
              <span className="panelHeaderDesc">View and manage your School Logs.</span>
              <nav aria-label="breadcrumb" role="navigation">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item"><a href="/coordinator/overview">Dashboard</a></li>
                  <li className="breadcrumb-item"><a href="/coordinator/logsschool">School Logs</a></li>
                  <li className="breadcrumb-item active" aria-current="page">New School Logs</li>
                </ol>
              </nav>
            </div>
          </div>
        }
      />
      <br/>
      <div className="content">
        <Row>
          <Col md="12">
            <Card className="card-chart">
              <CardHeader>
                <CardTitle tag="h4" style={{"fontFamily": "Noto Sans","fontStyle": "normal","fontWeight": 400,"fontSize": "16px","lineHeight": "24px","color": "#70B2D8"}}>{currentDate.toUTCString()}</CardTitle>
                <a style={{float:"right", height:"30px"}} className="now-ui-icons cs-cancel_yellow" href="/coordinator/logsschool" />
                <br/>
              </CardHeader>
              <CardBody>
                <Input type="hidden" id="n_photourl"/>
                <Form action="/" className="form-horizontal" method="get">
                  <hr/>
                
                  <Row>
                    <Col sm="12">
                      <Row className="text-desc-notif">
                        <Col md="12">
                          <FormGroup>
                            <div className="form-floating">
                              <select className="form-control" id="rc_id" style={{"borderRadius": "5px"}} placeholder="Country" type="text" />
                              <label htmlFor="pwd">School</label>
                            </div>
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row className="text-desc-notif">
                        <Col md="12">
                          <FormGroup>
                            <div className="form-floating">
                              <Input id="reason" style={{"borderRadius": "5px"}} placeholder="Country" type="text" />
                              <label htmlFor="pwd">Reason</label>
                            </div>
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row className="text-desc-notif">
                        <Col md="12">
                          <FormGroup>
                            <div className="form-floating">
                              <textarea id="message" rows="8" className="form-control" style={{height:"auto",borderRadius: "5px", border: "1px solid #e3e3e3"}} placeholder="text"></textarea>
                              <label htmlFor="pwd">Message</label>
                            </div>
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row className="text-desc-notif">
                        <Col md="12">
                          <FormGroup>
                            <label style={{cursor:"pointer"}}><small>Upload document</small> <i className="now-ui-icons arrows-1_cloud-upload-94"></i><input className="form-control" type="file" id="photo"/></label>
                            <br/>
                            <img width="auto" style={{maxHeight:"81px"}} id="imgPreview"/>
                          </FormGroup>
                        </Col>
                      </Row>
                    </Col>
                  </Row>

                  <Row>
                    <Col sm="12">
                      <div className="pull-left">
                      	<button className="btn-csconfirm">Submit Log</button>
                      </div>
                    </Col>
                  </Row>
                  <br/>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
    
      </div>
    </>
  );
  }
}

export default NotificationNew;
