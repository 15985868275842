import Setting from "views/Pages/HostFamily/Setting/Setting.js";

let routes_hostfamily = [
  {
    path: "/user",
    name: "logo",
    icon: "now-ui-icons cs-menu",
    component: Setting,
    layout: "/hostfamily"
  },
  {
    path: "/setting",
    name: "Settings",
    icon: "now-ui-icons cs-setting",
    component: Setting,
    layout: "/hostfamily"
  }
];

export default routes_hostfamily;
