import React from "react";

// reactstrap components
import {
  Card,
  CardBody,
  Table,
  Row,
  Col
} from "reactstrap";

// core components
import PanelHeader from "components/PanelHeader/PanelHeader.js";

function StandardReport() {
  return (
    <>
      <PanelHeader size="sm"
        content={
          <div className="content">
            <div className="header text-left">
              <span className="panelHeaderDesc">View and manage the Standard Reports.</span>
              <nav aria-label="breadcrumb" role="navigation">
                <ol class="breadcrumb">
                  <li class="breadcrumb-item"><a href="/agent/overview">Dashboard</a></li>
                  <li class="breadcrumb-item active" aria-current="page">Standard Reports</li>
                </ol>
              </nav>
            </div>
          </div>
        }
      />
      <div className="content">
        <Row>
          <Col xs={12} md={4}>
            <Card className="card-chart" style={{width: "329px",borderRadius: "20px"}}>
              <div className="containerThumbnail">
                <h4 className="ml-2 liveReportTitle">Travel Permission Form</h4>
                <p class="ml-2 liveReportDesc">Submitted by Student</p>
                <p><a href="/agent/report/standard-report/travel-permission-form" className="btn btn-md btn-livereport-detail">View details</a></p>
              </div>
            </Card>
          </Col>
          <Col xs={12} md={4}>
            <Card className="card-chart" style={{width: "329px",borderRadius: "20px"}}>
              <div className="containerThumbnail">
                <h4 class="ml-2 liveReportTitle">Activity Permission Form</h4>
                <p className="ml-2 liveReportDesc">Submitted by Student</p>
                <p><a href="/agent/report/standard-report/activity-permission-form" className="btn btn-md btn-livereport-detail">View details</a></p>
              </div>
            </Card>
          </Col>
          <Col xs={12} md={4}>
            <Card className="card-chart" style={{width: "329px",borderRadius: "20px"}}>
              <div className="containerThumbnail">
                <h4 class="ml-2 liveReportTitle">Student Note</h4>
                <p className="ml-2 liveReportDesc">Submitted by Student</p>
                <p><a href="/agent/report/standard-report/student-note" className="btn btn-md btn-livereport-detail">View details</a></p>
              </div>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default StandardReport;
