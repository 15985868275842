import AgentOverview from "views/Pages/Agent/Overview.js";
import AgentStandardReport from "views/Pages/Agent/StandardReport/StandardReport.js";
import AgentStandardListReportTpf from "views/Pages/Agent/StandardReport/StandardReportListTpf.js";
import AgentStandardDetailReportTpf from "views/Pages/Agent/StandardReport/StandardReportDetailTpf.js";
import AgentStandardListReportApf from "views/Pages/Agent/StandardReport/StandardReportListApf.js";
import AgentStandardDetailReportApf from "views/Pages/Agent/StandardReport/StandardReportDetailApf.js";
import StandardReportListStudentNote from "views/Pages/Agent/StandardReport/StandardReportListStudentNote.js";
import StandardReportDetailStudentNote from "views/Pages/Agent/StandardReport/StandardReportDetailStudentNote.js";

import AgentStudent from "views/Pages/Agent/Student/Student.js";
import AgentStudentDetail from "views/Pages/Agent/Student/StudentDetail.js";
import AgentStudentRegistration from "views/Pages/Agent/Student/StudentRegistration.js";

import Setting from "views/Pages/Agent/Setting/Setting.js";

import Notification from "views/Pages/Agent/Notification/Notification.js";
import NotificationDetail from "views/Pages/Agent/Notification/NotificationDetail.js";

import Notes from "views/Pages/Agent/Notes/Notes.js";
import NotesDetail from "views/Pages/Agent/Notes/NotesDetail.js";

import AgentRequest from "views/Pages/Agent/Request/Request.js";
import AgentRequestTpfDetail from "views/Pages/Agent/Request/Tpf/TpfDetail.js";
import AgentRequestApfDetail from "views/Pages/Agent/Request/Apf/ApfDetail.js";
import AgentRequestPhotograpicDetail from "views/Pages/Agent/Request/Photograpic/PhotograpicDetail.js";

import SchoolDetail from "views/Pages/Agent/School/SchoolDetail.js";
import HostFamilyDetail from "views/Pages/Agent/HostFamily/HostFamilyDetail.js";

import Profile from "views/Pages/Agent/Profile.js";

let routes_agent = [
  {
    path: "/user",
    name: "logo",
    icon: "now-ui-icons cs-menu",
    component: AgentOverview,
    layout: "/agent"
  },
  {
    path: "/overview",
    name: "Overview",
    icon: "now-ui-icons cs-dashboard",
    component: AgentOverview,
    layout: "/agent"
  },
  {
    path: "/report/standard-report/travel-permission-form/:id",
    name: "Travel Permission Form Detail",
    icon: "now-ui-icons cs-report",
    component: AgentStandardDetailReportTpf,
    layout: "/agent",
    exludeSidebar:true
  },
  {
    path: "/report/standard-report/student-note/:id",
    name: "Student Note Detail",
    icon: "now-ui-icons cs-report",
    component: StandardReportDetailStudentNote,
    layout: "/agent",
    exludeSidebar:true
  },
  {
    path: "/report/standard-report/activity-permission-form/:id",
    name: "Activity Permission Form Detail",
    icon: "now-ui-icons cs-report",
    component: AgentStandardDetailReportApf,
    layout: "/agent",
    exludeSidebar:true
  },
  {
    path: "/report/standard-report/activity-permission-form",
    name: "Activity Permission Form",
    icon: "now-ui-icons cs-report",
    component: AgentStandardListReportApf,
    layout: "/agent",
    exludeSidebar:true
  },
  {
    path: "/report/standard-report/student-note",
    name: "Student Notes",
    icon: "now-ui-icons cs-report",
    component: StandardReportListStudentNote,
    layout: "/agent",
    exludeSidebar:true
  },
  {
    path: "/report/standard-report/travel-permission-form",
    name: "Travel Permission Form",
    icon: "now-ui-icons cs-report",
    component: AgentStandardListReportTpf,
    layout: "/agent",
    exludeSidebar:true
  },
  {
    path: "/report/standard-report",
    name: "Reporting",
    icon: "now-ui-icons cs-report",
    component: AgentStandardReport,
    layout: "/agent",
  },
  {
    path: "/student/new/registration",
    name: "Student Registration",
    icon: "now-ui-icons cs-student",
    component: AgentStudentRegistration,
    layout: "/agent",
    exludeSidebar:true
  },
  {
    path: "/student/:id",
    name: "Student Detail",
    icon: "now-ui-icons cs-student",
    component: AgentStudentDetail,
    layout: "/agent",
    exludeSidebar:true
  },
  {
    path: "/student",
    name: "Student",
    icon: "now-ui-icons cs-student",
    component: AgentStudent,
    layout: "/agent"
  },
  {
    path: "/requests/activity-permission-form/:id",
    name: "Requests",
    icon: "now-ui-icons cs-submission",
    component: AgentRequestApfDetail,
    layout: "/agent",
    exludeSidebar:true
  },
  {
    path: "/requests/travel-permission-form/:id",
    name: "Requests",
    icon: "now-ui-icons cs-submission",
    component: AgentRequestTpfDetail,
    layout: "/agent",
    exludeSidebar:true
  },
  {
    path: "/requests/photograpic/:id",
    name: "Requests",
    icon: "now-ui-icons cs-submission",
    component: AgentRequestPhotograpicDetail,
    layout: "/agent",
    exludeSidebar:true
  },
  {
    path: "/requests",
    name: "Requests",
    icon: "now-ui-icons cs-report",
    component: AgentRequest,
    layout: "/agent"
  },
  {
    path: "/hf/:id",
    name: "Host Family Detail",
    icon: "now-ui-icons cs-student",
    component: HostFamilyDetail,
    layout: "/agent",
    exludeSidebar:true
  },
  {
    path: "/school/:id",
    name: "School Detail",
    icon: "now-ui-icons cs-student",
    component: SchoolDetail,
    layout: "/agent",
    exludeSidebar:true
  },
  // {
  //   path: "/notes/:id",
  //   name: "Notes",
  //   icon: "now-ui-icons cs-student",
  //   component: NotesDetail,
  //   layout: "/agent",
  //   exludeSidebar:true
  // },
  // {
  //   path: "/notes",
  //   name: "Notes",
  //   icon: "now-ui-icons cs-notes",
  //   component: Notes,
  //   layout: "/agent",
  // },
  {
    path: "/notification/:id",
    name: "Notifications.",
    icon: "now-ui-icons cs-student",
    component: NotificationDetail,
    layout: "/agent",
    exludeSidebar:true
  },
  {
    path: "/notifications",
    name: "Notifications",
    icon: "now-ui-icons cs-agent",
    component: Notification,
    layout: "/agent",
  },
  {
    path: "/setting",
    name: "Settings",
    icon: "now-ui-icons cs-setting",
    component: Setting,
    layout: "/agent"
  },
  {
    path: "/myaccount/detail/get",
    name: "My Account",
    icon: "now-ui-icons cs-student",
    component: Profile,
    layout: "/agent",
    exludeSidebar:true
  }
];

export default routes_agent;
