import React from "react";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Label,
  FormGroup,
  Form,
  Input,
  FormText,
  Row,
  Col,
  Table
} from "reactstrap";

// core components
import PanelHeader from "components/PanelHeader/PanelHeader.js";
import Switch from "react-bootstrap-switch";
import profileImg from "assets/upload/coordinator_profile.png";
import ImageUpload from "components/CustomUpload/ImageUpload.js";

function CoordinatorDetail() {
  return (
    <>
      <PanelHeader size="sm"
        content={
          <div className="content">
            <div className="header text-left">
              <span className="panelHeaderDesc">View and manage the Coordinator Details.</span>
              <nav aria-label="breadcrumb" role="navigation">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item"><a href="#">Dashboard</a></li>
                  <li className="breadcrumb-item"><a href="#">Coordinators</a></li>
                  <li className="breadcrumb-item active" aria-current="page">Ryan Gosling</li>
                </ol>
              </nav>
            </div>
          </div>
        }
      />
      <br/>
      <div className="content">
        <Row>
          <Col md="3">
            <Card className="card-chart">
              <CardBody>
                <Table responsive>
                  <tbody>
                    <tr>
                      <td className="td-noborder">
                        <a href="#c_spil_details" className="anchorItemDetail">SPIL Details</a>
                        <a href="#c_coordinator" className="anchorItemDetail">Coordinator Details</a>
                        <a href="#c_submissions" className="anchorItemDetail">Submissions</a>
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </CardBody>
            </Card>
          </Col>
          <Col md="9">
            <Card>
              <CardHeader className="card-header-profile">
                <Row>
                  <Col md={2}>
                    <img style={{float: "left",width:"76px",height:"76px",position:"relative",bottom:"18px"}} src={profileImg}></img>
                  </Col>
                  <Col md={8}>
                    <p className="profile-title">Ryan Gosling</p>
                    <p className="profile-desc">C00123456</p>
                  </Col>
                  <Col md={2}>
                    <Switch defaultValue={false} />
                  </Col>
                </Row>
              </CardHeader>
            </Card>
            <Card id="c_spil_details">
              <CardHeader>
                <CardTitle tag="h4" className="panelTitle">SPIL Details</CardTitle>
              </CardHeader>
              <CardBody>
                <Form action="/" className="form-horizontal" method="get">
                  <Row>
                    <Col sm="12">
                      <FormGroup>
                        <h4 style={{"fontFamily": "Noto Sans","fontStyle": "normal","fontWeight": 400,"fontSize": "16px","lineHeight": "24px","color": "#70B2D8"}}>Coordinator</h4>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm="12">
                      <Row>
                        <Col md="12">
                          <FormGroup>
                            <div className="form-floating">
                              <Input style={{"borderRadius": "5px"}} placeholder="ID" type="text" />
                              <label htmlFor="pwd">ID</label>
                            </div>
                          </FormGroup>
                        </Col>
                      </Row>
                    </Col>
                  </Row>

                  <Row>
                    <Col sm="12">
                      <FormGroup>
                        <h4 style={{"fontFamily": "Noto Sans","fontStyle": "normal","fontWeight": 400,"fontSize": "16px","lineHeight": "24px","color": "#70B2D8"}}>School</h4>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm="12">
                      <Row>
                        <Col md="6">
                          <FormGroup>
                            <div className="form-floating">
                              <Input style={{"borderRadius": "5px"}} placeholder="Name" type="text" />
                              <label htmlFor="pwd">Name</label>
                            </div>
                          </FormGroup>
                        </Col>
                        <Col md="6">
                          <FormGroup>
                            <div className="form-floating">
                              <Input style={{"borderRadius": "5px"}} placeholder="Country" type="text" />
                              <label htmlFor="pwd">Country</label>
                            </div>
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col md="6">
                          <FormGroup>
                            <div className="form-floating">
                              <Input style={{"borderRadius": "5px"}} placeholder="Town" type="text" />
                              <label htmlFor="pwd">Town</label>
                            </div>
                          </FormGroup>
                        </Col>
                        <Col md="6">
                          <FormGroup>
                            <div className="form-floating">
                              <Input style={{"borderRadius": "5px"}} placeholder="Type" type="text" />
                              <label htmlFor="pwd">Type</label>
                            </div>
                          </FormGroup>
                        </Col>
                      </Row>
                    </Col>
                  </Row>

                  <Row>
                    <Col sm="12">
                      <FormGroup>
                        <h4 style={{"fontFamily": "Noto Sans","fontStyle": "normal","fontWeight": 400,"fontSize": "16px","lineHeight": "24px","color": "#70B2D8"}}>Host Family</h4>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm="12">
                      <Row>
                        <Col md="6">
                          <FormGroup>
                            <div className="form-floating">
                              <Input style={{"borderRadius": "5px"}} placeholder="ID" type="text" />
                              <label htmlFor="pwd">Family Name</label>
                            </div>
                          </FormGroup>
                        </Col>
                        <Col md="6">
                          <FormGroup>
                            <div className="form-floating">
                              <Input style={{"borderRadius": "5px"}} placeholder="ID" type="text" />
                              <label htmlFor="pwd">Host Family Child</label>
                            </div>
                          </FormGroup>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm="12">
                      <Row>
                        <Col md="6">
                          <FormGroup>
                            <div className="form-floating">
                              <Input style={{"borderRadius": "5px"}} placeholder="ID" type="text" />
                              <label htmlFor="pwd">Mother Name</label>
                            </div>
                          </FormGroup>
                        </Col>
                        <Col md="6">
                          <FormGroup>
                            <div className="form-floating">
                              <Input style={{"borderRadius": "5px"}} placeholder="ID" type="text" />
                              <label htmlFor="pwd">Mother Smoker</label>
                            </div>
                          </FormGroup>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm="12">
                      <Row>
                        <Col md="6">
                          <FormGroup>
                            <div className="form-floating">
                              <Input style={{"borderRadius": "5px"}} placeholder="ID" type="text" />
                              <label htmlFor="pwd">Father Name</label>
                            </div>
                          </FormGroup>
                        </Col>
                        <Col md="6">
                          <FormGroup>
                            <div className="form-floating">
                              <Input style={{"borderRadius": "5px"}} placeholder="ID" type="text" />
                              <label htmlFor="pwd">Father Smoker</label>
                            </div>
                          </FormGroup>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm="12">
                      <Row>
                        <Col md="6">
                          <FormGroup>
                            <div className="form-floating">
                              <Input style={{"borderRadius": "5px"}} placeholder="ID" type="text" />
                              <label htmlFor="pwd">Telephone Home</label>
                            </div>
                          </FormGroup>
                        </Col>
                        <Col md="6">
                          <FormGroup>
                            <div className="form-floating">
                              <Input style={{"borderRadius": "5px"}} placeholder="ID" type="text" />
                              <label htmlFor="pwd">Telephone Mobile</label>
                            </div>
                          </FormGroup>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm="12">
                      <Row>
                        <Col md="6">
                          <FormGroup>
                            <div className="form-floating">
                              <Input style={{"borderRadius": "5px"}} placeholder="ID" type="text" />
                              <label htmlFor="pwd">Date Visited</label>
                            </div>
                          </FormGroup>
                        </Col>
                        <Col md="6">
                          <FormGroup>
                            <div className="form-floating">
                              <Input style={{"borderRadius": "5px"}} placeholder="ID" type="text" />
                              <label htmlFor="pwd">Address</label>
                            </div>
                          </FormGroup>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
    
                  <Row>
                    <Col sm="12">
                      <FormGroup>
                        <h4 style={{"fontFamily": "Noto Sans","fontStyle": "normal","fontWeight": 400,"fontSize": "16px","lineHeight": "24px","color": "#70B2D8"}}>Agent</h4>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm="12">
                      <Row>
                        <Col md="12">
                          <FormGroup>
                            <div className="form-floating">
                              <Input style={{"borderRadius": "5px"}} placeholder="ID" type="text" />
                              <label htmlFor="pwd">ID</label>
                            </div>
                          </FormGroup>
                        </Col>
                      </Row>
                    </Col>
                  </Row>

                  <Row>
                    <Col sm="12">
                      <div className="pull-right">
                      	<button className="btn-csconfirm">Update Changes</button>
                      </div>
                    </Col>
                  </Row>
                </Form>
              </CardBody>
            </Card>
            <Card id="c_coordinator">
              <CardHeader>
                <CardTitle tag="h4" className="panelTitle">Coordinator Details</CardTitle>
              </CardHeader>
              <CardBody>
                <Form action="/" className="form-horizontal" method="get">
                  <Row>
                    <Col sm="12">
                      <FormGroup>
                        <h4 style={{"fontFamily": "Noto Sans","fontStyle": "normal","fontWeight": 400,"fontSize": "16px","lineHeight": "24px","color": "#70B2D8"}}>Coordinator</h4>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm="12">
                      <Row>
                        <Col md="6">
                          <FormGroup>
                            <div className="form-floating">
                              <Input style={{"borderRadius": "5px"}} placeholder="ID" type="text" />
                              <label htmlFor="pwd">Name</label>
                            </div>
                          </FormGroup>
                        </Col>
                        <Col md="6">
                          <FormGroup>
                            <div className="form-floating">
                              <Input style={{"borderRadius": "5px"}} placeholder="ID" type="text" />
                              <label htmlFor="pwd">Company</label>
                            </div>
                          </FormGroup>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm="12">
                      <Row>
                        <Col md="6">
                          <FormGroup>
                            <div className="form-floating">
                              <Input style={{"borderRadius": "5px"}} placeholder="ID" type="text" />
                              <label htmlFor="pwd">Email</label>
                            </div>
                          </FormGroup>
                        </Col>
                        <Col md="6">
                          <FormGroup>
                            <div className="form-floating">
                              <Input style={{"borderRadius": "5px"}} placeholder="ID" type="text" />
                              <label htmlFor="pwd">Address</label>
                            </div>
                          </FormGroup>
                        </Col>
                      </Row>
                    </Col>
                  </Row>

                  <Row>
                    <Col sm="12">
                      <Row>
                        <Col md="6">
                          <FormGroup>
                            <div className="form-floating">
                              <Input style={{"borderRadius": "5px"}} placeholder="ID" type="text" />
                              <label htmlFor="pwd">Telephone</label>
                            </div>
                          </FormGroup>
                        </Col>
                        <Col md="6">
                          <FormGroup>
                            <div className="form-floating">
                              <Input style={{"borderRadius": "5px"}} placeholder="ID" type="text" />
                              <label htmlFor="pwd">Mobile</label>
                            </div>
                          </FormGroup>
                        </Col>
                      </Row>
                    </Col>
                  </Row>

                  <Row>
                    <Col sm="12">
                      <Row>
                        <Col md="6">
                          <FormGroup>
                            <div className="form-floating">
                              <Input style={{"borderRadius": "5px"}} placeholder="ID" type="text" />
                              <label htmlFor="pwd">Language</label>
                            </div>
                          </FormGroup>
                        </Col>
                        <Col md="6">
                          <FormGroup>
                            <div className="form-floating">
                              <Input style={{"borderRadius": "5px"}} placeholder="ID" type="text" />
                              <label htmlFor="pwd"></label>
                            </div>
                          </FormGroup>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
     
                
                  <Row>
                    <Col sm="12">
                      <div className="pull-right">
                        <button className="btn-csconfirm">Update Changes</button>
                      </div>
                    </Col>
                  </Row>
                </Form>
              </CardBody>
            </Card>
            <Card id="c_submissions">
              <CardHeader>
                <CardTitle tag="h4" className="panelTitle">Submissions</CardTitle>
              </CardHeader>
              <CardBody>
                <Form action="/" className="form-horizontal" method="get">
                  <Row>
                    <Col sm="12">
                      <FormGroup>
                        <h4 style={{"fontFamily": "Noto Sans","fontStyle": "normal","fontWeight": 400,"fontSize": "16px","lineHeight": "24px","color": "#70B2D8"}}>Coordinator</h4>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm="12">
                      <Row className="bundleBorder">
                        <Col md="2">
                          <FormGroup>
                            <div className="form-floating">             
                              <label htmlFor="pwd">Photos</label>
                            </div>
                          </FormGroup>
                        </Col>
                        <ImageUpload avatar />
                      </Row>
                    </Col>
                  </Row>

                  <Row>
                    <Col sm="12">
                      <FormGroup>
                        <h4 style={{"fontFamily": "Noto Sans","fontStyle": "normal","fontWeight": 400,"fontSize": "16px","lineHeight": "24px","color": "#70B2D8"}}>Logs</h4>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm="12">
                      <Row className="bundleBorder">
                        <Col md="7">
                          <FormGroup>
                            <div className="form-floating">             
                              <label htmlFor="pwd">Trip to Beach</label>
                            </div>
                          </FormGroup>
                        </Col>
                        <Col md="3">
                          <FormGroup>
                            <div className="form-floating">
                              <label htmlFor="pwd">07/06/2022, 06:45PM</label>
                            </div>
                          </FormGroup>
                        </Col>
                        <Col md="2">
                          <FormGroup>
                              <a href="#" style={{"color": "#717171","fontStyle": "normal","fontWeight": 400,"fontSize": "12px", "lineHeight": "16px"}}><i className="now-ui-icons cs-ov-edit-pen-dark" style={{"position": "relative", "top": "8px"}}></i>Views Detail</a>
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row className="bundleBorder">
                        <Col md="7">
                          <FormGroup>
                            <div className="form-floating">             
                              <label htmlFor="pwd">Bank Holiday weekend in Ireland</label>
                            </div>
                          </FormGroup>
                        </Col>
                        <Col md="3">
                          <FormGroup>
                            <div className="form-floating">
                              <label htmlFor="pwd">07/06/2022, 06:45PM</label>
                            </div>
                          </FormGroup>
                        </Col>
                        <Col md="2">
                          <FormGroup>
                              <a href="#" style={{"color": "#717171","fontStyle": "normal","fontWeight": 400,"fontSize": "12px", "lineHeight": "16px"}}><i className="now-ui-icons cs-ov-edit-pen-dark" style={{"position": "relative", "top": "8px"}}></i>Views Detail</a>
                          </FormGroup>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  
                  <Row>
                    <Col sm="12">
                      <FormGroup>
                        <h4 style={{"fontFamily": "Noto Sans","fontStyle": "normal","fontWeight": 400,"fontSize": "16px","lineHeight": "24px","color": "#70B2D8"}}>Garda Vetting</h4>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm="12">
                      <Row className="bundleBorder">
                        <Col md="10">
                          <FormGroup>
                            <div className="form-floating">             
                              <label htmlFor="pwd">Ryan Gosling</label>
                            </div>
                          </FormGroup>
                        </Col>
                        <Col md="2">
                          <div className="form-floating">    
                            <Switch style={{"margin-top":"10px"}}
                              onText="Approved"
                              offText="Declined"
                              defaultValue={false}
                            />
                          </div>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm="12">
                      <Row className="bundleBorder">
                        <Col md="10">
                          <FormGroup>
                            <div className="form-floating">             
                              <label htmlFor="pwd">Approved</label>
                            </div>
                          </FormGroup>
                        </Col>
                        <Col md="2">
                          <div className="form-floating">    
                            <Switch style={{"margin-top":"10px"}}
                              onText="Approved"
                              offText="Declined"
                              defaultValue={false}
                            />
                          </div>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                 
                  <Row>
                    <Col sm="12">
                      <div className="pull-right">
                        <button className="btn-csconfirm">Update Changes</button>
                      </div>
                    </Col>
                  </Row>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default CoordinatorDetail;
