import React from "react";
import { NavLink } from "react-router-dom";
// used for making the prop types of this component
import PropTypes from "prop-types";
// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from "perfect-scrollbar";

// reactstrap components
import { Nav, Collapse, Button } from "reactstrap";

// core components
import avatar from "assets/img/ryan.jpg";
import logo from "logo-white.svg";

var ps;

function SidebarCoordinator(props) {
  const [openAvatar, setOpenAvatar] = React.useState(false);
  const [collapseStates, setCollapseStates] = React.useState({});
  const sidebar = React.useRef();
  React.useEffect(() => {
    // if you are using a Windows Machine, the scrollbars will have a Mac look
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(sidebar.current, {
        suppressScrollX: true,
        suppressScrollY: false
      });
    }
    return function cleanup() {
      // we need to destroy the false scrollbar when we navigate
      // to a page that doesn't have this component rendered
      if (navigator.platform.indexOf("Win") > -1) {
        ps.destroy();
      }
      // to stop the warning of calling setState of unmounted component
      var id = window.setTimeout(null, 0);
      while (id--) {
        window.clearTimeout(id);
      }
    };
  });
  React.useEffect(() => {
    setCollapseStates(getCollapseStates(props.routes));
  }, []);
  // this creates the intial state of this component based on the collapse routes
  // that it gets through props.routes
  const getCollapseStates = (routes) => {
    let initialState = {};
    routes.map((prop, key) => {
      if (prop.collapse) {
        initialState = {
          [prop.state]: getCollapseInitialState(prop.views),
          ...getCollapseStates(prop.views),
          ...initialState
        };
      }
      return null;
    });
    return initialState;
  };
  // this verifies if any of the collapses should be default opened on a rerender of this component
  // for example, on the refresh of the page,
  // while on the src/views/forms/RegularForms.js - route /admin/regular-forms
  const getCollapseInitialState = (routes) => {
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse && getCollapseInitialState(routes[i].views)) {
        return true;
      } else if (window.location.href.indexOf(routes[i].path) !== -1) {
        return true;
      }
    }
    return false;
  };
  // this function creates the links and collapses that appear in the sidebar (left menu)
  const createLinks = (routes) => {
    return routes.map((prop, key) => {
      if (typeof prop.exludeSidebar=="undefined"){
        if (prop.collapse) {
          if (prop.path!=="/pages"){
            var st = {};
            st[prop["state"]] = !collapseStates[prop.state];
            return (
              <li
                className={getCollapseInitialState(prop.views) ? "active" : ""}
                key={key}
              >
                <a
                  href="#pablo"
                  data-toggle="collapse"
                  aria-expanded={collapseStates[prop.state]}
                  onClick={(e) => {
                    e.preventDefault();
                    setCollapseStates(st);
                  }}
                >
                  {prop.icon !== undefined ? (
                    <>
                      <i className={prop.icon + " " + activeRoute(prop.layout + prop.path)} />
                      <p>
                        {prop.name}
                        <b className="caret" />
                      </p>
                    </>
                  ) : (
                    <>
                      <span className="sidebar-mini-icon">{prop.mini}</span>
                      <span className="sidebar-normal">
                        {prop.name}
                        <b className="caret" />
                      </span>
                    </>
                  )}
                </a>
                <Collapse isOpen={collapseStates[prop.state]}>
                  <ul className="nav">{createLinks(prop.views)}</ul>
                </Collapse>
              </li>
            );
          }
        }
        if (prop.layout=="/coordinator"){
          return (
            <li className={activeRoute(prop.layout + prop.path)} key={key}>
              <a href={prop.layout + prop.path}>
                {prop.icon !== undefined ? (
                  <>
                    <i className={prop.icon + " " + activeRoute(prop.layout + prop.path)} />
                    {prop.name=="logo" ? (
                        <>
                          <p><img alt="..." src={require("assets/upload/spil.png")} /></p>
                        </>  
                      ) : (
                        <>
                          <p>{prop.name}</p>
                        </>
                    )}
                  </>
                ) : (
                  <>
                    <span className="sidebar-mini-icon">{prop.mini}</span>
                    <span className="sidebar-normal">{prop.name}</span>
                  </>
                )}
              </a>
            </li>
          );
        }

      }

    });
  };
  // verifies if routeName is the one active (in browser input)
  const activeRoute = (routeName) => {
    return window.location.href.indexOf(routeName) > -1 ? "active" : "";
  };
  return (
    <>
      <div className="sidebar" data-color={props.backgroundColor}>
        <div className="sidebar-wrapper" ref={sidebar}>
          <Nav>{createLinks(props.routes)}</Nav>
        </div>
      </div>
    </>
  );
}

SidebarCoordinator.defaultProps = {
  routes: [],
  showNotification: false,
  backgroundColor: "#416D86",
  minimizeSidebarCoordinator: () => {}
};

SidebarCoordinator.propTypes = {
  // links that are rendered
  routes: PropTypes.arrayOf(PropTypes.object),
  // if you want to show a notification when switching between mini sidebar and normal
  showNotification: PropTypes.bool,
  // background color for the component
  backgroundColor: PropTypes.oneOf([
    "blue",
    "yellow",
    "green",
    "orange",
    "red",
    "#416D86"
  ]),
  // function that is called upon pressing the button near the logo
  minimizeSidebarCoordinator: PropTypes.func
};

export default SidebarCoordinator;