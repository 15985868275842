import React from "react";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Label,
  FormGroup,
  Form,
  Input,
  FormText,
  Row,
  Col,
  Table
} from "reactstrap";

// core components
import PanelHeader from "components/PanelHeader/PanelHeader.js";
import Switch from "react-bootstrap-switch";
import profileImg from "assets/upload/profile.png";
import ImageUpload from "components/CustomUpload/ImageUpload.js";

function NotificationDetail() {
  const currentDate = new Date();
  return (
    <>
      <PanelHeader size="sm"
        content={
          <div className="content">
            <div className="header text-left">
              <span className="panelHeaderDesc">View and manage your Notifications.</span>
              <nav aria-label="breadcrumb" role="navigation">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item"><a href="#">Dashboard</a></li>
                  <li className="breadcrumb-item"><a href="#">Log Notes</a></li>
                  <li className="breadcrumb-item active" aria-current="page">Log 1</li>
                </ol>
              </nav>
            </div>
          </div>
        }
      />
      <br/>
      <div className="content">
        <Row>
          <Col md="12">
            <Card>
              <CardHeader>
                <CardTitle tag="h4" className="text-header-notif">Log 1</CardTitle>
                <h4 style={{"fontFamily": "Noto Sans","fontStyle": "normal","fontWeight": 400,"fontSize": "16px","lineHeight": "24px","color": "#70B2D8"}}>{currentDate.toUTCString()}</h4>
              </CardHeader>
              <CardBody>
                <Form action="/" className="form-horizontal" method="get">
                  <hr/>
                  <Row className="text-header-info-notif">
                    <Col sm="12">
                      <Row>
                        <Col md="2">
                          <FormGroup>
                            <label htmlFor="pwd">Log Status : <small>School Insiden</small></label>
                          </FormGroup>
                        </Col>
                        <Col md="2">
                          <FormGroup>
                            <label htmlFor="pwd">Student : <small>Andy Mooruy</small></label>
                          </FormGroup>
                        </Col>
                        <Col md="8">
                          <FormGroup>
                            <label htmlFor="pwd">School: <small>Our Lady of Lourds</small></label>
                          </FormGroup>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <hr/>
    
                  <Row>
                    <Col sm="12">
                      <Row className="text-desc-notif">
                        <Col md="12">
                          <FormGroup>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquet eget amet quam volutpat faucibus. Viverra suscipit augue mi massa. Lectus facilisis ultrices hendrerit magna porttitor tristique ante. Mauris, dignissim non, ac felis, sem interdum posuere.</p>
                          </FormGroup>
                        </Col>
                      </Row>
                    </Col>
                  </Row>

                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
    
      </div>
    </>
  );
}

export default NotificationDetail;
