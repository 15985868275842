import React from "react";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Label,
  FormGroup,
  Form,
  Input,
  FormText,
  Row,
  Col,
  Table
} from "reactstrap";

// core components
import PanelHeader from "components/PanelHeader/PanelHeader.js";
import Switch from "react-bootstrap-switch";
import profileImg from "assets/upload/default.png";
import ImageUpload from "components/CustomUpload/ImageUpload.js";
import axios from "axios";
import {base_api_url, file_api_url} from "variables/general.js";
import $ from "jquery";
import swal from 'sweetalert';
import datepickerFactory from 'jquery-datepicker';

function getData() {

  const params = new Proxy(new URLSearchParams(window.location.search), {
    get: (searchParams, prop) => searchParams.get(prop),
  });

  let urlTarget = base_api_url + "/api/v1/hf/webgethostfamilybyid?hostFamilyId=" + params.formid; 
  return axios.get(urlTarget).then(res => {
    let datas = res.data.response;
    showData(datas);
  })
}

function showData(data) {
  let school = data.school;
  let coordinator = data.coodinatorDetails;
  let submissions = data.photosSubmitted;
  let gvlist = data.gvlist;
  data = data.hfDetails;
  localStorage.setItem("detailHf" + data.id, JSON.stringify(data));
  $("#attachedStudent").attr("href","/admin/host-family/attached/student?id="+data.id)
  $("#id").val(data.id);
  getMusicList(data.id);
  getPetList(data.id);
  $("#adminapprovalstatus").val(data.adminapprovalstatus)
  $("#t_id").html(data.id);
  $("#t_name").html(data.familyname);
  $("#br_name").html(data.familyname);
  $("#family_name").val(data.familyname);
  $("#mother_name").val(data.mothername);
  $("#mother_occupation").val(data.mothersoccupation);
  $("#mother_smoker").val(String(data.mothersmoker));
  $("#mother_nationality").val(data.mothernationality);
  $("#father_name").val(data.fathername);
  $("#father_occupation").val(data.fatheroccupation);
  $("#father_smoker").val(String(data.fathersmoker));
  $("#father_nationality").val(data.fathernationality);
  $("#telephone_home").val(data.telephonehome);
  $("#mobile").val(data.mobile);
  $("#whatsapp").val(data.whatsapp);
  $("#email").val(data.emailaddress);
  $("#host_family_child").val(data.nohostfamilychild);
  $("#notes").val(data.notes);
  $("#address").val(data.address);
  $("#eircode").val(data.eircode);
  $("#description_of_the_area").val(data.descriptionofthearea);
  $("#distance_to_school").val(data.distancetoschool);
  $("#travel_arrangement").val(data.travelarangements);
  $("#diatry_requirement").val(data.studentwithdietryrequirements);
  $("#available_single_room").val(data.numberofavailablesinglerooms);
  $("#music_instrument").val(String(data.musicalinstruments));
  $("#music_instrument_list").val(data.musicalinstrumentlist);
  $("#pet_list").val(data.petlist);
  $("#pets").val(String(data.pets));

  if (String(data.pets)=="false") {
    $("#pet_list").attr("readonly",true);
  } else {
    $("#pet_list").attr("readonly",false);
  }

  if (String(data.musicalinstruments)=="false") {
    $("#music_instrument_list").attr("readonly",true);
  } else {
    $("#music_instrument_list").attr("readonly",false);
  }

  $("#program_suitable_for").val(data.programmesuitablefor);
  $("#school_children_attend").val(data.schoolschildrenattend);
  $("#programme_rule_status").val(String(data.programmerulesstatus));
  $("#computer_access").val(String(data.accesstocomputer));
  $("#date_visited").val(data.datevisited);
  if (data.photourl) {
    $("#profile").attr("src",data.photourl);
    $("#imgPreview").attr("src",data.photourl);
    $("#f_photourl").val(data.photourl);
  }
  $("#fimgPreview").attr("src",data.familyphotourl);
  $("#f_familyphotourl").val(data.familyphotourl);

  if (data.fkschoolid) {
    getDataSchool(data.fkschoolid);
  } else {
    getDataSchoolOnly();
  }

  if (coordinator) {
    getDataCoordinator(coordinator.id);
  } else {
    getDataCoordinatorOnly();
  }

  if (submissions) {
    for (var is=0;is<submissions.length;is++) {
      let dateS;
      if (submissions[is].timestamp) {
        dateS = new Date(submissions[is].timestamp).toLocaleString();
      }
      $("#submissions_list").append(`
        <div class="bundleBorder row" style="height: 60px;width: 100%;margin-left: -4px;">
          <div class="col-md-3">
            <div class="form-group">
              <div class="form-floating">
                <label for="pwd">${submissions[is].notes}</label>
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <div class="form-floating">
                <label for="pwd">${dateS}</label>
              </div>
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-floating">
              <label>${(submissions[is].adminapproved)}</label>
            </div>
          </div>
          <div class="col-md-2">
            <div class="form-group">
              <a href="#" style="color: rgb(113, 113, 113); font-style: normal; font-weight: 400; font-size: 12px; line-height: 16px;"><i class="now-ui-icons cs-ov-edit-pen-dark" style="position: relative; top: 8px;"></i>Views Detail</a>
              </div>
            </div>
          </div>
      `);
    }
  }

  if (gvlist) {
    for (var iv=0;iv<gvlist.length;iv++) {
      $("#gv_list").append(`
          <div class="row">
            <div class="col-md-10">
              <div class="form-group">
                <div class="form-floating">
                  <input style="border-radius:5px;" class="form-control" value="${gvlist[iv].name}" type="text" placeholder="ID"/> 
                  <label for="pwd">Person</label>
                </div>
              </div>
            </div>
            <div class="col-md-2">
              <div class="form-group">
                <a href="#" style="color: rgb(113, 113, 113); font-style: normal; font-weight: 400; font-size: 12px; line-height: 16px;"><i class="now-ui-icons cs-download" style="position: relative; top: 8px;"></i></a>
              </div>
            </div>
          </div>
      `);
    }
  }
}

function getDataCoordinator(id) {
  let selEl = "#coordinator_id";

  return axios.get(base_api_url + '/api/v1/coord').then(res => {
    let datas = res.data.response;

    $(selEl).empty();
    $(selEl).append("<option value=''>Select</option>");
    
    for (var i=0;i<datas.length;i++) {
      $(selEl).append("<option data-company="+datas[i].company+" data-name="+datas[i].name+" value="+datas[i].id+">"+datas[i].name+"</option>");
    }

    axios.get(base_api_url + '/api/v1/coord/getcoordbyid?coordId=' + id).then(res => {
      $(selEl).val(res.data.response.id).trigger("change");
    })

  })
}

function getDataSchool(id) {
  let selEl = "#school_id"; 
  return axios.get(base_api_url + '/api/v1/school').then(res => {
    let datas = res.data.response;

    $(selEl).empty();
    $(selEl).append("<option value=''>Select</option>");

    for (var i=0;i<datas.length;i++) {
      $(selEl).append("<option data-type="+datas[i].type+" data-town="+datas[i].town+" data-country="+datas[i].country+" data-name="+datas[i].name+" value="+datas[i].id+">"+datas[i].name+"</option>");
    }

    axios.get(base_api_url + '/api/v1/school/getschoolbyid?schoolId=' + id).then(res => {
      $(selEl).val(res.data.response.SchoolEntity.id).trigger("change");
    })

  })
}

function getDataSchoolOnly() {
  let selEl = "#school_id"; 
  return axios.get(base_api_url + '/api/v1/school').then(res => {
    let datas = res.data.response;

    $(selEl).empty();
    $(selEl).append("<option value=''>Select</option>");

    for (var i=0;i<datas.length;i++) {
      $(selEl).append("<option data-type="+datas[i].type+" data-town="+datas[i].town+" data-country="+datas[i].country+" data-name="+datas[i].name+" value="+datas[i].id+">"+datas[i].name+"</option>");
    }

  })
}

function getDataCoordinatorOnly() {
  let selEl = "#coordinator_id";

  return axios.get(base_api_url + '/api/v1/coord').then(res => {
    let datas = res.data.response;

    $(selEl).empty();
    $(selEl).append("<option value=''>Select</option>");
    
    for (var i=0;i<datas.length;i++) {
      $(selEl).append("<option data-company="+datas[i].company+" data-name="+datas[i].name+" value="+datas[i].id+">"+datas[i].name+"</option>");
    }

  })
}

function uploadFile() {
  var bodyFormData = new FormData();
  let imageFile = $("#photo")[0].files[0];
  let user_login = JSON.parse(localStorage.getItem('user_login'));

  bodyFormData.append('file', imageFile);
  bodyFormData.append('username', user_login.email);

  axios({
    method: "post",
    url: file_api_url + "/api/v1/user/upload/file",
    data: bodyFormData,
    headers: { "Content-Type": "multipart/form-data" },
  }).then(function (response) {
    $("#f_photourl").val(response.data[0].path);
    submitData();
  });
}

function uploadFileFamily() {
  var bodyFormData = new FormData();
  let imageFile = $("#fphoto")[0].files[0];
  let user_login = JSON.parse(localStorage.getItem('user_login'));

  bodyFormData.append('file', imageFile);
  bodyFormData.append('username', user_login.email);

  axios({
    method: "post",
    url: file_api_url + "/api/v1/user/upload/file",
    data: bodyFormData,
    headers: { "Content-Type": "multipart/form-data" },
  }).then(function (response) {
    $("#f_familyphotourl").val(response.data[0].path);
    //
  });
}

function getMusicList(id){
  let selEl = "#music_instrument_list";

  return axios.get(base_api_url + '/api/v1/mi/getMusicalInstrumentsByHostFamily?familyId='+id+'&isActive=true').then(res => {
    if (res.data.response){
      $(selEl).val(res.data.response[0].instrument);
    }
  })
}

function getPetList(id){
  let selEl = "#pet_list";

  return axios.get(base_api_url + '/api/v1/pl/getPetsByHostFamily?familyId='+id+'&isActive=true').then(res => {
    if (res.data.response){
      $(selEl).val(res.data.response[0].pettype);
    }
  })
}

function sendMail(name, email, coord_name, coord_email) {
  let data = JSON.stringify({
    "name": name,
    "email": email,
    "coord_name":coord_name,
    "coord_email":coord_email
  });

  let config = {
    method: 'post',
    maxBodyLength: Infinity,
    url: file_api_url + '/api/v1/host-family/email/registration-approved',
    headers: { 
      'Content-Type': 'application/json'
    },
    data : data
  };

  axios.request(config)
  .then((response) => {
    console.log(JSON.stringify(response.data));
  })
  .catch((error) => {
    console.log(error);
  });
}

function submitPet(familyName, familyId) {
  var tzoffset = (new Date()).getTimezoneOffset() * 60000; //offset in milliseconds
  var localISOTime = (new Date(Date.now() - tzoffset)).toISOString().slice(0, -1);
  var timestamp = localISOTime;
  let requestData = {
    fkfamilyid: familyId,
    fkfamilyname: familyName,
    insideoroutside: "string",
    isactive: true,
    notes: "Pet list",
    pettype: $("#pet_list").val(),
    timestamp: timestamp
  }

  return axios.post(base_api_url + "/api/v1/pl", requestData).then(res => {
    //
  });
}

function submitMusic(familyName, familyId) {
  var tzoffset = (new Date()).getTimezoneOffset() * 60000; //offset in milliseconds
  var localISOTime = (new Date(Date.now() - tzoffset)).toISOString().slice(0, -1);
  var timestamp = localISOTime;
  let requestData = {
    fkfamilyid: familyId,
    fkfamilyname: familyName,
    isactive: true,
    notes: "Music list",
    instrument: $("#music_instrument_list").val(),
    timestamp: timestamp
  }

  return axios.post(base_api_url + "/api/v1/mi", requestData).then(res => {
    //
  });
}

function submitData() {

  let user_login = JSON.parse(localStorage.getItem('user_login'));
  var tzoffset = (new Date()).getTimezoneOffset() * 60000; //offset in milliseconds
  var localISOTime = (new Date(Date.now() - tzoffset)).toISOString().slice(0, -1);
  var timestamp = localISOTime;
  let requestData = JSON.parse(localStorage.getItem("detailHf" + $("#id").val()));

  let mothersmoker = false;
  let fathersmoker = false;
  let wifi = false;
  let pets = false;
  let music = false;
  let programmerulesstatus = false;

  if ($("#mother_smoker").val()=="true") {
    mothersmoker = true;
  }

  if ($("#father_smoker").val()=="true") {
    fathersmoker = true;
  }

  if ($("#computer_access").val()=="true") {
    wifi = true;
  }

  if ($("#pets").val()=="true") {
    pets = true;
    submitPet($("#family_name").val(), $("#id").val());
  }

  if ($("#music_instrument").val()=="true") {
    music = true;
    submitMusic($("#family_name").val(), $("#id").val());
  }

  if ($("#programme_rule_status").val()=="true") {
    programmerulesstatus = true;
  }

  requestData["accesstocomputer"] = wifi;
  requestData["adminapprovalstatus"] = $("#adminapprovalstatus").val();
  requestData["address"] = $("#address").val();
  requestData["datevisited"] = $("#date_visited").val();
  requestData["descriptionofthearea"] = $("#description_of_the_area").val();
  requestData["distancetoschool"] = $("#distance_to_school").val();
  requestData["eircode"] = $("#eircode").val();
  requestData["emailaddress"] = $("#email").val();
  requestData["familyname"] = $("#family_name").val();
  requestData["fathername"] = $("#father_name").val();
  requestData["fathernationality"] = $("#father_nationality").val();
  requestData["fatheroccupation"]= $("#father_occupation").val();
  requestData["fathersmoker"] = fathersmoker;
  requestData["mothername"] = $("#mother_name").val();
  requestData["mothernationality"] = $("#mother_nationality").val();
  requestData["mothersoccupation"] = $("#mother_occupation").val();
  requestData["mothersmoker"] = mothersmoker;
  requestData["numberofavailablesinglerooms"] = $("#available_single_room").val();
  requestData["fkcoordinator"] = $("#coordinator_id").val();
  requestData["fkschoolid"] = $("#school_id").val();
  requestData["photourl"] = $("#f_photourl").val();
  requestData["familyphotourl"] = $("#f_familyphotourl").val();
  requestData["pets"] = pets;
  requestData["musicalinstruments"] = music;
  requestData["programmerulesstatus"] = programmerulesstatus;
  requestData["programmesuitablefor"] = $("#program_suitable_for").val();
  requestData["schoolschildrenattend"] = $("#school_children_attend").val();
  requestData["studentwithdietryrequirements"] = $("#diatry_requirement").val();
  requestData["telephonehome"] = $("#telephone_home").val();
  requestData["wifiavailable"] = wifi;

  return axios.put(base_api_url + "/api/v1/hf/updatebyhostFamilyid?role="+user_login.role, requestData).then(res => {

    if ($("#adminapprovalstatus").val()=="APPROVED") {
      sendMail($("#family_name").val(), $("#email").val(), $("#coordinator_name").val(), $("#coordinator_email").val());
    }

    swal("Success","Successfully update data!","success");
    setTimeout(function(){
     window.location.reload();
    },500);
  }).catch(function (error) {
    swal("Failed!", "Something went wrong!", "error");
  });
}

class HostFamilyDetail extends React.Component {

  componentDidMount(){
    getData()
    datepickerFactory($);
    $("#date_visited").datepicker({dateFormat:"dd/mm/yy"});

    $(document).on("click",".btn-csconfirm",function(e){
      e.preventDefault();
      
      let optional = ["available_single_room","music_instrument_list","pet_list","date_visited","school_name"];

      let notValid = 0;
      let msgval = "";
      let cv;
      $(".errorCs").remove();

        $.each($('form input'), function(index, formField) {
          if ($(formField).val()==="") {
            if (!optional.includes($(formField).attr("id"))) {
              cv = $(formField).parent().find("label").text();
              if (cv!==""  && $(formField).attr("type")!=="file" && $(formField).attr("type")!=="hidden") {
                msgval = cv + " is required! <br/>";
                $(formField).parent().append("<span class='errorCs'>" + msgval + "</span>");
                notValid += 1;
              }
            }
          }
        });

        if (notValid>0) {
          //$("#msgValidation").show();
        } else {
          if ($("#photo").val()=="") {
            submitData();
          } else {
            uploadFile();
          }
        }

      
    });

    $(document).on("click",".close",function(){
      $(this).parent().parent().hide();
    });

    $(document).on("change","#music_instrument",function(e){
      if ($(this).val()!=="true") {
        $("#music_instrument_list").attr("readonly",true);
      } else {
        $("#music_instrument_list").attr("readonly",false);
      }
    });

    $(document).on("change","#pets",function(e){
      if ($(this).val()!=="true") {
        $("#pet_list").attr("readonly",true);
      } else {
        $("#pet_list").attr("readonly",false);
      }
    });


    $('#fphoto').change(function(){
      const file = this.files[0];
      if (file){
        $(".errorCs").remove();
        if (file.type=="image/jpeg" || file.type=="image/png") {
          let reader = new FileReader();
          reader.onload = function(event){
            $('#fimgPreview').attr('src', event.target.result);
          }
          reader.readAsDataURL(file);
          uploadFileFamily();
        } else {
          $('#fphoto').val("");
          $("#fimgPreview").attr("src","");
          $('#fphoto').parent().parent().append("<span class='errorCs'>The image must be a file of type: jpg, jpeg, png.</span>");
        }
      }
    });

    $('#photo').change(function(){
      const file = this.files[0];
      if (file){
        $(".errorCs").remove();
        if (file.type=="image/jpeg" || file.type=="image/png") {
          let reader = new FileReader();
          reader.onload = function(event){
            $('#imgPreview').attr('src', event.target.result);
          }
          reader.readAsDataURL(file);
        } else {
          $('#photo').val("");
          $("#imgPreview").attr("src","");
          $('#photo').parent().parent().append("<span class='errorCs'>The image must be a file of type: jpg, jpeg, png.</span>");
        }
      }
    });

    $(document).on("change","#school_id",function(e){
      $("#school_name").val($(this).find(':selected').data('name'));
      $("#school_country").val($(this).find(':selected').data('country'));
      $("#school_town").val($(this).find(':selected').data('town'));
      $("#school_type").val($(this).find(':selected').data('type'));
    });

    $(document).on("change","#coordinator_id",function(e){
      $("#coordinator_name").val($(this).find(':selected').data('name'));
      $("#coordinator_company").val($(this).find(':selected').data('company'));
    });

  }

  handleSwitch(elem, state) {
  }

  render(){
    return (
    <>
      <PanelHeader size="sm"
        content={
          <div className="content">
            <div className="header text-left">
              <span className="panelHeaderDesc">View and manage the Host Families Details.</span>
              <nav aria-label="breadcrumb" role="navigation">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item"><a href="/admin/overview">Dashboard</a></li>
                  <li className="breadcrumb-item"><a href="/admin/submission">Submissions</a></li>
                  <li className="breadcrumb-item active" aria-current="page" id="br_name"></li>
                </ol>
              </nav>
            </div>
          </div>
        }
      />
      <br/>
      <div className="content">
        <Form name="formsubmit" id="formsubmit" action="/" className="form-horizontal" method="post">
          <Row>
            <Col md="3">
              <Card className="card-chart">
                <CardBody>
                  <Table responsive>
                    <tbody>
                      <tr>
                        <td className="td-noborder">
                          <a href="#c_spil_details" className="anchorItemDetail">SPIL Details</a>
                          <a href="#c_host_family_details" className="anchorItemDetail">Host Family Details</a>
                          <a href="#c_submissions" className="anchorItemDetail">Submissions</a>
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </CardBody>
              </Card>
            </Col>
            <Col md="9">
              <Card>
                <CardHeader className="card-header-profile">
                  <Row>
                    <Col md={2}>
                      <img id="profile" style={{float: "left",width:"76px",height:"76px",position:"relative",bottom:"18px"}} src={profileImg}></img>
                    </Col>
                    <Col md={7}>
                      <p className="profile-title" id="t_name"></p>
                      <p className="profile-desc" id="t_id"></p>
                    </Col>
                    <Col md={3}>
                      <a className="viewAttached" id="attachedStudent">View attached Student</a>
                      <br/>
                      <br/>
                      <select className="form-control" id="adminapprovalstatus">
                        <option value="PENDING">PENDING</option>
                        <option value="APPROVED">APPROVED</option>
                        <option value="REJECTED">REJECTED</option>
                      </select>
                    </Col>
                  </Row>
                </CardHeader>
                <br/>
              </Card>
              <Card id="c_spil_details">
                <CardHeader>
                  <CardTitle tag="h4" className="panelTitle">SPIL Details</CardTitle>
                </CardHeader>
                <CardBody>
                  <Row>
                    <Col sm="12">
                      <FormGroup>
                        <h4 style={{"fontFamily": "Noto Sans","fontStyle": "normal","fontWeight": 400,"fontSize": "16px","lineHeight": "24px","color": "#70B2D8"}}>HostFamily</h4>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm="12">
                      <Row>
                        <Col md="12">
                          <FormGroup>
                            <div className="form-floating">
                              <Input readonly="true" id="id" style={{"borderRadius": "5px"}} placeholder="ID" type="text" />
                              <label htmlFor="pwd">ID</label>
                            </div>
                          </FormGroup>
                        </Col>
                      </Row>
                    </Col>
                  </Row>

                  <Row>
                    <Col sm="12">
                      <FormGroup>
                        <h4 style={{"fontFamily": "Noto Sans","fontStyle": "normal","fontWeight": 400,"fontSize": "16px","lineHeight": "24px","color": "#70B2D8"}}>School</h4>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6}>
                      <FormGroup>
                        <div className="form-floating">
                          <select placeholder="s" id="school_id" style={{borderRadius:"5px"}} className="form-control">
                            <option value="">Select</option>
                          </select>
                          <label htmlFor="pwd">School</label>
                        </div>
                      </FormGroup>
                    </Col>
                    <Col md={6}>
                      <FormGroup>
                        <div className="form-floating">
                          <Input readonly="true" id="school_name" style={{"borderRadius": "5px"}} placeholder="Name" type="text" />
                          <label htmlFor="pwd">Name</label>
                        </div>
                      </FormGroup>
                    </Col>
                  </Row>
    
                  <Row>
                    <Col sm="12">
                      <FormGroup>
                        <h4 style={{"fontFamily": "Noto Sans","fontStyle": "normal","fontWeight": 400,"fontSize": "16px","lineHeight": "24px","color": "#70B2D8"}}>Coordinator</h4>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={12}>
                      <FormGroup>
                        <div className="form-floating">
                          <select placeholder="s" id="coordinator_id" style={{borderRadius:"5px"}} className="form-control">
                            <option value="">Select</option>
                          </select>
                          <label htmlFor="pwd">Coordinator</label>
                        </div>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm="12">
                      <Row>
                        <Col md={6}>
                          <FormGroup>
                            <div className="form-floating">
                              <Input readonly="true" id="coordinator_name" style={{"borderRadius": "5px"}} placeholder="ID" type="text" />
                              <label htmlFor="pwd">Name</label>
                            </div>
                          </FormGroup>
                        </Col>
                        <Col md={6}>
                          <FormGroup>
                            <div className="form-floating">
                              <Input readonly="true" id="coordinator_company" style={{"borderRadius": "5px"}} placeholder="Reference" type="text" />
                              <label htmlFor="pwd">Company</label>
                            </div>
                          </FormGroup>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
              <Card id="c_host_family_details">
                <CardHeader>
                  <CardTitle tag="h4" className="panelTitle">Host Family Details</CardTitle>
                </CardHeader>
                <CardBody>
                  <Row>
                    <Col sm="12">
                      <FormGroup>
                        <h4 style={{"fontFamily": "Noto Sans","fontStyle": "normal","fontWeight": 400,"fontSize": "16px","lineHeight": "24px","color": "#70B2D8"}}>Host Families</h4>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm="12">
                      <Row>
                        <Col md="6">
                          <FormGroup>
                            <div className="form-floating">
                              <Input id="family_name" style={{"borderRadius": "5px"}} placeholder="ID" type="text" />
                              <label htmlFor="pwd">Family Name</label>
                            </div>
                          </FormGroup>
                        </Col>
                        <Col md="6">
                          <FormGroup>
                            <div className="form-floating">
                              <Input id="mother_name" style={{"borderRadius": "5px"}} placeholder="ID" type="text" />
                              <label htmlFor="pwd">Mother Name</label>
                            </div>
                          </FormGroup>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm="12">
                      <Row>
                        <Col md="6">
                          <FormGroup>
                            <div className="form-floating">
                              <Input id="mother_occupation" style={{"borderRadius": "5px"}} placeholder="ID" type="text" />
                              <label htmlFor="pwd">Mothers Occupation</label>
                            </div>
                          </FormGroup>
                        </Col>
                        <Col md="6">
                          <FormGroup>
                            <div className="form-floating">
                              <select className="form-control" id="mother_smoker" style={{"borderRadius": "5px"}} placeholder="ID">
                                <option value="true">Yes</option>
                                <option value="false" selected>No</option>
                              </select>
                              <label htmlFor="pwd">Mothers Smoker</label>
                            </div>
                          </FormGroup>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm="12">
                      <Row>
                        <Col md="6">
                          <FormGroup>
                            <div className="form-floating">
                              <Input id="mother_nationality" style={{"borderRadius": "5px"}} placeholder="ID" type="text" />
                              <label htmlFor="pwd">Mother Nationality</label>
                            </div>
                          </FormGroup>
                        </Col>
                        <Col md="6">
                          <FormGroup>
                            <div className="form-floating">
                              <Input id="father_name" style={{"borderRadius": "5px"}} placeholder="ID" type="text" />
                              <label htmlFor="pwd">Father Name</label>
                            </div>
                          </FormGroup>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm="12">
                      <Row>
                        <Col md="6">
                          <FormGroup>
                            <div className="form-floating">
                              <Input id="father_occupation" style={{"borderRadius": "5px"}} placeholder="ID" type="text" />
                              <label htmlFor="pwd">Father Occupation</label>
                            </div>
                          </FormGroup>
                        </Col>
                        <Col md="6">
                          <FormGroup>
                            <div className="form-floating">
                              <select className="form-control" id="father_smoker" style={{"borderRadius": "5px"}} placeholder="ID">
                                <option value="true">Yes</option>
                                <option value="false" selected>No</option>
                              </select>
                              <label htmlFor="pwd">Father Smoker</label>
                            </div>
                          </FormGroup>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm="12">
                      <Row>
                        <Col md="6">
                          <FormGroup>
                            <div className="form-floating">
                              <Input id="father_nationality" style={{"borderRadius": "5px"}} placeholder="ID" type="text" />
                              <label htmlFor="pwd">Father Nationality</label>
                            </div>
                          </FormGroup>
                        </Col>
                        <Col md="6">
                          <FormGroup>
                            <div className="form-floating">
                              <Input id="telephone_home" style={{"borderRadius": "5px"}} placeholder="ID" type="text" />
                              <label htmlFor="pwd">Telephone Home</label>
                            </div>
                          </FormGroup>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm="12">
                      <Row>
                        <Col md="6">
                          <FormGroup>
                            <div className="form-floating">
                              <Input id="mobile" style={{"borderRadius": "5px"}} placeholder="ID" type="text" />
                              <label htmlFor="pwd">Telephone Mobile</label>
                            </div>
                          </FormGroup>
                        </Col>
                        <Col md="6">
                          <FormGroup>
                            <div className="form-floating">
                              <Input readonly="true" id="email" style={{"borderRadius": "5px"}} placeholder="ID" type="text" />
                              <label htmlFor="pwd">Email</label>
                            </div>
                          </FormGroup>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm="12">
                      <Row>
                        <Col md="6">
                          <FormGroup>
                            <div className="form-floating">
                              <Input id="whatsapp" style={{"borderRadius": "5px"}} placeholder="ID" type="text" />
                              <label htmlFor="pwd">Whatsapp Y/N</label>
                            </div>
                          </FormGroup>
                        </Col>
                        <Col md="6">
                          <FormGroup>
                            <div className="form-floating">
                              <Input id="date_visited" style={{"borderRadius": "5px"}} placeholder="ID" type="text" />
                              <label htmlFor="pwd">Date Visited</label>
                            </div>
                          </FormGroup>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm="12">
                      <Row>
                        <Col md="6">
                          <FormGroup>
                            <div className="form-floating">
                              <Input id="host_family_child" style={{"borderRadius": "5px"}} placeholder="ID" type="text" />
                              <label htmlFor="pwd">Host Family Child</label>
                            </div>
                          </FormGroup>
                        </Col>
                        <Col md="6">
                          <FormGroup>
                          </FormGroup>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm="12">
                      <Row>
                        <Col md="12">
                          <FormGroup>
                            <div className="form-floating">
                              <Input id="notes" style={{"borderRadius": "5px"}} placeholder="ID" type="text" />
                              <label htmlFor="pwd">Notes</label>
                            </div>
                          </FormGroup>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm="12">
                      <Row>
                        <Col md="6">
                          <FormGroup>
                            <div className="form-floating">
                              <Input id="address" style={{"borderRadius": "5px"}} placeholder="ID" type="text" />
                              <label htmlFor="pwd">Address</label>
                            </div>
                          </FormGroup>
                        </Col>
                        <Col md="6">
                          <FormGroup>
                            <div className="form-floating">
                              <Input id="eircode" style={{"borderRadius": "5px"}} placeholder="ID" type="text" />
                              <label htmlFor="pwd">Eircode</label>
                            </div>
                          </FormGroup>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm="12">
                      <Row>
                        <Col md="6">
                          <FormGroup>
                            <div className="form-floating">
                              <Input id="description_of_the_area" style={{"borderRadius": "5px"}} placeholder="ID" type="text" />
                              <label htmlFor="pwd">Description of the Area</label>
                            </div>
                          </FormGroup>
                        </Col>
                        <Col md="6">
                          <FormGroup>
                            <div className="form-floating">
                              <Input id="distance_to_school" style={{"borderRadius": "5px"}} placeholder="ID" type="text" />
                              <label htmlFor="pwd">Distance to School / Travel Arrangements</label>
                            </div>
                          </FormGroup>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm="12">
                      <Row>
                        {/* <Col md="6">
                          <FormGroup>
                            <div className="form-floating">
                              <Input id="travel_arrangement" style={{"borderRadius": "5px"}} placeholder="ID" type="text" />
                              <label htmlFor="pwd">Travel Arrangements</label>
                            </div>
                          </FormGroup>
                        </Col> */}
                        <Col md="6">
                          <FormGroup>
                            <div className="form-floating">
                              <Input id="diatry_requirement" style={{"borderRadius": "5px"}} placeholder="ID" type="text" />
                              <label htmlFor="pwd">Are you ok of student has specific dietry requirements</label>
                            </div>
                          </FormGroup>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm="12">
                      <Row>
                        <Col md="6">
                          <FormGroup>
                            <div className="form-floating">
                              <Input id="available_single_room" style={{"borderRadius": "5px"}} placeholder="ID" type="text" />
                              <label htmlFor="pwd">Number of Available single rooms</label>
                            </div>
                          </FormGroup>
                        </Col>
                        <Col md="6">
                          <FormGroup>
                            <div className="form-floating">
                              <select className="form-control" id="computer_access" style={{"borderRadius": "5px"}} placeholder="ID">
                                <option value="true">Yes</option>
                                <option value="false">No</option>
                              </select>
                              <label htmlFor="pwd">Have you wifi / computer access</label>
                            </div>
                          </FormGroup>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm="12">
                      <Row>
                        <Col md="6">
                          <FormGroup>
                            <div className="form-floating">
                              <select className="form-control" id="music_instrument" style={{"borderRadius": "5px"}} placeholder="ID">
                                <option value="true">Yes</option>
                                <option value="false">No</option>
                              </select>
                              <label htmlFor="pwd">Musical Instrument</label>
                            </div>
                          </FormGroup>
                        </Col>
                        <Col md="6">
                          <FormGroup>
                            <div className="form-floating">
                              <select className="form-control" id="pets" style={{"borderRadius": "5px"}} placeholder="ID">
                                <option value="true">Yes</option>
                                <option value="false">No</option>
                              </select>
                              <label htmlFor="pwd">Pets</label>
                            </div>
                          </FormGroup>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm="12">
                      <Row>
                        <Col md="6">
                          <FormGroup>
                            <div className="form-floating">
                              <Input id="music_instrument_list" style={{"borderRadius": "5px"}} placeholder="ID" type="text" />
                              <label htmlFor="pwd">Musical Instrument List</label>
                            </div>
                          </FormGroup>
                        </Col>
                        <Col md="6">
                          <FormGroup>
                            <div className="form-floating">
                              <Input id="pet_list" style={{"borderRadius": "5px"}} placeholder="ID" type="text" />
                              <label htmlFor="pwd">Pets List</label>
                            </div>
                          </FormGroup>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm="12">
                      <Row>
                        <Col md="6">
                          <FormGroup>
                            <div className="form-floating">
                              <Input id="program_suitable_for" style={{"borderRadius": "5px"}} placeholder="ID" type="text" />
                              <label htmlFor="pwd">Programme Suitable For</label>
                            </div>
                          </FormGroup>
                        </Col>
                        <Col md="6">
                          <FormGroup>
                            <div className="form-floating">
                              <Input id="school_children_attend" style={{"borderRadius": "5px"}} placeholder="ID" type="text" />
                              <label htmlFor="pwd">School Children Attend</label>
                            </div>
                          </FormGroup>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm="12">
                      <Row className="bundleBorder">
                        <Col md="2">
                          <FormGroup>
                            <div className="form-floating">             
                              <label htmlFor="pwd">Photos</label>
                            </div>
                          </FormGroup>
                        </Col>
                        <img id="fimgPreview" style={{float: "left",width:"76px",height:"76px",position:"relative"}} src={profileImg}></img>
                        <input style={{marginLeft: "20px",marginTop: "24px"}} type="file" id="fphoto"/>
                        <input type="hidden" id="f_familyphotourl"/>
                      </Row>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm="12">
                      <Row>
                        <Col md="12">
                          <FormGroup>
                            <div className="form-floating">
                              <select className="form-control" id="programme_rule_status" style={{"borderRadius": "5px"}} placeholder="ID">
                                <option value="true">Active</option>
                                <option value="false">Inactive</option>
                              </select>
                              <label htmlFor="pwd">Programme Rule Status</label>
                            </div>
                          </FormGroup>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
              <Card id="c_submissions">
                <CardHeader>
                  <CardTitle tag="h4" className="panelTitle">Submissions</CardTitle>
                </CardHeader>
                <CardBody>
                  <Row>
                    <Col sm="12">
                      <FormGroup>
                        <h4 style={{"fontFamily": "Noto Sans","fontStyle": "normal","fontWeight": 400,"fontSize": "16px","lineHeight": "24px","color": "#70B2D8"}}>Photo</h4>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm="12">
                      <Row className="bundleBorder">
                        <Col md="2">
                          <FormGroup>
                            <div className="form-floating">             
                              <label htmlFor="pwd">Photos</label>
                            </div>
                          </FormGroup>
                        </Col>
                        <img id="imgPreview" style={{float: "left",width:"76px",height:"76px",position:"relative"}} src={profileImg}></img>
                        <input style={{marginLeft: "20px",marginTop: "24px"}} type="file" id="photo"/>
                        <input type="hidden" id="f_photourl"/>
                      </Row>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm="12">
                      <FormGroup>
                        <h4 style={{"fontFamily": "Noto Sans","fontStyle": "normal","fontWeight": 400,"fontSize": "16px","lineHeight": "24px","color": "#70B2D8"}}>Submissions</h4>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm="12" id="submissions_list">
                    </Col>
                  </Row>
                 
                  <Row>
                    <Col sm="12">
                      <FormGroup>
                        <h4 style={{"fontFamily": "Noto Sans","fontStyle": "normal","fontWeight": 400,"fontSize": "16px","lineHeight": "24px","color": "#70B2D8"}}>Garda Vetting</h4>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={12} id="gv_list">
                    </Col>
                  </Row>
                </CardBody>
              </Card>
              <Row>
                <Col sm="12">
                  <div align="center">
                    <button className="btn-csconfirm">Update Changes</button>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Form>
      </div>
    </>
  );
  }

}

export default HostFamilyDetail;