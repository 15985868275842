import React from "react";
import axios from "axios";
import $ from "jquery";

// reactstrap components
import {
  Card,
  CardBody,
  CardHeader,
  Form,
  Container,
  Col,
  Input,
  InputGroup,
  Button
} from "reactstrap";
import {base_api_url} from "variables/general.js";

// core components
import nowLogo from "assets/img/spill/logo.png";
import bgImage from "assets/img/spill/bg-login.svg";
import swal from 'sweetalert';

function getAdminData(id) {
  let currentUrl;
  let baseUrl = base_api_url;
  currentUrl = baseUrl + "/api/v1/admin/getadminbyid?adminID=" + id;
  return currentUrl;
}

function getUrlAction() {
  let currentUrl;
  let baseUrl = base_api_url;
  currentUrl = baseUrl + "/api/v1/login";
  return currentUrl;
}

class AdminLoginPage extends React.Component {
  componentDidMount(){

    setTimeout(function(){
      $("html").css("#background","#F6FCFF");
    },200);

    localStorage.removeItem("user_login");

    const params = new Proxy(new URLSearchParams(window.location.search), {
      get: (searchParams, prop) => searchParams.get(prop),
    });

    if (params.message) {
      swal("Ops!", "Unauthorized or session expired", "error");
    }

    let rb_admin = JSON.parse(localStorage.getItem("admin_rb"));

    if (rb_admin){
      $(".email").val(rb_admin.username);
      $(".password").val(rb_admin.password);
      $(".rememberMe").prop("checked",true);
    }

    $(document).on("click",".submitLogin",function(e){
      e.preventDefault();

      let remember = $(".rememberMe").is(":checked");

      if (remember) {
        let admin_rb = {
          username:$(".email").val(),
          password:$(".password").val()
        }
        localStorage.setItem("admin_rb", JSON.stringify(admin_rb));
      } else {
        localStorage.removeItem("admin_rb");
      }

      let urlTarget = getUrlAction(); 
        axios.post(urlTarget + "?username=" + $(".email").val() + "&password=" + $(".password").val(),{
          username: $(".email").val(),
          password: $(".password").val()
        }).then(res => {
        
        if (typeof res.data.access_token == "undefined" || res.data.is_active == "false") {
          swal("Failed!", "Token expired or user is not active!", "error");
        } else {

          let user_role = res.data.role;
          let reference_id = res.data.reference_id;
          let access_token = res.data.access_token;
          let refresh_token = res.data.refresh_token;
      
          axios.get(getAdminData(reference_id)).then(res => {
            const now = new Date()

            let rLogin = {
              email:$(".email").val(),
              expire:now.getTime() + 9000000,
              role:user_role,
              reference_id:reference_id,
              access_token:access_token,
              refresh_token:refresh_token
            };

            let resEnd = Object.assign(rLogin, res.data.response)
            localStorage.setItem("user_login",JSON.stringify(resEnd));

            swal("Success","Login successful!","success");

            setTimeout(function(){
              window.location.href = window.location.origin + "/" + user_role + "/overview";
            },500);
          });          
        }

      }).catch(
        function (error) {
          swal("Failed!", "Something went wrong!", "error");
        }
      )
    })
    $(document).on("click",".close",function(){
      $(this).parent().parent().fadeOut();
    });
  }

  

  render(){
    return (
      <>
        <div className="content">
          <div className="login-page">
            <Form>
              <Card className="card-login card-plain">
                <CardBody className="cs-card-body">
                  <div>
                    <h4 className="lblTextHead">Release notes :</h4>
                    <label className="lblTextBody">Everyone loves neat, transparent, informative release notes. Everyone would also rather avoid maintaining them. What a hassle to have to evaluate what issues have been solved between two points in project's timeline, what types of problems they were, are they important to inform the users about, what issues solved them, etc.

  Wouldn't it be great to get fantastic release notes compiled for you automatically based on all the hard work you put into your GitHub issues and pull requests?

  The main motivation for bringing gren to life was the need for auto-generating release notes for every tag in a project. The process, as explained here, requires the tagger to go to your project's releases page in GitHub, draft that tag as a new release and manually add what has changed.

  Let gren take care of that for you. It automates this process and also writes release notes for you, creating something like this:</label>
                    <h4 className="lblTextHead">Bug fixes notes :</h4>
                    <label className="lblTextBody">.all-contributorsrc
docs: add viatrix as a contributor (#297)
15 months ago
</label>

                    <h4 className="lblTextHead">App Version :</h4>
                    <label className="lblTextBody">Spil.ie - V1.0  Beta</label>
                  </div>
                </CardBody>
              </Card>
            </Form>
          </div>
        </div>
      </>
    );

  }
}

export default AdminLoginPage;