import React, {Component} from "react";
import $ from "jquery";

import datepickerFactory from 'jquery-datepicker';
import TableFilter from "views/Components/TableFilter.js";
import axios from "axios";
import {base_api_url, default_start_date, default_end_date, defPaginPage, defPaginPerpage, toPopulatePage, replaceEndTime} from "variables/general.js";
import { jsPDF } from "jspdf";
import autoTable from 'jspdf-autotable';

import {
  Card,
  CardBody,
  CardTitle,
  Row,
  Col,
  Button
} from "reactstrap";

import PanelHeader from "components/PanelHeader/PanelHeader.js";

function overrideDtColor(){
  $(".paginate_button").attr("style","border-radius: 30px;background: #B6C9D5;color: #FFFFFF!important;");
  $(".paginate_button.previous").html("<i class='now-ui-icons arrows-1_minimal-left'></i>").attr("style","borderRadius: 0px;background: #fff;color: #000!important;font-weight:bold;");
  $(".paginate_button.next").html("<i class='now-ui-icons arrows-1_minimal-right'></i>").attr("style","borderRadius: 0px;background: #fff;color: #000!important;font-weight:bold;");
  $(".dt-buttons").attr("style","display:none");
}

function getData(page, perpage, searchKey="",filterColumn1=false, filterColumn2=false, filterColumn3=false) {

  const params = new Proxy(new URLSearchParams(window.location.search), {
    get: (searchParams, prop) => searchParams.get(prop),
  });

  let user_login = JSON.parse(localStorage.getItem('user_login'));

  $("#pagLink").removeClass("active");
  let curpage = Number(page) - 1;
  let fromDate = $('#startDate').val();
  let toDate = $("#endDate").val();

  if ($('#startDate').val()!=="") {
    fromDate = fromDate.split("/")
    fromDate = fromDate[2].substring(0, 4) + "-" + fromDate[1] + "-" + fromDate[0]
    fromDate = new Date(fromDate);
    fromDate = fromDate.toISOString();
  }

  if ($("#endDate").val()!=="") {
    toDate = toDate.split("/")
    toDate = toDate[2].substring(0, 4) + "-" + toDate[1] + "-" + toDate[0]
    toDate = new Date(toDate);
    toDate = toDate.toISOString().replace("T00:00:00",replaceEndTime);
  }
  
  let urlTarget = base_api_url + '/api/v1/schoolassciation/schools?isActive=true&page=' + curpage + '&size=' + perpage + "&searchTerm=" + encodeURIComponent(searchKey.trim()) + "&fromdate=" + fromDate + "&todate=" + toDate + "&associationRole=coord&fkAssociationId=" + user_login.reference_id; 
  return axios.get(urlTarget).then(res => {
    let datas = res.data.response.content;
    let totalPage = Number(res.data.response.totalPages);

    showTable(datas);
    loopPagination(Number(page), totalPage);

    let total = $("tbody tr").length;
    let checked = $(".selectorRow:checked").length;
    $("#rowSelectedLabel").attr("placeholder", checked + " of " + total + " selected");

    if (filterColumn1) {
      $("#filterColumn1").val(filterColumn1).trigger("change");
    } 

    if (filterColumn2) {
      $("#filterColumn2").val(filterColumn2).trigger("change");
    } else {
      $("#filterColumn2").val([4,6,7,8,9,12]).trigger("change");
    }

    if (filterColumn3) {
      $("#filterColumn3").val(filterColumn3).trigger("change");
    }
  })
}

function loopPagination(curPage, sizePage) {
  let paginContent = "";
  let prevPage = curPage - 1;
  let nextPage = curPage + 1;

  if (sizePage > 0) {
    paginContent = paginContent.concat(`<a data-perpage="${defPaginPerpage}" data-page="${prevPage}" class="prevPag pagLink arrowpag" href="#"><i class="now-ui-icons arrows-1_minimal-left"></i></a>`);

    // Determine the start and end of the page range
    let startPage = Math.max(1, curPage - 4);
    let endPage = Math.min(sizePage, startPage + 8);

    // Expand the range if fewer than 9 pages are shown
    while ((endPage - startPage) < 8 && (startPage > 1 || endPage < sizePage)) {
      if (startPage > 1) {
        startPage--;
      }
      if (endPage < sizePage) {
        endPage++;
      }
    }

    // Display the page links within the range
    for (let i = startPage; i <= endPage; i++) {
      if (i == curPage) {
        paginContent = paginContent.concat(`<a href="#" data-perpage="${defPaginPerpage}" data-page="${i}" class="pagLink active">${i}</a>`);
      } else {
        paginContent = paginContent.concat(`<a href="#" data-perpage="${defPaginPerpage}" data-page="${i}" class="pagLink">${i}</a>`);
      }
    }

    let haveless = "";

    // Add "..." at the end if there are more pages
    if (endPage < sizePage) {
      haveless = "<a style='background: white;color: #416D86!important;'> ... </a>";
    }

    paginContent = paginContent.concat(`${haveless}<a data-perpage="${defPaginPerpage}" data-page="${nextPage}" class="nextPag arrowpag pagLink" href="#"><i class="now-ui-icons arrows-1_minimal-right"></i></a>`);
  }

  setTimeout(function () {
    if (curPage == 1) {
      $(".prevPag").hide();
    } else {
      $(".prevPag").show();
    }

    if (curPage == sizePage) {
      $(".nextPag").hide();
    } else {
      $(".nextPag").show();
    }
  }, toPopulatePage);

  $(".pagination").html(paginContent);
}

function showTable(datas) {

  let currentUrl = window.location.origin + "/coordinator/school/";

  let tableContent = "";
  $("#tbody").empty();

  if (datas.length>0) {
    for (let i=0;i<datas.length;i++) {
      tableContent = tableContent.concat(`<tr>
            <td style="padding-left: 15px;padding-right:10px;width:4%;fontStyle: normal;fontWeight: 400;fontSize: 14px,lineHeight: 20px" className="td-noborder"><input type="checkbox" name="selectorRow" class="selectorRow"></td>
            <td style="display:none;" className="td-noborder">${datas[i].id}</td>
            <td style="display:none;" className="td-noborder">${datas[i].name}</td>
            <td style="display:none;" className="td-noborder">${datas[i].country}</td>
            <td style="display:none;" className="td-noborder">${datas[i].town}</td>
            <td style="display:none;" className="td-noborder">${datas[i].type}</td>
            <td style="display:none;" className="td-noborder">${datas[i].address}</td>
            <td style="display:none;" className="td-noborder">${datas[i].emailaddress}</td>
            <td style="display:none;" className="td-noborder">${datas[i].mobile}</td>
            <td style="display:none;" className="td-noborder">${datas[i].website}</td>
            <td style="display:none;" className="td-noborder">${datas[i].principalname}</td>
            <td style="display:none;" className="td-noborder">${datas[i].yearlyenrolementfees}</td>
            <td style="display:none;" className="td-noborder">${datas[i].languages}</td>
            <td style="display:none;" className="td-noborder">${datas[i].jackets}</td>
            <td style="width:17%" className="td-noborder"><a style="color:#F9B933;fontStyle:normal;fontWeight: 400;fontSize: 12px;lineHeight: 16px" href=${currentUrl + datas[i].id + "?id=" + datas[i].id}><i style="position: relative;top:8px" class="now-ui-icons cs-ov-edit-pen"></i>Views Detail</a></td>
          </tr>`);
    }
    $(".containerPagination").show();
  } else {
    tableContent = tableContent.concat(`<tr><td></td><td>Not found</td><td></td><td></td></tr>`);
    $(".containerPagination").hide();
  }

  $("#tbody").html(tableContent);

}

function downloadCSVFile(csv_data) {
    let CSVFile = new Blob([csv_data], { type: "text/csv" });
    var temp_link = document.createElement('a');
    let timestamp = new Date();
    temp_link.download = timestamp.toISOString() + ".csv";
    var url = window.URL.createObjectURL(CSVFile);
    temp_link.href = url;
    temp_link.style.display = "none";
    document.body.appendChild(temp_link);
    temp_link.click();
    document.body.removeChild(temp_link);
}

function tableToCSV() {
    var csv_data = [];

    var rows = $("#table > thead > tr");
    for (var i = 0; i < rows.length; i++) {
        var cols = rows[i].querySelectorAll('td,th');
        var csvrow = [];
        for (var j = 1; j < cols.length - 1; j++) {
          if (j!==cols.length-1) {
            if (!$(cols[j]).is(":hidden")){
              csvrow.push(escapeCSVValue(cols[j].innerHTML));
            }
          }
        }
        csv_data.push(csvrow.join(","));
    }

    var rows = $("#table > tbody > tr");
    for (var i = 0; i < rows.length; i++) {
      if ($(rows[i]).find('.selectorRow').is(':checked')) {
        var cols = rows[i].querySelectorAll('td,th');
        var csvrow = [];
        for (var j = 1; j < cols.length - 1; j++) {
          if (j!==cols.length-1) {
            if (!$(cols[j]).is(":hidden")){
              csvrow.push(escapeCSVValue(cols[j].innerHTML));
            }
          }
        }
        csv_data.push(csvrow.join(","));
      }
    }
    csv_data = csv_data.join('\n');
    downloadCSVFile(csv_data);
}

function escapeCSVValue(value) {
    // If the value contains a comma or new line, enclose it in double quotes and escape existing double quotes
    if (value.includes(',') || value.includes('\n')) {
        return '"' + value.replace(/"/g, '""') + '"';
    } else {
        return value;
    }
}

function tableToPdf(){
  const doc = new jsPDF();
  let fillHeads = [];
  let fillBodys = [];
  
  let theadel = $("table#table > thead > tr > th");

  theadel.each(function(index, el){
    if (index==0 || index==theadel.length-1 || $(el).is(":hidden")) {

    } else {
      fillHeads.push($(el).text());
    }
  });

  let tbodyel = $("table#table > tbody > tr");
  let tds = [];

  tbodyel.each(function(index, el){
    if ($(el).find('.selectorRow').is(':checked')) {
      let tds = [];
      let tdParents = $(el).find("td");
      tdParents.each(function(id,vd){
        if (id==0 || id==tdParents.length-1 || $(vd).is(":hidden")) {

        } else {
          tds.push($(vd).text());
        }
      })
      fillBodys.push(tds)
    }
  });

  doc.autoTable({
    head: [fillHeads],
    body: fillBodys
  })
  let timestamp = new Date();
  doc.save(timestamp.toISOString() + ".pdf");
}

function replaceNullWithEmptyString(value) {
  if (value == null) {
    return '-';
  } else {
    if (value.length>0) {
      return value.replaceAll(",","");
    } else {
      return value;
    }
  }
}

function tableToCSVAll() {
    let user_login = JSON.parse(localStorage.getItem('user_login'));
    let urlTarget = base_api_url + "/api/v1/export/coord/schoollist/csvall?role=coord&userId="+user_login.reference_id;
    axios.get(urlTarget).then(res => {
      let datas = res.data.response;

      var csv_data = [];

      var rows = datas[0];
      var csvrow = [];
      for (let key in rows) {
        csvrow.push(key.replaceAll("_"," "));
      }

      csv_data.push(csvrow.join(","));

      var rows = datas;
      for (var i = 0; i < rows.length; i++) {
        var cols = rows[i];
        var csvrow = [];
        for (let key in cols) {
          let valCs = replaceNullWithEmptyString(cols[key]);
          csvrow.push(valCs);
        }
        csv_data.push(csvrow.join(","));  
      }

      csv_data = csv_data.join('\n');
      downloadCSVFile(csv_data);
    })

}

class School extends React.Component {

  state = {
    datas: [],
    page:defPaginPage,
    perpage:defPaginPerpage,
    url:"",
    totalPage:0
  }

  componentDidMount(){
    overrideDtColor();
    datepickerFactory($);
    $('#startDate').datepicker({dateFormat:"dd/mm/yyyy"});
    $('#endDate').datepicker({dateFormat:"dd/mm/yyyy"});
    $("#startDate").val(default_start_date);
    $('#endDate').val(default_end_date);

    getData(defPaginPage, defPaginPerpage);

    $(document).on("click",".pagination a.pagLink",function(e){
      e.preventDefault();
      let page = $(this).data('page');
      let perpage = defPaginPerpage
      let searchKey = $("#dtSearchCs").val();
      getData(Number(page),Number(perpage), searchKey, $("#filterColumn1").val(), $("#filterColumn2").val(), $("#filterColumn3").val());
    });

    $(document).on("click","#submitSearch",function(e){
      e.preventDefault();
      let page = defPaginPage;
      let perpage = defPaginPerpage;
      let searchKey = $("#dtSearchCs").val();
      getData(Number(page),Number(perpage),searchKey, $("#filterColumn1").val(), $("#filterColumn2").val(), $("#filterColumn3").val());
    });

    $(document).on("change",".selectorRowAll",function(){
      if(this.checked) {
        $(".selectorRow").prop("checked", true);
      } else {
        $(".selectorRow").prop("checked", false);
      }
      let total = $("tbody tr").length;
      let checked = $(".selectorRow:checked").length;
      $("#rowSelectedLabel").attr("placeholder", checked + " of " + total + " selected");
    });

    $(document).on("change",".selectorRow",function(){
      let total = $("tbody tr").length;
      let checked = $(".selectorRow:checked").length;
      $("#rowSelectedLabel").attr("placeholder", checked + " of " + total + " selected");
    });

    $(document).on("click","#addSchool",function(){
      window.location.href = window.location.origin + "/coordinator/school/new/registration";
    });

    let select2Init1 = $("#filterColumn1").select2({placeholder:"SPIL Details",closeOnSelect : false,
      allowHtml: true,
      allowClear: true,
      tags: false
    });

    $(document).on('change','select#filterColumn1', function (e) {
        e.preventDefault(); 
        let counter = select2Init1.select2('data').length;
        let parents = $(this).parent();
        parents.find(".select2Counter").remove();
        if (counter>0) {
          parents.find(".select2-selection--multiple").append("<span class='select2Counter'>"+counter+" selected</span>")
        }
        $("#filterColumn1 > option").each(function() {
            let inc = $(this).attr('data-column');
            if ($(this).is(':selected')){
              $('table#table td:nth-child('+inc+')').show();
              $('table#table th:nth-child('+inc+')').show();
            } else {
              $('table#table td:nth-child('+inc+')').hide();
              $('table#table th:nth-child('+inc+')').hide();
            }
        });
    });

    let select2Init2 = $("#filterColumn2").select2({placeholder:"School Details",closeOnSelect : false,
      allowHtml: true,
      allowClear: true,
      tags: false
    });

    $(document).on('change','select#filterColumn2', function (e) {
        e.preventDefault(); 
        let counter = select2Init2.select2('data').length;
        let parents = $(this).parent();
        parents.find(".select2Counter").remove();
        if (counter>0) {
          parents.find(".select2-selection--multiple").append("<span class='select2Counter'>"+counter+" selected</span>")
        }
        $("#filterColumn2 > option").each(function() {
            let inc = $(this).attr('data-column');
            if ($(this).is(':selected')){
              $('table#table td:nth-child('+inc+')').show();
              $('table#table th:nth-child('+inc+')').show();
            } else {
              $('table#table td:nth-child('+inc+')').hide();
              $('table#table th:nth-child('+inc+')').hide();
            }
        });
    });

    $(document).on("click",".btnExportCsv",function(){
      tableToCSV();
    });

    $(document).on("click",".btnExportPdf",function(){
      tableToPdf();
    });

    $(document).on("click",".btnExportCsvAll",function(){
      tableToCSVAll();
    });

  }

  render(){
    return(
    <>
      <PanelHeader size="sm"
        content={
          <div className="content">
            <div className="header text-left">
              <span className="panelHeaderDesc">View and manage School details.</span>
              <nav aria-label="breadcrumb" role="navigation">
                <ol class="breadcrumb">
                  <li class="breadcrumb-item"><a href="/coordinator/overview">Dashboard</a></li>
                  <li class="breadcrumb-item active" aria-current="page">School</li>
                </ol>
              </nav>
            </div>
          </div>
        }
      />
      <br/>
      <div className="content">
        <Row>
          <Col xs={12} md={12}>
            <Card>
              <CardBody>
                <TableFilter extraButton="yes" btnTitle="Add School" btnId="addSchool"/>
                <hr/>
                <Row>
                  <Col md={2}>
                    <div className="form-group">
                      <select className="form-control" id="filterColumn1" multiple="multiple">
                        <option value="2" className="toggle-vis" data-column="2">School ID</option>
                      </select>
                    </div>
                  </Col>
                  <Col md={2}>
                    <div className="form-group">
                      <select className="form-control" id="filterColumn2" multiple="multiple">
                        <option value="3" className="toggle-vis" data-column="3">School Name</option>
                        <option value="4" className="toggle-vis" data-column="4">Country</option>
                        <option value="5" className="toggle-vis" data-column="5">Town</option>
                        <option value="6" className="toggle-vis" data-column="6">School Type</option>
                        <option value="7" className="toggle-vis" data-column="7">Address</option>
                        <option value="8" className="toggle-vis" data-column="8">Email</option>
                        <option value="9" className="toggle-vis" data-column="9">Phone</option>
                        <option value="10" className="toggle-vis" data-column="10">Website</option>
                        <option value="11" className="toggle-vis" data-column="11">Principal Name</option>
                        <option value="12" className="toggle-vis" data-column="12">Year Enrolment Fees</option>
                        <option value="13" className="toggle-vis" data-column="13">Languages</option>
                        <option value="14" className="toggle-vis" data-column="14">School Jacket</option>
                      </select>
                    </div>
                  </Col>
                  <Col md={2}>
                  </Col>
                  <Col md={6}>
                    <div class="form-group pull-right">
                      <span style={{"marginRight":"10px"}}><i className="now-ui-icons arrows-1_share-66"/> Export To</span>
                      <button className="btnExport btnExportCsv"><i className="now-ui-icons files_single-copy-04"/> CSV</button>
                      <button className="btnExport btnExportPdf"><i className="now-ui-icons files_single-copy-04"/> PDF</button>
                      <button className="btnExport btnExportCsvAll"><i className="now-ui-icons files_single-copy-04"></i> All CSV</button>
                    </div>
                  </Col>
                </Row>
                <div className="containerTable">
                  <table id="table" style={{width:"100%"}} className="align-items-center justify-content-center mb-0">
                     <thead>
                        <tr>
                          <th style={{paddingLeft: "15px",borderTopLeftRadius:"30px",borderBottomLeftRadius:"30px"}}><input type="checkbox" name="selectorRow" className="selectorRowAll"/></th>
                          <th className="minWidthCs" style={{display:"none"}}>School ID</th>
                          {/* <th className="minWidthCs" style={{display:"none"}}>Coordinator Name</th>
                          <th className="minWidthCs" style={{display:"none"}}>Coordinator Company</th>
                          <th className="minWidthCs" style={{display:"none"}}>Host Family</th> */}
                          <th className="minWidthCs" style={{display:"none"}}>School Name</th>
                          <th className="minWidthCs" style={{display:"none"}}>Country</th>
                          <th className="minWidthCs" style={{display:"none"}}>Town</th>
                          <th className="minWidthCs" style={{display:"none"}}>School Type</th>
                          <th className="minWidthCs" style={{display:"none"}}>Address</th>
                          <th className="minWidthCs" style={{display:"none"}}>Email</th>
                          <th className="minWidthCs" style={{display:"none"}}>Phone</th>
                          <th className="minWidthCs" style={{display:"none"}}>Website</th>
                          <th className="minWidthCs" style={{display:"none"}}>Principal Name</th>
                          <th className="minWidthCs" style={{display:"none"}}>Yearly Enrolement Fees</th>
                          <th className="minWidthCs" style={{display:"none"}}>Languages</th>
                          <th className="minWidthCs" style={{display:"none"}}>School Jackets</th>
                          <th className="minWidthCs"></th>
                        </tr>
                     </thead>

                     <tbody id="tbody" className="tbodycs">
                     </tbody>
                  </table>
                </div>
                <div className="containerPagination" style={{width:"100%"}} align="center">
                  <br/>
                  <div className="pagination">
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
    )
  }
}

export default School;