import React from "react";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Label,
  FormGroup,
  Form,
  Input,
  FormText,
  Row,
  Col,
  Table
} from "reactstrap";

// core components
import PanelHeader from "components/PanelHeader/PanelHeader.js";
import Switch from "react-bootstrap-switch";
import profileImg from "assets/upload/profile.png";
import ImageUpload from "components/CustomUpload/ImageUpload.js";
import axios from "axios";
import {base_api_url} from "variables/general.js";
import $ from "jquery";

function getUrlAction() {
  let currentUrl;
  let baseUrl = base_api_url;
  currentUrl = baseUrl + "/api/v1/nc/getnotebyid";

  return currentUrl;
}

function getData() {

  const params = new Proxy(new URLSearchParams(window.location.search), {
    get: (searchParams, prop) => searchParams.get(prop),
  });

  let urlTarget = getUrlAction() + '?notesId=' + params.id; 
  return axios.get(urlTarget).then(res => {
    let datas = res.data.response;
    showData(datas);
  })
}

function showData(data) {
  $("#message").html(data.notes);
  $("#school").html(data.receiversname);
  $("#reason").html(data.reason);
  $("#subject").html(data.subject);
  $("#br_subject").html(data.subject);
  if (data.uploadedfile) {
    $("#ImageUpload").attr("src",data.uploadedfile);
  } else {
    $("#ImageUpload").hide();
  }

  if (data.formtype=="apf"||data.formtype=="tpf"||data.formtype=="photograpic") {

    if (data.formtype=="apf") {
      $(".viewform_link").attr("href",window.location.origin + "/admin/report/standard-report/activity-permission-form/" + data.fkformid);
    } else if (data.formtype=="tpf") {
      $(".viewform_link").attr("href",window.location.origin + "/admin/report/standard-report/travel-permission-form/" + data.fkformid);
    } else {
      $(".viewform_link").attr("href",window.location.origin + "/admin/report/standard-report/photograpic/" + data.fkformid);
    }
    $("#section_v_f").show();
  } else {
    $("#section_v_f").hide();
  }

  if (data.timestamp) {
    let rd = new Date(data.timestamp);

    rd = rd.toUTCString();
    $("#timestamp").html(rd);
  }
}

class NotificationDetail extends React.Component {

  componentDidMount(){
    getData()
  }

  render(){
    const currentDate = new Date();
    return (
    <>
      <PanelHeader size="sm"
        content={
          <div className="content">
            <div className="header text-left">
              <span className="panelHeaderDesc">View and manage your School Logs detail.</span>
              <nav aria-label="breadcrumb" role="navigation">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item"><a href="/coordinator/overview">Dashboard</a></li>
                  <li className="breadcrumb-item"><a href="/coordinator/logsschool">School Logs</a></li>
                  <li className="breadcrumb-item active" aria-current="page" id="br_subject"></li>
                </ol>
              </nav>
            </div>
          </div>
        }
      />
      <br/>
      <div className="content">
        <Row>
          <Col md="12">
            <Card>
              <CardHeader>
                <CardTitle tag="h4" className="text-header-notif" id="subject"></CardTitle>
                <h4 style={{"fontFamily": "Noto Sans","fontStyle": "normal","fontWeight": 400,"fontSize": "16px","lineHeight": "24px","color": "#70B2D8"}} id="timestamp">{currentDate.toUTCString()}</h4>
              </CardHeader>
              <CardBody>
                <Form action="/" className="form-horizontal" method="get">
                  <hr/>
                  <Row className="text-header-info-notif">
                    <Col sm="12">
                      <Row>
                        <Col md="2">
                          <FormGroup>
                            <label htmlFor="pwd">School : <span id="school"></span></label>
                          </FormGroup>
                        </Col>
                        <Col md="8">
                          <FormGroup>
                            <label htmlFor="pwd">Reason: <span id="reason"></span></label>
                          </FormGroup>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <hr/>
    
                  <Row>
                    <Col sm="12">
                      <Row className="text-desc-notif">
                        <Col md="12">
                          <FormGroup>
                            <p>RE: <span id="subject"></span></p>
                            <p id="message"></p>
                          </FormGroup>
                        </Col>
                      </Row>
                    </Col>
                  </Row>

                  <Row>
                    <Col sm="12">
                      <Row className="text-desc-notif">
                        <Col md="12">
                          <FormGroup>
                            <p><img width="200" height="200" id="ImageUpload"/></p>
                          </FormGroup>
                        </Col>
                      </Row>
                    </Col>
                  </Row>

                  <Row id="section_v_f">
                    <Col sm="12">
                      <div className="pull-left">
                      	<a style={{"background":"#416D86"}} className="btn viewform_link btn-csconfirm">View Form</a>
                      </div>
                    </Col>
                  </Row>
                </Form>
              </CardBody>
              <br/>
            </Card>
          </Col>
        </Row>
    
      </div>
    </>
  );
  }
}

export default NotificationDetail;
