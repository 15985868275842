import React from "react";

// reactstrap components
import { Row, Col } from "reactstrap";
import $ from "jquery";
import datepickerFactory from 'jquery-datepicker';

function TableFilter(props) {

  const showExport = (props) => {
    if (props.buttonExport=="yes") {
      return (
        <div style={{paddingLeft: "20px"}}>
          <div className="form-group pull-right" style={{marginTop: "6px"}}>
            <span style={{"marginRight":"10px"}}><i className="now-ui-icons arrows-1_share-66"></i> Export To</span>
            <button style={{width:"71px"}} className="btnExport btnExportCsv"><i className="now-ui-icons files_single-copy-04"></i> CSV</button>
            <button style={{width:"71px"}} className="btnExport btnExportPdf"><i className="now-ui-icons files_single-copy-04"></i> PDF</button>
          </div>
        </div>
      )
    }
  }

  if (typeof props.extraButton !=="undefined"){
    return (
      <>
        <Row>
          <div className="col-md-2">
            <div className="input-group" style={{"top":"9px"}}>
              <input readonly="true" className="form-control" style={{color:"black",height:"36px",width: "124px",border: "1px solid #B6C9D5"}} id="rowSelectedLabel" type="text"/>
            </div>
          </div>
          <div className="col-md-2">
            <div className="input-group" style={{"top":"9px"}}>
              <div className="input-group-prepend" style={{"height":"36px", borderTopLeftRadius: "20px", background: "#DAE8EF", borderBottomLeftRadius: "20px",border: "1px solid #B6C9D5",borderRight:"none"}}>
                <div className="input-group-text">
                  <i className="now-ui-icons cs-search"></i>
                </div>
              </div>
              <input style={{padding: "5px",width: "120px",height:"36px",border: "1px solid #B6C9D5",borderLeft:"none"}} id="dtSearchCs" type="text" className="form-control"/>
            </div>
          </div>
          <div className="col-md-2">
            <div className="input-group" style={{"top":"9px"}}>
              <span className="lblBeforeDate">From</span>&nbsp;&nbsp;
              <input style={{width: "102px",height:"36px"}} id="startDate" type="text" className="form-control"/>
              <div className="input-group-append" style={{height:"36px",borderTopRightRadius: "20px", borderBottomRightRadius: "20px"}}>
                <div className="input-group-text dateinputright">
                  <i className="now-ui-icons cs-calendar_black"></i>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-2">
            <div className="input-group" style={{"top":"9px"}}>
              <span className="lblBeforeDate">To</span>&nbsp;&nbsp;
              <input style={{width: "102px",height:"36px"}} id="endDate" type="text" className="form-control"/>
              <div className="input-group-append" style={{height:"36px", borderTopRightRadius: "20px", borderBottomRightRadius: "20px"}}>
                <div className="input-group-text dateinputright">
                  <i className="now-ui-icons cs-calendar_black"></i>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-2">
            <div className="input-group" style={{"top":"9px"}}>
              <button id="submitSearch" style={{"border":"none","width":"124px","height":"36px","background":"#F9B933","borderRadius":"30px","color":"white"}}>Search</button>
            </div>
          </div>
          <div className="col-md-2">
            <div className="form-group pull-right" style={{marginTop: "6px"}}>
              <button id={props.btnId} style={{"border":"none","width":"154px","height":"36px","background":"#416D86","borderRadius":"30px","color":"white"}}><i className="now-ui-icons ui-1_simple-add"></i> {props.btnTitle}</button>              
            </div>
          </div>
        </Row>
      </>
    );
  } else {
    return (
      <>
        <Row>
          <div className="col-md-2">
            <div className="input-group" style={{"top":"9px"}}>
              <input readonly="true" className="form-control" style={{color:"black",height:"36px",width: "124px",border: "1px solid #B6C9D5"}} id="rowSelectedLabel" type="text"/>
            </div>
          </div>
          <div className="col-md-2">
            <div className="input-group" style={{"top":"9px"}}>
              <div className="input-group-prepend" style={{"height":"36px", borderTopLeftRadius: "20px", background: "#DAE8EF", borderBottomLeftRadius: "20px",border: "1px solid #B6C9D5",borderRight:"none"}}>
                <div className="input-group-text">
                  <i className="now-ui-icons cs-search"></i>
                </div>
              </div>
              <input style={{padding: "5px",width: "120px",height:"36px",border: "1px solid #B6C9D5",borderLeft:"none"}} id="dtSearchCs" type="text" className="form-control"/>
            </div>
          </div>
          <div className="col-md-2">
            <div className="input-group" style={{"top":"9px"}}>
              <span className="lblBeforeDate">From</span>&nbsp;&nbsp;
              <input style={{width: "102px",height:"36px"}} id="startDate" type="text" className="form-control"/>
              <div className="input-group-append" style={{height:"36px",borderTopRightRadius: "20px", borderBottomRightRadius: "20px"}}>
                <div className="input-group-text dateinputright">
                  <i className="now-ui-icons cs-calendar_black"></i>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-2">
            <div className="input-group" style={{"top":"9px"}}>
              <span className="lblBeforeDate">To</span>&nbsp;&nbsp;
              <input style={{width: "102px",height:"36px"}} id="endDate" type="text" className="form-control"/>
              <div className="input-group-append" style={{height:"36px", borderTopRightRadius: "20px", borderBottomRightRadius: "20px"}}>
                <div className="input-group-text dateinputright">
                  <i className="now-ui-icons cs-calendar_black"></i>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-2">
            <div className="input-group" style={{"top":"9px"}}>
              <button id="submitSearch" style={{"border":"none","width":"124px","height":"36px","background":"#F9B933","borderRadius":"30px","color":"white"}}>Search</button>
            </div>
          </div>
          {showExport(props)}
        </Row>
      </>
    );
  }
}

$(document).on("change",".hasDatepicker",function(){
  $(this).val($(this).val().slice(0, -4));
});

export default TableFilter;
